/* import __COLOCATED_TEMPLATE__ from './select-ticket-type.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type TicketType from 'embercom/models/inbox/ticket-type';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import type Store from '@ember-data/store';
import { type MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import type IntercomAttribute from 'embercom/models/attribute';

export interface Args {
  mappingVisibility: MappingVisibility;
  integrationCode: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SelectTicketType extends Component<Signature> {
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare store: Store;

  // @ts-ignore
  @service declare attributeService: AttributeService;

  @tracked items: TicketType[] = [];
  @tracked selectedItemId: string | undefined = undefined;

  constructor(owner: any, args: Args) {
    super(owner, args);
    this.refreshTicketTypes();
  }

  @action refreshTicketTypes() {
    taskFor(this.loadTicketTypes).perform();
  }

  @dropTask *loadTicketTypes(): TaskGenerator<void> {
    let ticketCategory =
      this.mappingVisibility === 'private' ? TicketCategory.Task : TicketCategory.Request;

    let items = yield this.store.findAll('inbox/ticket-type');
    let filteredItems = items.filter(
      (item: TicketType) => item.category === ticketCategory && !item.archived,
    );

    let unmappableDataTypes = ['list', 'files'];

    let hasUnmappableRequiredAttributes = (ticketTypeId: string) => {
      let attributes: IntercomAttribute[] =
        this.attributeService.crmTicketAttributesByType[ticketTypeId] || [];

      return attributes
        .filter((attr) => attr.required)
        .some((attr) => unmappableDataTypes.includes(attr.type));
    };

    this.items = filteredItems.map((item: TicketType) => {
      let base = {
        text: item.name,
        value: item.id,
        component:
          'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/select-ticket-type-dropdown-option',
        componentAttrs: { emojiUri: item.emojiUri },
      };

      if (hasUnmappableRequiredAttributes(item.id)) {
        return {
          isDisabled: true,
          tooltipText: this.translation('unmappable-ticket-type'),
          ...base,
        };
      }
      return base;
    });

    let ticketTypeId = this.importConfigurationService.getTicketTypeId(this.mappingVisibility);
    if (ticketTypeId === null || ticketTypeId === undefined) {
      if (filteredItems.firstObject) {
        this.selectedItemId = filteredItems.firstObject.id;
        this.onSelectItem(filteredItems.firstObject.id);
      } else {
        this.selectedItemId = undefined;
      }
    } else {
      this.selectedItemId = ticketTypeId;
    }
  }

  get mappingVisibility() {
    return this.args.mappingVisibility;
  }

  get defaultLabel() {
    return this.translation('default');
  }

  @action onSelectItem(value: string) {
    this.selectedItemId = value;
    if (this.mappingVisibility === 'private') {
      this.importConfigurationService.setPrivateTicketTypeId(value);
    } else {
      this.importConfigurationService.setPublicTicketTypeId(value);
    }
  }

  translation = (path: string, options = {}) => this.intl.t(this.translationKey(path), options);
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.select-ticket-type.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Lib::SelectTicketType': typeof SelectTicketType;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/select-ticket-type': typeof SelectTicketType;
  }
}
