/* import __COLOCATED_TEMPLATE__ from './instructions-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';

export default class InstructionsComponent extends Component {
  @service intl;
  @service appService;
  @service realTimeEventService;

  @tracked confirmationCode;
  @tracked selectedDomain;
  @tracked confirmationLink;
  @tracked emailProviderOptions = [
    { value: 'google', label: this.intl.t('onboarding.home.steps.forward-emails.Google') },
    { value: 'microsoft', label: this.intl.t('onboarding.home.steps.forward-emails.Microsoft') },
    { value: 'other', label: this.intl.t('onboarding.home.steps.forward-emails.Other') },
  ];

  constructor() {
    super(...arguments);
    if (this.args.emailSet) {
      this.realTimeEventService.on(
        'ReceivedForwardEmailConfirmationLink',
        this,
        'fetchEmailForwardingConfirmationLink',
      );
    }
    this.selectedDomain = this.emailProviderOptions[0].value;
  }

  willDestroy() {
    super.willDestroy(...arguments);
    if (this.args.emailSet) {
      this.realTimeEventService.off(
        'ReceivedForwardEmailConfirmationLink',
        this,
        'fetchEmailForwardingConfirmationLink',
      );
    }
  }

  @action
  setSelectedDomain(value) {
    if (this.selectedDomain !== value) {
      this.selectedDomain = value;
    }
  }

  @action
  async fetchEmailForwardingConfirmationLink() {
    let request = {};
    request = {
      url: '/ember/forward_email_confirmation_code',
      type: 'GET',
      data: {
        app_id: this.appService.app.id,
      },
    };
    let link = await ajax(request);
    if (link) {
      this.confirmationLink = link['confirmation_code'];
    }
  }
}
