/* import __COLOCATED_TEMPLATE__ from './reply-type.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { replyTypeOptions, defaultReactions } from 'embercom/models/news/item';

export default class ReplyTypeSwitcher extends Component {
  get localizedContent() {
    return this.args.newsItem.localizedNewsItemContents.firstObject;
  }

  get replyType() {
    return this.localizedContent.replyType || replyTypeOptions.disabled;
  }

  get replyTypeOptions() {
    return replyTypeOptions;
  }

  @action
  updateReplyType(replyOption) {
    this.localizedContent.replyType = replyOption;

    if (replyOption === replyTypeOptions.reaction) {
      if (this.localizedContent.reactionSet.length === 0) {
        this.localizedContent.reactionSet = defaultReactions;
      }
    } else {
      this.localizedContent.reactionSet = [];
    }
  }
}
