/* import __COLOCATED_TEMPLATE__ from './preview-fin-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

export default class PreviewFinWrapper extends Component {
  @service notificationsService;
  @service intercomEventService;
  @service store;
  @service intl;

  @tracked showFinPreview = false;
  @tracked showLoadingIcon = true;
  @tracked finEvalRequest;
  @tracked showModal = false;
  @tracked selectedTab = 'sample_questions';

  constructor() {
    super(...arguments);
    this.getFinPreviewRequest.perform();
  }

  get fontAssetBold() {
    return assetUrl('/assets/fonts/TTNormsPro-Bold.woff2');
  }

  get fontAssetNormal() {
    return assetUrl('/assets/fonts/TTNormsPro-Regular.woff2');
  }

  get previewFinUrl() {
    return `${window.location.origin}/client/preview_your_fin/${this.args.token}`;
  }

  get sharableLink() {
    return `${window.location.origin}/a/preview-fin?token=${this.args.token}&source=previewfin`;
  }

  get inboxLocation() {
    return `${window.location.origin}/a/apps/_/inbox`;
  }

  get pagesImported() {
    return this.finEvalRequest?.pages_count || 0;
  }

  get helpcenterSources() {
    return (
      this.finEvalRequest?.metadata?.urls.map((hc_source) => ({
        ...hc_source,
        logo_src: this._getLogoSrcUrl(hc_source.logo),
      })) || ''
    );
  }

  get appId() {
    return this.finEvalRequest?.app_id || '';
  }

  get appName() {
    return (
      this.finEvalRequest?.metadata?.app_name ||
      this.intl.t('onboarding.preview-fin.workspace-fallback')
    );
  }

  get sampleQuestions() {
    let questions = this.finEvalRequest?.metadata?.sample_questions;
    if (questions?.length > 0) {
      return questions.slice(0, 3).map((text, ind) => ({
        imageLocation: assetUrl(
          `/assets/images/onboarding/fin-preview/question-card-person-${ind + 1}.png`,
        ),
        text,
      }));
    }
    return [];
  }

  get helpCenterURL() {
    return this.finEvalRequest?.help_center_url;
  }

  get hasSampleQuestionsAvailabe() {
    return this.finEvalRequest?.metadata?.sample_questions?.length > 0;
  }

  get sourceForAnalytics() {
    if (this.args.source === 'previewfin') {
      return 'link_from_shareablefinpreview';
    } else if (this.args.source === 'onboardinghome') {
      return 'link_from_onboardinghome';
    } else {
      return 'link_from_unknown_source';
    }
  }

  _getLogoSrcUrl(source) {
    if (!source) {
      return;
    }
    if (typeof source === 'string') {
      return source;
    }
    return source[0].formats[0].src;
  }

  triggerAnalyticsForCopy(_action) {
    this.intercomEventService.trackAnalyticsEvent({
      action: _action,
      section: 'shareable_fin_preview',
      object: 'shareable_fin_preview_link',
      token: this.args.token,
      app_id: this.appId,
      place: 'preview-fin',
    });
  }

  @action
  sendMessageToMessenger(message) {
    window.document
      .querySelector('#hosted-messenger-fin-demo-preview')
      .contentWindow.postMessage(JSON.stringify({ type: 'intercom-send-message', message }), '*');
  }

  @action
  toggleShowModal(toggleOption) {
    this.showModal = toggleOption;
  }

  @dropTask
  *getFinPreviewRequest() {
    try {
      this.finEvalRequest = yield ajax({
        url: `/fin_evaluation_request/fin_preview?token=${this.args.token}`,
        type: 'GET',
      });
      if (this.finEvalRequest.expired) {
        this.notificationsService.notifyError(
          this.intl.t('onboarding.preview-fin.error-loading-expired-message'),
        );
        console.error('shareable-fin-preview-token-expired');
        this.showLoadingIcon = false;
      } else {
        this.showFinPreview = true;
        this.showLoadingIcon = false;
        if (!this.hasSampleQuestionsAvailabe) {
          this.selectedTab = 'fin_helpcenters';
        }
        this.intercomEventService.trackAnalyticsEvent({
          action: 'viewed',
          object: this.sourceForAnalytics,
          place: 'shareable-fin-preview-page',
          section: 'shareable_fin_preview',
          token: this.args.token,
          app_id: this.appId,
        });
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('onboarding.preview-fin.error-loading-message'),
      );
      this.showLoadingIcon = false;
      if (e.jqXHR.status === 404) {
        console.error('shareable-fin-preview-token-invalid');
        this.intercomEventService.trackAnalyticsEvent({
          action: 'viewed',
          object: 'invalid_token_shareable_fin_preview',
          place: 'shareable-fin-preview-page',
          section: 'shareable_fin_preview_error',
          token: this.args.token,
          app_id: this.appId,
        });
      }
    }
  }
}
