/* import __COLOCATED_TEMPLATE__ from './checklist.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import Component from '@glimmer/component';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type GuideLibraryService from 'embercom/services/guide-library-service';

interface Args {
  closePopover: any;
}

interface Signature {
  Args: Args;
  Element: never;
}

const HUMAN_READABLE_WIZARD_NAMES = {
  evaluation_wizard_chatbot_v2: 'onboarding.guide-library.checklist.product-names.chatbot',
  evaluation_wizard_helpdesk_v2: 'onboarding.guide-library.checklist.product-names.helpdesk',
  evaluation_wizard_helpdesk_tickets: 'onboarding.guide-library.checklist.product-names.helpdesk',
  evaluation_wizard_proactive_support_v2:
    'onboarding.guide-library.checklist.product-names.proactive-support',
  evaluation_wizard_proactive_support_tickets:
    'onboarding.guide-library.checklist.product-names.proactive-support',
  guide_library_foundational_steps_v2:
    'onboarding.guide-library.checklist.product-names.foundational-steps',
  guide_library_foundational_steps_tickets_intervention:
    'onboarding.guide-library.checklist.product-names.foundational-steps',
};

export default class Checklist extends Component<Signature> {
  @service declare router: Router;
  @service declare intercomEventService: any;
  @service declare onboardingHomeService: any;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare guideLibraryService: GuideLibraryService;

  get steps() {
    return this.guideLibraryService.currentLevel.steps.filter(
      (step: any) => step.stepData?.isDisplayOnlyStep || step.stepData?.isFoundationalStep,
    );
  }

  get hasIncompleteSteps() {
    return this.steps.some((step: any) => step.available);
  }

  get hasAccessToIASettingsHub() {
    return this.appService.app.canSeeNewIASettingsHub;
  }

  get currentProductName() {
    return this.intl.t(
      HUMAN_READABLE_WIZARD_NAMES[
        this.guideLibraryService.currentLevel.identifier as keyof typeof HUMAN_READABLE_WIZARD_NAMES
      ],
    );
  }

  get philMessageIdentifier() {
    if (this.guideLibraryService.lastCompletedStep?.identifier === 'display_only_setup_fin_v2') {
      return 'onboarding.guide-library.checklist.phil-message-fin-ingestion-complete';
    }
    if (this.hasIncompleteSteps) {
      if (
        [
          'guide_library_foundational_steps_v2',
          'guide_library_foundational_steps_tickets_intervention',
          'guide_library_foundational_steps_v2_copilot',
          'guide_library_foundational_steps_tickets_intervention_copilot',
        ].includes(this.guideLibraryService.currentLevel.identifier)
      ) {
        return 'onboarding.guide-library.checklist.phil-message-foundational-steps';
      } else if (
        [
          'evaluation_wizard_proactive_support_v2',
          'evaluation_wizard_proactive_support_tickets',
        ].includes(this.guideLibraryService.currentLevel.identifier)
      ) {
        return `onboarding.guide-library.checklist.phil-message-proactive-support`;
      }
      return `onboarding.guide-library.checklist.phil-message`;
    }
    return `onboarding.guide-library.checklist.phil-message-complete`;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Checklist': typeof Checklist;
  }
}
