/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class NewsItemEditorComponent extends Component {
  @service store;
  @service contentEditorService;
  @service newsfeedSiteService;
  @service newsfeedsService;
  @service newsLabelsService;
  @service appService;
  @tracked newsfeeds = [];

  constructor() {
    super(...arguments);
    this.newsfeedSiteService.loadSite.perform();
    this.newsfeedsService.loadNewsfeeds.perform();
    this.newsLabelsService.loadLabels.perform();
  }

  get app() {
    return this.appService.app;
  }

  get newsItem() {
    return this.args.newsItem;
  }

  get site() {
    return this.newsfeedSiteService.site || {};
  }

  get showNewsCenterLink() {
    return this.assignedToNewsfeedWeb;
  }

  get assignedToNewsfeedWeb() {
    return this.newsfeedsService.assignedToNewsfeedWeb(this.newsItem);
  }

  get isLive() {
    return this.contentEditorService.ruleset.isLive;
  }

  get isPublished() {
    return this.isLive && this.assignedToNewsfeedWeb;
  }

  get publicUrl() {
    return `${this.site.url}/${this.newsItem.publicUrlPath}`;
  }
}
