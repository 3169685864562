/* import __COLOCATED_TEMPLATE__ from './tab.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Brand from 'embercom/models/brand';
import type CustomEmailAddress from 'embercom/models/custom-email-address';
import type SenderEmailAddressSettings from 'embercom/models/sender-email-address-settings';
import { tracked } from '@glimmer/tracking';

interface Args {
  senderEmailAddressSettings: SenderEmailAddressSettings[];
  senderEmailAddresses: CustomEmailAddress[];
  bounceSettings: BounceSettings[];
  dkimSettings: DkimSettings[];
  brands: Brand[];
  step: string | null;
  changeStep: (step: string | null) => void;
}

interface Signature {
  Args: Args;
}

export interface DkimSettings {
  domain: string;
  validRecordExists: boolean;
  validDmarcExists: boolean;
  domainForCustomerDnsRecord: string;
  domainForIntercomDnsRecord: string;
  hasVerifiedAdminEmails: boolean;
  verify: () => Promise<void>;
}

export interface BounceSettings {
  rootDomain: string;
  validRecordExists: boolean;
  value: string;
  host: string;
}

export interface DomainEmailAddress {
  id: number;
  email: string;
  verified: boolean;
  domainName: string;
  brandName: string;
  brandId: number;
  outboundStatus: string;
  forwardingEnabled: boolean;
  senderEmailAddresses: CustomEmailAddress[];
  senderEmailAddressSettings?: SenderEmailAddressSettings;
}

export interface Domain {
  name: string;
  isAuthenticated: boolean;
  bounceSettings: BounceSettings;
  dkimSettings: DkimSettings;
  addresses: DomainEmailAddress[];
}

export default class Tab extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;

  @tracked isEmailSetupSidebarOpen = false;
  @tracked email = 'placeholder@placeholder.com';

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.handleStepChange();
  }

  willUpdate() {
    this.handleStepChange();
  }

  handleStepChange() {
    if (this.step) {
      this.openEmailSetupSidebar();
    }
  }

  get step() {
    return this.args.step;
  }

  @action openEmailSetupSidebar() {
    this.isEmailSetupSidebarOpen = true;
  }

  @action closeEmailSetupSidebar() {
    this.isEmailSetupSidebarOpen = false;
    this.args.changeStep(null);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::Tab': typeof Tab;
  }
}
