/* import __COLOCATED_TEMPLATE__ from './dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Signature {
  Element: any;
  Args: Args;
}

interface Args {
  dropdown: any;
  onSelectItem: () => void;
  group: any;
  description: string;
  icon: any;
}

export default class DropdownItem extends Component<Signature> {
  @action
  selectItem() {
    this.args.dropdown.hideDropdownOverlay();
    this.args.onSelectItem();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::All::Learn::DropdownItem': typeof DropdownItem;
  }
}
