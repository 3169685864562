/* import __COLOCATED_TEMPLATE__ from './new-address-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type NotificationsService from 'embercom/services/notifications-service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import type {
  SenderEmailAddressSettings,
  SenderEmailAddressSettingsWireFormat,
} from './types/sender-email-address-settings';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { postRequest, type ResponseError } from 'embercom/lib/inbox/requests';
import { isValidEmail } from 'embercom/lib/email';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  onCreateNewAddress: (senderEmailAddressSettings: SenderEmailAddressSettings) => void;
}

interface Signature {
  Args: Args;
  Element: never;
}

interface SenderEmailAddressSettingsRequestParams {
  app_id: string;
  admin_id: string;
  email: string;
  brand_id?: number;
  sender_email_addresses: {
    name: string;
  }[];
}

export default class NewAddressModal extends Component<Signature> {
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare appService: any;
  @service declare intl: IntlService;

  @tracked email = '';
  @tracked senderName = '';

  @action
  handleClose() {
    this.args.onClose();
  }

  @dropTask
  *createTask(): TaskGenerator<void> {
    let params: SenderEmailAddressSettingsRequestParams = {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      sender_email_addresses: [{ name: this.senderName.trim() }],
      email: this.email,
    };

    try {
      let response = yield postRequest('/ember/sender_email_address_settings', params);
      let returnedResponse: SenderEmailAddressSettingsWireFormat =
        yield response.json() as SenderEmailAddressSettingsWireFormat;

      this.args.onCreateNewAddress({
        id: returnedResponse.id,
        email: returnedResponse.email,
      });
      this.handleClose();
    } catch (error) {
      console.error('createTask() error', error);
      this.notificationsService.notifyError(yield this.getErrorMessage(error));
      return;
    }
  }

  private async getErrorMessage(error: ResponseError) {
    let defaultMessage = this.intl.t(
      'new-settings.workspace.brands.new-address-modal.failed-to-create-address',
    );
    try {
      let response = await error.response.json();
      return response?.errors?.[0]?.message ?? defaultMessage;
    } catch {
      return defaultMessage;
    }
  }

  @action
  handleCreate() {
    taskFor(this.createTask).perform();
  }

  get isCreating() {
    return taskFor(this.createTask).isRunning;
  }

  get canCreate(): boolean {
    return Boolean(this.isReady && !this.isCreating);
  }

  get isReady() {
    return this.email && isValidEmail(this.email) && this.senderName.trim().length > 0;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Brands::NewAddressModal': typeof NewAddressModal;
  }
}
