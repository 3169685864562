/* import __COLOCATED_TEMPLATE__ from './content-types.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { objectIcons, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

const NUMBER_OF_CONTENT_TYPES_TO_DISPLAY = 2;
export default class ContentTypes extends Component {
  @service intl;
  @service outboundHomeService;

  get items() {
    let items = [];
    this.outboundHomeService.contentToDisplay.forEach((type) => {
      let objectName = objectNames[type];
      items.pushObject({
        text: this.intl.t(`outbound.content-type-component.items.${objectName}`),
        icon: objectIcons[type],
        value: objectName,
        isSelected: this.args.selectedObjectTypes?.includes(objectName),
      });
    });

    return [{ items }];
  }

  get selectedItemsToDisplay() {
    return this.args.selectedObjectTypes
      ?.slice(0, NUMBER_OF_CONTENT_TYPES_TO_DISPLAY)
      .map((objectName) => this.intl.t(`outbound.content-type-component.items.${objectName}`))
      .join(', ');
  }

  get countOtherItems() {
    let otherItems = this.args.selectedObjectTypes?.length - NUMBER_OF_CONTENT_TYPES_TO_DISPLAY;
    return otherItems > 0 ? otherItems : 0;
  }

  @action
  doSearch(objectNames) {
    this.args.filterContent('selectedObjectTypes', objectNames);
  }
}
