/* import __COLOCATED_TEMPLATE__ from './cda-input-row.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import Component from '@ember/component';
import { inject as service } from '@ember/service';
export default Component.extend({
  intl: service(),
  hasError: computed('erroredCdas', 'erroredCdas.length', 'name', function () {
    if (this.erroredCdas.length > 0) {
      return this.erroredCdas.find((cda) => {
        return cda === this.name;
      });
    }
  }),

  tagName: 'tr',

  name: '',
  description: '',
  type: '',

  get typeOptions() {
    return [
      { label: this.intl.t('onboarding.home.cda-input-row.labels.string'), value: 'String' },
      { label: this.intl.t('onboarding.home.cda-input-row.labels.number'), value: 'Number' },
      { label: this.intl.t('onboarding.home.cda-input-row.labels.boolean'), value: 'Boolean' },
      { label: this.intl.t('onboarding.home.cda-input-row.labels.date'), value: 'Date' },
    ];
  },
});
