/* import __COLOCATED_TEMPLATE__ from './border-radius-input.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class BorderRadiusInput extends Component {
  @tracked borderRadius;

  constructor() {
    super(...arguments);
    this.initialize();
  }

  checkMinBorderRadiusLimit() {
    if (this.borderRadius < 0) {
      this.borderRadius = 0;
    }
  }

  checkMaxBorderRadiusLimit() {
    if (this.borderRadius > 100) {
      this.borderRadius = 100;
    }
  }

  @action
  initialize() {
    if (!this.args.borderRadius) {
      this.borderRadius = 0;
    } else {
      this.borderRadius = this.args.borderRadius?.slice(0, -2);
    }
  }

  @action
  onChange() {
    this.checkMinBorderRadiusLimit();
    this.checkMaxBorderRadiusLimit();
    this.args.onChange(`${this.borderRadius}px`);
  }
}
