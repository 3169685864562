/* import __COLOCATED_TEMPLATE__ from './section-header.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import { computed } from '@ember/object';
import { and, not, equal } from '@ember/object/computed';

export default Component.extend({
  classNames: ['onboarding__home__guide-accordion__section-header'],
  attributeBindings: ['data-test-onboarding-accordion-section-header'],
  'data-test-onboarding-accordion-section-header': true,
  classNameBindings: [
    'stateClass',
    'paddingClass',
    'shouldFadeComponent:o__faded',
    'level:o__level',
  ],
  title: null,
  iconName: null,
  state: null,
  isOpen: false,

  level: null,
  step: null,

  paddingClass: ternary('step', 'u__pad__15', 'u__pad__20 u__padl__25'),
  stateClass: computed('state', function () {
    return `o__${this.state}`;
  }),
  isClosed: not('isOpen'),
  completed: equal('state', 'completed'),
  shouldFadeComponent: and('completed', 'isClosed'),
});
