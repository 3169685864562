/* import __COLOCATED_TEMPLATE__ from './templates-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';

interface Args {
  emailTemplateTitles: $TSFixMe;
  showSideDrawer: boolean;
  closeSideDrawer: () => void;
}

export default class TemplatesSideDrawer extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare paywallService: $TSFixMe;
  @service declare router: Router;
  @service declare intercomEventService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  async _transitionAfterPaywallCheck(destination: string) {
    try {
      await this.paywallService.paywall({ featureName: 'custom_email_templates' });
      this.router.transitionTo(destination);
    } catch {
      this.paywallService.handleError;
    }
  }

  @action
  createNewCustomEmailTemplate() {
    this.trackAnalyticsEvent('create_html_template');
    this._transitionAfterPaywallCheck('apps.app.settings.channels.email.new-template');
  }

  @action
  createNewVisualCustomEmailTemplate() {
    this.trackAnalyticsEvent('create_visual_template');
    this._transitionAfterPaywallCheck('apps.app.settings.channels.email.new-visual-template');
  }

  trackAnalyticsEvent(object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::TemplatesSideDrawer': typeof TemplatesSideDrawer;
  }
}
