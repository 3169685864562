/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import PricingMetric from 'embercom/lib/purchase/pricing-metric';
import type IntlService from 'embercom/services/intl';
import { capitalize } from '@ember/string';

interface Signature {
  Args: {
    contract: $TSFixMe;
  };
}

export default class Header extends Component<Signature> {
  @service declare appService: any;
  @service declare customerService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  get contract() {
    return this.args.contract;
  }

  get customer() {
    return this.customerService.customer;
  }

  get subscription() {
    return this.customer.subscription;
  }

  get hasMultiWorkspaces() {
    return this.subscription.numberOfSecondarySubscriptions > 0;
  }

  get isPrimaryWorkspace() {
    return this.subscription.isAnnualContractPrimarySubscription;
  }

  get totalNumberOfWorkspaces() {
    return (this.subscription.numberOfSecondarySubscriptions as number) + 1;
  }

  get usageLastUpdatedAt() {
    return this.contract.billingSnapshotLastUpdatedAt;
  }

  get nonSeatMetrics() {
    let nonSeatLimitList = [];
    for (let [key, value] of Object.entries(this.contract.contractUsageLimits)) {
      let pricingMetric = new PricingMetric({ pricing_metric: key }, this.intl);
      if (
        !pricingMetric.isSeatMetric &&
        pricingMetric.metric !== 'contracted_solution_seat_count' &&
        (value as number) > 0
      ) {
        nonSeatLimitList.push(capitalize(pricingMetric.pluralize(2)));
      }
    }
    return nonSeatLimitList.uniq();
  }

  get formattedNonSeatMetrics() {
    return this.intl.formatList(this.nonSeatMetrics, { style: 'long', type: 'conjunction' });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Billing::Summary::Header': typeof Header;
  }
}
