/* import __COLOCATED_TEMPLATE__ from './triggering-workflows.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { states } from 'embercom/models/data/matching-system/matching-constants';

export default class TriggeringWorkflows extends Component {
  @service appService;
  @tracked triggeringWorkflows = [];

  liveState = states.live;

  constructor() {
    super(...arguments);
    this.fetchTriggeringWorkflows.perform();
  }

  get hasTriggeringWorkflows() {
    return this.triggeringWorkflows.length > 0;
  }

  @task({ restartable: true })
  *fetchTriggeringWorkflows() {
    let object = this.appService.app.canUseReusableWorkflowContentDependency
      ? this.args.ruleset
      : this.args.customBot;
    this.triggeringWorkflows = yield object.loadDependentObjects();
  }
}
