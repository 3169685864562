/* import __COLOCATED_TEMPLATE__ from './sms-outbound-settings-form.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class SmsOutboundSettingsForm extends Component {
  @service notificationsService;
  @tracked smsOutboundSettings = this.args.smsOutboundSettings;

  get characterCount() {
    return this.smsOutboundSettings.companyInfoText
      ? this.smsOutboundSettings.companyInfoText.trim().length
      : 0;
  }

  @task
  *handleSubmit() {
    yield this.smsOutboundSettings
      .save()
      .then(() => {
        this.notificationsService.notifyConfirmation('SMS settings successfully saved.');
      })
      .catch(() => {
        this.notificationsService.notifyError(
          'There was a problem saving the sms settings, please try again. If the problem persists please contact us.',
        );
      });
  }
}
