/* import __COLOCATED_TEMPLATE__ from './install-a-recommended-app.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { readOnly } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  appService: service(),
  intl: service(),
  appstoreService: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),
  visibleAppRows: 1,
  appsPerRow: 4,
  completionCallback: null,

  showMoreAppsButtonLabel: computed(
    'intl.locale',
    'visibleAppRows',
    'recommendationRows.[]',
    function () {
      if (!this.recommendationRows.length || this.hideMoreAppsButton) {
        return;
      }
      return this.intl.t('onboarding.home.steps.install-a-recommended-app.show-more-apps', {
        nextAppCardRowLength: this.recommendationRows[this.visibleAppRows].length,
      });
    },
  ),

  showMoreAppsButtonvisible: computed('recommendationRows.[]', function () {
    return this.recommendationRows && this.recommendationRows.length > 1;
  }),

  hideMoreAppsButton: computed('visibleAppRows', 'recommendationRows.[]', function () {
    return !this.recommendationRows || this.recommendationRows.length === this.visibleAppRows;
  }),

  recommendationRows: computed('step.modelData.recommendations.[]', function () {
    if (this.step.modelData) {
      let sortedRecommendations = this._findAndBumpInstalledRecommendation(
        this.step.modelData.recommendations,
      );
      return this._splitRecommendations(sortedRecommendations);
    }

    return [];
  }),

  _findAndBumpInstalledRecommendation(recommendations) {
    let firstInstalledRecommendation = recommendations.find((r) => r.isInstalled);
    if (!firstInstalledRecommendation) {
      return recommendations;
    }
    let firstInstalledIndex = recommendations.indexOf(firstInstalledRecommendation);
    return [firstInstalledRecommendation].concat(
      recommendations.slice(0, firstInstalledIndex),
      recommendations.slice(firstInstalledIndex + 1),
    );
  },

  _splitRecommendations(recommendations) {
    let rows = [];
    for (let i = 0; i < recommendations.length; i += this.appsPerRow) {
      rows.push(recommendations.slice(i, i + this.appsPerRow));
    }
    return rows;
  },

  _dispatchAnalyticEvent({ action, object }) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.step.analyticsMetadata,
      section: 'inline_step',
      action,
      object,
    });
  },

  showMoreApps: action(function () {
    this.set('visibleAppRows', this.visibleAppRows + 1);
    this._dispatchAnalyticEvent({
      action: 'clicked',
      object: 'show_more_apps',
    });
  }),

  openModal: action(function (modalAppPackage) {
    this.onSelectAppPackage(modalAppPackage);
    this._dispatchAnalyticEvent({
      action: 'clicked',
      object: modalAppPackage,
    });
  }),

  closeModal: action(function (modalAppPackage) {
    if (modalAppPackage.isInstalled && !this.step.completed) {
      this.completionCallback();
    }
    this.onCloseAppPackageModal();
  }),

  actions: {
    markStepComplete() {
      this.completionCallback();
      this._dispatchAnalyticEvent({
        action: 'clicked',
        object: 'skip_install_app',
      });
    },
  },
});
