/* import __COLOCATED_TEMPLATE__ from './trigger-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { TRIGGER_CONTEXT_TYPES } from 'embercom/lib/operator/custom-bots/constants';
import type CustomBotConfig from 'embercom/objects/operator/configuration/custom-bot';

interface Args {
  triggers: (CustomBotConfig & { onSelect: () => void })[];
  shouldShowPaywallBadges: boolean;
  onClose: () => void;
  createWorkflow: () => void;
  transitionBack: () => void;
  recordAnalyticsEvent: (actionLabel: string) => void;
}

export default class TriggerSelector extends Component<Args> {
  get triggerContextGroups() {
    return TRIGGER_CONTEXT_TYPES.map((contextType) => ({
      contextType,
      triggers: this.args.triggers.filter((trigger) => trigger.triggerContextType === contextType),
    })).filter((group) => group.triggers.length > 0);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::TriggerSelector': typeof TriggerSelector;
  }
}
