/* import __COLOCATED_TEMPLATE__ from './free-trial-button.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type FinTrialService from 'embercom/services/fin-trial-service';
import type IntlService from 'ember-intl/services/intl';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Element: HTMLDivElement;
}

export default class FreeTrialButton extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare finTrialService: FinTrialService;
  @service declare intl: IntlService;

  @tracked showModal = false;

  get showButton() {
    if (this.finTrialService.trialExpired) {
      return this.finTrialService.isNonPricing5SalesforceContracted;
    }
    return this.finTrialService.isTrialCustomer;
  }

  get buttonLabel() {
    if (this.finTrialService.freeTrialNotStarted) {
      return this.intl.t('operator.fin.free-trial-button.not-started');
    } else if (this.finTrialService.freeTrialStarted) {
      return this.intl.t('operator.fin.free-trial-button.started', {
        remainingDays: this.finTrialService.trialDaysRemaining,
      });
    } else {
      return this.intl.t('operator.fin.free-trial-button.expired');
    }
  }

  get modalHeader() {
    if (this.finTrialService.freeTrialNotStarted) {
      return this.intl.t('operator.fin.free-trial-button.modal.heading.not-started');
    } else if (this.finTrialService.freeTrialStarted) {
      return this.intl.t('operator.fin.free-trial-button.modal.heading.started', {
        remainingDays: this.finTrialService.trialDaysRemaining,
      });
    } else {
      return this.intl.t('operator.fin.free-trial-button.modal.heading.expired');
    }
  }

  @action
  openModal() {
    this.showModal = true;
  }

  @action
  closeModal() {
    this.showModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::FreeTrialButton': typeof FreeTrialButton;
  }
}
