/* import __COLOCATED_TEMPLATE__ from './branching-qualification-answer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import { isEmpty } from '@ember/utils';
import { action } from '@ember/object';
import { reads } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
export default Component.extend({
  notificationsService: service(),
  tagName: '',
  isEditing: reads('answer.justAdded'),
  displayValidationErrors: false,

  init() {
    this._super(...arguments);
    this.set('answerActions', [
      {
        items: [
          {
            text: 'Edit reply button',
            icon: 'edit',
            onSelectItem: () => this.enableEditing(),
          },
          {
            text: 'Delete reply button',
            icon: 'delete',
            onSelectItem: () => this.removeBranchingAnswer(),
          },
        ],
      },
    ]);
  },

  enableEditing() {
    this.set('isEditing', true);
    this.onBeginEditing();
  },

  removeBranchingAnswer() {
    this.onRemoveAnswer(this.answer);
  },

  completeEditing: action(function () {
    let answer = this.answer;
    if (isEmpty(answer.get('label'))) {
      if (answer.get('justAdded')) {
        this.removeBranchingAnswer();
      } else {
        this.set('displayValidationErrors', true);
        this.notificationsService.notifyError("Lead category can't be blank.");
      }
      return;
    }
    this.set('displayValidationErrors', false);
    this.set('isEditing', false);
    this.set('justAdded', false);
    this.onCompleteEditing(answer);
  }),

  cancelEditing: action(function () {
    let answer = this.answer;
    answer.rollbackAttributes();
    if (isEmpty(answer.get('label'))) {
      return this.onRemoveAnswer(answer);
    }
    this.set('isEditing', false);
  }),
});
