/* import __COLOCATED_TEMPLATE__ from './invite-tech-person-card-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { post } from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import { isValidEmail } from 'embercom/lib/email';
import { isEmpty } from '@ember/utils';

const EMAIL_SEPARATORS = [',', '|', ';', ' ', '\n', '\t'];
const EMAIL_SPLIT_REGEX = new RegExp(`[${EMAIL_SEPARATORS.join('\\')}]+`);

export default class InviteTechPersonCardComponent extends Component {
  @service appService;
  @service router;
  @service notificationsService;
  @service intercomEventService;
  @service intl;
  @tracked newEmail = '';
  @tracked validEmails = 0;
  @tracked inviteEmails = [];

  get app() {
    return this.appService.app;
  }

  addEmails(value) {
    let existingEmails = new Set(this.inviteEmails.map((obj) => obj.value));
    let adminEmails = new Set(this.appService.app.admins.map((admin) => admin.email));
    this.extractEmails(value).forEach((email) => {
      let invalidReason = this.invalidTooltipMessage({
        isExistingEmail: existingEmails.has(email),
        isAdminEmail: adminEmails.has(email),
        isInvalidEmail: !isValidEmail(email),
      });

      let isInvalid = invalidReason !== null;
      this.inviteEmails.pushObject({
        value: email,
        isInvalid,
        invalidTooltipContent: invalidReason,
      });
      if (!isInvalid) {
        this.validEmails += 1;
      }
      existingEmails.add(email);
    });
  }

  get isSubmitDisabled() {
    return !(this.validEmails > 0 || isValidEmail(this.newEmail));
  }

  get showHint() {
    return this.inviteEmails.length > 0 || this.newEmail.trim();
  }

  @action
  handleKeydown(event) {
    if (event.key === 'Enter' || EMAIL_SEPARATORS.includes(event.key)) {
      this.addNewlyEnteredEmail();
      event.preventDefault();
      return;
    }
    if (event.key === 'Backspace' && this.inviteEmails.length > 0 && isEmpty(this.newEmail)) {
      this.inviteEmails.popObject();
      event.preventDefault();
      return;
    }
  }

  @action
  handlePaste(event) {
    let value = (event.clipboardData || window.clipboardData).getData('text');
    this.addEmails(value);
    event.preventDefault();
  }

  @action
  removeEmail(obj) {
    this.inviteEmails.removeObject(obj);
    if (!obj.isInvalid) {
      this.validEmails -= 1;
    }
  }

  addNewlyEnteredEmail() {
    this.addEmails(this.newEmail);
    this.newEmail = '';
  }

  extractEmails(value) {
    return value
      .trim()
      .split(EMAIL_SPLIT_REGEX)
      .map((email) => email.trim())
      .map((email) => {
        if (this.surroundedWithBrackets(email)) {
          return email.slice(1, email.length - 1);
        } else {
          return email;
        }
      })
      .filter((email) => email !== '');
  }

  surroundedWithBrackets(email) {
    if (email[0] === '<' && email[email.length - 1] === '>') {
      return true;
    }
    return false;
  }

  invalidTooltipMessage(params) {
    if (params.isInvalidEmail) {
      return this.intl.t('settings.teammates.invites.invite.invalid-email-format');
    } else if (params.isExistingEmail) {
      return this.intl.t('settings.teammates.invites.invite.existing-email');
    } else if (params.isAdminEmail) {
      return this.intl.t('settings.teammates.invites.invite.existing-teammate-email');
    }
    return null;
  }

  // eslint-disable-next-line require-yield
  @dropTask *submitInviteTechPersonData() {
    try {
      this.addNewlyEnteredEmail();
      let emails = this.inviteEmails.filter((obj) => !obj.isInvalid).map((obj) => obj.value);
      let params = {
        app_id: this.app.id,
        step: this.args.step,
        emails,
      };
      yield post('/ember/onboarding/home/setup_mail/send_setup_mail', params);
      if (this.args.trackEvent) {
        this.intercomEventService.trackAnalyticsEvent({
          ...this.args.trackEvent,
          section: 'invite-tech-person-card',
          action: 'sent',
        });
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'onboarding.home.get-ready-to-chat-with-your-customers.invite-tech-person-card-component.send-success',
          {
            email: emails.join(', '),
          },
        ),
      );
      this.inviteEmails = [];
    } catch (error) {
      if (error.jqXHR?.responseJSON.errors.email) {
        this.notificationsService.notifyError(
          this.intl.t(
            'onboarding.home.get-ready-to-chat-with-your-customers.invite-tech-person-card-component.invalid-email',
          ),
        );
      } else if (error.jqXHR?.responseJSON.errors.invite_limit_reached) {
        this.notificationsService.notifyError(
          this.intl.t(
            'onboarding.home.get-ready-to-chat-with-your-customers.invite-tech-person-card-component.invite-limit-reached',
          ),
        );
      } else if (error.jqXHR?.responseJSON.errors.invite_already_sent) {
        this.notificationsService.notifyError(
          this.intl.t(
            'onboarding.home.get-ready-to-chat-with-your-customers.invite-tech-person-card-component.invite-already-sent',
          ),
        );
      } else if (error.jqXHR?.responseJSON.errors.permission) {
        this.notificationsService.notifyError(
          this.intl.t(
            'onboarding.home.get-ready-to-chat-with-your-customers.invite-tech-person-card-component.invalid-permissions',
          ),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t(
            'onboarding.home.get-ready-to-chat-with-your-customers.invite-tech-person-card-component.unable-to-save-data',
          ),
        );
      }
    }
  }
}
