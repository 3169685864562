/* import __COLOCATED_TEMPLATE__ from './section-footer.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface SectionFooterArgs {
  paywall?: any;
  helpArticleLink?: string;
  sectionId: string;
  addAction?: () => void;
  manageAction?: () => void;
}

const SectionFooter = templateOnlyComponent<SectionFooterArgs>();
export default SectionFooter;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::ContentSources::SectionFooter': typeof SectionFooter;
  }
}
