/* import __COLOCATED_TEMPLATE__ from './title-cell.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { outboundCustomReportTests } from 'embercom/models/data/outbound/constants';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import ContentDependency from 'embercom/models/matching-system/content-dependency';
import { NO_TRIGGER_TARGET } from 'embercom/lib/operator/custom-bots/constants';

export default class TitleCell extends Component {
  @service appService;
  @service lowEngagementContentService;
  @service store;

  liveState = states.live;

  get app() {
    return this.appService.app;
  }

  get outboundCustomReportTests() {
    return outboundCustomReportTests;
  }

  get showTest() {
    if (this.args.wrapper.hasTestsWithNoControlGroup) {
      return outboundCustomReportTests.testA;
    }

    return outboundCustomReportTests.noTest;
  }

  get showLowEngagementTooltip() {
    return this.lowEngagementContentService.isLowEngagementContent(this.args.wrapper);
  }

  get isBackgroundWorkflow() {
    return this.args.wrapper.contents.firstObject?.contentData?.parallel === true;
  }

  get isWorkflowWithFin() {
    return this.args.wrapper.contents.firstObject?.contentData?.has_fin_step === true;
  }

  get isAvailableFromInbox() {
    return this.allowManualTrigger && this.isWorkflowLive;
  }

  get isWorkflowLive() {
    return this.args.wrapper.contents.firstObject?.state === states.live;
  }

  get allowManualTrigger() {
    let contentData = this.args.wrapper.contents.firstObject?.contentData;
    if (!contentData) {
      return false;
    }

    return this.isReusableWorkflow && contentData.allow_manual_trigger === true;
  }

  get isReusableWorkflow() {
    return this.args.sortableGroupName === NO_TRIGGER_TARGET;
  }

  get selectedTriggerPredicates() {
    if (
      !this.args.triggerPredicates ||
      !this.isLogicalTriggerPredicate(this.args.triggerPredicates.type)
    ) {
      return [];
    }

    if (!this.args.wrapper.contents.firstObject?.contentData?.trigger_predicates?.length) {
      // default select the first option if there is no selected option
      return [this.args.triggerPredicates.options.firstObject];
    }

    let selectedPredicates =
      this.args.wrapper.contents.firstObject.contentData.trigger_predicates.firstObject.predicates?.map(
        (predicate) => predicate.value,
      );

    if (!selectedPredicates) {
      return [this.args.triggerPredicates.options.firstObject];
    }

    return this.args.triggerPredicates.options.filter((option) =>
      selectedPredicates.includes(option.value),
    );
  }

  isLogicalTriggerPredicate(type) {
    return ['or', 'and'].includes(type);
  }

  get dependentWorkflows() {
    if (this.app.canUseReusableWorkflowContentDependency) {
      let dependencies = this.args.wrapper.contents.firstObject?.contentData?.dependent_workflows;
      if (!dependencies?.length) {
        return [];
      }

      return dependencies.map((dependency) => {
        return new ContentDependency(dependency);
      });
    } else {
      let dependencies = this.args.wrapper.contents.firstObject?.contentData?.triggering_bots;
      if (!dependencies?.length) {
        return [];
      }

      return dependencies.map((dependency) => {
        return new ContentDependency({
          entity_id: dependency.id,
          entity_type: objectTypes.triggerableCustomBot,
          title: dependency.title,
          ruleset_id: dependency.ruleset_id,
          state: dependency.live ? states.live : states.draft,
        });
      });
    }
  }

  get inboundTriggerDescription() {
    let inboundTriggerUUID =
      this.args.wrapper.contents?.firstObject?.contentData?.trigger_predicates?.firstObject
        ?.predicates?.firstObject?.value;
    let other = this.store
      .peekAll('workflow-connector/inbound-trigger')
      .findBy('uuid', inboundTriggerUUID);
    let translationKey = null;
    if (other?.name === 'Jira Issue Updated') {
      translationKey =
        'operator.custom-bot.trigger.webhook-received.jira-issue-updated-description';
    } else if (other?.name === 'Jira Comment Created') {
      translationKey =
        'operator.custom-bot.trigger.webhook-received.jira-issue-status-updated-description';
    }

    return translationKey;
  }
}
