/* import __COLOCATED_TEMPLATE__ from './hosted-messenger.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { timeout } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';

export default class HostedMessenger extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @service realTimeEventService;

  @tracked messengerReady = false;
  @tracked messengerOpen = false;
  @tracked conversationId = false;

  get appIdCode() {
    return this.appService.app.id;
  }

  get iframeUrl() {
    return `${window.location.origin}/hosted_messenger/${this.appIdCode}`;
  }

  get hostedMessengerIframe() {
    return window.frames['hosted-messenger-sandbox'];
  }

  get hostedMessengerWindow() {
    return this.hostedMessengerIframe.contentWindow;
  }

  get visitorId() {
    return this.hostedMessengerWindow.Intercom?.('getVisitorId');
  }

  get messengerClosed() {
    return !this.messengerOpen;
  }

  get waitingForMessage() {
    return this.pollForBooted.isRunning;
  }

  get isLoading() {
    return this.messengerReady && this.waitingForMessage;
  }

  get isTesting() {
    return ENV.environment === 'test';
  }

  @dropTask
  *pollForBooted() {
    if (this.visitorId) {
      this.messengerReady = true;
      return;
    }
    yield timeout(200);
    this.pollForBooted.perform();
  }

  @action
  handleHostedMessengerWindowMessage(event) {
    switch (event.data.key) {
      case 'hosted-messenger-opened':
      case 'hosted-messenger-closed':
        this.syncMessengerOpenState(event);
        break;
      default:
        console.info('Unhandled message', event);
    }
  }

  @action
  syncMessengerOpenState(event) {
    if (event.data.key === 'hosted-messenger-opened') {
      this.messengerReady = true;
      this.messengerOpen = true;
    } else if (event.data.key === 'hosted-messenger-closed') {
      this.messengerOpen = false;
    }
  }

  @action
  listenForThreadsCreated() {
    this.realTimeEventService.on('ThreadUpdated', this, async (event) => {
      await this.checkForHostedMessengerCreatedConversation.perform(event.conversationId);
    });
    // We may not need this if we only use manual messages instead of user initiated
    // messages to start conversations.
    this.realTimeEventService.on('ThreadAssigned', this, (event) => {
      this.checkForHostedMessengerCreatedConversation.perform(event.conversationId);
    });
    this.realTimeEventService.on('ThreadReopened', this, (event) => {
      this.checkForHostedMessengerCreatedConversation.perform(event.conversationId);
    });
  }

  @dropTask
  *checkForHostedMessengerCreatedConversation(conversationId) {
    if (this.conversationId) {
      return;
    }

    let conversationRecords = yield this.store.query(
      'onboarding/hosted-messenger-created-conversation',
      { conversation_id: conversationId },
    );
    let conversationRecord = conversationRecords.get('firstObject');
    if (conversationRecord) {
      this.conversationId = conversationId;

      this._trackAnalyticsEvent({
        action: 'clicked',
        object: 'send_message',
      });

      this.notifyOpener(conversationId);
      yield conversationRecord.claim();
    }
  }

  notifyOpener(conversationId) {
    if (!window.opener) {
      return;
    }
    window.opener.postMessage(
      { key: 'hosted-messenger-conversation-started', conversationId },
      window.location.origin,
    );
  }

  @action
  _trackAnalyticsEvent(metadata) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.step?.analyticsMetadata,
      ...metadata,
    });
  }

  @action
  closeWindow() {
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'close_button',
    });
    window.close();
  }
}
