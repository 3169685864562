/* import __COLOCATED_TEMPLATE__ from './fallback-option-pass-to-team.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import Component from '@glimmer/component';

interface Signature {
  Element: never;
  Args: {
    disableContinue: boolean;
    continueCallback: () => void;
    triggerSave: () => void;
  };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class FallbackOptionPassToTeam extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::AiBotWizard::EwChatbotFinFallbackComponents::FallbackOptionPassToTeam': typeof FallbackOptionPassToTeam;
  }
}
