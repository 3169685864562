/* import __COLOCATED_TEMPLATE__ from './office-hours-tabbed.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { type TabItem } from '../common/standard-base';
import { inject as service } from '@ember/service';
import type ApplicationInstance from '@ember/application/instance';
import { tracked } from '@glimmer/tracking';
import ajax from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  schedules: any;
  settings: any;
  changeTab: (tab: string) => void;
  tab: string;
}

interface Signature {
  Args: Args;
  Element: never;
}
export default class OfficeHoursTabbed extends Component<Signature> {
  @service declare appService: any;
  @service declare intercomEventService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare store: any;
  @service declare intl: $TSFixMe;

  @tracked showModal = false;
  @tracked scheduleToUpdate: any = null;
  @tracked scheduleToDelete: any = null;
  @tracked dependentObjects: any[] = [];
  @tracked showConfirmScheduleDelete = false;
  @tracked schedules = [];
  @tracked defaultSchedule: any = null;
  @tracked customSchedules = [];
  @tracked hasCustomSchedules = false;

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    if (!args.tab) {
      args.changeTab('general');
    }
    this.initializeSchedules();
    this.intl.maybeLoadMessengerTranslations();
  }

  @action
  initializeSchedules() {
    this.schedules = this.schedulesModel.filter((schedule: any) => !schedule.isNew);
    this.defaultSchedule = this.schedules.find((schedule: any) => schedule.isDefault);
    this.customSchedules = this.schedules.filter((schedule: any) => !schedule.isDefault);
    this.hasCustomSchedules = this.customSchedules.length > 0;
  }

  get schedulesModel() {
    return this.args.schedules;
  }

  get app() {
    return this.appService.app;
  }

  get tabs(): Array<TabItem> {
    return [
      {
        label: 'new-settings.workspace.office-hours.tabs.general',
        value: 'general',
      },
      {
        label: 'new-settings.workspace.office-hours.tabs.custom-office-hours',
        value: 'custom-office-hours',
      },
    ];
  }

  get showCustomOfficeHoursEmptyState() {
    return !this.customSchedules || this.customSchedules.length === 0;
  }

  @action
  openSettingsModal(scheduleData: any) {
    this.scheduleToUpdate = this.schedulesModel.find(
      (schedule: any) => schedule.name === scheduleData.name,
    );
    this.showModal = true;
  }

  dependentObjectsForSchedule(scheduleToDelete: any): any[] {
    return ajax({
      url: '/ember/office_hours_schedules/dependent_objects',
      type: 'GET',
      data: {
        app_id: this.app.id,
        id: scheduleToDelete.id,
      },
    });
  }

  @dropTask
  *attemptDeleteSchedule(scheduleData: any) {
    let scheduleToDelete = this.schedulesModel.find(
      (schedule: any) => schedule.name === scheduleData.name,
    );
    let objects: any[] = yield this.dependentObjectsForSchedule(scheduleToDelete);
    this.scheduleToDelete = scheduleData;
    this.dependentObjects = objects;
    if (objects.length > 0) {
      this.showConfirmScheduleDelete = true;
    } else {
      yield taskFor(this.deleteSchedule).perform();
    }
    this.refreshModel();
  }

  @dropTask
  *deleteSchedule() {
    let scheduleToDelete = this.schedulesModel.find(
      (schedule: any) => schedule.name === this.scheduleToDelete.name,
    );
    yield scheduleToDelete.destroyRecord();
    this.scheduleToDelete = null;
    this.refreshModel();
  }

  @action
  changeTab(newTab: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'navigated',
      object: 'office_hours_tab',
      place: 'settings.office_hours',
      section: newTab,
    });
    this.args.changeTab(newTab);
  }

  @action
  showOfficeHourArticle() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'help_content_link',
      place: 'settings.office_hours',
      section: 'general_office_hours_banner',
    });
    if (this.selectedTab === 'general') {
      window.Intercom('showArticle', 732390); // https://www.intercom.com/help/en/articles/732390-set-your-default-office-hours
    } else if (this.selectedTab === 'custom-office-hours') {
      window.Intercom('showArticle', 3305941); // https://www.intercom.com/help/en/articles/3305941-Define-office-hours-for-each-team-inbox
    }
  }

  @action
  createCustomOfficeHours(fromHeader = false) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'custom_office_hours_button',
      place: 'settings.office_hours',
      section: fromHeader ? 'header.custom_office_hours' : 'discovery_banner.custom_office_hours',
    });
    this.scheduleToUpdate = this.store.createRecord('office-hours-schedule');
    this.showModal = true;
  }

  @action
  async updateDefaultScheduleIntervals(intervals: any[]) {
    try {
      this.defaultSchedule.timeIntervals = intervals;
      await this.defaultSchedule.save();
      this.initializeSchedules();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.workspace.office-hours.notification.general-office-hours.success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.workspace.office-hours.notification.general-office-hours.error'),
      );
      throw error;
    }
  }

  @action
  refreshModel() {
    this.store.findAll('office-hours-schedule', this.app?.id);
    this.store.findRecord('messenger-settings/availability', this.app?.id);
    this.initializeSchedules();
  }

  @action
  closeModal() {
    this.showModal = false;
    this.scheduleToDelete = null;
    this.showConfirmScheduleDelete = false;
    if (this.scheduleToUpdate) {
      this.scheduleToUpdate.rollbackAttributes?.();
      this.scheduleToUpdate = null;
    }
  }

  @action
  async saveAvailabilitySetting(params: any) {
    try {
      if (params.reportingExcludeOooHours !== undefined) {
        this.args.settings.reportingExcludeOooHours = params.reportingExcludeOooHours;
      }
      if (params.customResponseDelayValue !== undefined) {
        this.args.settings.customResponseDelayValue = params.customResponseDelayValue;
      }
      if (params.customResponseDelayEnabled !== undefined) {
        this.args.settings.customResponseDelayEnabled = params.customResponseDelayEnabled;
      }
      if (params.customResponseDelay !== undefined) {
        this.args.settings.customResponseDelay = params.customResponseDelay;
      }

      await this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.workspace.office-hours.notification.general-office-hours.success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.workspace.office-hours.notification.general-office-hours.error'),
      );
      throw error;
    }
  }

  get selectedTab() {
    return this.args.tab;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::OfficeHoursTabbed': typeof OfficeHoursTabbed;
  }
}
