/* import __COLOCATED_TEMPLATE__ from './ticket-states.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { TicketSystemState } from 'embercom/objects/inbox/conversation';
import type TicketCustomState from 'embercom/objects/inbox/ticket-custom-state';
import { ticketSystemStatesList } from 'embercom/objects/inbox/ticket-custom-state';
import { tracked } from '@glimmer/tracking';
import type TicketStateService from 'embercom/services/ticket-state-service';
import type IntlService from 'ember-intl/services/intl';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import type AppSettings from 'embercom/objects/inbox/app-settings';
import { put } from 'embercom/lib/ajax';
import type Session from 'embercom/services/session';

type TabView = 'live' | 'archived';
interface Args {
  systemStatesOptions: any;
  appSettings: AppSettings;
}

export default class TicketStates extends Component<Args> {
  @service store: any;
  @service declare appService: any;
  @service declare intl: IntlService;
  @service declare ticketStateService: TicketStateService;
  @service declare notificationsService: any;
  @service declare session: Session;

  @tracked currentTab: TabView = 'live';

  @tracked ticketStateFiltering = this.args.appSettings.ticketStateFiltering;

  get app() {
    return this.appService.app;
  }
  get currentTabLabel() {
    return this.currentTab === 'live'
      ? this.intl.t('settings.ticket-data.list-ticket-live-tab')
      : this.intl.t('settings.ticket-data.list-ticket-archived-tab');
  }

  get archivedCustomStatesCount() {
    return this.ticketStateService.archivedTicketCustomStates.length;
  }
  get ticketSystemStates(): TicketSystemState[] {
    return ticketSystemStatesList();
  }

  @action switchTab(tab: TabView) {
    this.currentTab = tab;
  }

  get systemStates() {
    return [
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.submitted',
        ),
        systemState: TicketSystemState.Submitted,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.Submitted),
      },
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.in_progress',
        ),
        systemState: TicketSystemState.InProgress,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.InProgress),
      },
      {
        title: this.intl.t(
          'settings.ticket-data.ticket-type-configuration.system-states.waiting_on_customer',
        ),
        systemState: TicketSystemState.WaitingOnCustomer,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.WaitingOnCustomer),
      },
      {
        title: this.intl.t('settings.ticket-data.ticket-type-configuration.system-states.resolved'),
        systemState: TicketSystemState.Resolved,
        ...this.ticketStateService.getIconForSystemState(TicketSystemState.Resolved),
      },
    ];
  }
  @action onRestoreTicketCustomState(ticketCustomState: TicketCustomState) {
    taskFor(this.unarchiveTicketCustomState).perform(ticketCustomState);
  }
  @dropTask
  *unarchiveTicketCustomState(ticketCustomState: TicketCustomState) {
    try {
      yield this.ticketStateService.unarchiveTicketCustomState(ticketCustomState.id);
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-states.unarchive.successfully-unarchived', {
          ticketCustomState: ticketCustomState.adminLabel,
        }),
      );
      if (this.archivedCustomStatesCount === 1) {
        this.currentTab = 'live';
      }
      this.ticketStateService.reloadTicketCustomStates();
    } catch (error) {
      if (error.jqXHR?.responseJSON?.message) {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.message);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.ticket-states.unarchive.unknown-error', {
            ticketCustomState: ticketCustomState.adminLabel,
          }),
        );
      }
    }
  }

  @action
  async toggleTicketStateFiltering() {
    let previousTicketStateFiltering = this.ticketStateFiltering;
    this.ticketStateFiltering = !this.ticketStateFiltering;

    try {
      await put(`/ember/inbox/app_settings/update_ticket_state_filtering`, {
        app_id: this.session.workspace.id,
        ticket_state_filtering: this.ticketStateFiltering,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.ticket-data.enable-ticket-filtering.settings-saved'),
      );
    } catch (error) {
      this.ticketStateFiltering = previousTicketStateFiltering;
      this.notificationsService.notifyError(
        this.intl.t('settings.ticket-data.enable-ticket-filtering.saving-settings-failed'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tickets::TicketStates': typeof TicketStates;
  }
}
