/* import __COLOCATED_TEMPLATE__ from './switch.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  isOn: boolean;
  isDisabled: boolean;
  onClick: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const Switch = templateOnlyComponent<Signature>();
export default Switch;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::SectionsAccordion::Switch': typeof Switch;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections-accordion/switch': typeof Switch;
  }
}
