/* import __COLOCATED_TEMPLATE__ from './keep-messenger-secure.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import {
  INSTALL_TYPE,
  LOGGED_IN_CODE_DROPDOWN_ITEMS,
  THIRD_PARTY_DROPDOWN_ITEMS,
} from 'embercom/lib/messenger/installation-constants';
import { action } from '@ember/object';
import moment from 'moment-timezone';

export default class KeepMessengerSecure extends Component {
  @service appService;
  @service store;
  @service intercomEventService;

  @tracked selectedInstallMethod = null;
  @tracked selectedInstallType = this.defaultInstallType;
  @tracked reminder = null;
  @tracked selectedDate;
  @tracked disableDatesBefore = moment();
  @tracked showSkipModal = false;

  constructor() {
    super(...arguments);
    if (!this.app.api_secret) {
      // this will only work if the teammate has permissions, otherwise it will set the secret to null
      this.app.fetchAPISecret.perform();
    }
    this.fetchReminderData();
  }

  _startOfNextHour() {
    return this.todayInAppTimezone.add(1, 'hour').startOf('hour');
  }

  get todayInAppTimezone() {
    return moment.tz(this.appService.app.timezone);
  }

  get app() {
    return this.appService.app;
  }

  get baseAnalyticsMetadata() {
    return {
      ...this.args.step.analyticsMetadata,
      installType: this.selectedInstallMethod,
    };
  }

  get dropdownItems() {
    return this.selectedInstallMethod === INSTALL_TYPE.THIRD_PARTY
      ? THIRD_PARTY_DROPDOWN_ITEMS
      : LOGGED_IN_CODE_DROPDOWN_ITEMS;
  }

  get defaultInstallType() {
    return this.dropdownItems.firstObject.value;
  }

  get installationComponentPath() {
    return `guide/start-screen/tasks/identity-verification/${this.selectedInstallType}-component`;
  }

  get enableRemindMeButton() {
    return this.selectedDate !== this.reminder?.send_at;
  }

  @action setSelectedInstallType(installType) {
    this.selectedInstallType = installType;
  }

  @action setSelectedInstallMethod(installMethod) {
    this.selectedInstallMethod = installMethod;
  }

  @action setWithCode() {
    this.setSelectedInstallMethod(INSTALL_TYPE.CODE);
    if (this.selectedInstallType !== this.defaultInstallType) {
      this.setSelectedInstallType(this.defaultInstallType);
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'with_code',
      place: 'idv_onboarding',
    });
  }

  @action setWithThirdParty() {
    this.setSelectedInstallMethod(INSTALL_TYPE.THIRD_PARTY);
    if (this.selectedInstallType !== this.defaultInstallType) {
      this.setSelectedInstallType(this.defaultInstallType);
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'with_third_party_app',
      place: 'idv_onboarding',
    });
  }

  @action openSkipModal() {
    this.showSkipModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'mark_as_completed',
      place: 'idv_onboarding',
    });
  }

  @action closeSkipModal() {
    this.showSkipModal = false;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'cancel_idv_skip_modal',
      place: 'idv_onboarding',
    });
  }

  @action markCompleted() {
    this.args.completionCallback();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'confirm_idv_skip_modal',
      place: 'idv_onboarding',
    });
    this.showSkipModal = false;
  }

  @action async fetchReminderData() {
    try {
      this.reminder = await this.store.queryRecord(
        'onboarding/home/identity_verification_reminder',
        {},
      );
      this.selectedDate = this.reminder?.send_at || this._startOfNextHour().add(1, 'day').toDate();
    } catch (error) {
      console.error('Failed to fetch reminders:', error);
    }
  }

  @action onDateChange(date) {
    this.selectedDate = date;
  }

  @action async createOrUpdateReminder() {
    if (this.reminder) {
      this.reminder.set('send_at', this.selectedDate);
      await this.reminder.save();
      // this makes no sense why i have to do this,
      // but it triggers a re-compute of enableRemindMeButton
      // to make sure the button gets disabled
      this.selectedDate = this.reminder.send_at;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'update_reminder',
        place: 'idv_onboarding',
      });
    } else {
      this.reminder = this.store.createRecord('onboarding/home/identity_verification_reminder', {
        send_at: this.selectedDate,
      });
      await this.reminder.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_reminder',
        place: 'idv_onboarding',
      });
    }
  }
}
