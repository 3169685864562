/* import __COLOCATED_TEMPLATE__ from './confirm-schedule-delete.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { groupBy } from 'embercom/helpers/group-by';

export default class ConfirmScheduleDelete extends Component {
  get hasDependentRules() {
    return this.args.dependentObjects.reduce((acc, val) => acc || val.label === 'Rule', false);
  }

  get groupedDependentObjects() {
    return groupBy(this.args.dependentObjects, (item) => item.label);
  }
}
