/* import __COLOCATED_TEMPLATE__ from './template-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import {
  channels,
  useCases,
  getStarted,
  objectives,
  useCaseNames,
  channelNames,
  objectiveNames,
  contentObjectNames,
  analyticEventNames,
  outboundSupportedTemplateObjectTypes,
  operatorSupportedTemplateObjectTypes,
  seriesSupportedTemplateObjectTypes,
  contentObjectFeatures,
} from 'embercom/models/data/outbound/template-constants';
import { inject as service } from '@ember/service';

const OPERATOR_ROUTE = 'apps.app.automation';
const GET_STARTED_TEMPLATES = ['30', '17', '48', '38', '28', '974'];
const MESSENGER = [
  objectTypes.post,
  objectTypes.chat,
  objectTypes.checklist,
  objectTypes.newsItem,
  objectTypes.customBot,
];
const WEBSITE = [
  objectTypes.survey,
  objectTypes.banner,
  objectTypes.tour,
  objectTypes.tooltipGroup,
];
const MOBILE = [objectTypes.push, objectTypes.carousel, objectTypes.sms];

const NON_MESSAGE_BASED_CONTENT_TYPES = [
  objectTypes.tour,
  objectTypes.checklist,
  objectTypes.tooltipGroup,
  objectTypes.customBot,
  objectTypes.survey,
  objectTypes.series,
  objectTypes.buttonCustomBot,
  objectTypes.inboundCustomBot,
  objectTypes.triggerableCustomBot,
];

export default class TemplateSelector extends Component {
  @service router;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get createNewMessageTypeKey() {
    if (this.args.selectedContentType !== undefined) {
      return `outbound.new-content-modal.template-selector.create-new-item.${
        objectNames[this.args.selectedContentType]
      }`;
    }

    return 'outbound.new-content-modal.template-selector.message';
  }

  get isMessageBased() {
    return !NON_MESSAGE_BASED_CONTENT_TYPES.includes(this.args.selectedContentType);
  }

  get objectives() {
    return objectives;
  }

  get contentTypes() {
    if (this.args.restrictToObjectType) {
      return [this.args.restrictToObjectType];
    }

    if (this.isSeries) {
      return seriesSupportedTemplateObjectTypes;
    }

    if (this.isInOperator) {
      return operatorSupportedTemplateObjectTypes;
    }

    let contentTypes = outboundSupportedTemplateObjectTypes;
    contentTypes = contentTypes.filter(
      (objectType) =>
        objectType !== objectTypes.legacyMessageEmail && this._canUseObjectType(objectType),
    );

    return contentTypes;
  }

  get humanReadableContentObjectNames() {
    return contentObjectNames;
  }

  get humanReadableObjectiveName() {
    return objectiveNames;
  }

  get humanReadableUseCaseNames() {
    return useCaseNames;
  }

  get humanReadableChannelNames() {
    return channelNames;
  }

  get isInOperator() {
    // This wil satisfy the old custom bot view and the new merged cb view
    // TODO: This can be changed to the merged cb view once released to all
    return this.router.currentRouteName?.includes(OPERATOR_ROUTE);
  }

  get isSeries() {
    return this.args.selectedContentType === objectTypes.series;
  }

  get isInOutboundAllRoute() {
    return this.args.isInOutboundAll;
  }

  get objectiveIsSelected() {
    return Object.values(this.objectives).includes(this.args.selectedObjective);
  }

  get channelIsSelected() {
    return Object.values(channels).includes(this.args.selectedObjective);
  }

  get getStartedIsSelected() {
    return this.args.selectedObjective === this.getStartedPage.objective;
  }

  get shouldShowStartFromScratchSelector() {
    return !this.objectiveIsSelected || !this.args.isInOutboundAll;
  }

  get availableObjectivesFromFilteredTemplates() {
    let availableObjectives = {};
    this.filterByContentType(this.args.templates).forEach((template) => {
      if (availableObjectives[template.objective]) {
        return;
      }

      Object.keys(this.objectives).forEach((key) => {
        if (this.objectives[key] === template.objective) {
          availableObjectives = { ...availableObjectives, [key]: template.objective };
        }
      });
    });
    return { all: objectives.all, ...availableObjectives };
  }

  get isExistingAdmin() {
    //To Do Logic
    return true;
  }

  get getStartedPage() {
    return { objective: getStarted, templates: GET_STARTED_TEMPLATES };
  }

  get objectTypesForChannels() {
    let channel = analyticEventNames[this.args.selectedObjective];
    return this.availableChannels[channel].objectTypes;
  }

  get availableUseCases() {
    return {
      customerSupport: useCases.customerSupport,
      onboarding: useCases.onboarding,
      transactional: useCases.transactional,
      customerEngagement: useCases.customerEngagement,
    };
  }

  get availableChannels() {
    return {
      messenger: { objective: channels.messenger, objectTypes: MESSENGER },
      website: { objective: channels.website, objectTypes: WEBSITE },
      mobile: { objective: channels.mobile, objectTypes: MOBILE },
    };
  }

  get templates() {
    let templates = this.args.templates || [];
    templates = this.filterBySupportedObjectTypes(templates);
    templates = this.filterByObjective(templates);
    templates = this.filterByContentType(templates);
    templates = this.filterByContentTypeForChannels(templates);
    templates = this.filterTemplatesForGetStarted(templates);
    return templates;
  }

  get showTemplateSection() {
    if (this.args.selectedContentType === objectTypes.whatsapp) {
      return isPresent(this.templates);
    }
    // We want to hide the templates section if it is empty and a custom bot is selected
    return !(this.templates.length === 0 && this.args.selectedCustomBotTarget);
  }

  filterBySupportedObjectTypes(templates) {
    return templates.filter((template) => this.contentTypes.includes(template.objectType));
  }

  filterByContentType(templates) {
    if (templates && this.contentTypes.includes(this.args.selectedContentType)) {
      return templates.filter((template) => template.objectType === this.args.selectedContentType);
    }

    return templates || [];
  }

  filterByContentTypeForChannels(templates) {
    if (this.channelIsSelected) {
      let templatesForChannel = [];
      this.objectTypesForChannels.forEach((type) => {
        let templateForContentType = this.args.templates.filter(
          (template) => template.objectType === type,
        );

        templatesForChannel = [...templatesForChannel, ...templateForContentType];
      });
      return templatesForChannel;
    }

    return templates || [];
  }

  filterTemplatesForGetStarted(templates) {
    if (this.getStartedIsSelected) {
      return this.args.templates.filter((template) => GET_STARTED_TEMPLATES.includes(template.id));
    }

    return templates || [];
  }

  filterByObjective(templates) {
    if (this.objectiveIsSelected && this.args.selectedObjective !== objectives.all) {
      return templates.filter((template) => template.objective === this.args.selectedObjective);
    }

    return templates;
  }

  _canUseObjectType(objectType) {
    if (!contentObjectFeatures.hasOwnProperty(objectType)) {
      return true;
    }

    return contentObjectFeatures[objectType].any((feature) =>
      this.appService.app.canUseFeature(feature),
    );
  }
}
