/* import __COLOCATED_TEMPLATE__ from './newsfeeds-title-cell.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class NewsfeedsTitleCell extends Component {
  @service store;
  @service newsFeedService;

  @action
  async openModal() {
    await this.newsFeedService.openModal(this.args.wrapper.contentWrapperId);
  }

  @action
  async deleteNewsfeed() {
    await this.newsFeedService.openDeleteNewsfeedConfirmModal(this.args.wrapper.contentWrapperId);
  }
}
