/* import __COLOCATED_TEMPLATE__ from './remind-dismiss-cta.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { post } from 'embercom/lib/ajax';
import { tracked } from 'tracked-built-ins';
import {
  FLOATING_WIDGET_DISMISSAL_KEY,
  FLOATING_WIDGET_REMINDER_LOCALSTORAGE_KEY,
} from 'embercom/objects/onboarding/checklist/step';
interface Args {
  hideChecklist: () => void;
  appId: string;
}

export default class RemindDismissCta extends Component<Args> {
  @tracked reminderTimeline: string[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    let storedReminderTimeline = localStorage.getItem(FLOATING_WIDGET_REMINDER_LOCALSTORAGE_KEY);
    if (storedReminderTimeline) {
      this.reminderTimeline = JSON.parse(storedReminderTimeline) as string[];
    }
  }

  get showDismissButton() {
    return this.reminderTimeline.length >= 3;
  }

  @action
  onRemindLater() {
    this.reminderTimeline.push(Date.now().toString());
    localStorage.setItem(
      FLOATING_WIDGET_REMINDER_LOCALSTORAGE_KEY,
      JSON.stringify(this.reminderTimeline),
    );
    this.args.hideChecklist();
  }

  @action
  onDismissed() {
    taskFor(this.dismissWidget).perform();
  }

  @dropTask
  *dismissWidget() {
    let params = {
      page_key: FLOATING_WIDGET_DISMISSAL_KEY,
    };
    try {
      this.args.hideChecklist();
      yield post(`/ember/admin_pageviews?app_id=${this.args.appId}`, params);
    } catch (e) {
      console.error('Failed to save dismissal', e);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::FloatingWidget::Checklist::RemindDismissCta': typeof RemindDismissCta;
    'onboarding/floating-widget/checklist/remind-dismiss-cta': typeof RemindDismissCta;
  }
}
