/* import __COLOCATED_TEMPLATE__ from './time-interval-row.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import TimePeriod from 'embercom/models/settings/time-period';
import TimesByHalfHour from 'embercom/models/data/times-by-half-hour';
import { DAY_IN_MINUTES } from 'embercom/components/matching-system/matching-timetable-editor';
import TimePeriods from 'embercom/models/data/time-periods';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  store: service(),
  intl: service(),
  officeHoursLabelService: service(),

  availablePeriods: computed(
    'row.period',
    'intl.locale',
    'row.timeIntervals.@each.{startMinute,endMinute}',
    function () {
      let translatedTimePeriods = TimePeriods.map((period) => ({
        text: this.intl.t(period.textTranslationKey),
        value: period.value,
      }));

      if (!translatedTimePeriods.map((period) => period.value).includes(this.row.period)) {
        let periods = translatedTimePeriods.slice();
        periods.push({
          text: this.officeHoursLabelService.getTranslatedLabel(this.row.period),
          value: this.row.period,
        });
        return periods;
      }

      return translatedTimePeriods;
    },
  ),

  availableStartTimes: TimesByHalfHour.slice(0, TimesByHalfHour.length - 1),

  availableEndTimes: computed('row.startMinute', function () {
    return TimesByHalfHour.filter(({ value }) => value > this.row.startMinute);
  }),

  periodSelectionChanged: action(function (value) {
    let periodValues = TimePeriod.periodValueMapping[value];
    let newIntervals = [];
    for (let day = periodValues.startDay; day <= periodValues.endDay; day++) {
      let addition = DAY_IN_MINUTES * day;
      let interval = this.store.createFragment('matching-system/timetable/time-interval', {
        startMinute: this.row.startMinute + addition,
        endMinute: this.row.endMinute + addition,
      });
      newIntervals.pushObject(interval);
    }
    this.set('row.timeIntervals', newIntervals);
    this.onRowEdit();
  }),

  updateStartTimeSelection: action(function (value) {
    this.set('row.startMinute', value);
    if (this.row.endMinute <= value) {
      this.set('row.endMinute', undefined);
    }
    this.row.timeIntervals.forEach((interval) => {
      interval.set('startMinute', value + interval.startMinuteOffset);
    });
    this.onRowEdit();
  }),

  updateEndTimeSelection: action(function (value) {
    this.set('row.endMinute', value);
    this.row.timeIntervals.forEach((interval) => {
      interval.set('endMinute', value + interval.endMinuteOffset);
    });
    this.onRowEdit();
  }),
});
