/* import __COLOCATED_TEMPLATE__ from './conversation-extraction.hbs'; */
/* RESPONSIBLE TEAM: team-conversational-knowledge */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ContentImportService from 'embercom/services/content-import-service';
import { tracked } from '@glimmer/tracking';
import { type PulseAccordion } from 'glint/pulse';
import type IntlService from 'ember-intl/services/intl';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import { action } from '@ember/object';
import { CONVERSATIONAL_KNOWLEDGE_FEEDBACK_SURVEY_ID } from 'embercom/lib/content-service/constants';

interface Args {
  accordion: PulseAccordion;
}

export default class ConversationExtraction extends Component<Args> {
  @service declare contentImportService: ContentImportService;
  @service declare intl: IntlService;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked showConversationSettingsModal = false;
  @tracked showDisableFeatureModal = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.contentImportService.fetchConversationExtractionSettings();
  }

  get isEnabled() {
    return this.contentImportService.conversationExtractionSettings?.enabled;
  }

  get dropdownOptions() {
    return [
      {
        items: [
          {
            text: this.intl.t(
              'operator.fin.setup.content-sources.conversation-content.conversation-extraction-experiment.manage-dropdown-options.open-settings',
            ),
            icon: 'settings',
            onSelectItem: () => this.openConversationSettingsModal(),
          },
          {
            text: this.intl.t(
              'operator.fin.setup.content-sources.conversation-content.conversation-extraction-experiment.manage-dropdown-options.go-to-content',
            ),
            icon: 'new-window',
            onSelectItem: () => this.goToConversationalContent(),
          },
          {
            text: this.intl.t(
              'operator.fin.setup.content-sources.conversation-content.conversation-extraction-experiment.manage-dropdown-options.give-feedback',
            ),
            icon: 'survey-filled',
            onSelectItem: () => this.openFeedbackSurvey(),
          },
          {
            text: this.intl.t(
              'operator.fin.setup.content-sources.conversation-content.conversation-extraction-experiment.manage-dropdown-options.disable-feature',
            ),
            icon: 'trash',
            isDestructive: true,
            onSelectItem: () => this.openDisableFeatureModal(),
          },
        ],
      },
    ];
  }

  @action goToConversationalContent() {
    let folder_id = this.contentImportService.conversationExtractionSettings?.folderID;
    if (!folder_id) {
      return;
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'folder',
      folder_id,
    });
    this.knowledgeHubService.goToFolder(folder_id);
  }

  @action openConversationSettingsModal() {
    this.showConversationSettingsModal = true;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      object: 'conversation_extraction_settings',
      enabled: this.isEnabled,
    });
  }

  @action openFeedbackSurvey() {
    window.Intercom('startSurvey', CONVERSATIONAL_KNOWLEDGE_FEEDBACK_SURVEY_ID);
  }

  @action openDisableFeatureModal() {
    this.showDisableFeatureModal = true;
  }

  @action async disableFeature() {
    if (!this.contentImportService.conversationExtractionSettings) {
      return;
    }

    await this.knowledgeHubService.ensurePermissions();

    try {
      this.contentImportService.conversationExtractionSettings.enabled = false;
      await this.contentImportService.conversationExtractionSettings.save();
      this.intercomEventService.trackAnalyticsEvent({
        action: 'disabled',
        object: 'conversation_extraction_settings',
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.update-notifications.success',
        ),
      );
      this.showDisableFeatureModal = false;
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t(
          'knowledge-hub.conversational.conversation-extraction-settings.update-notifications.failure',
        ),
      });
    }
  }

  @action closeSettingsModal() {
    this.contentImportService.conversationExtractionSettings?.rollbackAttributes();
    this.showConversationSettingsModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::ContentSources::ConversationExtraction': typeof ConversationExtraction;
  }
}
