/* import __COLOCATED_TEMPLATE__ from './create-short-article.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { action } from '@ember/object';

export default Component.extend({
  router: service(),
  appService: service(),
  onboardingHomeExternalStepService: service(),
  intercomEventService: service(),

  step: null,

  app: readOnly('appService.app'),

  trackClickedAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.step.analyticsMetadata,
      action: 'clicked',
      object: 'go_to_articles',
      section: 'inline_step',
    });
  },

  async _ensureArticlesExist() {
    await this.onboardingHomeExternalStepService.generateExampleDataForStep(this.step.identifier);
  },

  transitionToTour: action(async function () {
    this.trackClickedAnalyticsEvent();

    await this._ensureArticlesExist();

    this.router.transitionTo('apps.app.articles.articles', {
      queryParams: {
        external_step: 'create_short_article',
        shouldShowStepBanner: true,
      },
    });
  }),
});
