/* import __COLOCATED_TEMPLATE__ from './domain-records.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
import { type BounceSettings, type DkimSettings } from './tab';

interface Args {
  dkimSettings: DkimSettings;
  bounceSettings: BounceSettings;
  isDomainAuthenticated: boolean;
}

export default class DomainRecords extends Component<Args> {
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @tracked isVerifying = false;
  @tracked showVerificationError = false;

  get domainSettingRecords() {
    let rows = [
      {
        name: this.args.dkimSettings?.domainForCustomerDnsRecord,
        type: this.intl.t('new-settings.channels.email.addresses.domain-sidebar.cname'),
        value: `${this.args.dkimSettings?.domainForIntercomDnsRecord}.`,
        status: this.args.dkimSettings?.validRecordExists,
        id: 'dkim-cname',
      },
      {
        name: this.args.bounceSettings?.host,
        type: this.intl.t('new-settings.channels.email.addresses.domain-sidebar.cname'),
        value: `${this.args.bounceSettings?.value}.`,
        status: this.args.bounceSettings?.validRecordExists,
        id: 'bounce-settings-cname',
      },
      {
        name: `_dmarc.${this.args.dkimSettings?.domain}`,
        type: this.intl.t('new-settings.channels.email.addresses.domain-sidebar.txt'),
        value: 'v=DMARC1; p=none',
        status: this.args.dkimSettings?.validDmarcExists,
        id: 'dmarc-txt',
      },
    ];
    return rows;
  }

  @action
  async verifyDomain() {
    try {
      this.isVerifying = true;
      await this.args.dkimSettings.verify();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.channels.email.addresses.domain-sidebar.domain-verification-success',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t(
          'new-settings.channels.email.addresses.domain-sidebar.domain-verification-failed',
        ),
      );
    } finally {
      this.isVerifying = false;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Addresses::DomainRecords': typeof DomainRecords;
  }
}
