/* import __COLOCATED_TEMPLATE__ from './option-path-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import NoTriggerCustomBotWorkflowsResource from 'embercom/lib/operator/custom-bots/no-trigger-custom-bot-workflows-resource';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { useResource } from 'ember-resources';

const HANDOVER_TO_ANOTHER_BOT_SELECTION = 'handover_to_another_bot';

export default class OptionPathSelector extends Component {
  @service intl;

  @tracked showNoTriggerBotSelector = false;
  @tracked workflowsResource = useResource(this, NoTriggerCustomBotWorkflowsResource, () => ({
    currentBotId: this.args.publicAPI.contentObject.id,
  }));

  get availablePathsDropdownItems() {
    let pathsList = [];
    if (this.args.publicAPI.contentObject?.isCustomBot) {
      pathsList.push({
        items: [
          {
            text: this.intl.t(
              'operator.custom-bot.editor.option-path-selector.handover-to-another-bot',
            ),
            icon: 'bot-filled',
            value: HANDOVER_TO_ANOTHER_BOT_SELECTION,
          },
        ],
      });
    }
    if (this.args.availablePaths.length > 0) {
      let pathSection = {
        heading: this.intl.t('operator.custom-bot.editor.option-path-selector.path-heading'),
        items: this.args.availablePaths
          .filter((path) => !path.isStartOverPath)
          .map((path) => ({
            text: path.titleWithLabel,
            value: path,
            icon: path.endStateStep?.type === 'trigger_workflow' ? 'bot-filled' : null,
          })),
      };
      pathsList.push(pathSection);
    }
    return pathsList;
  }

  @action
  selectPath(path) {
    if (path === HANDOVER_TO_ANOTHER_BOT_SELECTION) {
      this.showNoTriggerBotSelector = true;
    } else {
      this.args.publicAPI.actions.linkPathToControl(this.args.control, path);
    }
  }

  @action
  onBotSelectorClose() {
    this.showNoTriggerBotSelector = false;
  }

  @action
  onBotSelectorSelectItem(workflow) {
    this.args.publicAPI.actions.addPathWithTriggerWorkflowAndLinkToControl(
      workflow.workflowId,
      workflow.title,
      this.args.control,
    );
    this.showNoTriggerBotSelector = false;
  }
}
