/* import __COLOCATED_TEMPLATE__ from './set-fin-live.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  setFinLive: () => void;
  canSetFinLive: boolean;
}

interface Signature {
  Args: Args;
}

const SetFinLive = templateOnlyComponent<Signature>();
export default SetFinLive;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::ConfigureStarterPlan::SetFinLive': typeof SetFinLive;
  }
}
