/* import __COLOCATED_TEMPLATE__ from './newsfeed-web-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class NewsfeedWebToggle extends Component {
  @service newsfeedsService;
  @service intl;

  get newsfeeds() {
    return this.newsfeedsService.newsfeeds;
  }

  get newsfeedWeb() {
    return this.newsfeeds.findBy('platform', 'web') || {};
  }

  get selectedNewsfeedIds() {
    return this.args.newsItem.newsfeedIds || [];
  }

  get assignedToNewsfeedWeb() {
    return this.selectedNewsfeedIds.includes(Number(this.newsfeedWeb.id));
  }

  @action
  onToggle() {
    if (this.assignedToNewsfeedWeb) {
      this.args.newsItem.newsfeedIds = this.selectedNewsfeedIds.filter(
        (id) => id !== Number(this.newsfeedWeb.id),
      );
    } else {
      this.args.newsItem.newsfeedIds = [...this.selectedNewsfeedIds, Number(this.newsfeedWeb.id)];
    }
  }
}
