/* import __COLOCATED_TEMPLATE__ from './auto-responders.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { enqueueTask } from 'ember-concurrency-decorators';
import type { TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  settings: $TSFixMe;
}

export default class AutoResponders extends Component<Args> {
  @service declare store: Store;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @enqueueTask
  *updateSetting(): TaskGenerator<void> {
    try {
      yield this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.args.settings.toggleProperty('allowAutoResponders');
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  toggleAutoResponders() {
    this.args.settings.toggleProperty('allowAutoResponders');
    taskFor(this.updateSetting).perform();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced::AutoResponders': typeof AutoResponders;
  }
}
