/* import __COLOCATED_TEMPLATE__ from './qualification-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { computed } from '@ember/object';
import { readOnly, mapBy } from '@ember/object/computed';
import Component from '@ember/component';
import { inject } from '@ember/service';
import { task } from 'ember-concurrency';

export default Component.extend({
  attributeService: inject(),
  appService: inject(),
  store: inject(),
  allowNoAttributes: true,

  init() {
    this._super(...arguments);
    this.fetchQualificationAttributes.perform();
  },

  app: readOnly('appService.app'),
  currentQualificationAttributeIdentifiers: mapBy('attributesInUse', 'identifier'),

  fetchQualificationAttributes: task(function* () {
    let qualificationAttributes = yield this.store.findAll('people/qualification-attribute');
    this.set('qualificationAttributes', qualificationAttributes);
  }).drop(),

  allowedRuleConditionalAttributes: computed(
    'currentQualificationAttributeIdentifiers',
    function () {
      let selectedIdentifiers = this.currentQualificationAttributeIdentifiers;
      let attributeService = this.attributeService;

      let selectableAttributeIdentifiers = selectedIdentifiers
        .compact()
        .concat([
          'email',
          'phone',
          'geoip_data.country_name',
          'geoip_data.region_name',
          'geoip_data.city_name',
        ])
        .uniq();

      selectableAttributeIdentifiers.push('owner_id');

      return [
        {
          attributes: attributeService.findByIdentifiers(selectableAttributeIdentifiers),
        },
      ];
    },
  ),

  allowedRuleActions: computed(
    'app.hasMarketoEnrichmentIntegration',
    'app.hasSalesforceIntegration',
    'app.canUseBranchingQualification',
    'app.hasHubSpotIntegration',
    'app.canUsePriorityRules',
    function () {
      let actions = ['assign-conversation', 'tag-user', 'add-tag-to-conversation'];

      if (this.get('app.hasSalesforceIntegration')) {
        actions.push('send-to-salesforce');
      }

      if (this.get('app.hasHubSpotIntegration')) {
        actions.push('send-to-hubspot');
      }

      if (this.get('app.hasMarketoEnrichmentIntegration')) {
        actions.push('send-to-marketo');
        actions.push('trigger-marketo-campaign');
      }

      if (this.get('app.canUsePriorityRules')) {
        actions.push('change-conversation-priority');
      }

      if (this.get('app.canUseBranchingQualification')) {
        actions.push('send-app-with-introduction');
        actions.pushObjects(['send-follow-up-reply', 'close-conversation-with']);
      }

      return actions;
    },
  ),
});
