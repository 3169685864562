/* import __COLOCATED_TEMPLATE__ from './general-conversation-attachments.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type AttachmentSettings from 'embercom/models/attachment-settings';

interface Args {
  attachmentSettings: AttachmentSettings;
  saveTask: () => void;
  isSaveTaskRunning: boolean;
  canSaveAttachmentSettings: boolean;
  toggleUserAttachmentSettingsEnabled: () => void;
}

interface Signature {
  Args: Args;
}

export default class GeneralConversationAttachments extends Component<Signature> {
  @tracked showRiskConfirmation = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }

  @action
  toggleOtherFiles() {
    if (!this.args.attachmentSettings.allowedAttachmentFiletypesEnabled) {
      this.openOtherFilesModal();
    } else {
      this.args.attachmentSettings.set('allowedAttachmentFiletypesEnabled', false);
      // The user turned off the other files, so lets reset the agreedToRisk
      this.args.attachmentSettings.set('allowedAttachmentFiletypesAgreedToRisk', false);
    }
  }

  @action
  enableOtherFiles() {
    this.args.attachmentSettings.set('allowedAttachmentFiletypesEnabled', true);
    this.args.attachmentSettings.set('allowedAttachmentFiletypesAgreedToRisk', true);
    this.closeOtherFilesWarningModal();
  }

  @action
  openOtherFilesModal() {
    this.showRiskConfirmation = true;
  }

  @action
  cancelOtherFiles() {
    this.args.attachmentSettings.set('allowedAttachmentFiletypesEnabled', false);
    this.args.attachmentSettings.set('allowedAttachmentFiletypesAgreedToRisk', false);
    this.closeOtherFilesWarningModal();
  }

  @action
  closeOtherFilesWarningModal() {
    this.showRiskConfirmation = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::GeneralConversationAttachments': typeof GeneralConversationAttachments;
    'new-settings/workspace/security/general-conversation-attachments': typeof GeneralConversationAttachments;
  }
}
