/* import __COLOCATED_TEMPLATE__ from './ew-chatbot-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import Component from '@glimmer/component';
import { type StepData } from '../../wizard';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    stepData: StepData;
    step: any;
  };
  Blocks: {
    whiteContentContainer: [];
    grayContainer: [];
  };
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class EwChatbotWrapper extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Common::AutomationWizard::EwChatbotWrapper': typeof EwChatbotWrapper;
  }
}
