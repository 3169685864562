/* import __COLOCATED_TEMPLATE__ from './numbered-heading.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import templateOnlyComponent from '@ember/component/template-only';

export interface Args {
  index: number;
  text: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const NumberedHeading = templateOnlyComponent<Signature>();
export default NumberedHeading;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Lib::NumberedHeading': typeof NumberedHeading;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/numbered-heading': typeof NumberedHeading;
  }
}
