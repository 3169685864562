/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable ember/no-computed-properties-in-native-classes */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import AiAgentProfileEditorConfig from 'embercom/objects/visual-builder/configuration/editor/ai-agent-profile';

export default class Editor extends Component {
  @service appService;
  @service contentEditorService;
  @tracked selectedLocale = this.defaultLocale;

  get selectedLocalization() {
    return this.args.localizations.find(
      (localization) => localization.locale === this.selectedLocale,
    );
  }

  get localeOptions() {
    return this.args.localizations.map((locale) => ({
      text: locale.name.concat(' ', '(', locale.locale.toUpperCase(), ')'),
      value: locale.id,
    }));
  }

  @action
  updateLocale(locale) {
    this.selectedLocale = locale;
  }

  get defaultLocale() {
    let defaultLocalization = this.args.localizations.find((l) => l.default);

    return defaultLocalization ? defaultLocalization.locale : 'en';
  }

  get editorConfiguration() {
    return new AiAgentProfileEditorConfig({
      targetChannels: this.contentEditorService.activeObject.targetChannels,
    });
  }

  get currentVisualBuilderObject() {
    return this.selectedLocalization.visualBuilderObject;
  }
}
