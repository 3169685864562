/* import __COLOCATED_TEMPLATE__ from './source.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import { isPresent } from '@ember/utils';

interface Args {
  sources: Array<ContentImportSource>;
  sourceId: number | null;
  doSearch: (sourceId: number | null) => void;
}

const ANY_SOURCE_ID = -1;

export default class SourceFilter extends Component<Args> {
  @service intl!: IntlService;

  get sources() {
    return [
      ...[
        { text: this.intl.t('operator.external-content.filters.source.any'), value: ANY_SOURCE_ID },
      ],
      ...this.args.sources.map((source) => {
        return { text: source.url, value: source.id };
      }),
    ];
  }

  get selectedSourceId() {
    return isPresent(this.args.sourceId) ? this.args.sourceId : ANY_SOURCE_ID;
  }

  get selectedSource() {
    let source = this.sources.find((source) => Number(source.value) === Number(this.args.sourceId));
    return source && isPresent(this.args.sourceId)
      ? this.intl.t('operator.external-content.filters.source.source-is', { source: source.text })
      : this.intl.t('operator.external-content.filters.source.source-is-any');
  }

  @action
  filterBySource(sourceId: number | null) {
    this.args.doSearch(sourceId === ANY_SOURCE_ID ? null : sourceId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::ExternalContent::Filters::Source': typeof SourceFilter;
  }
}
