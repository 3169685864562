/* import __COLOCATED_TEMPLATE__ from './reset-survey-modal-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { post } from 'embercom/lib/ajax';

interface Signature {
  Args: {
    isOpen: boolean;
    toggleModal: () => void;
  };
  Element: never;
}

export default class ResetSurveyModalComponent extends Component<Signature> {
  @service declare appService: any;

  @dropTask
  *resetSurvey() {
    yield post('/ember/onboarding/lead_profile_survey/reset', {
      app_id: this.appService.app.id,
    });
    this.args.toggleModal();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::ProfilingSurvey::ResetSurveyModalComponent': typeof ResetSurveyModalComponent;
  }
}
