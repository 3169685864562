/* import __COLOCATED_TEMPLATE__ from './predicates-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    isReadOnly?: boolean;
    predicates: $TSFixMe;
    predicateContext?: $TSFixMe;
    attributeGroupList?: object[];
    attributeListOverride?: object[];
    onPredicatesUpdate?: (predicates: $TSFixMe) => void;
    addConditionLabel?: string;
    supportedPredicateAttributes?: $TSFixMe[];
  };
}

export default class PredicatesEditor extends Component<Signature> {
  @tracked connectionSwitcherHovered = false;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OperatorFlows::Editor::PredicatesEditor': typeof PredicatesEditor;
    'operator-flows/editor/predicates-editor': typeof PredicatesEditor;
  }
}
