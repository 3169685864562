/* import __COLOCATED_TEMPLATE__ from './default-template-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import filterTemplates from 'embercom/lib/outbound/email-template-helper';

export default class DefaultTemplateSelector extends Component {
  @service appService;
  @service notificationsService;

  get app() {
    return this.appService.app;
  }

  get emailTemplatesSettingsIsClean() {
    return !this.args.emailTemplatesSettings.hasDirtyAttributes;
  }

  get templatesAvailableForAppDefault() {
    let emailTemplates = this.args.emailTemplateTitles.filter((template) => {
      return !template.isHtmlMode;
    });
    emailTemplates = filterTemplates({
      templates: emailTemplates,
      selectedTemplateIdName: this.args.emailTemplatesSettings.default_email_template,
    });

    if (!this.app.canUseEmailPremadeTemplateMigration) {
      emailTemplates = emailTemplates.filter((template) => {
        return !template.isBlockTemplate;
      });
    }

    return emailTemplates;
  }

  @action
  async save() {
    await this.args.emailTemplatesSettings.save().then(
      () => {
        this.notificationsService.notifyConfirmation(
          'Your Intercom Email template settings have been updated',
        );
        // Update the app model with the newly stored default template.
        // This avoids having to reload the app model for this setting to take effect
        // when composing emails from the user list
        let newDefaultTemplate = this.args.emailTemplateTitles.findBy(
          'id',
          this.args.emailTemplatesSettings.default_email_template,
        );
        this.app.set('default_email_template_id', newDefaultTemplate.id);
        this.app.set('default_email_template', newDefaultTemplate.name);
      },
      (error) => {
        this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
      },
    );
  }
}
