/* import __COLOCATED_TEMPLATE__ from './ask-for-contact-details.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import EmberObject from '@ember/object';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';

export default class AskForContactDetails extends Component {
  @service appService;
  @service intercomEventService;
  @service notificationsService;

  get contactCollection() {
    return this.args.step?.modelData?.contactCollection;
  }

  get collectSms() {
    return this.contactCollection?.collectSms;
  }

  get contactCollectionDisabled() {
    return !this.contactCollection?.enabled;
  }

  get messengerSettings() {
    return this.args.step?.modelData?.messengerSettings;
  }

  get settingsAreDirty() {
    return this.contactCollection?.hasDirtyAttributes;
  }

  get adminCanChangeContactDetails() {
    return this.appService.app.currentAdmin.currentAppPermissions.can_create_and_edit_bots;
  }

  get settings() {
    return EmberObject.create({ contactCollection: this.contactCollection });
  }

  get isSaveEnabled() {
    if (!this.adminCanChangeContactDetails) {
      return false;
    }

    return this.args.step?.available || this.settingsAreDirty;
  }

  @dropTask
  *completeStep() {
    try {
      let settingsHaveChanged = this.settingsAreDirty;
      yield this.contactCollection.save();
      if (settingsHaveChanged) {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'changed',
          object: 'ask_for_contact_details',
          ...this.args.step.analyticsMetadata,
        });
      }
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.ask-for-contact-details.error-default'),
      });
    }

    if (this.args.step?.completed) {
      return this.args.completionCallback({
        stepCompletedText: this.intl.t(
          'onboarding.home.steps.ask-for-contact-details.operator-setting-saved',
        ),
      });
    }
    yield this.args.completionCallback();
  }
}
