/* import __COLOCATED_TEMPLATE__ from './company-segment.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';

interface Signature {
  Args: any;
  Element: never;
}

export default class CompanySegment extends Component<Signature> {
  @service declare intl: IntlService;

  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get allVisibleSegments() {
    return [...this.visibleSegments, ...this.nonEditableSegments];
  }

  get segments() {
    return this.app.companySegments;
  }

  get admin() {
    return this.app.currentAdmin;
  }

  get hiddenSegments() {
    return this.hidableSegments.filter((seg: any) => !this.visibleSegments.includes(seg));
  }

  get hidableSegments() {
    return this.segments.filter((segment: any) => segment.is_editable);
  }

  get nonEditableSegments() {
    return this.segments.filter((segment: any) => !segment.is_editable);
  }

  get visibleSegments() {
    let visibleSegmentIds = this.admin.visible_segment_ids;
    if (!visibleSegmentIds) {
      return [];
    }
    return this.hidableSegments.filter((segment: any) =>
      visibleSegmentIds.includes(segment.get('id')),
    );
  }

  @action
  segmentColumns(hiddenOrVisible: string) {
    return [
      {
        label: this.intl.t('account.base.account-controller-base.segment-name'),
        valueComponent: 'visibility/segments/table-cells/name',
      },
      {
        label: this.intl.t('account.base.account-controller-base.created-by'),
        valueComponent: 'visibility/segments/table-cells/created-by',
      },
      {
        label: this.intl.t('account.base.account-controller-base.created'),
        valueComponent: 'visibility/segments/table-cells/created',
      },
      {
        valueComponent: `visibility/segments/table-cells/actions-for-${hiddenOrVisible}`,
      },
    ];
  }

  @action
  showSegment(segment: any) {
    this.admin.addSegmentIdsToAdminsVisibleSegmentIds([segment.get('id')]);
    this.admin.save();
  }

  @action
  hideSegment(segment: any) {
    this.admin.removeSegmentIdsFromAdminsVisibleSegmentIds([segment.get('id')]);
    this.admin.save();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Personal::VisibleToYou::CompanySegment': typeof CompanySegment;
  }
}
