/* import __COLOCATED_TEMPLATE__ from './fin-template-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { FIN_WORKFLOWS } from 'embercom/lib/operator/custom-bots/constants';
import type Template from 'embercom/models/template';

interface Args {
  templates: Template[];
  selectTemplate: any;
  onClose: any;
  shouldShowPaywallBadges: boolean;
  recordAnalyticsEvent: any;
  transitionToPreview: any;
}

export default class FinTemplateSelector extends Component<Args> {
  get group() {
    return FIN_WORKFLOWS;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::FinTemplateSelector': typeof FinTemplateSelector;
    'outbound/new-content-modal/workflows/fin-template-selector': typeof FinTemplateSelector;
  }
}
