/* import __COLOCATED_TEMPLATE__ from './overview.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* eslint-disable @intercom/intercom/max-file-length */
import {
  CONVERSATION_STARTED_TARGET,
  EVERYONE_TARGET,
  NEW_CONVERSATION_TARGET,
  NO_TRIGGER_TARGET,
  OUTBOUND_TARGET,
  NEW_COMMENT_TARGET,
  NEW_ADMIN_COMMENT_TARGET,
  CONVERSATION_STATUS_CHANGED_TARGET,
  CONVERSATION_ATTRIBUTE_CHANGED_TARGET,
  USER_INACTIVITY_TARGET,
  ADMIN_INACTIVITY_TARGET,
  ADMIN_NOTE_CREATED_TARGET,
  TICKET_CREATED_TARGET,
  TICKET_STATE_UPDATED_TARGET,
  NEW_PHONE_CALL_TARGET,
  WEBHOOK_RECEIVED_TARGET,
  CONVERSATION_ASSIGNMENT_CHANGED_TARGET,
  AI_AGENT_WORKFLOW_TARGET,
  NEW_ZENDESK_TICKET_TARGET,
  NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET,
  NEW_SALESFORCE_CASE_TARGET,
  type AdditionalSearchableData,
} from 'embercom/lib/operator/custom-bots/constants';
import { getOwner } from '@ember/application';

import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';
import type { BotConfigTarget } from 'embercom/objects/operator/configuration/configuration';
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { all } from 'ember-concurrency';
import { assert } from '@ember/debug';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import CustomBotListData, {
  PAGE_SIZE,
} from 'embercom/objects/operator/configuration/custom-bots/custom-bot-list-data';
import type Template from 'embercom/models/template';
import type Store from '@ember-data/store';

const TARGETS_ORDER = [
  OUTBOUND_TARGET,
  EVERYONE_TARGET,
  NEW_CONVERSATION_TARGET,
  CONVERSATION_STARTED_TARGET,
  NEW_ZENDESK_TICKET_TARGET,
  NEW_ZENDESK_SUNSHINE_CONVERSATION_TARGET,
  NEW_SALESFORCE_CASE_TARGET,
  NEW_COMMENT_TARGET,
  NEW_ADMIN_COMMENT_TARGET,
  CONVERSATION_STATUS_CHANGED_TARGET,
  CONVERSATION_ATTRIBUTE_CHANGED_TARGET,
  CONVERSATION_ASSIGNMENT_CHANGED_TARGET,
  USER_INACTIVITY_TARGET,
  ADMIN_INACTIVITY_TARGET,
  ADMIN_NOTE_CREATED_TARGET,
  TICKET_CREATED_TARGET,
  TICKET_STATE_UPDATED_TARGET,
  NO_TRIGGER_TARGET,
  NEW_PHONE_CALL_TARGET,
  WEBHOOK_RECEIVED_TARGET,
  AI_AGENT_WORKFLOW_TARGET,
] as const;

interface Args {
  title: string;
  state: string | null;
  audience?: string;
  tags: string | null;
  targetChannels: AdditionalSearchableData['targetChannels'];
  showSearch: boolean;
  parallel: boolean | null;
  hasFin: boolean | null;
  launchPaywall?: boolean;
  finSetupState: { chatIsLive: boolean; emailIsLive: boolean } | null;
}

export default class OperatorCustomBotsOverviewComponent extends Component<Args> {
  constructor(owner: any, args: Args) {
    super(owner, args);
    taskFor(this.loadTemplates).perform();
    taskFor(this.fetchAllInboundTriggers).perform();
  }

  willDestroy() {
    super.willDestroy();
    this.outboundHomeService.resetFilters();
  }

  @service declare appService: any;
  @service tagService: any;
  @service outboundHomeService: any;
  @service declare store: Store;
  @service intercomEventService: any;
  @service router: any;

  featureKey = 'bot_workflows';
  pageSize = PAGE_SIZE;

  @tracked selectedTarget: BotConfigTarget | null = null;
  @tracked selectedTemplate: Template | null = null;
  @tracked selectedTemplateContentObject: any = null;
  @tracked objectTypeOverride: number | null = null;
  @tracked launchUpgradeModal = false;
  @tracked tags = [];
  @tracked dataForTarget = this.orderedCustomBotLists.reduce(
    (dataForTarget, { target, objectType }) => {
      dataForTarget[target] = new CustomBotListData(
        getOwner(this) as any,
        this.app.id,
        target,
        objectType,
      );
      return dataForTarget;
    },
    {} as Record<BotConfigTarget, CustomBotListData>,
  );
  @tracked showContentCreationModal = false;
  @tracked templates: Template[] = [];
  @tracked disableTransitionBack = false;
  @tracked availableTriggers: any[] = [];
  @tracked installedAppPackage = null;
  @tracked showInstallAppPackageModal = false;

  @action
  async displayInstallAppPackageModal(appPackageCode: string) {
    this.installedAppPackage = await this.store.findRecord('appstore/app-package', appPackageCode);
    this.showInstallAppPackageModal = true;
    this.showContentCreationModal = false;
  }

  @task({ drop: true })
  *loadTemplates() {
    this.templates = yield this.store.findAll('template');
    if (this.router.currentRoute?.queryParams?.open_workflows_template_modal) {
      let template =
        this.templates.find(
          (template) =>
            template.id === this.router.currentRoute?.queryParams?.open_workflows_template_modal,
        ) || null;
      if (template) {
        this.selectTemplate(template);
        this.showContentCreationModal = true;
      }
    }
  }

  @task({ restartable: true })
  *fetchAllInboundTriggers() {
    this.availableTriggers = yield this.store.findAll('workflow-connector/inbound-trigger');
  }

  @task({ restartable: true })
  *fetchAllCustomBots() {
    this._setFilterValues();
    let tasks = Object.values(this.dataForTarget).map((data) => {
      // Ensure that the title to be filtered on is set correctly.
      data.title = this.args.title;
      return taskFor(data.setupInitialState).perform();
    });
    yield all(tasks);
  }

  @task({ drop: true })
  *reloadAllCustomBots() {
    let tasks = Object.values(this.dataForTarget).map((data) => {
      return taskFor(data.reload).perform();
    });
    yield all(tasks);
  }

  @task({ drop: true })
  *reloadData() {
    let tasks = [taskFor(this.fetchTags).perform(), taskFor(this.reloadAllCustomBots).perform()];
    yield all(tasks);
  }

  @task({ drop: true })
  *fetchTags() {
    this.tags = yield this.tagService.getMessagesTags(this.app);
  }

  @action
  deleteBots(archivedIds: string[]) {
    Object.values(this.dataForTarget).forEach((data) => {
      data.bots = data.bots.filter((bot) => !archivedIds.includes(bot.id));
    });
  }

  @action
  filterContent(filterProperty: string, value: any) {
    let queryParams = this._getQueryParamFromFilter(filterProperty, value);
    this.router.transitionTo('apps.app.automation.workflows-overview', { queryParams });
  }

  @action
  async loadMore(target: BotConfigTarget) {
    await taskFor(this.dataForTarget[target].fetchNextPage).perform();
  }

  @action
  onHeadingClicked(target: BotConfigTarget, event: KeyboardEvent) {
    let finalState = !this.dataForTarget[target].isOpen;
    this.dataForTarget[target].setOpenState(finalState);
    // If the alt key was pressed when the click happened, toggle all the other lists too.
    if (event.altKey) {
      Object.values(this.dataForTarget).forEach((data) => {
        data.setOpenState(finalState);
      });
    }
  }

  @action
  toggleTemplateModal(selectedTemplateId?: string) {
    this.showContentCreationModal = !this.showContentCreationModal;
    if (!this.showContentCreationModal) {
      this.objectTypeOverride = null;
      this.selectedTarget = null;
      this.selectedTemplate = null;
    } else if (selectedTemplateId) {
      let template = this.templates.find((template) => template.id === selectedTemplateId);
      if (template) {
        this.selectTemplate(template, true);
      }
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: this.showContentCreationModal ? 'opened' : 'closed',
      object: 'admin',
      context: 'template_modal',
      custom_bot_target: this.selectedTarget,
      model_title: this.selectedTemplate?.title,
      model_id: this.selectedTemplate?.id,
      model_object_type: this.selectedTemplate?.objectType,
    });
  }

  @action
  resetSearch() {
    this.performSearchTransition({ name: '' });
  }

  @action
  performSearchTransition(item?: { name: string }) {
    let queryParams = {
      title: item && item.name.length > 0 ? item.name : null,
    };
    this.router.transitionTo('apps.app.automation.workflows-overview', { queryParams });
  }

  @action
  createCustomBot(
    contentType: string | null = null,
    _deliveryChannel = null,
    _audienceType = null,
    templateId = null,
  ) {
    this.showContentCreationModal = false;
    let rulesetParams: {
      app_id: string;
      object_type: string | null;
      template_id?: string;
      role_predicate_group?: object;
      object_data?: object;
      match_behavior?: number;
    } = {
      app_id: this.app.id,
      object_type: contentType,
    };

    let botConfig = this.selectedTarget && CUSTOM_BOT_CONFIG[this.selectedTarget];

    assert(
      'Either the selected contentType is not a Custom Bot or this content type needs to be implemented',
      !!botConfig,
    );

    let { matchBehavior, rolePredicateGroup, botType, trigger } = botConfig;

    if (templateId) {
      rulesetParams.template_id = templateId;
    } else {
      rulesetParams.match_behavior = matchBehavior;
      rulesetParams.role_predicate_group = rolePredicateGroup;
      rulesetParams.object_data = {
        type: botType,
        trigger_type: trigger,
      };
    }

    this.router.transitionTo('apps.app.content.new', rulesetParams);
  }

  @action
  ensureOnlySupportedFilters() {
    let selectedAudienceValues = this.selectedAudienceValues;
    let selectedStateValue = this.selectedStateValue;
    let selectedTagValues = this.selectedTagValues;
    let additionalSearchableData = this.additionalSearchableData;

    // Reset all filters so that ones that aren't supported on this page are removed.
    this.outboundHomeService.resetFilters();

    // Set the filters that are supported on this page.
    this.outboundHomeService.safelySet('selectedAudienceValues', selectedAudienceValues);
    this.outboundHomeService.safelySet('selectedStateValue', selectedStateValue);
    this.outboundHomeService.safelySet('selectedTagValues', selectedTagValues);
    this.outboundHomeService.safelySet('additionalSearchableData', additionalSearchableData);
  }

  @action
  resetFilters() {
    this.outboundHomeService.safelySet('additionalSearchableData', null);
    // Transitioning to the overview page with null filters will cause
    // all custom bots to be fetched again, so we do not need to call
    // fetchAllCustomBots.perform() explicitly.
    this.router.transitionTo('apps.app.automation.workflows-overview', {
      queryParams: {
        title: null,
        state: null,
        audience: null,
        tags: null,
        targetChannels: null,
        parallel: null,
        hasFin: null,
      },
    });
  }

  @action
  selectTemplate(template: Template, isFromEmptyState = false) {
    this.disableTransitionBack = isFromEmptyState;
    this.selectedTemplateContentObject = template.ruleset.rulesetLinks.firstObject.object;
    this.selectedTarget = this.selectedTemplateContentObject.target;
    this.selectedTemplate = template;

    if (isFromEmptyState && this.app.canUseWorkflowsSetup) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: `template_card`,
        place: 'workflows_overview',
        section: 'empty_state',
        template_id: template.id,
      });
    }
  }

  @action
  selectedBotIdsInTarget(target: BotConfigTarget) {
    return this.dataForTarget[target].bots.filter((bot) => bot.isSelected).map((bot) => bot.id);
  }

  get selectedBots() {
    return Object.values(this.dataForTarget)
      .map((value) => value.bots)
      .flat()
      .filter((bot) => bot.isSelected);
  }

  get hasSelectedBots() {
    return this.selectedBots.length > 0;
  }

  get app() {
    return this.appService.app;
  }

  get hasAppliedFilters() {
    return !!(
      this.args.title !== null ||
      this.selectedAudienceValues?.length > 0 ||
      this.selectedStateValue !== null ||
      this.selectedTagValues?.length > 0 ||
      (this.additionalSearchableData &&
        Object.values(this.additionalSearchableData).some((val) => val !== null))
    );
  }

  get orderedCustomBotLists() {
    return TARGETS_ORDER.map((target) => CUSTOM_BOT_CONFIG[target]).filter((config) =>
      config.visibleToApp(this.app),
    );
  }

  get allTriggersArePaywalled() {
    return this.orderedCustomBotLists.every(
      (config) =>
        config.requiredBillingFeature && !this.app.canUseFeature(config.requiredBillingFeature),
    );
  }

  get selectedAudienceValues() {
    return this.outboundHomeService.selectedAudienceValues;
  }

  get selectedStateValue() {
    return this.outboundHomeService.selectedStateValue;
  }

  get selectedSenderValue() {
    return this.outboundHomeService.selectedSenderValue;
  }

  get selectedTagValues() {
    return this.outboundHomeService.selectedTagValues;
  }

  get selectedTagOperator() {
    return this.outboundHomeService.selectedTagOperator;
  }

  get additionalSearchableData(): AdditionalSearchableData | null {
    return this.outboundHomeService.additionalSearchableData;
  }

  get selectedMatchBehaviorValues() {
    return this.outboundHomeService.selectedMatchBehaviorValues;
  }

  get totalBots() {
    return Object.values(this.dataForTarget).reduce((total, data) => total + data.totalCount, 0);
  }

  get triggersWithData() {
    return this.orderedCustomBotLists.filter((botConfig) => {
      let target: BotConfigTarget = botConfig.target;
      let data = this.dataForTarget[target];
      return (target === 'no-trigger' && data.loading) || data.totalCount > 0;
    });
  }

  get showEmptyState() {
    return (
      !this.isLoadingNonLinkingBotBots &&
      this.triggersWithData.length === 0 &&
      !this.hasAppliedFilters
    );
  }

  get showEmptyFilterState() {
    return (
      !this.isLoadingNonLinkingBotBots &&
      this.triggersWithData.length === 0 &&
      this.hasAppliedFilters
    );
  }

  get isLoadingNonLinkingBotBots() {
    // Slicing off linking bots which is at the end of the ordered cb list,
    // we don't want to hold off on showing the bots that generally load faster.
    return this.orderedCustomBotLists
      .slice(0, -1)
      .some((botConfig) => this.dataForTarget[botConfig.target].loading);
  }

  get hasSomeWorkflows() {
    return this.totalBots > 0;
  }

  get finSetupLiveForEmail() {
    return !!this.args.finSetupState?.emailIsLive;
  }

  // All bot types that can be created, adds a callback for updating the chosen bot type in the creation flow.
  selectableCustomBotTypes = this.orderedCustomBotLists
    .filter((config) => config.selectableCustomBotType)
    .map((botConfig) =>
      Object.assign(botConfig, {
        onSelect: () => {
          let target: BotConfigTarget = botConfig.target;
          this.selectedTarget = target;
          this.intercomEventService.trackAnalyticsEvent({
            action: 'clicked',
            object: 'custom_bot_target',
            custom_bot_target: target,
          });
        },
      }),
    );

  _setFilterValues() {
    this.outboundHomeService.safelySet(
      'selectedStateValue',
      this.args.state !== null ? parseInt(this.args.state, 10) : null,
    );
    this.outboundHomeService.safelySet(
      'selectedAudienceValues',
      this.args.audience?.length ? this.args.audience.split(',') : null,
    );
    this.outboundHomeService.safelySet(
      'selectedTagValues',
      this.args.tags !== null ? this.args.tags.split(',') : null,
    );

    let { targetChannels, parallel, hasFin } = this.args;
    this.outboundHomeService.safelySet('additionalSearchableData', {
      targetChannels,
      parallel,
      hasFin,
    });
  }

  _getQueryParamFromFilter(filterProperty: string, value: any) {
    switch (filterProperty) {
      case 'selectedAudienceValues':
        return { audience: value?.length ? value : null };
      case 'selectedStateValue':
        return { state: value };
      case 'selectedTagValues':
        return { tags: value?.length ? value : null };
      case 'additionalSearchableData':
        return {
          targetChannels: value?.targetChannels?.length ? value.targetChannels : null,
          parallel: value?.parallel,
          hasFin: value?.hasFin,
        };
    }
    assert('filterProperty must by supported by Custom Bots');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::CustomBots::Overview': typeof OperatorCustomBotsOverviewComponent;
  }
}
