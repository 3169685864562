/* import __COLOCATED_TEMPLATE__ from './setup.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';
import { type ZendeskConnectionData } from 'embercom/components/new-settings/data/imports-exports/import-zendesk';
import { tracked } from '@glimmer/tracking';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type Router from '@ember/routing/router-service';

export interface Args {
  context: ZendeskConnectionData;
  updateConnectionData: (
    isConnected: boolean,
    subdomain: string,
    hasStartedMigration: boolean,
  ) => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export const HELP_LINKS = {
  migration_guide:
    'https://www.intercom.com/help/en/articles/9307237-migrate-your-zendesk-ticket-user-and-organization-data',
  import_articles:
    'https://www.intercom.com/help/en/articles/56643-import-public-articles-from-zendesk',
} as const;

export default class Setup extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare redirectService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare router: Router;

  @tracked url = '';
  @tracked showDisconnectDialog = false;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    if (this.args.context.subdomain) {
      this.url = `https://${this.args.context.subdomain}.zendesk.com`;
    }
  }

  @action async connect() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'connect_account',
    });
    let url = await taskFor(this.generateRedirectUrl).perform();
    await this.redirectService.redirect(url);
  }

  @action onDisconnect() {
    this.args.updateConnectionData(false, '', false);
  }

  @action goToConfigImportPage() {
    this.router.transitionTo('apps.app.settings.data.imports-exports.import-zendesk');
  }

  @dropTask *generateRedirectUrl(): TaskGenerator<string> {
    let requestData: any = {
      app_id: this.appService.app.id,
      admin_id: this.appService.app.currentAdmin.id,
      app_package_code: 'migrate-from-zendesk',
      additional_params: {
        subdomain: this.subdomain,
      },
    };
    let url;
    try {
      let response = yield post('/ember/appstore/redirect_url', requestData);
      url = response.url;
    } catch (response) {
      this.notificationsService.notifyError(response.jqXHR.responseJSON.message);
    }
    return url;
  }

  get validUrl(): boolean {
    if (this.url.startsWith('https://')) {
      try {
        let url = new URL(this.url);
        return url.hostname.endsWith('zendesk.com');
      } catch (e) {
        return false;
      }
    }
    return false;
  }

  private get subdomain() {
    try {
      let url = new URL(this.url);
      return url.hostname.replace('.zendesk.com', '');
    } catch (e) {
      // this should not happen
      return '';
    }
  }

  @action openMigrationGuide() {
    this.openDocumentation('migration_guide');
  }

  @action openImportArticlesGuide() {
    this.openDocumentation('import_articles');
  }

  private openDocumentation(label: keyof typeof HELP_LINKS) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'help_link',
      target_link: label,
    });
    safeWindowOpen(HELP_LINKS[label], '_blank');
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.setup-page.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::Setup': typeof Setup;
    'new-settings/data/imports-exports/import-zendesk/setup': typeof Setup;
  }
}
