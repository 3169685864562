/* import __COLOCATED_TEMPLATE__ from './advanced.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
  model: $TSFixMe;
}
export default class Advanced extends Component<Args> {
  @service declare appService: $TSFixMe;

  get inboxEmailAddress() {
    return this.appService.app.inbox_email;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced': typeof Advanced;
  }
}
