/* import __COLOCATED_TEMPLATE__ from './action-info.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import {
  keywordResponseTypes,
  keywordResponseActionTranslationKeys,
} from 'embercom/models/data/sms/constants';

interface Args {
  smsOutboundSettings: any;
  keywordType: any;
}

export default class ActionInfo extends Component<Args> {
  keywordResponseTypes = keywordResponseTypes;

  get translationKey() {
    if (
      this.args.keywordType === this.keywordResponseTypes.default &&
      !this.args.smsOutboundSettings.enabledToDeflectToInbox
    ) {
      return null;
    } else {
      return keywordResponseActionTranslationKeys[this.args.keywordType];
    }
  }

  get shouldBeDisplayed() {
    return this.translationKey !== null;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::Settings::Sms::ResponseSettings::ActionInfo': typeof ActionInfo;
  }
}
