/* import __COLOCATED_TEMPLATE__ from './foundational-step-card.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type GuideLibraryService from 'embercom/services/guide-library-service';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    step: any;
    onClick: () => void;
    place: string;
    shouldAnimate?: boolean;
  };
}
export default class FoundationalStepCard extends Component<Signature> {
  @service declare guideLibraryService: GuideLibraryService;
  @service declare appService: $TSFixMe;

  @action async handleClick() {
    this.guideLibraryService.closeChecklistPopover();

    this.args.onClick();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::FoundationalStepCard': typeof FoundationalStepCard;
  }
}
