/* import __COLOCATED_TEMPLATE__ from './new-pricing-option.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import type Store from '@ember-data/store';
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Args {
  icon: InterfaceIconName;
  type: 'card' | 'menu-item';
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class NewPricingOption extends Component<Signature> {
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service declare customerService: $TSFixMe;
  @service declare store: Store;

  get canSeePricingMigration() {
    return this.customerService.canSeePricingMigration;
  }

  get isActive() {
    let regex = new RegExp(
      String.raw`/apps\/${this.appService.app.id}/settings/workspace/migration`,
    );
    return Boolean(this.router.currentURL.match(regex));
  }

  @action
  onClick() {
    this.router.transitionTo('apps.app.settings.workspace.migration', this.appService.app.id);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::NewPricingOption': typeof NewPricingOption;
    'new-settings/workspace/new-pricing-option': typeof NewPricingOption;
  }
}
