/* import __COLOCATED_TEMPLATE__ from './bulk-deactivate.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { put } from 'embercom/lib/ajax';
import { toPercentString } from 'embercom/lib/percentage-formatter';
import { states } from 'embercom/models/data/matching-system/matching-constants';
import { contentWrapperTypes } from 'embercom/models/data/outbound/constants';
import { CAN_SEND_MESSAGES } from 'embercom/lib/outbound/constants';

export default class BulkDeactivate extends Component {
  @service appService;
  @service notificationsService;
  @service realTimeEventService;
  @service intl;
  @service permissionsService;

  @tracked showDeactivationResultsModal = false;
  @tracked failedContentWrappers = [];
  @tracked deactivatedContentWrappers = [];

  lastReceivedNexusTimestamp = 0;

  constructor() {
    super(...arguments);

    this.realTimeEventService.on('BulkDeactivationStatus', this, 'showBulkDeactivationUpdate');
  }

  willDestroy() {
    super.willDestroy();
    this.realTimeEventService.off('BulkDeactivationStatus', this, 'showBulkDeactivationUpdate');
  }

  get app() {
    return this.appService.app;
  }

  // disable bulk action for series, legacy message types or messages that aren't live
  get isDisabled() {
    return this.args.selectedContentWrappers.any((contentWrapper) => {
      if (
        !contentWrapper?.relations?.series &&
        contentWrapper.data.contentWrapperType === contentWrapperTypes.ruleset &&
        contentWrapper?.contents?.firstObject?.state === states.live
      ) {
        return false;
      }
      return true;
    });
  }

  @task
  *bulkDeactivateMessages() {
    try {
      yield this.permissionsService.checkPermission(
        CAN_SEND_MESSAGES,
        this.intl.t('outbound.bulk-archive.permission-error'),
      );
      yield put(`/ember/content_service/contents/bulk_deactivate`, {
        app_id: this.app.id,
        content_wrapper_ids: this.args.selectedContentWrappers.map(
          (contentWrapper) => contentWrapper.id,
        ),
      });
    } catch (e) {
      let message = this.intl.t('outbound.bulk-deactivate.error-response');
      if (e && e.message) {
        message = e.message;
      }
      this.notificationsService.notifyError(message);
    }
  }

  showBulkDeactivationUpdate(event) {
    if (this.lastReceivedNexusTimestamp > event.timestamp) {
      return;
    }
    let notificationText = '';
    if (event.eventType === 'deactivating') {
      notificationText = this.intl.t(
        'outbound.bulk-deactivate.deactivation-progress-notification',
        {
          percentage: toPercentString(event.progress * 100),
        },
      );
    } else if (event.eventType === 'complete') {
      this.deactivatedContentWrappers = event.success;
      this.failedContentWrappers = event.errors;
      if (this.failedContentWrappers.length) {
        this.showDeactivationResultsModal = true;
        this.args.onAfterSave();
        return;
      }
      if (this.deactivatedContentWrappers.length > 1) {
        notificationText = this.intl.t(
          'outbound.bulk-deactivate.deactivation-results-modal.multiple-messages.success',
          {
            deactivatedContentWrappersLength: this.deactivatedContentWrappers.length,
          },
        );
      } else {
        notificationText = this.intl.t(
          'outbound.bulk-deactivate.deactivation-results-modal.single-message.success',
        );
      }
      this.args.onAfterSave();
    }
    this.notificationsService.removeNotification('bulk-deactivate-messages');
    this.lastReceivedNexusTimestamp = event.timestamp;
    this.notificationsService.notifyConfirmation(
      notificationText,
      5000,
      'bulk-deactivate-messages',
    );
  }
}
