/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class Filters extends Component {
  @service appService;
  @service intl;
  @service intercomEventService;
  @service outboundHomeService;

  get activeFilter() {
    return this.args.activeFilter;
  }

  get app() {
    return this.appService.app;
  }

  get selectableFilters() {
    let items = [];
    if (!this.args.hideDeliveryTypeTabs) {
      items.push({
        text: this.intl.t('outbound.all.filters.audience'),
        icon: 'rules',
        value: 'match-behavior',
        isActive: this.activeFilter === 'match-behavior',
      });
    }

    if (this.args.showNewsfeedComponent) {
      items.push({
        text: this.intl.t('outbound.all.filters.newsfeed'),
        icon: 'rss',
        value: 'newsfeed',
        isActive: this.activeFilter === 'newsfeed',
      });
    }

    if (!this.args.hideAudienceComponent) {
      items.push({
        text: this.intl.t('outbound.all.filters.people-type'),
        icon: 'multiple-people',
        value: 'audience',
        isActive: this.activeFilter === 'audience',
      });
    }

    items.push({
      text: this.intl.t('outbound.all.filters.state'),
      icon: 'active',
      value: 'state',
      isActive: this.activeFilter === 'state',
    });

    if (!this.args.hideSenderComponent) {
      let text =
        this.outboundHomeService.activeList === objectTypes.series
          ? this.intl.t('outbound.series.created-by')
          : this.intl.t('outbound.all.filters.sender');

      items.push({
        text,
        icon: 'person',
        value: 'sender',
        isActive: this.activeFilter === 'sender',
      });
    }

    if (!this.args.hideTagComponent) {
      items.push({
        text: this.intl.t('outbound.all.filters.tag'),
        icon: 'tag',
        value: 'tag',
        isActive: this.activeFilter === 'tag',
      });
    }

    if (this.args.showSubscriptionTypeComponent) {
      items.push({
        text: this.intl.t('outbound.all.filters.subscription-type'),
        icon: 'email-filled',
        value: 'subscription-type',
        isActive: this.activeFilter === 'subscription-type',
      });
    }

    if (this.args.showChannelComponent) {
      items.push({
        text: this.intl.t('outbound.all.filters.channel'),
        icon: 'multiplatform',
        value: 'target-channel',
        isActive: this.activeFilter === 'target-channel',
      });
    }

    items.push({
      text: this.intl.t('outbound.all.filters.date'),
      icon: 'calendar',
      value: 'date',
      isActive: this.activeFilter === 'date',
    });

    if (!this.args.inSeriesRoute) {
      items.push({
        text: this.intl.t('outbound.all.filters.series'),
        icon: 'series',
        value: 'series',
        isActive: this.activeFilter === 'series',
      });
    }

    let filtersAppliedValues = this.filtersApplied;
    return [
      {
        items: items.reject(
          (item) => filtersAppliedValues.includes(item.value) || this.activeFilter === item.value,
        ),
      },
    ];
  }

  get filtersApplied() {
    let activeFiltersApplied = [];

    if (this.args.selectedMatchBehaviorValues?.length > 0) {
      activeFiltersApplied.push('match-behavior');
    }

    if (this.args.selectedAudienceValues?.length > 0) {
      activeFiltersApplied.push('audience');
    }

    if (this.stateHasValue) {
      activeFiltersApplied.push('state');
    }

    if (this.args.selectedSenderValue) {
      activeFiltersApplied.push('sender');
    }

    if (this.args.selectedTagValues?.length > 0) {
      activeFiltersApplied.push('tag');
    }

    if (this.args.selectedSubscriptionTypeValue?.length > 0) {
      activeFiltersApplied.push('subscription-type');
    }

    if (this.args.targetChannels?.length > 0) {
      activeFiltersApplied.push('target-channel');
    }

    if (this.args.predicates?.length > 0) {
      activeFiltersApplied.push('date');
    }

    if (this.hideSeriesHasValue) {
      activeFiltersApplied.push('series');
    }
    return activeFiltersApplied;
  }

  get hideSeriesHasValue() {
    return isPresent(this.args.hideSeries);
  }

  /**
   * return true when there is a value
   * fix for DRAFT (0) return true
   * @returns {boolean}
   */
  get stateHasValue() {
    return isPresent(this.args.selectedStateValue);
  }

  @action
  addFilter(filterType) {
    this.args.setActiveFilter(filterType);
    this.intercomEventService.trackEvent('adding-filter', { filter_type: filterType });
  }

  @action removeFilter() {
    this.args.setActiveFilter(null);
  }

  @action
  doSearch(item) {
    this.args.filterContent('searchString', item.name);
  }

  @action
  resetSearch() {
    this.args.filterContent('searchString', null);
  }
}
