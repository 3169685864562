/* import __COLOCATED_TEMPLATE__ from './reset-filters.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  hasAppliedFilter: boolean;
  totalCount: number;
  resetFilters: () => void;
}

export default class ResetFilters extends Component<Args> {
  @service intercomEventService: any;

  get isVisible(): boolean {
    return this.args.hasAppliedFilter;
  }

  @action
  resetFilters() {
    this.args.resetFilters();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'reset_filters_button',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::ResetFilters': typeof ResetFilters;
    'outbound/reset-filters': typeof ResetFilters;
  }
}
