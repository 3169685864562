/* import __COLOCATED_TEMPLATE__ from './learn.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { objectTypes, objectNames } from 'embercom/models/data/matching-system/matching-constants';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import { LEARN_MORE_CONTENT_IDS } from 'embercom/lib/outbound/constants';

export default class Learn extends Component {
  @service intercomEventService;
  @service intl;

  trackAnalytics(contentType, id) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn_link',
      place: 'outbound_all',
      content_type: contentType,
      link_to: id,
    });
  }

  showGetStartedArticle = () => {
    window.Intercom('showArticle', LEARN_MORE_CONTENT_IDS.getStarted);
    this.trackAnalytics(objectNames[objectTypes.article], LEARN_MORE_CONTENT_IDS.getStarted);
  };

  showSeriesArticle = () => {
    window.Intercom('showArticle', LEARN_MORE_CONTENT_IDS.series);
    this.trackAnalytics(objectNames[objectTypes.article], LEARN_MORE_CONTENT_IDS.series);
  };

  startSurvey = () => {
    Intercom('startSurvey', LEARN_MORE_CONTENT_IDS.survey);
    this.trackAnalytics(objectNames[objectTypes.survey], LEARN_MORE_CONTENT_IDS.survey);
  };

  startChecklist = () => {
    Intercom('startChecklist', LEARN_MORE_CONTENT_IDS.checklist);
    this.trackAnalytics(objectNames[objectTypes.checklist], LEARN_MORE_CONTENT_IDS.checklist);
  };

  startProductTour = () => {
    Intercom('startTour', LEARN_MORE_CONTENT_IDS.productTour);
    this.trackAnalytics(objectNames[objectTypes.tour], LEARN_MORE_CONTENT_IDS.productTour);
  };

  navigateToOutboundArticles = () => {
    safeWindowOpen(
      'https://www.intercom.com/help/en/collections/2091449-outbound-messages-campaigns',
      '_blank',
    );
    this.trackAnalytics(objectNames[objectTypes.article], 2091449);
  };

  trackLearningOpened = () => {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'learn_dropdown',
    });
  };
}
