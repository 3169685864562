/* import __COLOCATED_TEMPLATE__ from './quick-replies-editor.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import {
  USER_OBJECT_TYPE_IDENTIFIER,
  USER_OBJECT_TYPE_NAME_PERSON,
  CONVERSATION_OBJECT_TYPE_IDENTIFIER,
  CONVERSATION_OBJECT_TYPE_NAME,
  STANDARD_OBJECT_TYPE_IDENTIFIERS,
} from 'embercom/models/custom-objects/constants/object-types';
import { REFERENCES_MANY, REFERENCES_ONE } from 'embercom/models/objects/constants/reference-types';
import { isEmpty } from '@ember/utils';

const OBJECT_TYPE_IDENTIFIER_TO_NAME_MAP = {
  [USER_OBJECT_TYPE_IDENTIFIER]: USER_OBJECT_TYPE_NAME_PERSON,
  [CONVERSATION_OBJECT_TYPE_IDENTIFIER]: CONVERSATION_OBJECT_TYPE_NAME,
};

const OBJECT_TYPE_IDENTIFIER_TO_ICON_MAP = {
  [USER_OBJECT_TYPE_IDENTIFIER]: 'multiple-people',
  [CONVERSATION_OBJECT_TYPE_IDENTIFIER]: 'conversation',
};

export default class QuickRepliesEditor extends Component {
  @service intl;
  @service attributeService;
  @service customObjectsService;

  get step() {
    return this.args.step;
  }

  get selectedObjectType() {
    return this.customObjectsService.findCustomObjectTypeByIdentifier(
      this.selectedCustomObjectTypeIdentifier,
    );
  }

  get selectedCustomObjectTypeIdentifier() {
    return this.step.objectTypeForButtonGeneration;
  }

  get selectedCustomObjectTypeName() {
    return this.selectedObjectType?.name;
  }

  get selectedAttributeForButtonGeneration() {
    if (
      this.step.relationshipAttributeForButtonGenerationId &&
      this.step.attributeObjectTypeForButtonGeneration
    ) {
      return this.dropdownItemsForQuerySelector[0].items.find((attributeItem) => {
        let attribute = attributeItem.value;

        return (
          this._selectedAttributeId(attribute) ===
            this.args.step.relationshipAttributeForButtonGenerationId &&
          attribute.objectTypeIdentifier === this.step.attributeObjectTypeForButtonGeneration
        );
      });
    }
  }

  get emptyStateForCustomObjectTypeSelector() {
    return [
      {
        value: null,
        isDisabled: true,
        component:
          'operator-flows/editor/custom-object-selector/empty-states/custom-object-type-item',
        componentShouldReplaceItem: true,
      },
    ];
  }

  get emptyStateForAttributeDescriptorSelector() {
    return [
      {
        value: null,
        isDisabled: true,
        component:
          'operator-flows/editor/custom-object-selector/empty-states/attribute-descriptor-item',
        componentShouldReplaceItem: true,
        customObjectIdentifier: this.selectedCustomObjectTypeIdentifier,
      },
    ];
  }

  get emptyStateForQuerySelectorDropdown() {
    return [
      {
        value: null,
        isDisabled: true,
        componentShouldReplaceItem: true,
        component: 'operator-flows/editor/custom-object-selector/empty-states/query-selector-item',
        customObjectTypeName: this.selectedCustomObjectTypeName,
      },
    ];
  }

  get dropdownItemsForQuerySelector() {
    let customObjectDropdownItems = this.selectedObjectType.attributeDescriptors.filter(
      (attributeDescriptor) => {
        return (
          attributeDescriptor.isRelationshipDataType &&
          !attributeDescriptor.archived &&
          STANDARD_OBJECT_TYPE_IDENTIFIERS.includes(
            attributeDescriptor.referencedObjectTypeIdentifier,
          )
        );
      },
    );
    let attributes = customObjectDropdownItems.concat(this.userAndConversationDropdownItems);

    let dropdownItems = attributes.map((attribute) => {
      return {
        text: attribute.objectTypeName,
        value: attribute,
        component: 'operator-flows/editor/custom-object-selector/query-selector-item',
        componentAttrs: {
          objectType: attribute.objectTypeName,
          referenceAttribute: attribute.name,
          referenceType: this._referenceTypeText(attribute),
          referencedObjectType: this._generateTextForReferencedObject(attribute),
          objectTypeIcon: this._objectTypeAndReferencedObjectTypeIcon(attribute).objectTypeIcon,
          referencedObjectTypeIcon:
            this._objectTypeAndReferencedObjectTypeIcon(attribute).referencedObjectTypeIcon,
        },
      };
    });
    if (isEmpty(dropdownItems)) {
      return [{ items: this.emptyStateForQuerySelectorDropdown }];
    }
    return [{ items: dropdownItems }];
  }

  get userAndConversationDropdownItems() {
    let userAndConversationRelationshipAttributes =
      this.attributeService.userAndConversationRelationshipAttributes.rejectBy('archived');

    return userAndConversationRelationshipAttributes.filter(
      (attribute) =>
        attribute.referencedObjectTypeIdentifier === this.selectedCustomObjectTypeIdentifier,
    );
  }

  _generateTextForReferencedObject(attribute) {
    switch (attribute.objectTypeIdentifier) {
      case USER_OBJECT_TYPE_IDENTIFIER:
      case CONVERSATION_OBJECT_TYPE_IDENTIFIER:
        return OBJECT_TYPE_IDENTIFIER_TO_NAME_MAP[attribute.objectTypeIdentifier];
      default:
        return OBJECT_TYPE_IDENTIFIER_TO_NAME_MAP[attribute.referencedObjectTypeIdentifier];
    }
  }

  _objectTypeAndReferencedObjectTypeIcon(attribute) {
    switch (attribute.objectTypeIdentifier) {
      case USER_OBJECT_TYPE_IDENTIFIER:
        return { objectTypeIcon: 'multiple-people', referencedObjectTypeIcon: 'multiple-people' };
      case CONVERSATION_OBJECT_TYPE_IDENTIFIER:
        return { objectTypeIcon: 'conversation', referencedObjectTypeIcon: 'conversation' };
      default:
        return {
          objectTypeIcon: 'custom-objects',
          referencedObjectTypeIcon:
            OBJECT_TYPE_IDENTIFIER_TO_ICON_MAP[attribute.referencedObjectTypeIdentifier],
        };
    }
  }

  _referenceTypeText(attribute) {
    let referenceType = attribute.reference?.referenceType;
    let isStandardObjectAttribute = STANDARD_OBJECT_TYPE_IDENTIFIERS.includes(
      attribute.objectTypeIdentifier,
    );

    switch (referenceType) {
      case REFERENCES_MANY:
        return isStandardObjectAttribute
          ? this.intl.t('operator.workflows.visual-builder.for')
          : this.intl.t('operator.workflows.visual-builder.contains');
      case REFERENCES_ONE:
        return this.intl.t('operator.workflows.visual-builder.equals');
      default:
        return undefined;
    }
  }

  _selectedAttributeId(attribute) {
    switch (attribute.objectTypeIdentifier) {
      case USER_OBJECT_TYPE_IDENTIFIER:
      case CONVERSATION_OBJECT_TYPE_IDENTIFIER:
        return attribute.cdaId;
      default:
        return attribute.id;
    }
  }

  @action
  setAttributeDescriptor(selectedValue) {
    this.step.objectTypeDisplayAttributeId = selectedValue;
  }

  get selectedAttributeDescriptorValue() {
    if (this.step.objectTypeDisplayAttributeId) {
      return this.step.objectTypeDisplayAttributeId;
    }
  }

  @action
  setSelectedAttributeForButtonGeneration(selectedValue) {
    if (selectedValue) {
      this.step.relationshipAttributeForButtonGenerationId =
        this._selectedAttributeId(selectedValue);
      this.step.attributeObjectTypeForButtonGeneration = selectedValue.objectTypeIdentifier;
    }
  }

  get dropdownItemsForReplyLimit() {
    return [1, 2, 3, 4, 5, 10, 15, 20, 25].map((number) => {
      return {
        text: number,
        value: number,
      };
    });
  }

  @action
  setReplyLimit(selectedValue) {
    this.step.replyLimit = selectedValue;
  }

  get selectedReplyLimit() {
    if (this.step.replyLimit) {
      return this.step.replyLimit;
    }
  }
}
