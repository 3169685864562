/* import __COLOCATED_TEMPLATE__ from './access-permissions.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type IntlService from 'ember-intl/services/intl';
// @ts-ignore
import { copy } from 'ember-copy';

interface Args {
  ruleset?: $TSFixMe;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class TicketsPortalAccessPermissions extends Component<Signature> {
  @service declare intl: IntlService;

  @tracked usersSideSheetVisible = false;

  constructor(owner: $TSFixMe, args: Args) {
    super(owner, args);
  }

  @action
  onUpdatePredicates(predicates: $TSFixMe) {
    this.args.ruleset.set('predicateGroup.predicates', copy(predicates));
    this.args.ruleset.get('fetchAudiencePreviewTask').perform();
  }

  @action
  onUpdateRolePredicates(rolePredicates: $TSFixMe) {
    this.args.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));
    this.args.ruleset.get('fetchAudiencePreviewTask').perform();
  }

  get isFetchingRulesetAudience() {
    return this.args.ruleset?.fetchAudiencePreviewTask.isRunning ?? false;
  }

  @action
  toggleUsersSideSheet() {
    this.usersSideSheetVisible = !this.usersSideSheetVisible;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tickets::TicketsPortal::AccessPermissions': typeof TicketsPortalAccessPermissions;
    'new-settings/helpdesk/tickets/tickets-portal/access-permissions': typeof TicketsPortalAccessPermissions;
  }
}
