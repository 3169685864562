/* import __COLOCATED_TEMPLATE__ from './labels-table.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import EmberObject, { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { captureException } from 'embercom/lib/sentry';
import { tracked } from '@glimmer/tracking';

export default class LabelsTableComponent extends Component {
  @service appService;
  @service intl;
  @service store;
  @service newsLabelsService;
  @service notificationsService;
  @tracked labelBeingDeleted = null;
  @tracked showDeleteLabelConfirmModal = false;
  @tracked sortState = {
    valuePath: 'label.name',
    direction: 'asc',
  };

  constructor() {
    super(...arguments);
    this.newsLabelsService.loadLabels.perform();
  }

  get app() {
    return this.appService.app;
  }

  get labels() {
    return this.newsLabelsService.labelList;
  }

  get hasLabels() {
    return this.labels?.length > 0;
  }

  get columns() {
    let columns = [];
    columns.push({
      label: 'Name',
      valuePath: 'label.name',
      width: '80%',
      isSortable: true,
    });

    columns.push({
      label: this.intl.t('outbound.news-labels.labels-table.created-at'),
      valuePath: 'label.created_at',
      width: '10%',
      isSortable: true,
    });

    columns.push({
      label: this.intl.t('outbound.news-labels.labels-table.news-items'),
      valuePath: 'label.news_item_count',
      type: 'number',
      tooltip: this.intl.t('outbound.news-labels.labels-table.number-of-use'),
      isSortable: true,
      width: '10%',
    });

    return columns;
  }

  @task({ drop: true })
  *saveLabel(label) {
    let labelName = label.name.trim();

    if (!labelName) {
      this.notificationsService.notifyError(
        this.intl.t('outbound.news-labels.labels-table.label-without-name'),
      );
    } else if (
      this.labels.some((labelData) => {
        return (
          labelData.label !== label &&
          labelData.label.name.localeCompare(labelName, undefined, { sensitivity: 'accent' }) === 0
        );
      })
    ) {
      this.notificationsService.notifyError(
        this.intl.t('outbound.news-labels.labels-table.name-already-taken'),
      );
    } else {
      try {
        yield label.save();
        this.clearLabelBeingEdited();
      } catch (error) {
        captureException(error);
        this.notificationsService.notifyError(
          this.intl.t('outbound.news-labels.labels-table.failed-to-save'),
        );
      }
    }
  }

  @action
  async createLabel() {
    let newLabel = this.store.createRecord('news/label', {
      created_at: Date.now(),
      name: '',
    });

    let newRow = EmberObject.create({
      label: newLabel,
      editing: true,
    });

    this.addToTop(newRow);
    return newRow;
  }

  @action
  async editLabel(label) {
    this.setLabelBeingEdited(label);
  }

  @action
  async deleteLabel() {
    try {
      let label = this.labelBeingDeleted.label;
      let deleteLabelId = Number(label.id);
      await label.destroyRecord();
      this.newsLabelsService.loadLabels.perform();
      this.showDeleteLabelConfirmModal = false;
      this.labelBeingDeleted = null;
      this.notificationsService.notifyConfirmation(
        this.intl.t('outbound.news-labels.notify-confirmation.deleted'),
      );
      this.store.peekAll('news/item').forEach((newsItem) => {
        newsItem.labelIds = newsItem.labelIds?.filter((id) => id !== deleteLabelId);
      });
    } catch (error) {
      console.error('error', error);
      this.notificationsService.notifyError(error);
    }
  }

  @action
  onSort(valuePath) {
    let direction = 'asc';
    if (this.sortState.valuePath === valuePath && this.sortState.direction === 'asc') {
      direction = 'desc';
    }
    this.sortState = { valuePath, direction };

    this._sortData();
  }

  _sortData() {
    let { valuePath, direction } = this.sortState;
    let sortedData = this.labels.sortBy(valuePath);
    if (direction === 'desc') {
      sortedData = sortedData.reverse();
    }
    this.newsLabelsService.labelList = sortedData;
  }

  setLabelBeingEdited(label) {
    this.clearLabelBeingEdited();
    label.set('editing', true);
  }

  clearLabelBeingEdited() {
    this.labels.setEach('editing', false);
  }

  addToTop(label) {
    this.labels.insertAt(0, label);
  }

  @action
  requestDeletion(label) {
    this.labelBeingDeleted = label;
    this.showDeleteLabelConfirmModal = true;
  }

  @action
  closeDeleteLabelConfirmModal() {
    this.showDeleteLabelConfirmModal = false;
  }
}
