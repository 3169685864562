/* import __COLOCATED_TEMPLATE__ from './silent-delivery-switcher.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class SilentDeliverySwitcher extends Component {
  get localizedContent() {
    return this.args.newsItem.localizedNewsItemContents.firstObject;
  }
  get deliverSilently() {
    return this.localizedContent.deliverSilently;
  }

  @action
  onToggle() {
    this.localizedContent.deliverSilently = !this.deliverSilently;
  }
}
