/* import __COLOCATED_TEMPLATE__ from './ai-answers-heading.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  createAction: () => void;
}

export default class AiAnswersHeading extends Component<Args> {
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  get disableAddProfileButton() {
    return (
      this.appService.app.canUseFeature('workflows_core_billing_feature') &&
      !this.appService.app.canUseFeature('self-serve-support-disable-profile-creation-killswitch')
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::AiAnswersHeading': typeof AiAnswersHeading;
  }
}
