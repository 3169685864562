/* import __COLOCATED_TEMPLATE__ from './workflow-sidedrawer.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type WorkflowSidedrawerService from 'embercom/services/workflow-sidedrawer-service';
import { action } from '@ember/object';

interface Args {
  afterSetLive?: () => void;
  afterSetPause?: () => void;
}

export default class WorkflowSidedrawer extends Component<Args> {
  @service declare workflowSidedrawerService: WorkflowSidedrawerService;

  get isOpen() {
    return this.workflowSidedrawerService.sidedrawerOpen;
  }

  @action
  onClose() {
    this.workflowSidedrawerService.teardown();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::WorkflowSidedrawer': typeof WorkflowSidedrawer;
  }
}
