/* import __COLOCATED_TEMPLATE__ from './template-card.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { type TEMPLATE_GROUPS } from 'embercom/lib/operator/custom-bots/constants';
import Component from '@glimmer/component';
import type Template from 'embercom/models/template';
import { AI_AGENT_EMAIL_TEMPLATE_ID } from 'embercom/lib/operator/resolution-bot/constants';

type Args = {
  onClick: () => void;
  onHover?: () => void;
  group: (typeof TEMPLATE_GROUPS)[number]; // TODO after constants are converted to TS use a proper type here
  template: Template;
  isShowCaseTemplate?: boolean;
  shouldShowPaywallBadges: boolean;
  isFinTemplate?: boolean;
};

export default class TemplateCard extends Component<{ Element: HTMLDivElement; Args: Args }> {
  get requiredBillingFeature(): string {
    return this.args.template.ruleset.rulesetLinks.firstObject.object.config.requiredBillingFeature;
  }

  get isFinOverEmailTemplate(): boolean {
    return this.args.template.id === AI_AGENT_EMAIL_TEMPLATE_ID;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::TemplateCard': typeof TemplateCard;
  }
}
