/* import __COLOCATED_TEMPLATE__ from './step-state-banner-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { computed } from '@ember/object';

export default Component.extend({
  intercomEventService: service(),
  router: service(),
  intl: service(),

  step: null,
  linkToText: null,
  linkToRoute: null,
  linkToQueryParams: null,

  ctaBodyText: null,
  bodyText: computed('intl.locale', 'ctaBodyText', function () {
    return this.ctaBodyText ?? this.intl.t('onboarding.home.step-state-banner-component.nice-work');
  }),

  init() {
    this._super(...arguments);
    if (this.step.completed) {
      this.set('isVisible', true);
    }
  },

  actions: {
    transitionToRoute(route) {
      this.intercomEventService.trackAnalyticsEvent({
        ...this.step.analyticsMetadata,
        action: 'clicked',
        object: `${this.step.analyticsMetadata.step_identifier}_cta`,
      });
      this.router.transitionTo(route, { queryParams: this.linkToQueryParams });
    },
  },
});
