/* import __COLOCATED_TEMPLATE__ from './notifications.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface Args {
  settings: $TSFixMe;
}

export default class Notifications extends Component<Args> {
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  @tracked emailNotificationDestinationUrl = this.args.settings.emailNotificationDestinationUrl;

  get app() {
    return this.appService.app;
  }

  @action
  toggleUserEmailFollowUps() {
    this.args.settings.set('userEmailFollowUps', !this.args.settings.userEmailFollowUps);
    taskFor(this.save).perform();
  }

  @action
  toggleUserTicketEmailFollowUps() {
    this.args.settings.set(
      'userTicketEmailFollowUps',
      !this.args.settings.userTicketEmailFollowUps,
    );
    taskFor(this.save).perform();
  }

  @action
  saveEmailNotificationOverrideUrl() {
    this.args.settings.set('emailNotificationDestinationUrl', this.emailNotificationDestinationUrl);
    taskFor(this.save).perform();
  }

  @dropTask
  *save(): TaskGenerator<any> {
    try {
      yield this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('apps.app.settings.email-notifications.not-updated'),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::Notifications': typeof Notifications;
  }
}
