/* import __COLOCATED_TEMPLATE__ from './avatar-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable promise/prefer-await-to-then */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import $ from 'jquery';
import { equal } from '@ember/object/computed';
import { defer } from 'rsvp';

export default Component.extend({
  init() {
    this._super(...arguments);
    this.set('fileWasChosen', this.get('identity.avatarType') === 'custom');
  },

  isDefault: equal('identity.avatarType', 'default'),

  setCustomAvatar: action(function (file) {
    this.set('identity.avatarUpload', file);
    this._readFileAsDataURL(file).then((dataURL) => this.onChange(dataURL));
  }),

  setCustomAvatarFromFilePicker: action(function (fileList) {
    if (fileList.length < 1) {
      this.set('fileWasChosen', false);
      this.setCustomAvatar(null);
    } else {
      this.set('fileWasChosen', true);
      this.setCustomAvatar(fileList[0]);
      this.set('identity.avatarType', 'custom');
    }
  }),

  actions: {
    openFilePicker() {
      $('input:file', this.element).click();
    },
  },

  _readFileAsDataURL(file) {
    let deferred = defer();
    let reader = new FileReader();
    reader.onload = (e) => deferred.resolve(e.target.result);
    reader.readAsDataURL(file);
    return deferred.promise;
  },
});
