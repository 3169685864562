/* import __COLOCATED_TEMPLATE__ from './unauthenticated-tooltip.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { isPresent } from '@ember/utils';

export default class UnauthenticatedTooltip extends Component {
  @service store;

  @tracked domains = [];

  constructor() {
    super(...arguments);
    this.loadSenderDomains.perform();
  }

  get hasAuthenticatedDomain() {
    return isPresent(this.domains.findBy('isAuthenticated'));
  }

  get hasNotCreatedAnEmail() {
    return !isPresent(this.domains.findBy('hasCreatedAnEmail'));
  }

  @task
  *loadSenderDomains() {
    this.domains = yield this.store.findAll('settings/sender-domain');
  }
}
