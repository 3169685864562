/* import __COLOCATED_TEMPLATE__ from './free-trial-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type FinTrialService from 'embercom/services/fin-trial-service';
import type IntlService from 'ember-intl/services/intl';

interface FreeTrialBannerArgs {
  hideBeforeTrialStarted?: boolean;
  hideAfterTrialStarted?: boolean;
  context?: 'profile' | 'workflows' | 'step' | 'setup';
}

interface Signature {
  Args: FreeTrialBannerArgs;
  Element: HTMLDivElement;
}

export default class FreeTrialBanner extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare router: $TSFixMe;
  @service declare finTrialService: FinTrialService;
  @service declare intl: IntlService;

  get showBanner() {
    return this.finTrialService.isTrialCustomer && !this.forceHideBanner;
  }

  get forceHideBanner() {
    return (
      (this.finTrialService.freeTrialNotStarted && this.args.hideBeforeTrialStarted) ||
      (!this.finTrialService.freeTrialNotStarted && this.args.hideAfterTrialStarted)
    );
  }

  get trialNotStartedMessage() {
    if (this.args.context === 'workflows') {
      return this.intl.t('operator.fin.free-trial.workflows.trial-start', {
        url: this._finOverviewUrl(),
        htmlSafe: true,
      });
    }
    if (this.args.context === 'step') {
      if (
        this.appService.app.hasAnswerBot &&
        !this.appService.app.usesResolutionsWithCustomAnswers
      ) {
        return this.intl.t('operator.fin.free-trial.step.legacy.trial-start', {
          url: this._finOverviewUrl(),
          htmlSafe: true,
        });
      }
      return this.intl.t('operator.fin.free-trial.step.trial-start', {
        url: this._finOverviewUrl(),
        htmlSafe: true,
      });
    }
    if (this.args.context === 'setup') {
      if (this.canUseWorkflows) {
        return this.intl.t('operator.fin.free-trial.setup.trial-start.workflows.paid');
      }
      return this.intl.t('operator.fin.free-trial.setup.trial-start.workflows.not-paid');
    }
    return this.intl.t('operator.fin.free-trial.trial-start');
  }

  get trialStartedMessage() {
    if (this.finTrialService.isSalesforceContracted) {
      if (this.args.context === 'setup') {
        if (this.finTrialService.isContractedForFin) {
          return this.intl.t('operator.fin.free-trial.salesforce.contracted');
        }
        return this.intl.t('operator.fin.free-trial.setup.started.sales-led');
      }
      if (this.finTrialService.isContractedForFin) {
        return this.intl.t('operator.fin.free-trial.workflows.salesforce.contracted', {
          url: this._finOverviewUrl(),
          htmlSafe: true,
        });
      }
      return this.intl.t('operator.fin.free-trial.salesforce.started', {
        url: this._finOverviewUrl(),
        htmlSafe: true,
      });
    }

    if (this.args.context === 'setup') {
      return this.intl.t('operator.fin.free-trial.setup.started.self-serve');
    }

    return this.intl.t('operator.fin.free-trial.self-serve.started', {
      url: this._finOverviewUrl(),
      htmlSafe: true,
    });
  }

  get trialExpiredMessage() {
    if (this.finTrialService.isSalesforceContracted) {
      if (this.args.context === 'workflows') {
        return this.intl.t('operator.fin.free-trial.workflows.salesforce.expired', {
          url: this._finOverviewUrl(),
          htmlSafe: true,
        });
      }
      if (this.args.context === 'step') {
        return this.intl.t('operator.fin.free-trial.step.salesforce.expired', {
          url: this._finOverviewUrl(),
          htmlSafe: true,
        });
      }
      if (this.args.context === 'setup') {
        if (this.canUseWorkflows) {
          return this.intl.t('operator.fin.free-trial.setup.expired.sales-led.workflows.paid');
        }
        return this.intl.t('operator.fin.free-trial.setup.expired.sales-led.workflows.not-paid');
      }
      return this.intl.t('operator.fin.free-trial.salesforce.expired', {
        url: this._finOverviewUrl(),
        htmlSafe: true,
      });
    }
    if (this.args.context === 'setup') {
      return this.intl.t('operator.fin.free-trial.setup.expired.self-serve');
    }
    return this.intl.t('operator.fin.free-trial.self-serve.expired', {
      url: this._finOverviewUrl(),
      htmlSafe: true,
    });
  }

  get canUseWorkflows() {
    return this.appService.app.canUseFeature('workflows_core_billing_feature');
  }

  @action
  viewReport() {
    this.router.transitionTo('apps.app.reports.customer-support.fin-ai-agent');
  }

  _finOverviewUrl() {
    return this.router.urlFor('apps.app.fin-ai-agent.setup');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::FreeTrialBanner': typeof FreeTrialBanner;
  }
}
