/* import __COLOCATED_TEMPLATE__ from './snippet-adder.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';

import type ContentSnippet from 'embercom/models/content-service/content-snippet';
import type IntlService from 'ember-intl/services/intl';
import { type RelevantContent } from 'embercom/lib/content-service/fin-answers-api';
import { EntityType } from 'embercom/models/data/entity-types';
import type Store from '@ember-data/store';

interface Args {
  onSave?: (snippet: ContentSnippet) => void;
  defaultTitle?: string;
}

export default class SnippetAdder extends Component<Args> {
  constructor(owner: unknown, args: Args) {
    super(owner, args);
  }
  @service declare store: Store;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare intl: IntlService;

  @tracked snippet?: ContentSnippet;
  @tracked isCreating = false;

  @action async openContentSnippetEditor(): Promise<void> {
    taskFor(this.createContentSnippet).perform();
    this.isCreating = true;
    this.trackAnalyticsEvent('clicked', 'create_snippet_button');
  }

  @action contentSnippetSaved(): void {
    this.isCreating = false;
    if (this.args.onSave) {
      this.args.onSave(this.snippet!);
    }
    this.trackAnalyticsEvent('clicked', 'save_button');
  }

  get snippetAsRelevantContent(): RelevantContent {
    return {
      entity_id: this.snippet!.id,
      entity_type: EntityType.ContentSnippet,
      title: this.snippet!.title || '',
    };
  }

  get isLoadingEditor(): boolean {
    return taskFor(this.createContentSnippet).isRunning;
  }

  @action snippetEditingCanceled(): void {
    this.isCreating = false;
    this.snippet = undefined;
  }

  @task *createContentSnippet(): TaskGenerator<void> {
    this.snippet = yield this.store.createRecord('content-service/content-snippet');
    if (this.args.defaultTitle !== undefined) {
      this.snippet!.title = this.args.defaultTitle;
    }
  }

  private trackAnalyticsEvent(action: string, object: string, metadata?: any): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      section: 'operator',
      context: 'inbox',
      place: 'fin_answer_debugger',
      ...metadata,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::AnswerDebugger::SnippetAdder': typeof SnippetAdder;
  }
}
