/* import __COLOCATED_TEMPLATE__ from './single-value-statistic-cell.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { statisticTypes, statisticKeys } from 'embercom/models/data/outbound/constants';
import { isEmpty } from '@ember/utils';
import _ from 'underscore';
import { isNullOrUndefined } from 'util';

export default class SingleValueStatisticCell extends Component {
  @service appService;

  get statisticTypeKey() {
    return this.args.statisticTypeKey;
  }

  get contentTypeUsesFailure() {
    return this.args.contentTypeUsesFailure;
  }

  get statisticTypes() {
    return statisticTypes;
  }

  get statisticCellId() {
    return this.args.statisticCellId;
  }

  get statistic() {
    if (this.contentTypeUsesFailure) {
      return this._failureStats;
    }

    // find in the passed in stats the object the matching stat and mutate it to set type to this.statisticType
    let stat = this.args.content.stats.find((stat) => stat.key === this.args.key);
    if (isEmpty(stat)) {
      return null;
    }
    return {
      id: stat.id,
      denominator: stat.denominator,
      value: stat.value,
      key: stat.key,
      type: this.statisticTypeKey,
    };
  }

  get _failureStats() {
    let failureStat = this.args.content.stats.find(
      (stat) => stat.key === statisticKeys.emailFailures,
    );

    let failureCategoryStatCount;
    if (!_.isEmpty(failureStat.subaggregations)) {
      let failureCategoryStat = failureStat.subaggregations.find(
        (subagg) => subagg.value === this.args.failureCategory,
      );

      failureCategoryStatCount = failureCategoryStat?.count;
    }

    return {
      id: failureStat.id,
      denominator: failureStat.denominator,
      value: failureCategoryStatCount || 0,
      type: this.statisticTypeKey,
      key: failureStat.key,
    };
  }

  get isSentStat() {
    return this.args.key === `${statisticKeys.sent}-${statisticTypes.integer}`;
  }

  get isZeroStat() {
    return !this.isSentStat && isNullOrUndefined(this.statistic.value);
  }

  get zeroPercentage() {
    return '0%';
  }
}
