/* import __COLOCATED_TEMPLATE__ from './alphanumeric-sender-info.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class SmsOutboundSettingsForm extends Component {
  @service intl;
  @service notificationsService;
  @tracked smsOutboundSettings = this.args.smsOutboundSettings;

  get characterCount() {
    return this.smsOutboundSettings.alphanumericSenderId
      ? this.smsOutboundSettings.alphanumericSenderId.trim().length
      : 0;
  }

  get isValidInput() {
    /* allowed characters: A-Z, a-z, 0-9, space, -, +, _, &
     * cannot contain only numbers
     * must be less than 11 characters
     */
    let re = /^(?![0-9]+$)[a-zA-Z0-9_+&\ -]{2,}$/;
    if (
      this.characterCount === 0 ||
      this.characterCount > 11 ||
      !re.test(this.smsOutboundSettings.alphanumericSenderId)
    ) {
      return false;
    }
    return true;
  }

  @task
  *handleSubmit() {
    // remove trailing spaces before saving
    this.smsOutboundSettings.alphanumericSenderId =
      this.smsOutboundSettings.alphanumericSenderId.trim();
    yield this.smsOutboundSettings
      .save()
      .then(() => {
        this.notificationsService.notifyConfirmation(
          this.intl.t(
            'outbound.settings.sms.accordion.alphanumeric-sender-info.notification.success',
          ),
        );
      })
      .catch((e) => {
        if (e.jqXHR.responseJSON.errors) {
          this.notificationsService.notifyError(e.jqXHR.responseJSON.errors);
          return;
        }
        this.notificationsService.notifyError(
          this.intl.t(
            'outbound.settings.sms.accordion.alphanumeric-sender-info.notification.error',
          ),
        );
      });
  }
}
