/* import __COLOCATED_TEMPLATE__ from './guide-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import ScrollToLevelUtils from 'embercom/lib/home/scroll-to-level';
import { action } from '@ember/object';
import { sectionHeader } from 'embercom/lib/home/sections';
import {
  FIN_PHASE_TWO,
  GUIDES_WITH_VIDEO_HEADER,
  FIN_GENERAL_RELEASE,
} from '../../../lib/home/guides';
import { tracked } from '@glimmer/tracking';

export default class GuideComponent extends Component {
  @service appService;
  @service notificationsService;
  @service onboardingHomeService;
  @service guideAssistantService;
  @service store;
  @service router;
  @service intl;
  @service intercomEventService;

  @tracked initiallyOpenLevel = null;

  constructor() {
    super(...arguments);
    this._setInitiallyOpenLevel();
    this._resetBannerState();
  }

  get showVideoHeader() {
    return GUIDES_WITH_VIDEO_HEADER.includes(this.guide.identifier);
  }

  get guide() {
    return this.onboardingHomeService.guide;
  }

  get isFinGuide() {
    return this.guide.identifier === FIN_PHASE_TWO;
  }

  get isFinGeneralReleaseGuide() {
    return this.guide.identifier === FIN_GENERAL_RELEASE;
  }

  get videoId() {
    if ([FIN_PHASE_TWO, FIN_GENERAL_RELEASE].includes(this.guide.identifier)) {
      return '051oh49ne1';
    }
    return 'sb2mx33k1p';
  }

  get formattedSectionHeadersWithLevels() {
    let result = [];
    this.guide.levels.forEach((level) => {
      let { guide_section } = level;
      if (!result[guide_section]) {
        result[guide_section] = {
          title: this.intl.t(
            `onboarding.home.sections.${sectionHeader(
              this.guide.identifier,
              guide_section,
              this.guide.forceAllGuide,
            )}`,
          ),
          levels: [level],
        };
      } else {
        result[guide_section].levels.push(level);
      }
    });
    return result;
  }

  @dropTask
  *onStepComplete() {
    yield this.guideAssistantService.refreshScript();
  }

  @action
  scrollToInitiallyOpenLevel() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'index',
      section: 'index',
      guide_identifier: this.guide.identifier,
    });

    if (this.initiallyOpenLevel) {
      ScrollToLevelUtils.scrollToLevel(this.initiallyOpenLevel.get('identifier'));
    }
  }

  @action
  openSection(identifier) {
    let eventName = null;

    switch (identifier) {
      case 'setup_support_channels_exp_jan_2023':
      case 'setup_intercom_messenger_exp_jan_2023':
      case 'add_channels_to_inbox_exp_jan_2023':
      case 'm23c_setup_messenger':
      case 'm23c_get_most_of_inbox':
      case 'get_ahead_with_proactive_support_fin_release':
        eventName = 'home_visit_get_set_up';
        break;
      case 'integrate_with_tools_exp_jan_2023':
      case 'setup_help_centre_exp_jan_2023':
      case 'power_intercom_data_exp_jan_2023':
      case 'm23c_power_intercom_data':
        eventName = 'home_visit_getting_more';
        break;
    }
    this.onboardingHomeService.createPageViewForLevel.perform(identifier, eventName);
  }

  _setInitiallyOpenLevel() {
    if (this.args.initiallyOpenStep) {
      this.intercomEventService.trackAnalyticsEvent({
        ...this.args.initiallyOpenStep.analyticsMetadata,
        action: 'viewed',
        object: this.args.initiallyOpenStep.identifier,
        section: 'inline_step',
      });
      return this._setInitiallyOpenLevelAndCreatePageView(this.args.initiallyOpenStep.level);
    }

    return this._setInitiallyOpenLevelAndCreatePageView(this.guide.latestAvailableLevel);
  }

  _setInitiallyOpenLevelAndCreatePageView(level) {
    if (!level) {
      return;
    }
    this.initiallyOpenLevel = level;
    this.onboardingHomeService.createPageViewForLevel.perform(level.get('identifier'));
  }

  _resetBannerState() {
    localStorage.removeItem('show-banner-for-onboarding-step');
  }
}
