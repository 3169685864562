/* import __COLOCATED_TEMPLATE__ from './attribute-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable promise/prefer-await-to-then */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class AttributeSelector extends Component {
  @service attributeService;
  @service modalService;
  @service permissionsService;
  @tracked showAttributeCreationTypeModal = false;
  @tracked showConversationAttributeCreationModal = false;

  @action
  openAttributeModal(type) {
    this.showAttributeCreationTypeModal = false;
    this.permissionsService.checkPermission('can_access_product_settings').then(() => {
      if (type === 'conversation') {
        this.showConversationAttributeCreationModal = true;
      } else {
        this.modalService.openModal('settings/modals/attribute-details', {
          allowArchival: false,
          typeToCreate: type,
          availableTypes: this.args.availableTypes,
          showPeopleAndCompanyToggleTab: false,
          onSave: this.attributeCreated,
        });
      }
    });
  }

  @action
  attributeCreated(attribute) {
    this.attributeCreatedCallbacks(attribute);
  }

  @action
  conversationAttributeCreated(descriptor) {
    let attribute = this.attributeService.findByIdentifier(descriptor.identifier);
    this.attributeCreatedCallbacks(attribute);
  }

  attributeCreatedCallbacks(attribute) {
    this.args.onSelectAttribute(attribute);
    if (this.args.onAttributeCreated) {
      this.args.onAttributeCreated(attribute);
    }
  }
}
