/* import __COLOCATED_TEMPLATE__ from './visible-to-you.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type TabItem } from 'embercom/components/new-settings/common/standard-base';

interface Args {}

interface Signature {
  Args: Args;
  Blocks: any;
}

const TABS: TabItem[] = [
  {
    label: 'new-settings.personal.visible-to-you.tab-group.company-segments',
    value: 'company-segments',
  },
  {
    label: 'new-settings.personal.visible-to-you.tab-group.user-segments',
    value: 'user-segments',
  },
  {
    label: 'new-settings.personal.visible-to-you.tab-group.tags',
    value: 'tags',
  },
];

export default class VisibleToYou extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare router: RouterService;

  get tabs() {
    let tabs = TABS;
    if (!this.appService.app.companies_enabled) {
      return tabs.filter((tab: $TSFixMe) => tab.value !== 'company-segments');
    }
    return tabs;
  }

  get selectedTab(): string {
    let routeTabMap: Record<string, string> = {
      'apps.app.settings.personal.visible-to-you.company-segments': 'company-segments',
      'apps.app.settings.personal.visible-to-you.user-segments': 'user-segments',
      'apps.app.settings.personal.visible-to-you.tags': 'tags',
    };
    return routeTabMap[this.router.currentRouteName];
  }

  @action
  onChangeTab(tab: string) {
    this.router.transitionTo(`apps.app.settings.personal.visible-to-you.${tab}`);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Personal::VisibleToYou': typeof VisibleToYou;
    'new-settings-personal-visible-to-you': typeof VisibleToYou;
  }
}
