/* import __COLOCATED_TEMPLATE__ from './duplicate-custom-bot-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';
import Component from '@glimmer/component';
import { OUTBOUND_EDITOR_ROUTES } from 'embercom/models/outbound/content-wrapper';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import { objectNames } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';

export default class DuplicateCustomBotSelector extends Component {
  @service appService;
  @service notificationsService;
  @service store;
  @service router;
  @service intl;
  @service intercomEventService;

  @tracked allCustomBots = [
    {
      value: null,
      component: 'outbound/new-content-modal/duplicate-bot-loading-state',
      componentShouldReplaceItem: true,
    },
  ];
  @tracked selectedBot;

  @action
  onOpen() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'duplicate_custom_bot',
      action: 'opened',
      custom_bot_target: this.args.selectedCustomBotTarget,
    });
    this.fetchAllCustomBots.perform();
  }

  @action
  onBotSelect(selectedBot) {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'duplicate_custom_bot',
      action: 'clicked',
      custom_bot_target: this.args.selectedCustomBotTarget,
      custom_bot_id: selectedBot.id,
    });
    this._duplicateFromBot.perform(selectedBot);
  }

  @task
  *_duplicateFromBot(selectedBot) {
    this.selectedBot = selectedBot;
    let { matchBehavior, objectType, target } =
      CUSTOM_BOT_CONFIG[this.args.selectedCustomBotTarget];

    let ruleset = yield this.store.findRecord('matching-system/ruleset', selectedBot.ruleset_id);

    this.notificationsService.clear();
    try {
      let result = yield ruleset.duplicate({
        new_object_type: objectType,
        new_object_data: {
          duplicating: true,
          new_object_type: objectType,
          original_custom_bot_id: selectedBot.id,
          target,
        },
        match_behavior: matchBehavior,
      });

      if (result.id) {
        this.notificationsService.notifyConfirmation(
          this.intl.t('outbound.new-content-modal.duplicate-custom-bot-selector.success'),
        );
        let routeName = OUTBOUND_EDITOR_ROUTES[objectNames[objectType]];
        this.router.transitionTo(routeName, result.id, {
          queryParams: { mode: 'edit' },
        });
      }
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('outbound.new-content-modal.duplicate-custom-bot-selector.failure'),
      );
    }
  }

  @task({ drop: true })
  *fetchAllCustomBots() {
    let allBots = yield ajax({
      url: '/ember/operator_custom_bots/duplicate_bot_list',
      type: 'GET',
      data: {
        selected_target: this.args.selectedCustomBotTarget,
        app_id: this.appService.app.id,
      },
    });

    this.allCustomBots = allBots
      .map((bot) => {
        let { is_disabled: isDisabled, title: text } = bot;
        return {
          text,
          value: bot,
          isDisabled,
          tooltipText:
            isDisabled &&
            this.intl.t(
              'outbound.new-content-modal.duplicate-custom-bot-selector.disabled-message',
            ),
        };
      })
      .sort((a, b) => Number(a.isDisabled) - Number(b.isDisabled));
  }
}
