/* import __COLOCATED_TEMPLATE__ from './self-assign-section.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import { action } from '@ember/object';
import Component from '@glimmer/component';

interface Args {
  selfAssignedSelection: string;
  saveSelfAssignSetting?: (value?: boolean) => void;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class SelfAssignSection extends Component<Signature> {
  @action
  saveSelfAssignSetting(value: boolean) {
    if (this.args.saveSelfAssignSetting) {
      this.args.saveSelfAssignSetting(value);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::TabsComponents::SelfAssignSection': typeof SelfAssignSection;
  }
}
