/* import __COLOCATED_TEMPLATE__ from './content.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import type Owner from '@ember/owner';
import { action } from '@ember/object';

export interface Args {}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class Content extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  attachInlineImagesSetting: boolean;
  migrateAttachmentsSetting: boolean;

  constructor(owner: Owner, args: Args) {
    super(owner, args);
    this.attachInlineImagesSetting = this.importConfigurationService.attachInlineImagesSetting;
    this.migrateAttachmentsSetting = this.importConfigurationService.migrateAttachmentsSetting;
  }

  @action toggleAttachInlineImagesSetting() {
    this.importConfigurationService.setAttachInlineImagesSetting(!this.attachInlineImagesSetting);
  }

  @action toggleMigrateAttachmentsSetting() {
    this.importConfigurationService.setMigrateAttachmentsSetting(!this.migrateAttachmentsSetting);
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.sections.ticket.content.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Ticket::Content': typeof Content;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/ticket/content': typeof Content;
  }
}
