/* import __COLOCATED_TEMPLATE__ from './help-center.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  helpCenters: { text: string; value: string }[];
  selectedHelpCenter?: HelpCenterSite;
  onChangeHelpCenter: (helpCenter: string) => void;
}

interface Signature {
  Args: Args;
  Element: Element;
}

const TicketsPortalHelpCenter = templateOnlyComponent<Signature>();

export default TicketsPortalHelpCenter;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tickets::TicketsPortal::HelpCenter': typeof TicketsPortalHelpCenter;
    'new-settings/helpdesk/tickets/tickets-portal/help-center': typeof TicketsPortalHelpCenter;
  }
}
