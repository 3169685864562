/* import __COLOCATED_TEMPLATE__ from './relevant-passage.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import Component from '@glimmer/component';
import { EntityType } from 'embercom/models/data/entity-types';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    entity_type: EntityType;
    passage: string;
  };
}

export default class RelevantPassage extends Component<Signature> {
  get parsedPassage(): string {
    switch (this.args.entity_type) {
      case EntityType.ArticleContent: {
        return this.removeTitleFromPassage(this.args.passage, '</h1>');
      }
      case EntityType.ContentSnippet: {
        let answerBlockTag = '&lt;b&gt;Answer:&lt;/b&gt; ';
        if (this.args.passage.includes(answerBlockTag)) {
          return this.removeAnswerBlockFromPassage(this.args.passage, answerBlockTag);
        } else {
          return this.removeTitleFromPassage(this.args.passage, '</h1>');
        }
      }
      default: {
        return this.args.passage;
      }
    }
  }

  removeTitleFromPassage(passage: string, titleTag: string): string {
    if (passage.includes(titleTag)) {
      let endOfTitleBlock = passage.indexOf(titleTag) + titleTag.length;
      return passage.slice(endOfTitleBlock);
    } else {
      return passage;
    }
  }

  removeAnswerBlockFromPassage(passage: string, answerBlockTag: string): string {
    let pTag = '<p>';
    let startOfAnswerBlockTag = passage.indexOf(answerBlockTag) - pTag.length;
    let passageMinusAnswerBlock = passage.replace(answerBlockTag, '');
    return passageMinusAnswerBlock.slice(startOfAnswerBlockTag);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::AnswerDebugger::RelevantPassage': typeof RelevantPassage;
  }
}
