/* import __COLOCATED_TEMPLATE__ from './trial-start-success.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type FinTrialService from 'embercom/services/fin-trial-service';
import { FIN_PRICE_PER_RESOLUTION } from 'embercom/lib/billing';

interface Arguments {
  isOpen: boolean;
  onModalClose: () => void;
}

interface Signature {
  Args: Arguments;
  Element: HTMLDivElement;
}

export default class TrialStartSuccess extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare finTrialService: FinTrialService;
  @service declare appService: any;
  @service declare intercomEventService: any;
  @service declare purchaseAnalyticsService: any;

  get pricePerResolution() {
    return FIN_PRICE_PER_RESOLUTION;
  }

  get finPricingLearnMoreUrl() {
    return this.appService.app.finPricingLearnMoreUrl;
  }

  @action
  trackAnalyticsAndCloseModal() {
    this.purchaseAnalyticsService.trackEvent({
      action: 'viewed',
      place: 'fin_trial_start_success_modal',
      object: 'fin_trial_start_success_modal_closed',
    });

    this.args.onModalClose();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Modals::TrialStartSuccess': typeof TrialStartSuccess;
  }
}
