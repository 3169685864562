/* import __COLOCATED_TEMPLATE__ from './overview-step.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Signature {
  Args: any;
}

export default class OverviewStep extends Component<Signature> {
  @action onForwardEmailsClick() {
    //TODO: Implement
  }

  @action onSendEmailsClick() {
    //TODO: Implement
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::OverviewStep': typeof OverviewStep;
    'new-settings/channels/email/connect-email/overview-step': typeof OverviewStep;
  }
}
