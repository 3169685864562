/* import __COLOCATED_TEMPLATE__ from './drag-and-drop.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { ref } from 'ember-ref-bucket';
import ajax from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';

export default class DragAndDrop extends Component {
  @tracked file;
  @tracked isDragging = false;

  @ref('inputElement') inputElement;

  @service appService;

  @dropTask *uploadImage(fileData) {
    yield ajax({
      url: `/ember/sms/sender_supporting_documents`,
      type: 'POST',
      data: this.filePayload(fileData),
    })
      .then((response) => {
        this.handleUploadSuccess(response);
      })
      .catch((error) => {
        this.handleUploadError(error);
      });
  }

  filePayload(fileData) {
    return JSON.stringify({
      file_data: fileData.split(',')[1],
      content_type: this.file.type,
      id: `${this.appService.app.id}-${this.file.name}`,
      app_id: this.appService.app.id,
    });
  }

  handleUploadError(error) {
    this.clearFileInput();
  }

  handleUploadSuccess(response) {
    this.args.onImageUpload(response.url);
  }

  pickFile() {
    if (typeof this.args.pickFile === 'function') {
      // for testing component
      return this.args.pickFile.call(this);
    }
    this.clearFileInput();
    this.inputElement.click();
  }

  setFile(file) {
    this.file = file;
    let reader = new FileReader();
    reader.onload = (e) => {
      this.uploadImage.perform(e.target.result);
    };
    reader.readAsDataURL(file);
  }

  clearFileInput() {
    this.inputElement.value = '';
  }

  @action
  dragOver(e) {
    e.preventDefault();
    this.isDragging = true;
  }

  @action
  dragLeave(e) {
    e.preventDefault();
    this.isDragging = false;
  }

  @action
  drop(e) {
    e.preventDefault();
    this.isDragging = false;
    this.setFile(e.dataTransfer.files[0]);
  }

  @action
  change(e) {
    if (e.target.files.length) {
      this.setFile(e.target.files[0]);
    }
  }

  @action
  showFilePicker() {
    if (this.uploadImage.isRunning) {
      return;
    }
    this.pickFile();
  }
}
