/* import __COLOCATED_TEMPLATE__ from './authorization-screen.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed, action } from '@ember/object';
import { or, alias, map, not, readOnly, equal } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import ENV from 'embercom/config/environment';
import { task } from 'ember-concurrency';

export default Component.extend({
  isRedirecting: false,
  redirectService: service(),
  store: service(),
  regionService: service(),
  notificationsService: service(),
  intercomEventService: service(),
  adminId: readOnly('authorizationRequest.adminId'),
  adminEmailVerified: readOnly('authorizationRequest.adminEmailVerified'),
  adminEmailUnverified: not('adminEmailVerified'),
  adminEmailPendingVerification: readOnly('authorizationRequest.adminEmailPendingVerification'),
  appPackageName: alias('authorizationRequest.appPackageName'),
  appPackageId: alias('authorizationRequest.appPackageId'),
  appPackageIconUrl: alias('authorizationRequest.appPackageIconUrl'),

  apps: map('authorizationRequest.allApps', function (app) {
    return {
      text: app.name,
      value: app.idCode,
    };
  }),

  isDisabled: or('isRedirecting', 'adminEmailUnverified'),
  isInterconnectedApp: equal('appPackageId', 52478),
  isIntercomForumApp: equal('appPackageId', 33303),
  isIntercomForumTestApp: equal('appPackageId', 68615),
  isAcademyApp: equal('appPackageId', 38041),
  isIntercomInsidedCommunityApp: equal('appPackageId', 93581),
  shouldByPassPermissions: or(
    'isAcademyApp',
    'isInterconnectedApp',
    'isIntercomForumApp',
    'isIntercomForumTestApp',
    'isIntercomInsidedCommunityApp',
  ),
  selectedAppId: or(
    'authorizationRequest.initiallySelectedAppIdCode',
    'authorizationRequest.allApps.firstObject.idCode',
  ),

  selectedApp: computed('authorizationRequest.allApps', 'selectedAppId', function () {
    return this.get('authorizationRequest.allApps').findBy('idCode', this.selectedAppId);
  }),

  selectedAppIsConnectable: readOnly('selectedApp.connectable'),

  shouldDisplayWarning: computed(
    'selectedApp.idCode',
    'regionService.intercomAppCode',
    function () {
      return (
        this.get('selectedApp.idCode') === this.regionService.intercomAppCode &&
        ENV.environment !== 'development'
      );
    },
  ),

  privacyPolicyUrl: readOnly('authorizationRequest.appPackagePrivacyPolicyUrl'),
  termsOfServiceUrl: readOnly('authorizationRequest.appPackageTermsOfServiceUrl'),

  intercomCommunityPrivacyPolicyUrl: 'https://www.intercom.com/legal/privacy',
  intercomCommunityTermsOfServiceUrl: 'https://www.intercom.com/legal/terms-and-policies',

  async validateScopes() {
    let existingScopes = this.authorizationRequest.appPackageOauthScopeCategories.map(
      (category) => category.identifier,
    );
    let currentAuthorizationRequest = await this.store.queryRecord('oauth/authorization-request', {
      client_id: this.clientId,
    });
    let newCategory = currentAuthorizationRequest.appPackageOauthScopeCategories.find(
      (category) => existingScopes.includes(category.identifier) === false,
    );
    if (newCategory) {
      this.notificationsService.notifyWarning(
        'Authorization scopes have changed, please review and reauthorize',
      );
      this.set('authorizationRequest', currentAuthorizationRequest);
      return false;
    }
    return true;
  },

  authorize: task(function* (params) {
    let authorizationScopeIsValid = yield this.validateScopes();

    if (!authorizationScopeIsValid) {
      return;
    }

    if (!this.shouldByPassPermissions) {
      let adminHasModifyAppPermissions = yield this.adminHasPermissionToInstall();
      if (!adminHasModifyAppPermissions) {
        this.notificationsService.notifyError(
          `Sorry, you do not have permissions to modify this app installation.`,
        );
        return;
      }
    }

    try {
      let response = yield ajax({
        url: '/ember/oauth/connect',
        type: 'POST',
        contentType: 'application/json',
        data: JSON.stringify({
          app_id: this.selectedAppId,
          client_id: this.clientId,
          state: this.state,
          redirect_uri: this.redirectURI,
        }),
      });
      this.trackAuthorizedEvent();
      this.set('isRedirecting', true);
      this.redirectService.redirect(response.redirect_url);
    } catch (response) {
      this.set('isRedirecting', false);
      this.notificationsService.notifyError(
        `Something went wrong and we couldn't allow authorizing your request.`,
      );
    }
  }).drop(),

  trackAuthorizedEvent() {
    let analytics = {
      action: 'authorized',
      object: 'button',
      section: 'authorization_screen',
      app_package_name: this.appPackageName,
    };
    this.intercomEventService.trackAnalyticsEvent(analytics);
  },

  async adminHasPermissionToInstall() {
    if (
      this.selectedApp.is_test_app ||
      this.selectedApp.idCode === this.regionService.intercomAppCode
    ) {
      return true;
    }
    let app = await this.store.findRecord('app', this.selectedAppId);
    let admin = app.admins.findBy('id', this.adminId);
    let adminPermissionsForApp = admin.app_permissions.findBy('app_id', this.selectedAppId);
    return adminPermissionsForApp && adminPermissionsForApp['can_manage_apps_and_integrations'];
  },

  didInsertElement() {
    this._super(...arguments);
    this.set('isRedirecting', false);
    document.querySelector('body').style.minWidth = '0px';
  },

  willDestroyElement() {
    this._super(...arguments);
    this.set('isRedirecting', false);
  },

  didDestroyElement() {
    this._super(...arguments);
    document.querySelector('body').style.minWidth = '';
  },

  sendVerificationEmailToEndpoint(endpoint) {
    return ajax({
      type: 'PUT',
      url: `/ember/admins/${endpoint}`,
      data: JSON.stringify({
        id: this.adminId,
        app_id: this.selectedAppId,
      }),
    });
  },

  cancel: action(function () {
    window.history.back();
  }),

  changeRegion: action(function (newRegion) {
    let baseUrl;
    if (ENV.environment === 'development' || ENV.environment === 'test') {
      baseUrl = 'http://intercom.test';
    } else {
      baseUrl = this.regionService.appUrl(newRegion);
    }
    let redirectUrl = `${baseUrl}${window.location.pathname}${window.location.search}`;
    window.location = redirectUrl;
  }),

  sendVerificationEmail: task(function* () {
    try {
      if (this.adminEmailPendingVerification) {
        yield this.sendVerificationEmailToEndpoint('resend_verification_email');
      } else {
        if (this.adminEmailVerified || this.adminEmailPendingVerification) {
          return;
        }
        yield this.sendVerificationEmailToEndpoint('send_verification_email');
      }
      this.notificationsService.notifyConfirmation(
        'Verification email sent. Please check your email.',
      );
    } catch (e) {
      this.notificationsService.notifyError(
        'Something went wrong sending your verfication email. Please try again.',
      );
    }
  }),
});
