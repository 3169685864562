/* import __COLOCATED_TEMPLATE__ from './section-style-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';

export default class SectionStyleSettings extends Component {
  colorOptions = DEFAULT_MESSENGER_COLORS;

  get selectedSection() {
    return this.args.composerApi?.composer.state.selectedSection?.node;
  }

  get sectionTypeLocalisationKey() {
    let sectionType = this.selectedSection?.attrs?.sectionType;
    if (sectionType !== undefined) {
      return `outbound.settings.email-templates.section-sidebar.section-type.${sectionType}`;
    } else {
      return `outbound.settings.email-templates.section-sidebar.section-type.content`;
    }
  }

  get selectedBackgroundColor() {
    return this.args.composerApi?.composer.state.sectionBackgroundColor;
  }

  get selectedPadding() {
    return this.args.composerApi?.composer.state.sectionPadding;
  }

  @action
  changeColor(newColor) {
    if (!this.args.composerApi || newColor === this.selectedBackgroundColor) {
      return;
    }

    this.args.composerApi.composer.commands.styling.updateSectionBackgroundColor(newColor);
    this.args.composerApi.composer.commands.focus();
  }

  @action
  changePadding(padding) {
    if (!this.args.composerApi || padding === this.selectedPadding) {
      return;
    }

    this.args.composerApi.composer.commands.styling.updateSectionPadding(padding);
    this.args.composerApi.composer.commands.focus();
  }

  @action
  hideSectionType() {
    this.args.composerApi?.composer.hideSectionType();
    this.args.composerApi?.composer.commands.collapseSelection();
  }
}
