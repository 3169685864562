/* import __COLOCATED_TEMPLATE__ from './app-workflow-template-cards.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AppWorkflowTemplateCards extends Component {
  @service router;
  @service intercomEventService;

  @action
  redirectToAppStoreApp(appPackageCode) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      section: 'installed_apps',
      place: 'workflows',
      object: 'app_workflow_template_card',
      context: 'workflow.installed_apps.card',
      app_package_id: appPackageCode,
    });

    return this.router.transitionTo('apps.app.appstore', {
      queryParams: { app_package_code: appPackageCode },
    });
  }
}
