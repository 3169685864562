/* import __COLOCATED_TEMPLATE__ from './library-card.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import Component from '@glimmer/component';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  title: string;
  level: any;
  description: string;
  route: string;
  disabled?: boolean;
  onAction?: any;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class LibraryCard extends Component<Signature> {
  @service declare router: Router;
  @service declare intercomEventService: any;
  @service declare onboardingHomeService: any;
  @service declare appService: any;
  @service declare intl: IntlService;

  get imageUrl() {
    return assetUrl(
      `/assets/images/onboarding/guide-library/copilot/${this.args.level.identifier}.png`,
    );
  }

  get guide(): any {
    return this.onboardingHomeService.guide;
  }

  get wizardSteps() {
    return this.args.level.steps.filter((step: any) => !step.stepData.isDisplayOnlyStep);
  }

  get displayOnlySteps() {
    return this.args.level.steps.filter((step: any) => step.stepData.isDisplayOnlyStep);
  }

  get levelHasCompletedStep() {
    let wizardFreeLevelsToSkip = [
      'evaluation_wizard_chatbot_v2',
      'evaluation_wizard_helpdesk_tickets',
    ];
    if (wizardFreeLevelsToSkip.includes(this.args.level.identifier)) {
      return this.displayOnlySteps.some((step: any) => step.state === 'completed');
    }

    return (
      this.wizardSteps.some((step: any) => step.state === 'completed') ||
      this.wizardSteps.length === 0
    );
  }

  get title() {
    return this.args.title;
  }

  get showStepActions() {
    return this.levelHasCompletedStep || this.args.level.isInProgress;
  }

  @action onWizardEntered() {
    if (this.showStepActions) {
      return;
    }

    this.triggerWizardEnteredAnalytics();
    this.args.level.set('isInProgress', true);

    if (
      ['evaluation_wizard_helpdesk_tickets', 'evaluation_wizard_chatbot_v2'].includes(
        this.args.level.identifier,
      )
    ) {
      let firstStep = this.args.level.steps.firstObject;
      firstStep.stepData.displayOnlyCallback(this.router, firstStep);
      return;
    }

    this.router.transitionTo(this.args.route, this.appService?.app.id, {
      queryParams: { wizardId: this.args.level.identifier, stepId: null, source: null },
    });
  }

  @action triggerWizardEnteredAnalytics() {
    if (this.showStepActions) {
      return;
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `wizard.${this.args.level.identifier}.get_started`,
      context: `onboarding_guide_library.library.wizard_get_started`,
      place: 'guide-library',
      section: 'guide_library_home',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::LibraryCard': typeof LibraryCard;
  }
}
