/* import __COLOCATED_TEMPLATE__ from './templates.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import filterTemplates from 'embercom/lib/outbound/email-template-helper';
import { restartableTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type IntlService from 'embercom/services/intl';

interface Args {
  emailTemplatesSettings: $TSFixMe;
  emailTemplateTitles: $TSFixMe;
}

export default class Templates extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked showSideDrawer = false;

  get app() {
    return this.appService.app;
  }

  get selectListItems() {
    return this.templatesAvailableForAppDefault.map((template: $TSFixMe) => {
      return {
        text: template.name,
        value: template.id,
      };
    });
  }

  get templatesAvailableForAppDefault() {
    let emailTemplates = this.args.emailTemplateTitles.filter((template: $TSFixMe) => {
      return !template.isHtmlMode;
    });
    emailTemplates = filterTemplates({
      templates: emailTemplates,
      selectedTemplateIdName: this.args.emailTemplatesSettings.default_email_template,
    });

    if (!this.app.canUseEmailPremadeTemplateMigration) {
      emailTemplates = emailTemplates.filter((template: $TSFixMe) => {
        return !template.isBlockTemplate;
      });
    }
    return emailTemplates;
  }

  @action
  onSelectItem(value: string) {
    this.args.emailTemplatesSettings.default_email_template = value;
    taskFor(this.save).perform();
  }

  @restartableTask
  *save(): TaskGenerator<any> {
    try {
      yield this.args.emailTemplatesSettings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
      // Update the app model with the newly stored default template.
      // This avoids having to reload the app model for this setting to take effect
      // when composing emails from the user list
      let newDefaultTemplate = this.args.emailTemplateTitles.findBy(
        'id',
        this.args.emailTemplatesSettings.default_email_template,
      );
      this.app.set('default_email_template_id', newDefaultTemplate.id);
      this.app.set('default_email_template', newDefaultTemplate.name);
    } catch (error) {
      this.notificationsService.notifyError(error.jqXHR.responseJSON.error);
    }
  }

  @action
  openSideDrawer() {
    this.showSideDrawer = true;
  }

  @action
  closeSideDrawer() {
    this.showSideDrawer = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::Templates': typeof Templates;
  }
}
