/* import __COLOCATED_TEMPLATE__ from './nav-progress-badge.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type GuideLibraryService from 'embercom/services/guide-library-service';

interface Args {}

interface Signature {
  Args: Args;
  Element: never;
}

export default class NavProgressBadge extends Component<Signature> {
  @service declare guideLibraryService: GuideLibraryService;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::NavProgressBadge': typeof NavProgressBadge;
  }
}
