/* import __COLOCATED_TEMPLATE__ from './labels-select.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class LabelsSelect extends Component {
  @service store;
  @service intl;
  @service newsLabelsService;

  @tracked labels = [];

  constructor() {
    super(...arguments);
    this.newsLabelsService.loadLabels.perform();
  }

  get selectedLabelIds() {
    return this.args.newsItem.labelIds || [];
  }

  get items() {
    return this.newsLabelsService.labels.map(({ name, id }) => {
      let selectedIndex = this.selectedLabelIds.indexOf(Number(id));
      let isSelected = selectedIndex !== -1;
      return {
        value: Number(id),
        text: isSelected ? `${name} (${selectedIndex + 1})` : name,
        isSelected,
      };
    });
  }

  get label() {
    if (this.selectedLabelIds.length > 0) {
      return this.intl.t('outbound.news-items.edit.sidebar.labels.selected', {
        count: this.selectedLabelIds.length,
      });
    }
    return this.intl.t('outbound.news-items.edit.sidebar.labels.placeholder');
  }

  @action
  onSelectItem(labelIds) {
    let newLabelIds = labelIds.filter((id) => !this.selectedLabelIds.includes(id));
    let oldLabelIds = this.selectedLabelIds.filter((id) => labelIds.includes(id));
    this.args.newsItem.labelIds = [...oldLabelIds, ...newLabelIds];
  }
}
