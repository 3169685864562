/* import __COLOCATED_TEMPLATE__ from './response-behaviours-switch.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { responseProcessingBehaviours } from 'embercom/models/data/sms/constants';
import type IntlService from 'ember-intl/services/intl';
import { type ButtonType } from '@intercom/pulse/template-registry';

interface Args {
  smsOutboundSettings: any;
}

export default class ResponseBehavioursSwitch extends Component<Args> {
  responseProcessingBehaviours = responseProcessingBehaviours;

  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare appService: $TSFixMe;

  @tracked deflectToInboxIsOn;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.smsOutboundSettings) {
      this.deflectToInboxIsOn =
        this.args.smsOutboundSettings.responseProcessingBehaviour ===
        this.responseProcessingBehaviours.deflectToInbox;
    } else {
      this.deflectToInboxIsOn = false;
    }
  }

  get toggleButtonType() {
    if (this.deflectToInboxIsOn) {
      return {
        type: 'secondary' as ButtonType,
        label: this.intl.t(
          'outbound.settings.sms.accordion.response-behaviours-switch.action-button-text',
          { buttonLabel: 'off' },
        ),
      };
    } else {
      return {
        type: 'primary-live' as ButtonType,
        label: this.intl.t(
          'outbound.settings.sms.accordion.response-behaviours-switch.action-button-text',
          { buttonLabel: 'on' },
        ),
      };
    }
  }

  @action
  async toggleResponseBehaviour() {
    if (this.deflectToInboxIsOn) {
      this.deflectToInboxIsOn = false;
      this.args.smsOutboundSettings.responseProcessingBehaviour =
        this.responseProcessingBehaviours.keywordResponse;
    } else {
      this.deflectToInboxIsOn = true;
      this.args.smsOutboundSettings.responseProcessingBehaviour =
        this.responseProcessingBehaviours.deflectToInbox;
    }
    await this.args.smsOutboundSettings.save();
    this.notificationsService.notifyConfirmation(
      this.intl.t(
        'outbound.settings.sms.accordion.response-behaviours-switch.notify-settings-updated',
      ),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::Settings::Sms::ResponseBehavioursSwitch': typeof ResponseBehavioursSwitch;
  }
}
