/* import __COLOCATED_TEMPLATE__ from './resources-card-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import { action } from '@ember/object';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

interface Signature {
  Args: Args;
  Element: never;
}

interface Resource {
  id: string;
  link: string;
  performOnlyCallback?: boolean;
  callback?: () => void;
  icon: InterfaceIconName;
  title: string;
  description: string;
}

interface Args {
  resource: Resource;
}

export default class ResourcesCardComponent extends Component<Signature> {
  @service declare router: Router;
  @service declare intercomEventService: any;

  @action
  handleClick(event: MouseEvent) {
    if (this.args.resource.performOnlyCallback && this.args.resource.callback) {
      event.preventDefault();
      event.stopPropagation();
      this.args.resource.callback();
    }
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `more_resources.${this.args.resource.id}`,
      context: 'onboarding_guide_library.library.resource_card_component',
      place: 'guide_library_home',
      section: 'resource_card',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::ResourcesCardComponent': typeof ResourcesCardComponent;
  }
}
