/* import __COLOCATED_TEMPLATE__ from './card-setting-container.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    title: string;
    description?: string;
    targetIdentifier?: string;
    tabStyles?: string;
    classNames?: string;
  };
  Blocks: {
    extras: [];
    content: [];
    description: [];
  };
}

const CardSettingContainer = templateOnlyComponent<Signature>();
export default CardSettingContainer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Common::CardSettingContainer': typeof CardSettingContainer;
  }
}
