/* import __COLOCATED_TEMPLATE__ from './other.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  initialInactivityEnabled: boolean;
  initialInactivityThreshold: number;
  followingInactivityEnabled: boolean;
  followingInactivityThreshold: number;
  autoAwaySetting: any;
  updateTrackedProperty: (key: string, value: any) => void;
  selfAssignedSelection: string;
  currentShowTeammatesPresence: boolean;
  model: $TSFixMe;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class Other extends Component<Signature> {
  @service declare appService: $TSFixMe;

  get app() {
    return this.appService.app;
  }

  get hasManageCapacityFeature() {
    return this.app.canUseFeature('manage_capacity');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tabs::Other': typeof Other;
  }
}
