/* import __COLOCATED_TEMPLATE__ from './office-hours.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type ApplicationInstance from '@ember/application/instance';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import ajax from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'embercom/services/intl';

interface Args {
  schedules: any;
  settings: any;
}

interface Signature {
  Args: Args;
  Element: never;
}
export default class OfficeHours extends Component<Signature> {
  @service declare appService: any;
  @service declare store: any;
  @service declare intl: IntlService;

  @tracked schedules = [];
  @tracked scheduleToUpdate: any = null;
  @tracked showModal = false;
  @tracked scheduleToDelete: any = null;
  @tracked defaultSchedule: any = null;
  @tracked customSchedules = [];
  @tracked hasCustomSchedules = false;
  @tracked showConfirmScheduleDelete = false;
  @tracked dependentObjects: any[] = [];

  constructor(owner: ApplicationInstance, args: Args) {
    super(owner, args);
    this.initializeSchedules();
    this.intl.maybeLoadMessengerTranslations();
  }

  get app() {
    return this.appService.app;
  }

  @action
  initializeSchedules() {
    this.schedules = this.schedulesModel.filter((schedule: any) => !schedule.isNew);
    this.defaultSchedule = this.schedules.find((schedule: any) => schedule.isDefault);
    this.customSchedules = this.schedules.filter((schedule: any) => !schedule.isDefault);
    this.hasCustomSchedules = this.customSchedules.length > 0;
  }

  @action
  showOfficeHourArticle() {
    window.Intercom('showArticle', 3305941); // https://www.intercom.com/help/en/articles/3305941-Define-office-hours-for-each-team-inbox
  }

  get schedulesModel() {
    return this.args.schedules;
  }

  get settings() {
    return this.args.settings;
  }

  get defaultLocale() {
    let languages = this.store.peekRecord('messenger-settings/languages', this.app.id);
    return languages?.defaultLocale || this.app.locale || 'en';
  }

  dependentObjectsForSchedule(scheduleToDelete: any): any[] {
    return ajax({
      url: '/ember/office_hours_schedules/dependent_objects',
      type: 'GET',
      data: {
        app_id: this.app.id,
        id: scheduleToDelete.id,
      },
    });
  }

  @dropTask
  *attemptDeleteSchedule(scheduleData: any) {
    let scheduleToDelete = this.schedulesModel.find(
      (schedule: any) => schedule.name === scheduleData.name,
    );
    let objects: any[] = yield this.dependentObjectsForSchedule(scheduleToDelete);
    this.scheduleToDelete = scheduleData;
    this.dependentObjects = objects;
    if (objects.length > 0) {
      this.showConfirmScheduleDelete = true;
    } else {
      yield taskFor(this.deleteSchedule).perform();
    }
    this.refreshModel();
  }

  @dropTask
  *deleteSchedule() {
    let scheduleToDelete = this.schedulesModel.find(
      (schedule: any) => schedule.name === this.scheduleToDelete.name,
    );
    yield scheduleToDelete.destroyRecord();
    this.scheduleToDelete = null;
    this.refreshModel();
  }

  @action
  createOfficeHours() {
    this.scheduleToUpdate = this.store.createRecord('office-hours-schedule');
    this.showModal = true;
  }

  @action
  openSettingsModal(scheduleData: any) {
    this.scheduleToUpdate = this.schedulesModel.find(
      (schedule: any) => schedule.name === scheduleData.name,
    );
    this.showModal = true;
  }

  @action
  refreshModel() {
    this.store.findAll('office-hours-schedule', this.app?.id);
    this.store.findRecord('messenger-settings/availability', this.app?.id);
    this.initializeSchedules();
  }

  @action
  closeModal() {
    this.showModal = false;
    this.scheduleToDelete = null;
    this.showConfirmScheduleDelete = false;
    if (this.scheduleToUpdate) {
      this.scheduleToUpdate.rollbackAttributes?.();
      this.scheduleToUpdate = null;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::OfficeHours': typeof OfficeHours;
  }
}
