/* import __COLOCATED_TEMPLATE__ from './empty-state-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  containerClass: string;
  dismissalKey: string;
  paywallFeatureKey?: string;
  bodyContainerClass?: string;
  title?: string;
  description?: string;
  onPrimaryCtaClick?: () => void;
  primaryCtaLabel?: string;
  primaryCtaIcon?: InterfaceIconName;
  imageSrc: string;
  paywall?: $TSFixMe;
}

interface Signature {
  Element: any;
  Args: Args;
  Blocks: {
    extras: [];
  };
}

const EmptyStateBanner = templateOnlyComponent<Signature>();
export default EmptyStateBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GettingStarted::Common::EmptyStateBanner': typeof EmptyStateBanner;
    'onboarding/getting-started/common/empty-state-banner': typeof EmptyStateBanner;
  }
}
