/* import __COLOCATED_TEMPLATE__ from './social-channels.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

const SocialChannels = templateOnlyComponent<any>();
export default SocialChannels;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::SocialChannels': typeof SocialChannels;
  }
}
