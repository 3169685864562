/* import __COLOCATED_TEMPLATE__ from './ticket.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import { type MappingVisibility } from 'embercom/models/crm/attribute-mapping';

export interface Args {
  integrationCode: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export type TabName = 'team' | 'admin' | 'ticket-data' | 'content';

export default class Ticket extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  @tracked selectedTab: TabName = 'team';

  @action setSelectedTab(tab: TabName) {
    if (this.validateStep(this.selectedTab)) {
      this.selectedTab = tab;
    }
  }

  validateStep = (step: TabName) => {
    switch (step) {
      case 'admin':
        return this.validateAdmin();
      case 'ticket-data':
        return this.validateTicketData();
    }
    return true;
  };

  private validateAdmin() {
    if (this.importConfigurationService.fallbackAdminId) {
      return true;
    }

    this.notificationsService.notifyError(
      this.intl.t(this.translationKey('admin.errors.missing-default-admin')),
    );
    return false;
  }

  private validateTicketData() {
    // validate if the current selected customer and backoffice tickets have unmapped required attributes
    for (let visibility of ['public', 'private'] as MappingVisibility[]) {
      let ticketId = this.importConfigurationService.getTicketTypeId(visibility);
      if (!ticketId) {
        this.notificationsService.notifyError(
          this.intl.t(this.translationKey(`ticket-data.errors.missing-${visibility}-ticket-type`)),
        );
        return false;
      }
      let requiredMappings = this.importConfigurationService.listRequiredTicketMappings(
        ticketId,
        visibility,
      );

      for (let mapping of requiredMappings) {
        if (mapping.status !== 'done') {
          this.notificationsService.notifyError(
            this.intl.t(
              this.translationKey(`ticket-data.errors.missing-required-${visibility}-attributes`),
            ),
          );
          return false;
        }
      }
    }
    return true;
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.sections.ticket.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Ticket': typeof Ticket;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/ticket': typeof Ticket;
  }
}
