/* import __COLOCATED_TEMPLATE__ from './more-pill-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    item: any;
  };
}

const MorePillDropdownItem = templateOnlyComponent<Signature>();
export default MorePillDropdownItem;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::OfficeHours::MorePillDropdownItem': typeof MorePillDropdownItem;
    'new-settings/workspace/office-hours/more-pill-dropdown-item': typeof MorePillDropdownItem;
  }
}
