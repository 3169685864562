/* import __COLOCATED_TEMPLATE__ from './legacy-empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class OutboundEmptyState extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get objectKey() {
    let humanReadableObjectName = this.args.humanReadableObjectName
      ? this.args.humanReadableObjectName
      : 'message';
    return humanReadableObjectName.replaceAll(' ', '-').toLowerCase();
  }

  get titleLocalisationKey() {
    if (this.args.hasAppliedFilter) {
      return `outbound.empty-state.${this.objectKey}-object.title-no-filter-match`;
    }
    return `outbound.empty-state.${this.objectKey}-object.title`;
  }

  get createNewObjectLocalisationKey() {
    return `outbound.empty-state.${this.objectKey}-object.create-new-object`;
  }

  get createNewObjectReasonLocalisationKey() {
    return `outbound.empty-state.${this.objectKey}-object.creation-reason`;
  }

  get resetFiltersLocalisationKey() {
    return `outbound.empty-state.${this.objectKey}-object.reset-filters`;
  }
}
