/* import __COLOCATED_TEMPLATE__ from './activity-logs.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import moment from 'moment-timezone';
import AppAdminEventsQuery from 'embercom/lib/admins/app-admin-events-query';
import { inject as service } from '@ember/service';

export default class ActivityLogs extends Component {
  @service appService: any;

  maxDays = 30;

  @tracked fromDate: any = { day: null, month: null, year: null };
  @tracked toDate: any = { day: null, month: null, year: null };
  @tracked query: any = null;
  @tracked notValidRequest = false;
  @tracked requestedDays = 0;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    this.query = AppAdminEventsQuery.create({ appId: this.app.id, content: [] });

    let currentDate = new Date();
    this.fromDate = {
      day: currentDate.getDate(),
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    };
    this.toDate = {
      day: currentDate.getDate(),
      month: currentDate.getMonth() + 1,
      year: currentDate.getFullYear(),
    };

    this.load();
  }

  get displayedEvents() {
    return this.query;
  }

  get app() {
    return this.appService.app;
  }

  get fromMoment() {
    return this.dateToMoment(this.fromDate);
  }

  get toMoment() {
    return this.dateToMoment(this.toDate);
  }

  dateToMoment(date: { day: any; month: any; year: any }) {
    let newDate = { day: date.day, month: date.month - 1, year: date.year };
    return moment(newDate);
  }

  @action
  load() {
    this.requestedDays = Math.abs(this.toMoment.diff(this.fromMoment, 'days'));
    this.notValidRequest = this.requestedDays > this.maxDays;
    if (this.notValidRequest) {
      return;
    }
    this.query.loadBetweenDates.perform(this.fromMoment.unix(), this.toMoment.unix());
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Teammates::ActivityLogs': typeof ActivityLogs;
  }
}
