/* import __COLOCATED_TEMPLATE__ from './consent-capture.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class ConsentCapture extends Component {
  @tracked displayDragAndDrop = true;

  @action
  hideDisplayDragAndDropAndContinueOnImageUpload(image) {
    this.displayDragAndDrop = false;
    this.args.onImageUpload(image);
  }
}
