/* import __COLOCATED_TEMPLATE__ from './custom-objects.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  model: any;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class CustomObjects extends Component<Signature> {
  @service intl: $TSFixMe;
  @service notificationsService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service appService: $TSFixMe;
  @service modelDataCacheService: $TSFixMe;
  @tracked showObjectTypeModal = false;
  @tracked currentTab = 'live';

  @action
  changeTab(value: string) {
    this.currentTab = value;
  }

  get currentTabLabel() {
    return this.currentTab === 'live'
      ? this.intl.t('settings.custom-object-data.list.object-types.tab-live')
      : this.intl.t('settings.custom-object-data.list.object-types.label-archived');
  }

  get app() {
    return this.appService.app;
  }

  get customObjects() {
    return this.args.model.rejectBy('isSystemDefined');
  }

  get liveCustomObjects() {
    return this.customObjects.rejectBy('archived');
  }

  get archivedCustomObjects() {
    return this.customObjects.filterBy('archived');
  }

  get selectedCustomObjects() {
    if (this.currentTab === 'live') {
      return this.liveCustomObjects;
    } else {
      return this.archivedCustomObjects;
    }
  }

  get columns() {
    let columns = [
      {
        label: this.intl.t('settings.custom-object-data.list.object-types.column-object'),
        valuePath: 'name',
      },
      {
        label: this.intl.t('settings.custom-object-data.list.object-types.column-updated-at'),
        valuePath: 'updatedAt',
      },
      {
        label: this.intl.t('settings.custom-object-data.list.object-types.column-created-at'),
        valuePath: 'createdAt',
      },
    ];

    if (this.currentTab === 'live') {
      columns.push({
        label: '',
        valuePath: 'editAttribute',
      });
    } else {
      columns.push({
        label: this.intl.t('settings.custom-object-data.list.object-types.label-archived'),
        valuePath: 'archivedAt',
      });
    }

    return columns;
  }

  @dropTask
  *unArchiveCustomObjectType(customObject: any) {
    try {
      customObject.archived = false;
      yield customObject.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.custom-object-data.modals.unarchive.notification-success', {
          type: customObject.name,
        }),
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'unarchive',
        object: 'archive',
        place: 'custom_object',
        archive_object_type: 'object',
        archive_object_id: customObject.id,
      });
      if (this.archivedCustomObjects.length === 0) {
        this.currentTab = 'live';
      }
      this.app.updateLocalCache(this.modelDataCacheService);
    } catch (error) {
      customObject.rollbackAttributes();
      this.notificationsService.notifyError(
        this.intl.t('settings.custom-object-data.modals.unarchive.notification-failure', {
          type: customObject.name,
        }),
      );
    }
  }

  showSettingUpCOArticle() {
    window.Intercom('showArticle', 6298280); // https://www.intercom.com/help/en/articles/6298280-setting-up-a-custom-object
  }

  showUsingCOArticle() {
    window.Intercom('showArticle', 6298287); // https://www.intercom.com/help/en/articles/6298287-using-custom-objects-in-bots
  }

  triggerTour() {
    window.Intercom('startTour', 351605);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::CustomObjects': typeof CustomObjects;
  }
}
