/* import __COLOCATED_TEMPLATE__ from './editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';

export default class Editor extends Component {
  @tracked selectedLocale = this.defaultLocale;

  get selectedBotIntroLocalization() {
    return this.args.localizations.find(
      (localization) => localization.locale === this.selectedLocale,
    );
  }

  @action
  updateLocale(locale) {
    this.selectedLocale = locale;
  }

  get defaultLocale() {
    let defaultLocalization = this.args.localizations.find((l) => l.default);

    return defaultLocalization ? defaultLocalization.locale : 'en';
  }

  get localeOptions() {
    return this.args.localizations.map((localization) => ({
      locale_name: localization.name,
      locale_id: `(${localization.locale.toUpperCase()})`,
      value: localization.locale,
      state: localization.enabled,
      default: localization.default,
      component: 'operator/generic-triage/locale-option',
    }));
  }
}
