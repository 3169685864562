/* import __COLOCATED_TEMPLATE__ from './new-help-center-option.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  itemTitle: $TSFixMe;
  icon: InterfaceIconName;
  type: 'card' | 'menu-item';
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class NewHelpCenterOption extends Component<Signature> {
  @service declare helpCenterService: $TSFixMe;
  @service declare router: Router;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked showCreateHelpCenterModal = false;

  get maxHelpCenters() {
    return this.helpCenterService.maxNumberOfSites;
  }

  get hasMaxHelpCenters() {
    return this.helpCenterService.sites?.length >= this.maxHelpCenters;
  }

  @action
  onClick() {
    this.showCreateHelpCenterModal = !this.showCreateHelpCenterModal;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'new_help_center',
      place: 'new_settings',
    });
  }

  @action
  transitionToHelpCenterSettingsPage(helpCenter: HelpCenterSite) {
    this.router.transitionTo(
      'apps.app.settings.helpcenter.workspace-helpcenter',
      this.appService.app.id,
      helpCenter.id,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::HelpCenter::NewHelpCenterOptoon': typeof NewHelpCenterOption;
    'new-settings/helpcenter/new-help-center-option': typeof NewHelpCenterOption;
  }
}
