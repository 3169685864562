/* import __COLOCATED_TEMPLATE__ from './disconnect-dialog.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { taskFor } from 'ember-concurrency-ts';
import { dropTask } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { post } from 'embercom/lib/ajax';

export interface Args {
  isOpen: boolean;
  onClose: () => void;
  onDisconnect: () => void;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class Setup extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @action async disconnect() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'disconnect_account',
    });
    await taskFor(this.uninstallAppPackage).perform();
    this.args.onDisconnect();
    this.args.onClose();
  }

  @dropTask *uninstallAppPackage(): TaskGenerator<void> {
    yield post('/ember/migrate_from_crm_integrations/uninstall', {
      app_id: this.appService.app.id,
      integration_code: 'migrate-from-zendesk',
    });
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::DisconnectDialog': typeof Setup;
    'new-settings/data/imports-exports/import-zendesk/disconnect-dialog': typeof Setup;
  }
}
