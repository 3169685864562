/* import __COLOCATED_TEMPLATE__ from './invite-developer-card-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import type Plan from 'embercom/models/plan';
import { PRICING_5_X_CORE_ESSENTIAL_ID } from 'embercom/lib/billing';

interface Signature {
  Args: {};
  Element: never;
}

export default class InviteDeveloperCardComponent extends Component<Signature> {
  @service declare store: Store;
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked showModal = false;

  get isEssentialAndBillable() {
    let essentialPlan: Plan = this.store.peekRecord('plan', PRICING_5_X_CORE_ESSENTIAL_ID);

    return essentialPlan.billableCustomerPlan;
  }

  @action openModal() {
    if (this.permissionsService.currentAdminCan('can_manage_teammates')) {
      this.showModal = true;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'invite_engineer',
      });
      return;
    }

    this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_manage_teammates');
    this.showModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::Home::InviteDeveloperCardComponent': typeof InviteDeveloperCardComponent;
  }
}
