/* import __COLOCATED_TEMPLATE__ from './invite-developer-inline-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-setup */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { type Router } from '@ember/routing';

interface Args {
  displayIcon: boolean;
}
interface Signature {
  Args: Args;
  Element: never;
}

export default class InviteDeveloperInlineComponent extends Component<Signature> {
  @service declare store: Store;
  @service declare permissionsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @service declare appService: $TSFixMe;
  @service declare router: Router;

  @tracked showModal = false;

  get app() {
    return this.appService.app;
  }

  @action openModal() {
    if (!this.app.onPricing5) {
      this.router.transitionTo('apps.app.settings.workspace.teammates');
      return;
    }

    if (this.permissionsService.currentAdminCan('can_manage_teammates')) {
      this.showModal = true;
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'invite_engineer',
      });
      return;
    }

    this.permissionsService.loadAllAdminsAndShowPermissionRequestModal('can_manage_teammates');
    this.showModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::Home::InviteDeveloperInlineComponent': typeof InviteDeveloperInlineComponent;
  }
}
