/* import __COLOCATED_TEMPLATE__ from './teammate-presence-section.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  updateTrackedProperty: (key: string, value: any) => void;
  currentShowTeammatesPresence: boolean;
  saveTeammatePresenceSettings?: () => void;
}

interface Signature {
  Args: Args;
  Element: never;
}

const TeammatePresenceSection = templateOnlyComponent<Signature>();
export default TeammatePresenceSection;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::TabsComponents::TeammatePresenceSection': typeof TeammatePresenceSection;
  }
}
