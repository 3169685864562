/* import __COLOCATED_TEMPLATE__ from './confirm-email-card.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const ConfirmEmailCard = templateOnlyComponent<Signature>();

export default ConfirmEmailCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::ConfirmEmailCard': typeof ConfirmEmailCard;
    'new-settings/channels/email/connect-email/confirm-email-card': typeof ConfirmEmailCard;
  }
}
