/* import __COLOCATED_TEMPLATE__ from './conversation-details-sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type Session from 'embercom/services/session';
import type Conversation from 'embercom/objects/inbox/conversation';
import type SidebarSection from 'embercom/objects/inbox/sidebar-section';
import {
  type SidebarSectionDetail,
  type SupportedSidebarSection,
} from 'embercom/services/inbox-sidebar-service';
import type InboxSidebarService from 'embercom/services/inbox-sidebar-service';
import User, { type UserAttributes } from 'embercom/objects/inbox/user';
import { type RoleLabel } from 'embercom/objects/inbox/user-summary';
import Company from 'embercom/objects/inbox/company';

interface Args {
  conversation: Conversation;
}

interface Signature {
  Args: Args;
}

export default class InboxTourConversationDetailsSidebar extends Component<Signature> {
  @service declare session: Session;
  @service declare inboxSidebarService: InboxSidebarService;

  private latestConversationComponentIdentifier = 'latest-conversations';
  private userDetailsSectionComponentIdentifier = 'user-details';

  get sidebarSectionsToDisplay(): SidebarSection[] {
    let pinnedSections = this.inboxSidebarService.pinnedSidebarSections(this.args.conversation);
    let sections = [
      pinnedSections.find(
        (section) => section.componentIdentifier === this.userDetailsSectionComponentIdentifier,
      ),
      pinnedSections.find(
        (section) => section.componentIdentifier === this.latestConversationComponentIdentifier,
      ),
    ].filter((item) => item) as SidebarSection[];

    return [
      ...sections,
      ...pinnedSections.filter(
        (section) =>
          ![
            this.latestConversationComponentIdentifier,
            this.userDetailsSectionComponentIdentifier,
          ].includes(section.componentIdentifier),
      ),
    ];
  }

  @action onOpenSectionChange() {
    return () => {};
  }

  get user() {
    let companySummary = this.args.conversation.userSummary.company;

    let userAttributes: UserAttributes = {
      id: '',
      role: 'User',
      user_id: '001',
      created_at: '',
      last_request_at: '',
      session_count: 3,
      name: this.args.conversation.userSummary?.name || 'Onboarding user',
      geoip_data: {
        city_name: '',
        country_code2: '',
        country_name: '',
        region_code: '',
        timezone: '',
      },
      user_time: {
        local_time_12h: '',
      },
      companies: companySummary
        ? [new Company({ id: companySummary.id, name: companySummary.name }).attributes]
        : [],
      email: this.args.conversation.userSummary.email,
      unsubscribed_from_emails: false,
    };
    return new User(userAttributes);
  }

  get userAttributes() {
    return [
      {
        type: 'string',
        key: 'name',
        name: 'name',
        icon: 'person',
        humanFriendlyName: 'Name',
      },
      {
        type: 'string',
        key: 'email',
        name: 'email',
        icon: 'email',
        humanFriendlyName: 'Email',
      },
      {
        type: 'company',
        key: 'company',
        name: 'Company',
        icon: 'company',
        humanFriendlyName: 'Company',
        value: this.user.companies[0].name,
      },
    ];
  }

  get recentConversation() {
    return [
      {
        title: 'string',
        key: 'description',
        name: 'description',
        humanFriendlyName: 'Description',
        value: '',
      },
      {
        type: 'string',
        key: 'contactReason',
        name: 'contactReason',
        humanFriendlyName: 'Contact Reason',
        value: '',
      },
    ];
  }

  get sidebarSectionDetails(): {
    [key in SupportedSidebarSection]: SidebarSectionDetail;
  } {
    let roleLabel: RoleLabel | undefined = this.args.conversation.userSummary?.typeLabel;
    return this.inboxSidebarService.sidebarSectionDetails(roleLabel);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::InboxTour::ConversationDetailsSidebar': typeof InboxTourConversationDetailsSidebar;
  }
}
