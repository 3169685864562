/* import __COLOCATED_TEMPLATE__ from './demo-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';

export default Component.extend({
  tagName: '',

  completionCallback: null,
  demoCompletionAction: action(function (state) {
    this.completionCallback(state);
  }),
});
