/* import __COLOCATED_TEMPLATE__ from './checklist-step.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import STEPS from 'embercom/lib/home/steps';
import { type Step } from 'embercom/objects/onboarding/checklist/step';
import type GreatGuidanceService from 'embercom/services/great-guidance-service';

export interface Args {
  step: Step;
  isFirstStep: boolean;
  stepAccordion: any;
  collapseChecklist: () => void;
  shouldAnimate: boolean;
  currentlyOpenStepId: string | undefined;
  onCTASuccess?: (stepIdentifier: string) => void;
}

export default class ChecklistStep extends Component<Args> {
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service declare greatGuidanceService: GreatGuidanceService;
  @service declare intercomEventService: any;

  get isChecked() {
    return this.args.step.state === 'completed';
  }

  get app() {
    return this.appService.app;
  }

  get isStepDisabled() {
    return this.args.step.identifier === this.args.currentlyOpenStepId;
  }

  get descriptionClass() {
    return this.isChecked ? 'onboarding__floating-widget__completed-step-desc' : 'text-base';
  }

  @action
  primaryClickAction() {
    let stepIdentifier = this.args.step.identifier;
    let stepData = STEPS[stepIdentifier as keyof typeof STEPS] as any;
    if (!(stepIdentifier === 'great_guidance_invite_teammates' && this.appService.app.onPricing5)) {
      stepData.primaryAction(this.router, this.args.step, this.appService.app);
    }
    this.greatGuidanceService.nextStepId = this.args.step.identifier;
    this.args.collapseChecklist();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      context: 'getting_started_homepage',
      object: `${this.args.step.identifier}-cta`,
      section: 'onboarding.getting-started.widget',
      place: 'onboarding.getting-started',
      step_state: this.args.step?.state,
    });
    if (this.args.onCTASuccess) {
      this.args.onCTASuccess(this.args.step.identifier);
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::FloatingWidget::ChecklistStep': typeof ChecklistStep;
    'onboarding/floating-widget/checklist-step': typeof ChecklistStep;
  }
}
