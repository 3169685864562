/* import __COLOCATED_TEMPLATE__ from './auto-close-if-customer-becomes-inactive.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type FinWorkflowPreview from 'embercom/lib/workflows/fin-workflow-preview';

interface Args {
  accordion: $TSFixMe;
  finWorkflowPreview: FinWorkflowPreview;
  readOnly: boolean;
}

const MINUTE = 60;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export default class AutoCloseIfCustomerBecomesInactive extends Component<Args> {
  @service declare intl: IntlService;

  MIN_INACTIVITY_DURATION = 60;
  MAX_INACTIVITY_DURATION = 180 * 24 * 60 * 60; // 180 days in seconds

  @tracked days: number | string | undefined;
  @tracked hours: number | string | undefined;
  @tracked minutes: number | string | undefined;

  get finWorkflowPreview() {
    return this.args.finWorkflowPreview;
  }

  get endUserInactivityTimer() {
    return this.finWorkflowPreview.endUserInactivityTimer;
  }

  get autoCloseMessages() {
    return this.finWorkflowPreview.autoCloseMessageParts;
  }

  get timerInvalid() {
    return (
      this.finWorkflowPreview.endUserInactivityTimer < this.MIN_INACTIVITY_DURATION ||
      this.finWorkflowPreview.endUserInactivityTimer > this.MAX_INACTIVITY_DURATION
    );
  }

  get durationInDaysHoursMinutes() {
    let formatKey = this.determineFormatKey();
    return this.intl.t(
      `operator.fin.setup.setup-and-go-live.fin-auto-close.duration-summary.${formatKey}`,
      {
        days: this.parseInt(this.days),
        hours: this.parseInt(this.hours),
        minutes: this.parseInt(this.minutes),
      },
    );
  }

  determineFormatKey() {
    let formatKey = '';

    if (this.parseInt(this.days) > 0) {
      formatKey += 'd';
    }
    if (this.parseInt(this.hours) > 0) {
      formatKey += 'h';
    }
    if (this.parseInt(this.minutes) > 0) {
      formatKey += 'm';
    }

    if (formatKey === '') {
      formatKey = 'empty';
    }

    return formatKey;
  }

  parseInt(value: string | number | undefined): number {
    if (value === undefined) {
      return 0;
    } else if (value === '') {
      return 0;
    } else if (typeof value === 'string') {
      return parseInt(value, 10);
    } else {
      return value;
    }
  }

  @action
  updateInactivityDuration(number: number) {
    this.finWorkflowPreview.endUserInactivityTimer = number;
    this.setInactivityTimer();
  }

  setInactivityTimer() {
    let inactivityTimer = this.finWorkflowPreview.endUserInactivityTimer;

    this.days = Math.floor((inactivityTimer || 0) / DAY);
    this.hours = Math.floor(((inactivityTimer || 0) % DAY) / HOUR);
    this.minutes = Math.floor((((inactivityTimer || 0) % DAY) % HOUR) / MINUTE);
  }

  @action
  addAutoCloseMessagePart() {
    this.finWorkflowPreview.addAutoCloseMessagePart();
  }

  @action
  removeAutoCloseMessagePart(part: any) {
    this.finWorkflowPreview.removeAutoCloseMessagePart(part);
  }

  @action
  reorderAutoCloseMessageParts(parts: any) {
    this.finWorkflowPreview.reorderAutoCloseMessageParts(parts);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::WorkflowSections::AutoCloseIfCustomerBecomesInactive': typeof AutoCloseIfCustomerBecomesInactive;
  }
}
