/* import __COLOCATED_TEMPLATE__ from './tickets-portal.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import type HelpCenterSite from 'embercom/models/help-center-site';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';

const COMPANIES_ATTR_IDENTIFIER = 'companies';

interface Args {
  onSwitchHelpCenter: (helpCenter: HelpCenterSite) => void;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class TicketsPortal extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  @tracked helpCenters: HelpCenterSite[] = [];
  @tracked selectedHelpCenter: HelpCenterSite | undefined;
  @tracked cookieForwardingTestFailed = false;

  constructor(owner: $TSFixMe, args: $TSFixMe) {
    super(owner, args);
    taskFor(this.fetchHelpCenters).perform();
  }

  get app() {
    return this.appService.app;
  }

  get appAttributes() {
    return this.app.attributes.toArray();
  }

  get isMessengerInstalled() {
    return !!this.app.first_identified_request_at;
  }

  get isIdvEnabled() {
    if (this.app.canSkipTicketsPortalIdvRequirement) {
      return true;
    }

    return !!this.app.identityVerified;
  }

  get customDomainSetup() {
    return !!this.selectedHelpCenter?.customDomain;
  }

  get companiesUpdatesPrevented() {
    return !!this.appAttributes.find(
      (attr: $TSFixMe) => attr.identifier === COMPANIES_ATTR_IDENTIFIER,
    )?.preventUpdateFromMessenger;
  }

  get allPrerequisitesDone() {
    return (
      this.isMessengerInstalled &&
      this.isIdvEnabled &&
      this.customDomainSetup &&
      this.companiesUpdatesPrevented
    );
  }

  get fetchingHelpCenters() {
    return taskFor(this.fetchHelpCenters).isRunning;
  }

  @task({ restartable: true })
  *fetchHelpCenters(): TaskGenerator<void> {
    try {
      this.helpCenters = yield this.store.findAll('help-center-site');
      this.selectedHelpCenter = this.helpCenters.firstObject;
      if (this.helpCenters.firstObject) {
        this.args.onSwitchHelpCenter(this.helpCenters.firstObject);
        taskFor(this.runCookieForwardingTest).perform(this.helpCenters.firstObject.id);
        this.helpCenters.firstObject.ticketsPortalRuleset?.fetchAudiencePreviewTask.perform();
      }
    } catch (error) {
      this.notificationsService.notifyError(
        this.intl.t('new-settings.helpdesk.tickets.tickets-portal.help-center.error'),
      );
    }
  }

  get helpCenterOptions() {
    return this.helpCenters.map((helpCenter: HelpCenterSite) => {
      return {
        text: helpCenter.name,
        value: helpCenter.id,
      };
    });
  }

  @action switchHelpCenter(value: string) {
    this.selectedHelpCenter = this.helpCenters.find(
      (helpCenter: HelpCenterSite) => helpCenter.id === value,
    );
    if (this.selectedHelpCenter) {
      this.args.onSwitchHelpCenter(this.selectedHelpCenter);
      taskFor(this.runCookieForwardingTest).perform(this.selectedHelpCenter.id);
      this.selectedHelpCenter.ticketsPortalRuleset.fetchAudiencePreviewTask.perform();
    }
  }

  @task({ restartable: true })
  *runCookieForwardingTest(helpCenterId: string): TaskGenerator<void> {
    try {
      let response = yield ajax({
        url: '/ember/help_center_sites/cookie_forwarding_test',
        type: 'GET',
        data: {
          app_id: this.app.id,
          help_center_site_id: helpCenterId,
        },
      });

      this.cookieForwardingTestFailed = response.status !== 'Success';
    } catch (e) {
      captureException(e);
    }
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tickets::TicketsPortal': typeof TicketsPortal;
    'new-settings/helpdesk/tickets/tickets-portal': typeof TicketsPortal;
  }
}
