/* import __COLOCATED_TEMPLATE__ from './side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import templateOnlyComponent from '@ember/component/template-only';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    onClose: () => void;
    isOpen: boolean;
    sources: Array<ContentImportSource>;
    resetSearch: () => void;
  };
}

const SideDrawer = templateOnlyComponent<Signature>();
export default SideDrawer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::ExternalContent::Sources::SideDrawer': typeof SideDrawer;
  }
}
