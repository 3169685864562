/* import __COLOCATED_TEMPLATE__ from './email-setup-sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    email: string;
    step: string | null;
    isOpen: boolean;
    onClose: () => void;
  };
}

const EmailSetupSidebar = templateOnlyComponent<Signature>();

export default EmailSetupSidebar;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::EmailSetupSidebar': typeof EmailSetupSidebar;
  }
}
