/* import __COLOCATED_TEMPLATE__ from './guide-card.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  title: any;
  description: any;
  image: any;
  onCardClick: () => void;
}

interface Signature {
  Args: Args;
  Element: never;
}

const GuideCard = templateOnlyComponent<Signature>();
export default GuideCard;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GettingStarted::Common::GuideCard': typeof GuideCard;
  }
}
