/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { schedule } from '@ember/runloop';
import { assert } from '@ember/debug';
import $ from 'jquery';
import { computed } from '@ember/object';
import Component from '@ember/component';
import ENV from 'embercom/config/environment';
import { task } from 'ember-concurrency';
import { addEventListener, debounceTask } from 'ember-lifeline';

export default Component.extend({
  itemSelector: null,
  pageWhenWithinPixels: 300,
  _lastPagedAtHeight: -1,

  $viewport: computed('viewportSelector', function () {
    return $(this.viewportSelector);
  }),

  $scrollingContent: computed('scrollingContentSelector', function () {
    return this.$viewport.find(this.scrollingContentSelector);
  }),

  didInsertElement() {
    this._super(...arguments);
    assert('You must specify an `onPageRequested` action', this.onPageRequested !== undefined);
    assert('You must specify a `viewportSelector` property', this.viewportSelector);
    assert('You must specify a `scrollingContentSelector` property', this.scrollingContentSelector);

    addEventListener(this, this.$viewport.get(0), 'scroll', this.debouncedPaginateIfAppropriate);
    this.autoPaginateIfAppropriate();
  },

  willDestroyElement() {
    this.set('$viewport', null);
    this.set('$scrollingContent', null);
    this._super(...arguments);
  },

  debouncedPaginateIfAppropriate() {
    debounceTask(this, 'paginateIfAppropriate', ENV.APP._150MS);
  },

  autoPaginateIfAppropriate() {
    if (this.get('requestPage.isRunning')) {
      return;
    }

    if (this.contentHeight() <= this.viewportHeight() && this.hasWatermarkChanged()) {
      this.requestPage.perform();
    }
  },

  paginateIfAppropriate() {
    if (this.get('requestPage.isRunning')) {
      return;
    }
    if (this.isNearBottom()) {
      this.requestPage.perform();
    }
  },

  hasWatermarkChanged() {
    return this.watermark !== this.$scrollingContent.height();
  },

  setWatermark() {
    let contentHeight = this.$scrollingContent.height();
    this.set('watermark', contentHeight);
  },

  requestPage: task(function* () {
    this.setWatermark();

    yield this.onPageRequested();
    schedule('afterRender', () => {
      this.autoPaginateIfAppropriate();
    });
  }).enqueue(),

  viewportHeight() {
    return this.$viewport.height();
  },

  contentHeight() {
    return this.$scrollingContent.height();
  },

  scrollBottom() {
    let $viewport = this.$viewport;
    if (!$viewport) {
      return 0;
    }
    return this.viewportHeight() + $viewport.scrollTop();
  },

  pageTriggerPoint() {
    return this.contentHeight() - this.pageWhenWithinPixels;
  },

  isNearBottom() {
    if (!this.$viewport || !this.$scrollingContent) {
      return false;
    }
    return this.scrollBottom() > this.pageTriggerPoint();
  },
});
