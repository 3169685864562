/* import __COLOCATED_TEMPLATE__ from './uploader.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { ref } from 'ember-ref-bucket';
import { task } from 'ember-concurrency-decorators';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';

export default class CoverImageComponent extends Component {
  @service appService;
  @service notificationsService;
  @service imageCropperService;
  @service intl;

  @tracked isCropping = false;
  @tracked isUploading = false;
  @tracked uncroppedFile = null;
  @tracked previewUrl = null;

  @ref('inputElement') inputElement;

  allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png'];
  maxFileSize = 5000000; // 5MB
  aspectRatio = 16 / 9;

  constructor() {
    super(...arguments);
    this.previewUrl = this.args.content.coverImageUrl;
  }

  get app() {
    return this.appService.app;
  }

  get allowedTypeList() {
    return this.allowedFileTypes.join(',');
  }

  get allowedFormats() {
    return this.allowedFileTypes.reduce((message, type, index, types) => {
      let format = type.split('/')[1].toLocaleUpperCase();
      let firstItem = index === 0;
      let lastItem = index === types.length - 1;
      if (firstItem) {
        return format;
      }
      return `${message}${lastItem ? ' or ' : ', '}${format}`;
    }, '');
  }

  get showLoading() {
    return this.isUploading || this.isCropping;
  }

  @action
  drop(e) {
    e.preventDefault();
    if (typeof this.args.dropFile === 'function') {
      // this is here for testing purposes only
      return this.args.dropFile.call(this);
    }
    let file = e.dataTransfer.files[0];
    this.validateAndUpload(file);
  }

  @action
  showFilePicker() {
    if (typeof this.args.pickFile === 'function') {
      // this is here for testing purposes only
      return this.args.pickFile.call(this);
    }
    this.inputElement.click();
  }

  @action
  onChange(e) {
    let target = e.target;
    if (target?.files) {
      let file = target.files[0];
      this.validateAndUpload(file);
    }
  }

  @action
  showCropperModal(file) {
    this.isCropping = true;
    this.uncroppedFile = file;
  }

  @action
  onCrop(croppedFile) {
    this.handleCrop.perform(croppedFile);
  }

  @task({ drop: true })
  *handleCrop(croppedFile) {
    this.previewUrl = URL.createObjectURL(croppedFile);
    this.uncroppedFile = null;
    try {
      yield this.uploadImage.perform(croppedFile);
    } catch (error) {
      this.handleUploadError(error);
    }
    this.isCropping = false;
  }

  @action
  onCropModalClose() {
    this.isCropping = false;
    this.uncroppedFile = null;
  }

  @action
  removeLogo() {
    this.clearFileInput();
    this.args.content.coverImageUrl = null;
  }

  validateAndUpload(file) {
    if (this.validate(file)) {
      this.showCropperModal(file);
    }
  }

  @task({ drop: true })
  *cropAndUpload(originalFile) {
    this.isCropping = true;
    let options = { type: originalFile.type };
    let url = URL.createObjectURL(originalFile, options);

    try {
      yield this.imageCropperService.cropCenter(url, this.aspectRatio);
      this.previewUrl = this.imageCropperService.toDataURL(originalFile.type);
      let croppedFile = yield this.imageCropperService.toFile(originalFile.name, options);
      yield this.uploadImage.perform(croppedFile);
    } catch (error) {
      this.handleUploadError(error);
    }
    this.isCropping = false;
  }

  validate(file) {
    let errors = [];
    if (!this.validFormat(file)) {
      errors.push(
        this.intl.t('outbound.news-items.edit.editor.cover-image.errors.format', {
          formats: this.allowedFormats,
        }),
      );
    } else if (!this.validSize(file)) {
      errors.push(
        this.intl.t('outbound.news-items.edit.editor.cover-image.errors.size', {
          fileSize: this.maxFileSize / 1000000,
        }),
      );
    }
    if (errors.length > 0) {
      errors.forEach((error) => this.notificationsService.notifyError(error));
      return false;
    }
    return true;
  }

  validFormat(file) {
    return this.allowedFileTypes.includes(file.type);
  }

  validSize(file) {
    return file.size <= this.maxFileSize;
  }

  @task({ drop: true })
  *uploadImage(file) {
    try {
      this.isUploading = true;
      let uploader = new EmbercomFileUploader(file, {
        policyUrl: `/apps/${this.appService.app.id}/uploads`,
      });
      yield uploader.upload();
      this.handleUploadSuccess(uploader.policy);
    } catch (error) {
      this.handleUploadError(error);
    }
  }

  handleUploadSuccess(policy) {
    this.isUploading = false;
    this.args.content.coverImageUrl = policy.public_url;
  }

  handleUploadError(error) {
    this.isUploading = false;
    this.clearFileInput();
    this.notificationsService.notifyResponseError(error, {
      default: this.intl.t('outbound.news-items.edit.editor.cover-image.errors.upload'),
    });
  }

  clearFileInput() {
    this.previewUrl = null;
    this.inputElement.value = '';
  }
}
