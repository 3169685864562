/* import __COLOCATED_TEMPLATE__ from './step-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { timeout } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { GUIDES_WITH_VIDEO_HEADER } from '../../../lib/home/guides';

export default class StepComponent extends Component {
  @service notificationsService;
  @service onboardingHomeService;
  @service appService;
  @service intercomEventService;
  @service store;

  stepBannerCompleteDuration = 2000;

  get app() {
    return this.appService.app;
  }

  get guide() {
    return this.onboardingHomeService.guide;
  }

  get latestAvailableStep() {
    return this.guide.latestAvailableStep;
  }

  get identifier() {
    return this.args.step?.identifier;
  }

  get stepData() {
    return this.args.step?.stepData;
  }

  get isError() {
    return this.args.step?.state === 'failed';
  }

  @dropTask
  *updateStepState(state, accordionAPI, options = {}) {
    if (this.args.step.completed) {
      this.performAnimationsAndCallback.perform(accordionAPI);
      return this._displayCompletionBanner(options);
    }
    if (
      this.args.scrollIntoView &&
      GUIDES_WITH_VIDEO_HEADER.includes(this.guide.identifier) &&
      state === 'completed' &&
      window.document.querySelector(`#guide-section-header-${this.identifier}`)
    ) {
      window.document
        .querySelector(`#guide-section-header-${this.identifier}`)
        .scrollIntoView({ behavior: 'smooth' });
    }

    yield this.updateStep.perform(state, options);
    if (state === 'failed' || this.args.step.failed) {
      this.isError = true;
      this.failedCallback?.();
    } else {
      this.performAnimationsAndCallback.perform(accordionAPI);
    }

    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.step.analyticsMetadata,
      ...options.analyticsMetadata,
      action: state,
      object: this.identifier,
      section: 'inline_step',
    });
  }

  @dropTask
  *updateStep(state, options) {
    this.args.step.set('state', state);
    this._displayCompletionBanner(options);
    yield this.args.step.saveAndRefreshGuide();
  }

  @dropTask
  *performAnimationsAndCallback(accordionAPI) {
    yield this.stepCompleteAnimation.perform(accordionAPI);
    yield this.nextStepOpenAnimation.perform(accordionAPI);
    this.args.completionCallback();
  }

  @dropTask
  *stepCompleteAnimation(accordionAPI) {
    yield timeout(800);
    accordionAPI.closeSection(this.identifier);
    yield timeout(400);
  }

  @dropTask
  *nextStepOpenAnimation(accordionAPI) {
    if (this.args.step.get('nextStep.available')) {
      yield accordionAPI.openSection(this.args.step.nextStep.identifier);
    } else if (!this.args.step.get('level.allStepsComplete')) {
      yield accordionAPI.openSection(this.latestAvailableStep.identifier);
    }
  }

  @dropTask
  *openStep(accordionAPI, stepIdentifier) {
    let stepToOpen = this.store.peekRecord('onboarding/home/step', stepIdentifier);
    if (!stepToOpen) {
      return;
    }
    accordionAPI.closeSection(this.identifier);
    yield timeout(500);
    if (this.args.step.level.get('identifier') === stepToOpen.level.get('identifier')) {
      accordionAPI.openSection(stepToOpen.identifier);
    } else {
      this.args.openStepInDifferentLevelCallback(stepToOpen);
    }
  }

  @dropTask
  *resetStep(accordionAPI) {
    yield this.onboardingHomeService.resetGuide({ steps: [this.identifier] });
    accordionAPI.closeSection(this.identifier);
  }

  _displayCompletionBanner(options) {
    if (options.stepCompletedText) {
      return this.notificationsService.notifyConfirmation(
        options.stepCompletedText,
        this.stepBannerCompleteDuration,
      );
    }
  }

  @action
  onVideoClose(object, accordionAPI) {
    if (!this.args.step.completed) {
      this.intercomEventService.trackAnalyticsEvent({
        ...this.args.step.analyticsMetadata,
        action: 'viewed',
        object,
        section: 'index',
      });
      this.updateStepState.perform('completed', accordionAPI);
    }
  }
}
