/* import __COLOCATED_TEMPLATE__ from './workflow-type-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { type AdditionalSearchableData } from 'embercom/lib/operator/custom-bots/constants';
import type IntlService from 'embercom/services/intl';

interface Signature {
  Args: WorkflowTypeComponentArgs;
  Element: HTMLDivElement;
}

export interface WorkflowTypeComponentArgs {
  filterContent: (key: string, value: any) => void;
  parallel: AdditionalSearchableData['parallel'] | null;
  hasFin: AdditionalSearchableData['hasFin'] | null;
  additionalSearchableData: AdditionalSearchableData | null;
}

export default class WorkflowTypeComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare intl: IntlService;

  get app() {
    return this.appService.app;
  }

  get selectedWorkflowType() {
    let text =
      this.selectedValue === null
        ? this.intl.t('operator.workflows.workflow-type.filter.any').toLowerCase()
        : this.intl.t(`operator.workflows.workflow-type.${this.selectedValue}`);

    return `${this.intl.t('operator.workflows.workflow-type.filter.type-is')} ${text}`;
  }

  get additionalSearchableData() {
    return this.args.additionalSearchableData || {};
  }

  get selectedValue() {
    if (this.args.parallel !== null) {
      return this.args.parallel ? 'background' : 'customer-facing';
    } else if (this.args.hasFin !== null) {
      return 'has-fin';
    }

    return null;
  }

  get types() {
    return [
      { text: this.intl.t('operator.workflows.workflow-type.filter.any'), value: null },
      {
        icon: 'chat-bubble',
        text: this.intl.t('operator.workflows.workflow-type.customer-facing'),
        value: 'customer-facing',
      },
      {
        icon: 'branch',
        text: this.intl.t('operator.workflows.workflow-type.background'),
        value: 'background',
      },
      {
        icon: 'fin',
        text: this.intl.t('operator.workflows.workflow-type.has-fin'),
        value: 'has-fin',
      },
    ];
  }

  @action
  filterByWorkflowType(clickedType: 'customer-facing' | 'background' | 'has-fin' | null) {
    let data = {
      ...this.additionalSearchableData,
      hasFin: null,
      parallel: null,
    } as AdditionalSearchableData;

    if (clickedType === 'background' || clickedType === 'customer-facing') {
      data.parallel = clickedType === 'background';
    } else if (clickedType === 'has-fin') {
      data.hasFin = true;
    }

    this.args.filterContent('additionalSearchableData', data);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::Filters::WorkflowTypeComponent': typeof WorkflowTypeComponent;
  }
}
