/* import __COLOCATED_TEMPLATE__ from './custom-answers-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CustomAnswersBanner extends Component {
  @service navbarCollapsingService;

  @action
  toggleSidebar() {
    this.navbarCollapsingService.toggleNavbarCollapsed();
    let focusIsOnToggleButton = Boolean(
      document.activeElement?.closest('[data-submenu-collapse-toggle-button]'),
    );
    // we don't want the sidebar button to stay focused after click
    if (focusIsOnToggleButton) {
      document.activeElement?.blur();
    }
  }

  get learnDropdownList() {
    return [
      {
        items: [
          {
            text: this.openVideoModal,
            component: 'operator/fin/learn-dropdown',
            componentShouldReplaceItem: true,
          },
        ],
      },
    ];
  }
}
