/* import __COLOCATED_TEMPLATE__ from './prosemirror.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import AttributeInfoResolver from 'embercom/lib/common/template-attribute-resolver';
import {
  BaseConfig,
  BlocksDocument,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
  INPUT_RULE_MARKDOWN_ANCHOR,
} from '@intercom/embercom-prosemirror-composer';
import { EMOJI_TYPEAHEAD } from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';

class NewsItemComposerConfig extends BaseConfig {
  autoFocus = false;
  allowedBlocks = [
    'paragraph',
    'image',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
    'video',
    'button',
    'horizontalRule',
    'table',
  ];
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = true;
  inserters = {
    emojiInserter: 'composer/inserters/emoji-inserter',
  };
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];
  textDirection = 'ltr';
  tools = [
    { name: 'text-formatter' },
    { name: 'template-inserter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'button-editor', options: { showHelpLinkHeader: true } },
    { name: 'fallback-editor' },
    { name: 'media-inserter' },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    allowedAttachmentTypes: ['*'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };

  constructor({ app, eventService, resolver, placeholder }) {
    super();

    this.analytics = {
      trackAnalyticsEvent: (eventName, attrs) => {
        eventService.trackAnalyticsEvent({
          action: eventName,
          object: 'news_item',
          place: 'composer',
          ...attrs,
        });
      },
    };

    this.tools = this.tools.concat({
      name: 'image-editor',
      options: { supportAltAttributeEditing: app.canUseAdvancedEmailFormatting },
    });
    this.templating = { picker: 'common/attribute-picker', resolver };
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
    this.placeholder = placeholder;
  }
}

export default class ProsemirrorNewItemComposer extends Component {
  blocksDoc;
  @service appService;
  @service attributeService;
  @service contentEditorService;
  @service intercomEventService;
  @service store;
  @service intl;

  constructor() {
    super(...arguments);
    this.blocksDoc = new BlocksDocument(this.localizedNewsItemContents.blocks);
  }

  get app() {
    return this.appService.app;
  }

  get composerConfig() {
    return new NewsItemComposerConfig({
      app: this.app,
      eventService: this.intercomEventService,
      resolver: this.resolver,
      placeholder: this.intl.t('outbound.news-items.edit.editor.composer.placeholder'),
    });
  }

  get localizedNewsItemContents() {
    return this.args.newsItem?.localizedNewsItemContents?.firstObject;
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      includeAppAttributes: true,
      selectedEvents: this.selectedEvents,
    });
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }

  get serializedBlocks() {
    return this.localizedNewsItemContents?.blocks.serialize();
  }

  @action updateBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.localizedNewsItemContents.blocks = blockFragments;
  }
}
