/* import __COLOCATED_TEMPLATE__ from './identity-verification-android.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface IdentityVerificationAndroidArgs {
  selectedStep: string;
  onOpenStepChange: (stepId: string) => void;
  userSources: $TSFixMe;
  apiSecret: string;
  toggleHashCheckerVisibility: (visibility: boolean, secret: string) => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: IdentityVerificationAndroidArgs;
}

const maskedSecret = '****************************************';

export default class IdentityVerificationAndroid extends Component<Signature> {
  @tracked showSecret = false;

  get maskedSecret() {
    return maskedSecret;
  }

  @action
  toggleSecretVisibility(show: boolean) {
    this.showSecret = show;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::IdentityVerificationAndroid': typeof IdentityVerificationAndroid;
    'new-settings/workspace/security/identity-verification-android': typeof IdentityVerificationAndroid;
  }
}
