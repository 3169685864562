/* import __COLOCATED_TEMPLATE__ from './customisation.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  model: $TSFixMe;
}

const Customisation = templateOnlyComponent<Args>();
export default Customisation;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation': typeof Customisation;
  }
}
