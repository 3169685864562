/* import __COLOCATED_TEMPLATE__ from './messaging.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class Messaging extends Component {
  @service appService;
  @service notificationsService;

  get app() {
    return this.appService.app;
  }

  get canSeeHideAllUnsubscribeLinksSetting() {
    return this.app.hideAllUnsubscribeLinks && this.app.trustedForUnsubscribeLinks;
  }

  @task({ drop: true })
  *save() {
    try {
      yield this.args.messagingSettings.save();
      this.app.setProperties({
        hideAllUnsubscribeLinks: this.args.messagingSettings.hideAllUnsubscribeLinks,
        commentPlaceholder: this.args.messagingSettings.commentPlaceholder,
      });
      this.notificationsService.notifyConfirmation('Your Messaging settings have been updated');
    } catch (e) {
      this.notificationsService.notifyError(
        'There was an error updating your setting. Please try again.',
      );
    }
  }
}
