/* import __COLOCATED_TEMPLATE__ from './empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import {
  legacyObjectTypes,
  objectNames,
  objectTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';
import { LEARN_MORE_CONTENT_IDS } from 'embercom/lib/outbound/constants';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { objectIcons } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

const ALL = 'all';
const TRY_OUT = [objectTypes.survey, objectTypes.checklist, objectTypes.tour];
const TEMPLATES = ['30', '17', '48', '38', '28', '974', '1091', '848'];
export default class EmptyState extends Component {
  @service intercomEventService;
  @service outboundHomeService;
  @service intl;

  @tracked showContentCreationModal = false;
  @tracked selectedTemplate = this.args.selectedTemplate;

  get showNoMessagesText() {
    return this.args.totalCount === 0 && !this.args.hasAppliedFilters;
  }

  get selectedObjectTypes() {
    return this.args.selectedObjectTypes?.filter(
      (objectType) =>
        Number(objectType) !== legacyObjectTypes.legacyMessageEmail &&
        Number(objectType) !== legacyObjectTypes.legacyMessagePush,
    );
  }

  get singleContentTypeIsSelected() {
    return this.selectedObjectTypes?.length === 1;
  }

  get showTryOutButton() {
    return this.singleContentTypeIsSelected && TRY_OUT.includes(this.selectedObjectTypes[0]);
  }

  get selectedObjectName() {
    return objectNames[this.selectedObjectTypes[0]];
  }

  get tryOutLabel() {
    return `${this.intl.t('outbound.empty-state.try-out')}${this.selectedObjectName}`;
  }

  get tryIcon() {
    return objectIcons[this.selectedObjectTypes[0]];
  }

  get allEmptyStateValues() {
    let contentTypes = {};

    this.outboundHomeService.contentToDisplay.forEach((type) => {
      contentTypes[objectNames[type]] = {
        image: assetUrl(`/assets/images/outbound/${objectNames[type]}-empty-state.png`),
        header: `${this.intl.t(`outbound.empty-state.${objectNames[type]}.header`)}`,
        summary: `${this.intl.t(`outbound.empty-state.${objectNames[type]}.summary`)}`,
      };
    });
    return contentTypes;
  }

  get emptyStateValue() {
    return this.allEmptyStateValues[this.selectedObjectName];
  }

  get templates() {
    let templates = [];
    TEMPLATES.forEach((templateId) => {
      let template = this.args.templates.find((template) => template.id === templateId);
      if (template) {
        templates.push(template);
      }
    });
    return templates;
  }

  _trackAnalytics(contentType, id, target) {
    let targetObject = `empty_state_${target}`;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: targetObject,
      place: 'outbound_all',
      content_type: contentType,
      link_to: id,
    });
  }

  @action
  startTryOut() {
    Intercom(
      `start${capitalize(this.selectedObjectName)}`,
      LEARN_MORE_CONTENT_IDS[this.selectedObjectName],
    );
    this._trackAnalytics(
      this.selectedObjectName,
      LEARN_MORE_CONTENT_IDS[this.selectedObjectName],
      'try_out',
    );
  }

  @action
  showLearnMoreArticle() {
    let contentType = this.singleContentTypeIsSelected ? this.selectedObjectName : ALL;
    window.Intercom('showArticle', LEARN_MORE_CONTENT_IDS[contentType]);
    this._trackAnalytics(contentType, LEARN_MORE_CONTENT_IDS[contentType], 'learn_more');
  }
}
