/* import __COLOCATED_TEMPLATE__ from './padding-input.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class PaddingInput extends Component {
  @tracked padding;

  constructor() {
    super(...arguments);
    this.initialize();
  }

  checkMinPaddingLimit() {
    if (this.padding < 0) {
      this.padding = 0;
    }
  }

  checkMaxPaddingLimit() {
    if (this.padding > 80) {
      this.padding = 80;
    }
  }

  @action
  initialize() {
    if (!this.args.padding) {
      this.padding = 0;
    } else {
      this.padding = this.args.padding?.slice(0, -2);
    }
  }

  @action
  onChange() {
    this.checkMinPaddingLimit();
    this.checkMaxPaddingLimit();
    this.args.onChange(`${this.padding}px`);
  }
}
