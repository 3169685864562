/* import __COLOCATED_TEMPLATE__ from './audience-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  matchBehaviors,
  roleNames,
  roleTypes,
} from 'embercom/models/data/matching-system/matching-constants';
import { isPresent } from '@ember/utils';
import { audienceTypes } from 'embercom/helpers/audience-types';
import { capitalize } from '@ember/string';
import { pluralize } from 'ember-inflector';

export default class AudienceComponent extends Component {
  @service intl;
  @service appService;

  get app() {
    return this.appService.app;
  }

  get isOneOff() {
    let selectedMatchBehavior = this.args.selectedMatchBehaviorValues;

    return (
      selectedMatchBehavior?.length === 1 && selectedMatchBehavior[0] === matchBehaviors.static
    );
  }

  get allowedRoles() {
    if (this.isOneOff) {
      return [roleTypes.leadRole, roleTypes.userRole];
    }
    return [roleTypes.visitorRole, roleTypes.leadRole, roleTypes.userRole];
  }

  get audiences() {
    return this.allowedRoles.map((type) => {
      return {
        text: capitalize(pluralize(roleNames[type])),
        value: type,
        isSelected: this.args.selectedAudienceValues?.includes(type) || false,
      };
    });
  }

  get name() {
    let selected = this.selectedValues || this.args.selectedAudienceValues;
    if (isPresent(selected)) {
      return audienceTypes(selected);
    }
    if (this.isOneOff) {
      return this.intl.t('outbound.content-type-component.audience-component.leads-or-users');
    }
    return this.intl.t(
      'outbound.content-type-component.audience-component.visitors-leads-or-users',
    );
  }

  get isOpen() {
    return this.args.activeFilter === 'audience';
  }

  @action
  filterByAudiences(selectedValues) {
    this.args.filterContent('selectedAudienceValues', selectedValues);
  }

  @action
  removeFilter() {
    this.args.filterContent('selectedAudienceValues', []);
    this.args.removeFromActiveFilter('audience');
  }
}
