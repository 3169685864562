/* import __COLOCATED_TEMPLATE__ from './identity-verification-web.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';

interface IdentityVerificationWebArgs {
  selectedStep: string;
  onOpenStepChange: (step: string) => void;
  idvSettings: $TSFixMe;
  reminder: $TSFixMe;
  pingDomains: $TSFixMe;
  toggleHashCheckerVisibility: (visibility: boolean, secret: string) => void;
}

interface Signature {
  Element: HTMLDivElement;
  Args: IdentityVerificationWebArgs;
}

export default class IdentityVerificationWeb extends Component<Signature> {
  @service declare appService: any;
  @service declare intercomEventService: any;

  @tracked showSecret = false;
  @tracked showIdentityVerificationConfirmationModal = false;
  @tracked showIdentityVerificationDisablingModal = false;

  get secret() {
    return this.appService.app.api_secret;
  }

  get maskedSecret() {
    if (this.secret) {
      // Leave up to the last 4 characters visible
      return this.secret.replace(/.{4}(?=.)/g, '•');
    } else {
      return '';
    }
  }

  get isIdvEnabled() {
    return this.appService.app.identityVerified;
  }

  @action
  toggleIdentityVerification() {
    if (this.isIdvEnabled === true) {
      this.showIdentityVerificationDisablingModal = true;
    } else {
      this.showIdentityVerificationConfirmationModal = true;
    }
  }

  @action
  confirmIdentityVerificationEnforcement(enabled: boolean) {
    ajax({
      url: `/ember/apps/${this.appService.app.id}/update_identity_verification`,
      type: 'POST',
      data: JSON.stringify({
        identity_verified: enabled,
      }),
    });
    this.intercomEventService.trackEvent(`secure-mode-${enabled ? 'enabled' : 'disabled'}`, {
      action: enabled ? 'enabled' : 'disabled',
      object: 'secure_mode',
      place: `web_settings`,
      owner: 'growth',
    });
    this.appService.app.identityVerified = enabled;
    this.showIdentityVerificationConfirmationModal = false;
    this.showIdentityVerificationDisablingModal = false;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::IdentityVerificationWeb': typeof IdentityVerificationWeb;
    'new-settings/workspace/security/identity-verification-web': typeof IdentityVerificationWeb;
  }
}
