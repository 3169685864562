/* import __COLOCATED_TEMPLATE__ from './copy-link.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class extends Component {
  @service intercomEventService;

  @action
  sendAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'copy_link',
      context: this.args.linkContext,
      ...this.args.analyticsMetadata,
    });
  }
}
