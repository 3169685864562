/* import __COLOCATED_TEMPLATE__ from './setup-and-go-live.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import { objectTypes, states } from 'embercom/models/data/matching-system/matching-constants';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import type ContentWrapper from 'embercom/models/content-service/content-wrapper';
import type KnowledgeHubService from 'embercom/services/knowledge-hub-service';
import {
  BULK_ACTION_PROGRESSION_ID,
  BulkActionProgressionValues,
  type BulkActionProgressionEvent,
} from 'embercom/services/knowledge-hub-service';
import ENV from 'embercom/config/environment';

interface Arguments {
  liveFinWorkflowsOverridableBySetup: ContentWrapper[];
  hasContentReadyForFin: boolean;
  reloadSetupRuleset: (setupType: ChannelType) => void;
  previewConfiguration: $TSFixMe;
  finSetupRuleset: $TSFixMe;
  emailSetupRuleset: $TSFixMe;
  channelType: ChannelType;
}

interface Signature {
  Element: HTMLDivElement;
  Args: Arguments;
}

export default class SetupAndGoLive extends Component<Signature> {
  @service declare appService: any;
  @service declare outboundHomeService: any;
  @service declare knowledgeHubService: KnowledgeHubService;
  @service declare realTimeEventService: { on: Function; off: Function; subscribeTopics: Function };

  @tracked totalLiveFinProfilesCount = 0;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.fetchProfiles).perform();

    this.setBulkActionProgressActions();
  }

  willDestroy(): void {
    super.willDestroy();
    this.destroyNexusEvents();
  }

  private setBulkActionProgressActions() {
    this.realTimeEventService.on(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  private destroyNexusEvents() {
    this.realTimeEventService.off(BULK_ACTION_PROGRESSION_ID, this, 'handleBulkActionProgression');
  }

  async handleBulkActionProgression(event: BulkActionProgressionEvent) {
    let currentAdmin = this.appService.app.currentAdmin;
    if (currentAdmin.id !== event.admin_id.toString()) {
      return;
    }

    if (event.status === BulkActionProgressionValues.Complete) {
      if (!event.all_entities_unaffected) {
        setTimeout(() => {
          this.knowledgeHubService.fetchKnowledgeUsageSummary();
        }, ENV.APP._500MS);
      }
    }
  }

  @task({ restartable: true })
  *fetchProfiles(): TaskGenerator<void> {
    let response: $TSFixMe = yield this.outboundHomeService.contentSearchWithFilters({
      object_types: [objectTypes.resolutionBotBehavior],
      app_id: this.appService.app.id,
    });

    response.contentWrappers.forEach((profile: any) => {
      if (
        profile.contents.firstObject?.state &&
        profile.contents.firstObject?.state === states.live &&
        (profile.contents.firstObject.contentData?.use_ai_answers ||
          profile.contents.firstObject.contentData?.use_custom_answers)
      ) {
        this.totalLiveFinProfilesCount += 1;
      }
    });
  }

  get isLoading() {
    return taskFor(this.fetchProfiles).isRunning;
  }

  get relevantSetupRuleset() {
    if (this.args.channelType === 'email') {
      return this.args.emailSetupRuleset;
    }
    return this.args.finSetupRuleset;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::SetupAndGoLive': typeof SetupAndGoLive;
  }
}
