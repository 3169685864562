/* import __COLOCATED_TEMPLATE__ from './imports-exports.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { type TabItem } from 'embercom/components/new-settings/common/standard-base';
import { type ImportZendeskContext } from 'embercom/lib/apps/app/new-settings/data/imports-exports/import-zendesk/zendesk-context-loader';

interface Args {
  model: {
    csvImports: any;
    mailchimpImports: any;
    mixpanelImports: any;
    importZendesk: { enabled: boolean; context?: ImportZendeskContext };
  };
  tab: string;
  changeTab: (tab: string) => void;
}

const tabs: TabItem[] = [
  {
    label: 'new-settings.data.imports-exports.tabs.import-from-zendesk',
    value: 'import-zendesk',
  },
  {
    label: 'new-settings.data.imports-exports.tabs.import-csv',
    value: 'import-csv',
  },
  {
    label: 'new-settings.data.imports-exports.tabs.import-from-mixpanel',
    value: 'import-mixpanel',
  },
  {
    label: 'new-settings.data.imports-exports.tabs.import-from-mailchimp',
    value: 'import-mailchimp',
  },
  {
    label: 'new-settings.data.imports-exports.tabs.export-data',
    value: 'export-data',
  },
];

export default class ImportsExports extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare permissionsService: $TSFixMe;

  get selectedTab() {
    let defaultTab = this.canMigrateFromZendesk ? 'import-zendesk' : 'import-csv';
    if (this.args.tab === 'import-zendesk' && !this.canMigrateFromZendesk) {
      return defaultTab;
    }
    return this.args.tab || defaultTab;
  }

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (!args.tab) {
      this.setSelectedTab(this.canMigrateFromZendesk ? 'import-zendesk' : 'import-csv');
    }
  }

  get app() {
    return this.appService.app;
  }

  get canAccessBulkExport(): boolean {
    return this.appService.app.canBulkExport;
  }

  get tabs(): TabItem[] {
    let tabItems = [...tabs];
    if (!this.canMigrateFromZendesk) {
      tabItems = tabItems.slice(1);
    }
    if (!this.canAccessBulkExport) {
      tabItems.pop();
    }
    return tabItems;
  }

  @action
  setSelectedTab(value: string): void {
    this.args.changeTab(value);
  }

  get canMigrateFromZendesk() {
    return this.args.model.importZendesk.enabled;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports': typeof ImportsExports;
  }
}
