/* import __COLOCATED_TEMPLATE__ from './tag-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { IN_OPERATOR, NIN_OPERATOR } from 'embercom/lib/outbound/constants';
import type Tag from 'embercom/models/tag';
import { type TagOperator } from 'embercom/lib/knowledge-hub/constants';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  activeFilter?: string;
  selectedTagValues?: string[];
  selectedTagOperator: TagOperator | string;
  messagesTags: Tag[];
  filterContent: (key: string, value: string[]) => void;
  showDelete: boolean;
  removeFromActiveFilter: (filter: string) => void;
  filterOnSelectionChange?: boolean;
}

export default class TagComponent extends Component<Args> {
  @service appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;
  @tracked selectedValues = this.args.selectedTagValues || [];

  @tracked visibleTags: any = this.tags;

  tagName = '';
  inOperator = IN_OPERATOR;
  ninOperator = NIN_OPERATOR;

  get app() {
    return this.appService.app;
  }

  get additionalTagCount() {
    let selected = this.selectedValues || this.args.selectedTagValues;
    return selected.length - 1;
  }

  get hasMessagesTags() {
    return this.args.messagesTags.length > 0;
  }

  get hasNoMessagesTags() {
    return !this.hasMessagesTags;
  }

  get isOpen() {
    return this.args.activeFilter === 'tag';
  }

  get sampleSelectedTagName() {
    let selected = this.selectedValues || this.args.selectedTagValues;
    if (isPresent(selected)) {
      return this.store.peekRecord('tag', selected[0]).name;
    }
  }

  get emptyTagsMessage() {
    return {
      text: this.intl.t('outbound.all.tag-filter.no-tags'),
    };
  }

  get tags() {
    if (this.hasNoMessagesTags) {
      return [
        {
          text: this.emptyTagsMessage.text,
          value: 'name',
          component: 'outbound/filters/empty-tags-component',
          isDisabled: true,
        },
      ];
    }
    return this._getSortedTags();
  }

  get childDropdownName() {
    if (isPresent(this.sampleSelectedTagName)) {
      let label = `${this.sampleSelectedTagName}`;
      if (this.additionalTagCount > 0) {
        label += ` ${this.intl.t('outbound.all.tag-filter.other', {
          count: this.additionalTagCount,
        })}`;
      }
      return label;
    }
    return `${this.intl.t('outbound.all.tag-filter.any')}`;
  }

  get name() {
    if (isPresent(this.sampleSelectedTagName)) {
      let label = `${this.intl.t('outbound.all.tag-filter.tag')} ${this._friendlyOperator} ${
        this.sampleSelectedTagName
      }`;
      if (this.additionalTagCount > 0) {
        label += ` ${this.intl.t('outbound.all.tag-filter.other', {
          count: this.additionalTagCount,
        })}`;
      }
      return label;
    }
    return `${this.intl.t('outbound.all.tag-filter.tag')} ${this._friendlyOperator} ${this.intl.t(
      'outbound.all.tag-filter.any',
    )}`;
  }

  get _friendlyOperator() {
    if (this.args.selectedTagOperator === NIN_OPERATOR) {
      return this.intl.t('outbound.all.tag-filter.is-not');
    } else {
      return this.intl.t('outbound.all.tag-filter.is');
    }
  }

  @action
  filterByTags(selectedValues: string[]) {
    this.selectedValues = selectedValues;
  }

  @action removeFilter() {
    this.args.filterContent('selectedTagValues', []);
    this.args.removeFromActiveFilter('tag');
  }

  @action
  onClose() {
    this.args.filterContent('selectedTagValues', this.selectedValues);
  }

  @action
  filterOnSelectionChange(selectedValues: string[]) {
    this.args.filterContent('selectedTagValues', selectedValues);
  }

  @action
  onAttrUpdate() {
    this.selectedValues = this.args.selectedTagValues || [];
  }

  _getSortedTags() {
    let tagObjects = this.args.messagesTags.map((tag) => ({
      text: tag.name,
      value: tag.id,
      isSelected: this.args.selectedTagValues
        ? this.args.selectedTagValues.includes(tag.id)
        : false,
    }));
    return tagObjects.sortBy('isSelected').reverse();
  }

  @action
  filterValues(filter: any) {
    if (filter === '') {
      this.visibleTags = this.tags;
    } else {
      this.visibleTags = this.tags.filter((tag) =>
        tag.text.toLowerCase().includes(filter.toLowerCase()),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::Filters::TagComponent': typeof TagComponent;
    'outbound/filters/tag-component': typeof TagComponent;
  }
}
