/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { sentAsOptions } from 'embercom/models/data/outbound/constants';

export default class Sidebar extends Component {
  @service appService;

  sentAsOptions = sentAsOptions;

  get app() {
    return this.appService.app;
  }

  get showBadge() {
    return this.args.newsItem.sentAs === sentAsOptions.badge;
  }

  @action toggleShowBadge() {
    if (this.args.newsItem.sentAs === sentAsOptions.badge) {
      this.args.newsItem.sentAs = sentAsOptions.silent;
    } else {
      this.args.newsItem.sentAs = sentAsOptions.badge;
    }
  }
}
