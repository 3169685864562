/* import __COLOCATED_TEMPLATE__ from './sms-preview.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import ajax from 'embercom/lib/ajax';
import { countryData } from 'embercom/components/outbound/sms/onboarding/country-data';
import { phoneTypes } from '../../../../models/data/sms/constants';

export default class SmsPreview extends Component {
  @service appService;
  @service notificationsService;
  @service intl;
  @tracked toPhoneNumber = '';

  get prefix() {
    return countryData[this.args.purchasedPhoneNumber.countryCode].prefix;
  }

  get countryName() {
    return countryData[this.args.purchasedPhoneNumber.countryCode].name;
  }

  get isTollFree() {
    return this.args.purchasedPhoneNumber.phoneType === phoneTypes.tollFree;
  }

  @action
  updateToPhoneNumber(e) {
    this.toPhoneNumber = e.target.value;
  }

  @action
  showContentCreationModal() {
    this.args.createContent();
    this.args.setOnboardingStarted(false);
  }

  @task({ drop: true })
  *sendSms() {
    try {
      yield ajax({
        url: '/ember/sms/sms_preview_messages',
        type: 'POST',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          from_id: this.args.purchasedPhoneNumber.id,
          to: `${this.prefix}${this.toPhoneNumber}`,
        }),
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('outbound.sms.onboarding.sms-preview.sent-successfully'),
      );
      this.args.setOnboardingStarted(false);
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('outbound.sms.onboarding.sms-preview.failed-to-send'),
      );
    }
  }
}
