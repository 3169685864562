/* import __COLOCATED_TEMPLATE__ from './send-ticket-editor.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import intermoji from '@intercom/intermoji';
import TicketType from 'embercom/models/inbox/ticket-type';
import { TicketCategory } from 'embercom/objects/inbox/ticket';

export default class SendTicketEditor extends Component {
  @service appService;
  @service store;
  @service intl;
  @tracked ticketTypes;

  @task({ drop: true })
  *fetchTicketTypes() {
    let ticketTypes = yield this.store.findAll('inbox/ticket-type');

    ticketTypes = ticketTypes
      .reject(({ archived, internal, isTracker }) => archived || internal || isTracker)
      .sortBy('name');

    this.ticketTypes = ticketTypes;
  }

  get stepData() {
    if (this.args.isVisualBuilderStep) {
      return this.args.step;
    } else {
      return this.args.step.stepData;
    }
  }

  get ticketCategories() {
    return [
      {
        id: TicketCategory.Task,
        heading: this.intl.t('operator.workflows.visual-builder.send-ticket-menu.tickets.task'),
      },
      {
        id: TicketCategory.Request,
        heading: this.intl.t('operator.workflows.visual-builder.send-ticket-menu.tickets.request'),
      },
    ];
  }

  get selectListItemsByCategory() {
    let groupedTickets = TicketType.ticketTypesByCategory(this.ticketTypes);
    let ticketWithCategoriesLabels = this.ticketCategories
      .map((category) => {
        let categoryTickets = groupedTickets[category.id] || [];
        let icSelectFormattedItems = categoryTickets.map((ticket) => ({
          text: ticket.name,
          value: ticket.id,
        }));

        return {
          ...category,
          items: icSelectFormattedItems,
        };
      })
      .filter(({ items }) => items.length > 0);

    return ticketWithCategoriesLabels;
  }

  get loading() {
    return this.fetchTicketTypes.isRunning || !this.ticketTypes;
  }

  get selectedTicketType() {
    if (!this.ticketTypes) {
      return null;
    }

    return this.ticketTypes.find((item) => item.id === this.stepData.ticketTypeId);
  }

  get emojiUri() {
    if (this.selectedTicketType) {
      return intermoji.twemojiSVGUri(this.selectedTicketType.emoji);
    }
  }

  get hasError() {
    return !this.loading && !this.args.step.ticketTypeId;
  }

  get operatorIdentityUrl() {
    return this.appService.app?.operatorBot?.avatar?.image_urls?.square_50;
  }
}
