/* import __COLOCATED_TEMPLATE__ from './level-completed.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import { task, timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';

export default Component.extend({
  tagName: '',
  router: service(),
  onboardingHomeService: service(),
  guide: readOnly('onboardingHomeService.guide'),
  showBackToHomeBanner: false,
  appService: service(),

  init() {
    this._super(...arguments);
    this._transitionToBackToHomeBanner.perform();
  },

  _transitionToBackToHomeBanner: task(function* () {
    yield timeout(ENV.APP._3000MS);
    this.set('showBackToHomeBanner', true);
  }).restartable(),

  goToHome: action(function () {
    this.trackBannerClick('clicked', 'level_completed_banner');
    return this.router.transitionTo('apps.app.home', this.appService.app.id);
  }),
});
