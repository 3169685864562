/* import __COLOCATED_TEMPLATE__ from './security.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { type TabItem } from '../common/standard-base';

interface WorkspaceSettingsArgs {
  attachmentSettings: $TSFixMe;
  userSources: $TSFixMe;
  pingDomains: $TSFixMe;
  settings: $TSFixMe;
  idvSettings: $TSFixMe;
  reminder: $TSFixMe;
  apiSecrets: {
    ios: string;
    android: string;
  };
  initialProvisioningMethod: string;
  selectedProvisioningMethod: string;
  tab: string;
  section: string;
  changeTab: (tab: string, section: string) => void;
}

const SECURITY_TABS: TabItem[] = [
  {
    label: 'new-settings.workspace.security.tabs.general',
    value: 'general',
  },
  {
    label: 'new-settings.workspace.security.tabs.enforce-identity',
    value: 'enforce-identity',
  },
];

interface Signature {
  Element: HTMLDivElement;
  Args: WorkspaceSettingsArgs;
}

export default class Security extends Component<Signature> {
  @service declare appService: $TSFixMe;

  get selectedTab() {
    return this.args.tab;
  }

  get controlTabs() {
    return SECURITY_TABS;
  }

  @action
  selectTab(tab: string, sectionId = '') {
    this.args.changeTab(tab, sectionId);
  }

  @action
  changeTabAndOpenSection(tab: string, sectionId: string) {
    this.selectTab(tab, sectionId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security': typeof Security;
    'new-settings/workspace/security': typeof Security;
  }
}
