/* import __COLOCATED_TEMPLATE__ from './teammates.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import { getSeatInfo } from 'embercom/lib/admins/seat-info';

interface Args {
  invitedAdmins: any;
  roles: any;
  migrationState: any;
  tab: string;
  changeTab: (tab: string) => void;
  filterType: any;
  filterValue: any;
}

export default class Teammates extends Component<Args> {
  @service appService: $TSFixMe;
  @service customerService: $TSFixMe;

  @tracked showBulkInviteModal = false;
  @tracked seatInfo: any;
  @tracked filterType = null;
  @tracked filterValue = null;

  constructor(owner: unknown, args: never) {
    super(owner, args);
    this.filterType = this.args.filterType;
    this.filterValue = this.args.filterValue;
    taskFor(this.loadSeatInfo).perform();
  }

  get app() {
    return this.appService.app;
  }

  get selectedTab() {
    return this.args.tab || 'teammates';
  }

  get activeInvitedAdmins() {
    if (!this.args.invitedAdmins) {
      return [];
    }
    return this.args.invitedAdmins.filter((admin: any) => admin.active === true);
  }

  get shouldShowUpgradeBanner() {
    let customer = this.customerService.customer;
    return (
      !this.app.isSalesforceContracted ||
      (this.app.isSalesforceContracted &&
        !this.app.canUseSeatOverages &&
        customer.freeSeatCount >= customer.seatLimit)
    );
  }

  get hideEmptyStateBanner() {
    return this.app.humanAdmins.length > 1 || this.activeInvitedAdmins.length > 0;
  }

  @action
  setSelectedTab(value: string) {
    this.args.changeTab(value);
  }

  @action
  showMoreSeatsArticle() {
    window.Intercom('showArticle', 8205716); // https://www.intercom.com/help/en/articles/8205716-intercom-seats
  }

  @action
  showTeammateArticle() {
    window.Intercom('showArticle', 280); // https://www.intercom.com/help/en/articles/280-add-remove-delete-or-export-a-teammate
  }

  @action
  showPermissionsArticle() {
    window.Intercom('showArticle', 176); // https://www.intercom.com/help/en/articles/176-permissions-how-to-restrict-access-for-some-teammates
  }

  @action
  reloadPermissions() {
    return this.app.fetchAndUpdateAllAdminPermissions();
  }

  @action
  updatePaywalls() {
    if (this.app.canSeeCostChangeCard) {
      taskFor(this.loadSeatInfo).perform();
    }
  }

  @dropTask
  *loadSeatInfo(): Generator<any> {
    let seatInfo = yield getSeatInfo({
      numberOfSeatsRequired: 1,
      numberOfSeatsInUse: undefined,
      seatsInUseByInvites: undefined,
      isCopilotSeatAdded: false,
      copilotData: {},
    });
    this.seatInfo = seatInfo;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Teammates': typeof Teammates;
  }
}
