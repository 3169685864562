/* import __COLOCATED_TEMPLATE__ from './route-conversation-to-human.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { TrackedObject, tracked as builtInTracked } from 'tracked-built-ins';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency-decorators';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import LoadFinProfileModule from 'embercom/lib/home/load-fin-profile';
import { FIN_GENERAL_RELEASE } from 'embercom/lib/home/guides';
import ajax, { post } from 'embercom/lib/ajax';
import { copy } from 'ember-copy';

export default class RouteConversationToHumanComponent extends Component {
  @service appService;
  @service intl;
  @service onboardingHomeService;
  @service store;
  @service intercomEventService;
  @service outboundHomeService;
  @service notificationsService;

  @tracked value = null;
  @tracked selectedTitle = null;
  @tracked selectedBotOnlyLocale = this.defaultLocale;
  @tracked selectedHandoverMode = 'zendesk';
  @tracked ruleset = null;
  @tracked showCompletedBanner = false;
  @tracked sideBySideConfiguration = null;
  @tracked parts = [];
  @builtInTracked saveButtonStates = new TrackedObject({
    'route-to-inbox': 'visible',
    'close-the-conversation': 'visible',
    'route-to-another-support-tool': 'hidden',
  });
  localStorageKey = `onboardingHomeRouteConversationToHumanCompletedKey_${this.app.id}`;

  constructor() {
    super(...arguments);
    this.loadSideBySideConfiguration();
    this.loadFinProfile.perform();

    if (this.args.step.completed) {
      let completedKey = localStorage.getItem(this.localStorageKey) || this.value;
      this.value = completedKey;
    }
  }

  get rulesetSteps() {
    return this.ruleset.rulesetLinks?.firstObject.object.visualBuilderObject.groups.filter(
      (group) => group.title === 'Handover',
    ).firstObject.steps;
  }

  get app() {
    return this.appService.app;
  }

  @dropTask
  *loadFinProfile() {
    if (!this.ruleset) {
      this.ruleset = yield LoadFinProfileModule.loadFinProfile(this);
      if (this.closeConversationSelected) {
        this.rulesetSteps
          .filter((step) => step.type === 'operator/visual-builder/step/chat-message')
          .forEach((step) =>
            this.parts.pushObject(
              this.store.createFragment('operator/bot-only-message/part', {
                blocks: copy(step.blocks),
              }),
            ),
          );
      }
    }
    if (this.value === null) {
      if (this.routeConversationToAnotherToolSelected) {
        this.value = 'route-to-another-support-tool';
      } else if (this.routeConversationToInboxSelected) {
        this.value = 'route-to-inbox';
      } else {
        this.value = 'close-the-conversation';
      }
    }
    if (this.args.step.completed) {
      let completedKey = localStorage.getItem(this.localStorageKey);
      if (!completedKey) {
        localStorage.setItem(this.localStorageKey, this.value);
      }

      this._populateCompletedBanner();
    }
  }

  get resolutionBotBehaviour() {
    return this.ruleset?.rulesetLinks?.firstObject.object;
  }

  @action
  async save() {
    this.saveButtonStates[this.value] = 'disabled';
    await this.saveRuleset();
    if (this.sideBySideConfiguration?.activeTool) {
      this.deleteSideBySideConfiguration();
    }
  }

  @action
  onPartChange() {
    this.saveButtonStates['close-the-conversation'] = this._isContentEmpty()
      ? 'disabled'
      : 'visible';
  }

  @action
  removePart(part) {
    this.parts.removeObject(part);
    if (this.parts.length === 0) {
      this.addPart();
    }
  }

  @action
  addPart() {
    this.parts.pushObject(this.store.createFragment('operator/bot-only-message/part'));
  }

  @action
  async saveRuleset() {
    try {
      await this.ruleset.destroyRecord();
      let handover;
      if (this.value === 'route-to-inbox') {
        handover = 'route_to_inbox';
      } else if (this.value === 'close-the-conversation') {
        handover = 'close_conversation';
      } else {
        handover = 'route_to_another_support_tool';
      }
      this.ruleset = await post('/ember/operator_workflows/create_fin_workflow', {
        app_id: this.app.id,
        handover_type: handover,
        parts: handover === 'close_conversation' ? this.parts : [],
        role_predicate_group: {
          predicates: [{ attribute: 'role', type: 'role', comparison: 'in', value: ['user_role'] }],
        },
      });
      this.ruleset = this.store.push(this.store.normalize('matching-system/ruleset', this.ruleset));
      if (this.value === 'close-the-conversation') {
        this.saveButtonStates['route-to-inbox'] = 'visible';
      } else {
        this.saveButtonStates['close-the-conversation'] = 'visible';
      }
      let selection;
      if (this.routeConversationToInboxSelected) {
        selection = 'route_to_inbox';
      } else if (this.closeConversationSelected) {
        selection = 'close_conversation';
      } else {
        selection = 'hand_off_to_zendesk';
      }
      this.intercomEventService.trackAnalyticsEvent({
        action: 'completed',
        object: `route_conversation_to_human.${selection}`,
        place: 'onboarding_home',
        section: 'route_conversation_to_human',
      });
      if (!this.isFinGeneralReleaseGuide) {
        document.getElementById('embedded-messenger')?.contentWindow.location.reload();
      }

      if (
        (this.routeConversationToInboxSelected || this.closeConversationSelected) &&
        this.sideBySideConfiguration !== null
      ) {
        this.sideBySideConfiguration.activeTool = null;
      }
      this.markAsCompleted();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.route-conversation-to-human.error-saving'),
      });
    }
  }

  @action
  setBotMode(value) {
    if (value !== 'route-to-another-support-tool') {
      if (this.args.step.completed) {
        let completedKey = localStorage.getItem(this.localStorageKey);
        if (value === 'route-to-inbox') {
          this.saveButtonStates[value] = completedKey === 'route-to-inbox' ? 'disabled' : 'visible';
        } else if (value === 'close-the-conversation') {
          this.saveButtonStates[value] = this._isContentEmpty()
            ? 'disabled'
            : this.saveButtonStates[value];
        }
      }
    }
    this.value = value;
  }

  @action
  onZendeskEnabled() {
    this.markAsCompleted();
    if (!this.isFinGeneralReleaseGuide) {
      document.getElementById('embedded-messenger')?.contentWindow.location.reload();
    }
    this.saveButtonStates['route-to-inbox'] = 'visible';
    this.saveButtonStates['close-the-conversation'] = 'visible';
  }

  @action
  markAsCompleted() {
    localStorage.setItem(this.localStorageKey, this.value);
    this._populateCompletedBanner();
    this.args.completionCallback();
  }

  get defaultLocale() {
    return this.appService.app.locale || 'en';
  }

  get routeConversationToInboxSelected() {
    return (
      (this.resolutionBotBehaviour?.botMode === 'looping' &&
        !this.routeConversationToAnotherToolSelected) ||
      this.rulesetSteps.lastObject.type === 'operator/visual-builder/step/assign-conversation'
    );
  }

  get routeConversationToAnotherToolSelected() {
    return (
      this.sideBySideConfiguration?.activeTool === 'zendesk' ||
      this.rulesetSteps.lastObject.type === 'operator/visual-builder/step/workflow-connector'
    );
  }

  get closeConversationSelected() {
    return (
      this.resolutionBotBehaviour?.botMode === 'bot_only' ||
      this.rulesetSteps.lastObject.type === 'operator/visual-builder/step/close-conversation'
    );
  }

  get showSaveButton() {
    return this.saveButtonStates[this.value] !== 'hidden';
  }

  get disabledButton() {
    return this.saveButtonStates[this.value] === 'disabled';
  }

  _populateCloseConversationDefaultMessage() {
    this.resolutionBotBehaviour?.botOnlyMessageLocalizations?.forEach((messages) => {
      if (messages?.parts.length === 0) {
        messages?.parts.createFragment();
      }
      if (messages?.parts.firstObject.blocks.length === 0) {
        messages.parts.firstObject.blocks = [
          createFragmentFromBlock(this.store, {
            text: this.intl.t(
              'onboarding.home.steps.route-conversation-to-human.close-the-conversation-default-message',
              {
                htmlSafe: true,
              },
            ),
            type: 'paragraph',
          }),
        ];
      }
    });
  }

  get isFinGeneralReleaseGuide() {
    return this.onboardingHomeService.guide.identifier === FIN_GENERAL_RELEASE;
  }

  get handoverDropdownItems() {
    return [
      {
        value: 'zendesk',
        text: this.intl.t('onboarding.home.steps.route-conversation-to-human.handoff-to-zendesk'),
        icon: 'zendesk',
      },
      {
        value: 'other-tool',
        text: this.intl.t(
          'onboarding.home.steps.route-conversation-to-human.handoff-to-another-tool',
        ),
        icon: 'webhook',
      },
    ];
  }

  @action
  updateHandoverMode(mode) {
    this.selectedHandoverMode = mode;
  }

  _getTitle(value) {
    return this.intl
      .t(`onboarding.home.steps.route-conversation-to-human.${value}-completion-text`)
      .concat(
        this.isFinGeneralReleaseGuide
          ? this.intl.t(`onboarding.home.steps.route-conversation-to-human.to-preview`)
          : '',
      );
  }

  _isContentEmpty() {
    let currentMessage = this.resolutionBotBehaviour?.botOnlyMessageLocalizations?.filter(
      (messages) => messages.locale === this.selectedBotOnlyLocale,
    );
    let isContentEmpty =
      currentMessage?.firstObject?.parts?.length === 0 ||
      currentMessage?.firstObject?.parts?.firstObject?.blocks.length === 0 ||
      currentMessage?.firstObject?.parts?.firstObject?.blocks?.firstObject.text === '';
    return isContentEmpty;
  }

  _populateCompletedBanner() {
    let completedKey = localStorage.getItem(this.localStorageKey);

    if (completedKey) {
      this.showCompletedBanner = true;
      this.saveButtonStates[completedKey] =
        completedKey === 'route-to-another-support-tool' ? 'hidden' : 'disabled';
      this.selectedTitle = this._getTitle(completedKey);
    }
  }

  @action
  async loadSideBySideConfiguration() {
    this.sideBySideConfiguration = await this.store.findRecord(
      'side-by-side/handover/configuration',
      this.appService.app.id,
    );
  }

  async deleteSideBySideConfiguration() {
    try {
      await ajax({
        url: `/ember/side_by_side/handover/configurations/${this.appService.app.id}`,
        type: 'DELETE',
        data: JSON.stringify({
          app_id: this.appService.app.id,
          subdomain: this.sideBySideConfiguration?.zendeskConfiguration?.subdomain,
        }),
      });

      this.sideBySideConfiguration = null;
      let recordToDelete = this.store.peekRecord(
        'side-by-side/handover/configuration',
        this.appService.app.id,
      );
      this.store.unloadRecord(recordToDelete);
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.route-conversation-to-human.error-saving'),
      });
    }
  }
}
