/* import __COLOCATED_TEMPLATE__ from './turn-on-card.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class NewsfeedsSiteSettingsTurnOnCard extends Component {
  @service intl;
  @service notificationsService;
  @service intercomConfirmService;

  get site() {
    return this.args.site;
  }

  @action
  toggleNewsCenter() {
    this._toggleNewsCenterModal(!this.site.turnedOn);
  }

  _getSwitchNewsCenterModalOptions(turnOn) {
    let state = turnOn ? 'on' : 'off';
    let url = this.site.url;
    let title = this.intl.t(`outbound.newsfeeds.site-settings.confirm.${state}.title`);
    let body = this.intl.t(`outbound.newsfeeds.site-settings.confirm.${state}.body`, {
      url,
      htmlSafe: true,
    });
    let confirmButtonText = this.intl.t(`outbound.newsfeeds.site-settings.confirm.${state}.button`);
    return {
      title,
      body,
      confirmButtonText,
      primaryButtonType: turnOn ? 'primary-live' : 'primary-destructive',
    };
  }

  async _toggleNewsCenterModal(turnOn) {
    let confirmOptions = this._getSwitchNewsCenterModalOptions(turnOn);
    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      return;
    }
    try {
      this.site.turnedOn = turnOn;
      await this.args.saveSite();
      this.notificationsService.notifyConfirmation(
        this.intl.t('outbound.newsfeeds.site-settings.notifications.turned-state', {
          turnedOn: this.site.turnedOn,
        }),
      );
    } catch (error) {
      this.site.rollbackAttributes();
      this.args.handleServerError(error);
    }
  }
}
