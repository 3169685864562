/* import __COLOCATED_TEMPLATE__ from './completed.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: {
    notification: {
      data: {
        externalContentUrl: string;
        sourceUrl: string;
        ctaUrl: string;
      };
    };
  };
}

const Completed = templateOnlyComponent<Signature>();
export default Completed;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Notifications::ContentImport::Completed': typeof Completed;
  }
}
