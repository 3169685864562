/* import __COLOCATED_TEMPLATE__ from './editors.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-jquery */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { schedule } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import $ from 'jquery';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { isPresent } from '@ember/utils';
import type RouterService from '@ember/routing/router-service';
import type Store from '@ember-data/store';
import type IntercomConfirmService from 'embercom/services/intercom-confirm-service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  template: {
    id: string;
    isBlockTemplate: boolean;
    getBlocksHtml: () => any;
    template: any;
    blocks: any;
    name: string;
    save: () => any;
    getAssociatedMessages: (app: any) => any;
    rollbackAttributes: () => any;
    destroyRecord: () => any;
    duplicate: () => any;
  };
}

export default class Editors extends Component<Args> {
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare paywallService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;
  @tracked composerApi: any;
  @tracked showBlockSettings = false;
  @tracked isMobilePreview = false;
  @service declare store: Store;
  @service declare modalService: $TSFixMe;
  @service declare intercomConfirmService: IntercomConfirmService;
  @service declare intl: IntlService;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    schedule('afterRender', this, function () {
      // @ts-ignore
      this.floatingButtons = $('.js__template-editor__floating-buttons')[0];
      // @ts-ignore
      this.container = $('.js__settings-container')[0];
    });
  }

  container: any;
  floatingButtons: any;

  willDestroy() {
    super.willDestroy();
    this._teardownEventListeners();
  }

  get app() {
    return this.appService.app;
  }

  get selectedEditor() {
    if (this.hasTemplateBlocks) {
      return 'visual-editor';
    }
    return 'html-editor';
  }

  get templateAsHtml() {
    if (this.args.template.isBlockTemplate) {
      return this.args.template.getBlocksHtml();
    }

    return this.args.template.template;
  }

  get hasTemplateHTML() {
    return isPresent(this.args.template.template);
  }

  get hasTemplateBlocks() {
    return isPresent(this.args.template.blocks);
  }

  get hasUnsubscribeLinkTag() {
    return isPresent(this.templateAsHtml.match(/\{\{\s*?unsubscribe_link\s*?\}\}/));
  }

  get hasUnsubscribeURLTag() {
    return (
      isPresent(this.templateAsHtml.match(/\{\{\s*?unsubscribe_url\s*?\}\}/)) ||
      isPresent(this.templateAsHtml.match(/\%7B\%7B\%20\s*?unsubscribe_url\s*?\%20\%7D\%7D/))
    );
  }

  get hasContentTag() {
    return isPresent(this.templateAsHtml.match(/\{\{\s*?content\s*?\}\}/));
  }

  get hasUnsubscribeLinkOrURL() {
    return this.hasUnsubscribeURLTag || this.hasUnsubscribeLinkTag;
  }

  get canSaveTemplate() {
    return (
      this.hasTemplateName &&
      (this.hasTemplateHTML || this.hasTemplateBlocks) &&
      this.hasContentTag &&
      this.hasUnsubscribeLinkOrURL
    );
  }

  get hasTemplateName() {
    return isPresent(this.args.template.name);
  }

  get hasTemplateContent() {
    return isPresent(this.args.template.template) || isPresent(this.args.template.blocks);
  }

  _teardownEventListeners() {
    $('.js__template-editor__textarea').off('paste.template-editor');
    $('.js__template-editor__textarea').off('keyup.template-editor');
    this.floatingButtons = null;
    this.container = null;
  }

  _showValidationWarning() {
    if (!this.hasTemplateHTML && !this.hasTemplateBlocks) {
      this.notificationsService.notifyWarning(
        this.intl.t('outbound.settings.email-templates.validation-warnings.template-has-no-data'),
      );
    } else if (!this.hasContentTag) {
      this.notificationsService.notifyWarning(
        sanitizeHtml(
          'Your template is missing the <span style="font-family:monospace">{{ content }}</span> tag',
        ),
      );
    } else if (!this.hasUnsubscribeLinkOrURL && !this.args.template.isBlockTemplate) {
      this.notificationsService.notifyWarning(
        sanitizeHtml(
          'Your template is missing an <span style="font-family:monospace">{{ unsubscribe_link }}</span> or <span style="font-family:monospace">{{ unsubscribe_url }}</span> tag',
        ),
      );
    } else if (!this.hasUnsubscribeLinkOrURL) {
      this.notificationsService.notifyWarning(
        this.intl.t(
          'outbound.settings.email-templates.validation-warnings.missing-unsubscribe-option',
        ),
      );
    } else if (!this.hasTemplateName) {
      this.notificationsService.notifyWarning(
        this.intl.t('outbound.settings.email-templates.validation-warnings.template-name-missing'),
      );
    }
  }

  _generateConfirmationOptionsWithMessages(messages: any) {
    let confirmOptions = {
      title: this.intl.t('outbound.settings.email-templates.delete-template-modal.title'),
      body: this.intl.t('outbound.settings.email-templates.delete-template-modal.body'),
      confirmButtonText: this.intl.t(
        'outbound.settings.email-templates.delete-template-modal.confirm-button',
      ),
      primaryButtonType: 'primary-destructive',
    };

    if (messages.email_rulesets.length) {
      confirmOptions.body += this.intl.t(
        'outbound.settings.email-templates.delete-template-modal.body-email-rulesets',
      );

      messages.email_rulesets.forEach((ruleset: any) => {
        confirmOptions.body += `<br><a href='/a/apps/${this.app.id}/outbound/email/${ruleset.id}' target='_blank'>${ruleset.html_safe_title}</a>`;
      });
    }

    confirmOptions.body = sanitizeHtml(confirmOptions.body);
    return confirmOptions;
  }

  @action
  openTemplateHelp() {
    this.modalService.openModal('modal/email-template-help-modal');
  }

  @action
  async saveTemplate() {
    if (this.canSaveTemplate) {
      this.trackAnalyticsEvent('clicked', 'save');

      try {
        await this.args.template.save();

        let transition = await this.transitionToIndex();
        if (transition) {
          this.notificationsService.notifyConfirmation(
            this.intl.t('outbound.settings.email-templates.template-saved', {
              templateName: this.args.template.name,
            }),
          );
        }
      } catch (e) {
        this.notificationsService.notifyResponseError(e, {
          default: this.intl.t('outbound.settings.email-templates.save-error'),
        });
      }
    } else {
      this._showValidationWarning();
    }
  }

  @action
  async deleteTemplate() {
    let template = this.args.template;
    let messages = await template.getAssociatedMessages(this.app);
    if (messages) {
      let confirmOptions = this._generateConfirmationOptionsWithMessages(messages);
      let confirm = await this.intercomConfirmService.confirm(confirmOptions);
      if (confirm) {
        this.trackAnalyticsEvent('clicked', 'delete');
        template.rollbackAttributes();
        let templateTitle = this.store.peekRecord('email-template-titles', template.id);
        let response = await template.destroyRecord();
        if (response) {
          this.notificationsService.notifyConfirmation(
            this.intl.t('outbound.settings.email-templates.delete-confirmation', {
              templateName: template.name,
            }),
          );
          if (templateTitle) {
            templateTitle.deleteRecord();
          }
          this.transitionToIndex();
        }
      }
    }
  }

  @action
  async duplicateTemplate() {
    let template = this.args.template;
    try {
      this.trackAnalyticsEvent('clicked', 'duplicate');
      let newTemplate = await template.duplicate();
      this.transitionToEditTemplate(newTemplate.id);
      this.notificationsService.notifyConfirmation(
        this.intl.t('outbound.settings.email-templates.duplicate-confirmation', {
          templateName: template.name,
        }),
      );
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('outbound.settings.email-templates.duplicate-error'),
      });
    }
  }

  @action
  cancelChanges() {
    this.transitionToIndex();
  }

  trackAnalyticsEvent(action: string, object: string) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: 'email_templates',
      section: 'settings',
      template_type: this.args.template.isBlockTemplate ? 'visual' : 'html',
      template_id: this.args.template.id,
    });
  }

  @action
  toggleMobilePreview() {
    this.isMobilePreview = !this.isMobilePreview;
    this.showBlockSettings = false;
  }

  async transitionToIndex() {
    return await this.router.transitionTo('apps.app.settings.channels.email', {
      queryParams: { tab: 'customisation' },
    });
  }

  transitionToEditTemplate(templateId: number) {
    return this.router.transitionTo('apps.app.settings.channels.email.edit-template', templateId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::Settings::EmailTemplates::Editors': typeof Editors;
  }
}
