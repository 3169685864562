/* import __COLOCATED_TEMPLATE__ from './using-ips.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';

interface Args {
  dedicatedIp: $TSFixMe;
}

export default class UsingIps extends Component<Args> {
  @service declare store: Store;
  @service notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked showSideDrawer = false;
  @tracked model;
  @tracked value;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.model = this.args.dedicatedIp;
    this.value = this.args.dedicatedIp ? 'dedicated' : 'shared';
  }

  get isSaveDisabled() {
    if (this.model) {
      return this.value === 'dedicated';
    } else {
      return this.value === 'shared';
    }
  }

  @action
  async save() {
    try {
      this.model = await this.store.createRecord('settings/dedicated-ip').save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.email.dedicated-ips.saved'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('settings.email.dedicated-ips.error'),
      });
    }
  }

  @action
  toggleSideDrawerVisibility() {
    this.showSideDrawer = !this.showSideDrawer;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced::UsingIps': typeof UsingIps;
  }
}
