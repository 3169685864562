/* import __COLOCATED_TEMPLATE__ from './workload-management.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  model: any;
  openInboxOrderDrawer: () => void;
  updateAttributeSorting: (sorting: string[], isChanged: boolean) => void;
  saveAttributeSorting?: (sorting: string[]) => void;
  updateAssignmentLimit: (limit: number) => void;
  changeTab: (tab: string) => void;
  saveAssignmentLimit: (limit: number) => void;
  ticketsAssignmentDisabled: boolean;
  ticketsAssignmentDisabledUpdate: () => void;
  pullConversationEnabledUpdate: () => void;
  volumeControlSettings: any;
  updateVolumeControl: (settings: any) => void;
}

const WorkloadManagement = templateOnlyComponent<Args>();
export default WorkloadManagement;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tabs::WorkloadManagement': typeof WorkloadManagement;
    'new-settings/helpdesk/tabs/workload-management': typeof WorkloadManagement;
  }
}
