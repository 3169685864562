/* import __COLOCATED_TEMPLATE__ from './banner.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */

import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Args: any;
}

const Banner = templateOnlyComponent<Signature>();
export default Banner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::Banner': typeof Banner;
  }
}
