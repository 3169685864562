/* import __COLOCATED_TEMPLATE__ from './teammate-assignment-limits.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import templateOnlyComponent from '@ember/component/template-only';

interface Args {
  model: any;
  bulkUpdateLimit: number;
}

const TeammateAssignmentLimits = templateOnlyComponent<Args>();
export default TeammateAssignmentLimits;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tabs::TeammateAssignmentLimits': typeof TeammateAssignmentLimits;
    'new-settings/helpdesk/tabs/teammate-assignment-limits': typeof TeammateAssignmentLimits;
  }
}
