/* import __COLOCATED_TEMPLATE__ from './let-fin-introduce-itself.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type FinWorkflowPreview from 'embercom/lib/workflows/fin-workflow-preview';
import type BotPart from 'embercom/models/operator/bot-intro/part';

interface Signature {
  Args: {
    accordion: $TSFixMe;
    previewConfiguration: $TSFixMe;
    finWorkflowPreview: FinWorkflowPreview;
  };
}

export default class LetFinIntroduceItself extends Component<Signature> {
  @action
  addPart() {
    this.args.finWorkflowPreview.addIntroPart();
  }

  @action
  removePart(part: BotPart) {
    this.args.finWorkflowPreview.removeIntroPart(part);
  }

  @action
  reorderParts(parts: BotPart[]) {
    this.args.finWorkflowPreview.reorderIntroParts(parts);
  }

  @action
  resetToDefault() {
    this.args.finWorkflowPreview.resetToDefaultIntroParts();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::WorkflowSections::LetFinIntroduceItself': typeof LetFinIntroduceItself;
    'operator/fin/setup/workflow-sections/let-fin-introduce-itself': typeof LetFinIntroduceItself;
  }
}
