/* import __COLOCATED_TEMPLATE__ from './filters.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import type OperatorCustomBotsOverviewComponent from './overview';
import { type AdditionalSearchableData } from 'embercom/lib/operator/custom-bots/constants';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  title: string;
  resetSearch: () => void;
  performSearchTransition: OperatorCustomBotsOverviewComponent['performSearchTransition'];
  hasAppliedFilters: boolean;
  resetFilters: () => void;
  filterContent: (filterProperty: string, value: any) => void;

  // Filter specific args
  selectedAudienceValues: string[];
  selectedMatchBehaviorValues: string[];
  selectedStateValue: string;
  selectedTagValues: string[];
  selectedTagOperator: string;
  messagesTags: any[];
  additionalSearchableData: AdditionalSearchableData | null;
  targetChannels: AdditionalSearchableData['targetChannels'];
  parallel: AdditionalSearchableData['parallel'] | null;
  hasFin: AdditionalSearchableData['hasFin'] | null;
}

export default class Filters extends Component<Args> {
  @service declare intl: IntlService;

  @tracked activeFilter: string | null = null;

  get additionalFilters() {
    let filters = [
      {
        id: 'tag',
        text: this.intl.t('outbound.all.tag-filter.tag'),
        icon: 'tag',
        onSelectItem: () => this._setActiveFilter('tag'),
      },
    ].filter((item) => item.id !== this.activeFilter);

    return [
      {
        items: filters,
      },
    ];
  }

  get hasAdditionalFilters() {
    return this.additionalFilters[0].items.length;
  }

  @action
  removeFilter() {
    this.activeFilter = null;
  }

  @action
  _resetFilters() {
    this.activeFilter = null;
    this.args.resetFilters();
  }

  @action
  _setActiveFilter(id: string) {
    this.activeFilter = id;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::CustomBots::Filters': typeof Filters;
  }
}
