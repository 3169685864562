/* import __COLOCATED_TEMPLATE__ from './if-fin-cannot-resolve-the-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { action, computed } from '@ember/object';
import { inject as service } from '@ember/service';
// @ts-expect-error: copy is not typed
import { copy } from 'ember-copy';
import AssignConversation from 'embercom/models/operator/visual-builder/step/assign-conversation';
import CloseConversation from 'embercom/models/operator/visual-builder/step/close-conversation';
import ChatMessage from 'embercom/models/operator/visual-builder/step/chat-message';
import type Step from 'embercom/models/operator/visual-builder/step';
import type IntlService from 'embercom/services/intl';
import { type FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type Profile from 'embercom/models/ai-agent/profile';
import type Store from '@ember-data/store';
import type Group from 'embercom/models/operator/visual-builder/group';
import { type ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';
import { BOT_ONLY_MODE } from 'embercom/lib/operator/resolution-bot/constants';
import type BotOnlyMessageLocalization from 'embercom/models/operator/bot-only-message/localization';
import type BotOnlyMessagePart from 'embercom/models/operator/bot-only-message/part';

interface Args {
  accordion: $TSFixMe;
  ruleset: $TSFixMe;
  partialSave: (params: FinSetupBehaviorSaveParams) => void;
  channelType: ChannelType;
}

type HandoverType = 'route_to_inbox' | 'close_conversation' | null;

const ASSIGN_CONVERSATION_STEP_TYPE = 'operator/visual-builder/step/assign-conversation';
const CLOSE_CONVERSATION_STEP_TYPE = 'operator/visual-builder/step/close-conversation';
const CHAT_MESSAGE_STEP_TYPE = 'operator/visual-builder/step/chat-message';

export default class IfFinCannotResolveTheConversation extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare store: Store;
  @service declare intl: IntlService;

  @tracked handoverType: HandoverType = null;
  @tracked selectedGenericTriage = this.defaultGenericTriage;
  @tracked selectedLocale = this.defaultLocale;

  @tracked assigneeId = '0';

  @tracked closeConversationLocalizations: Record<string, Group> = {};
  @tracked closerMessages: Record<string, BotOnlyMessagePart[]> = {};
  @tracked selectedCloserParts: BotOnlyMessagePart[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    this.setInitialHandoverType();
  }

  get useBotModeForCloseHandover() {
    return this.appService.app.canUseFeature('ai-agent-use-bot-mode-in-setup-profiles');
  }

  get ruleset() {
    return this.args.ruleset;
  }

  get profile(): Profile {
    return this.ruleset.rulesetLinks.firstObject.object;
  }

  get assignee() {
    let assigneeId = this.assigneeId;

    return this.appService.app.assignableAdmins.findBy('id', assigneeId);
  }

  get genericTriageLocalizations() {
    return this.profile.genericTriageLocalizations;
  }

  get defaultGenericTriage() {
    let defaultLocalization = this.genericTriageLocalizations.find((l: $TSFixMe) => l.default);

    if (defaultLocalization) {
      return defaultLocalization;
    } else {
      let englishLocalization = this.genericTriageLocalizations.find(
        (l: $TSFixMe) => l.locale === 'en',
      );
      return englishLocalization;
    }
  }

  @computed('closeConversationLocalizations', 'selectedLocale')
  get selectedCloseConversationLocalization() {
    return this.closeConversationLocalizations[this.selectedLocale];
  }

  @computed('selectedCloseConversationLocalization.steps.@each.type')
  get chatMessagesForSelectedLocale() {
    return this.selectedCloseConversationLocalization.steps.filter(
      (step: Step) => step.type === CHAT_MESSAGE_STEP_TYPE,
    );
  }

  get invalidChatMessageLocales() {
    if (this.handoverType !== 'close_conversation') {
      return [];
    }

    let enabledLocalizations = this.genericTriageLocalizations.filter(
      (localization: $TSFixMe) => localization.enabled,
    );
    let invalidLocalizations: string[] = [];

    enabledLocalizations.forEach((localization: $TSFixMe) => {
      let steps = this.closeConversationLocalizations[localization.locale].steps;
      if (!steps.every((step: Step) => step.validations.isValid)) {
        invalidLocalizations.push(localization.name);
      }
    });
    return invalidLocalizations;
  }

  get closerPartsAreAllValid() {
    if (this.handoverType !== 'close_conversation') {
      return true;
    }
    // This is absolutely mental but doing this forces Ember to recompute
    // this getter when we add or remove a part. Otherwise it refuses to recompute
    this.selectedCloserParts.map((p) => p);

    return Object.values(this.closerMessages).every((partArray) =>
      partArray.every((part) => part.validations.isValid),
    );
  }

  get invalidCloserMessagesError() {
    if (this.closerPartsAreAllValid) {
      return '';
    }

    let invalidLocaleNames = this.profile.botOnlyMessageLocalizations
      .filter((localization) =>
        this.closerMessages[localization.locale].any((part) => part.validations.isValid === false),
      )
      .map((l) => l.name);

    return this.intl.t('operator.fin.setup.setup-and-go-live.empty-message-part-error', {
      languages: invalidLocaleNames.join(', '),
    });
  }

  get hasInvalidChatMessage() {
    return this.invalidChatMessageLocales.length > 0;
  }

  get invalidChatMessageError() {
    return this.intl.t('operator.fin.setup.setup-and-go-live.empty-message-part-error', {
      languages: this.invalidChatMessageLocales.join(', '),
    });
  }

  get localeOptions() {
    if (this.useBotModeForCloseHandover) {
      return this.profile.botOnlyMessageLocalizations.map((localization) => ({
        locale_name: localization.name,
        locale_id: `(${localization.locale.toUpperCase()})`,
        value: localization.locale,
        state: 'enabled',
        component: 'operator/generic-triage/locale-option',
      }));
    } else {
      return this.genericTriageLocalizations.map((localization: $TSFixMe) => ({
        locale_name: localization.name,
        locale_id: `(${localization.locale.toUpperCase()})`,
        value: localization.locale,
        state: localization.enabled,
        default: localization.default,
        component: 'operator/generic-triage/locale-option',
      }));
    }
  }

  get defaultLocale() {
    if (this.useBotModeForCloseHandover) {
      return Object.keys(this.closerMessages).firstObject;
    } else {
      return this.genericTriageLocalizations.find((l: $TSFixMe) => l.default)?.locale ?? 'en';
    }
  }

  get selectedBotOnlyMessageLocalization() {
    return this.profile.botOnlyMessageLocalizations.findBy('locale', this.selectedLocale);
  }

  /**
   * We used to store closer messages and close step in a generic triage on the profile
   * for the close_conversation handover type
   * This method checks wether the current generic triage is still doing that
   * (we also use generic triage to assign to a teammate for the route_to_inbox handover)
   */
  get isGenericTriageSetToCloseConversation() {
    return this.genericTriageLocalizations.any(
      (localization: $TSFixMe) =>
        this.getStep(localization, CLOSE_CONVERSATION_STEP_TYPE) !== undefined,
    );
  }

  @action
  setHandoverType(type: HandoverType) {
    this.handoverType = type;
    if (this.handoverType === 'close_conversation') {
      this.profile.botMode = BOT_ONLY_MODE;
      this.profile.preHandoverAnswerEnabled = false;
    } else {
      this.profile.botMode = 'looping';
    }
  }

  @action
  updateLocale(locale: string) {
    this.selectedLocale = locale;
    this.selectedGenericTriage = this.genericTriageLocalizations.findBy('locale', locale);
    this.selectedCloserParts = this.closerMessages[locale];
  }

  @action
  removeStep(step: ChatMessage) {
    if (this.chatMessagesForSelectedLocale.length === 1) {
      return;
    }
    this.selectedCloseConversationLocalization.steps.removeObject(step);
  }

  @action
  addStep() {
    let index = this.chatMessagesForSelectedLocale.length;
    let step = ChatMessage.createNewStep(this.store);
    this.selectedCloseConversationLocalization.steps.insertAt(index, step);
  }

  @action
  removePart(part: BotOnlyMessagePart) {
    if (this.selectedCloserParts.length > 1) {
      this.selectedCloserParts.removeObject(part);
    }
  }

  @action
  addPart() {
    this.selectedCloserParts.pushObject(
      this.store.createFragment('operator/bot-only-message/part', {
        blocks: [
          this.store.createFragment('common/blocks/paragraph', {
            type: 'paragraph',
            text: '',
          }),
        ],
      }),
    );
  }

  @action
  async updateHandover() {
    if (this.useBotModeForCloseHandover) {
      let generic_triage_localizations;
      let bot_only_message_localizations;

      if (this.handoverType === 'close_conversation') {
        this.profile.botOnlyMessageLocalizations.forEach((localization) => {
          localization.set('parts', this.closerMessages[localization.locale]);
        });
        bot_only_message_localizations = this.profile.botOnlyMessageLocalizations.map((l) =>
          l.serialize(),
        );
      } else {
        this.maybeSaveAssignmentHandoverGroup();
        generic_triage_localizations = this.genericTriageLocalizations.map((l) => l.serialize());
      }

      let params = {
        generic_triage_localizations, // used for the "Assign" handover type, or undefined if handover is set to close
        handover_type: this.handoverType,
        bot_only_message_localizations,
        pre_handover_settings: {
          enabled: this.profile.preHandoverAnswerEnabled,
        },
      };

      this.args.partialSave(params);
    } else {
      this.buildHandoverGroup();

      let generic_triage_localizations = this.genericTriageLocalizations.map((l: any) =>
        l.serialize(),
      );

      let params = {
        generic_triage_localizations,
        pre_handover_settings: {
          enabled: this.profile.preHandoverAnswerEnabled,
        },
      };

      this.args.partialSave(params);
    }
  }

  @action
  setAssigneeId(assigneeId: string) {
    this.assigneeId = assigneeId;
  }

  private getStep(
    genericTriageLocalization: $TSFixMe,
    stepType: string,
  ): AssignConversation | CloseConversation | undefined {
    return genericTriageLocalization.visualBuilderObject.groups.firstObject.steps.find(
      (step: AssignConversation | CloseConversation) => step.type === stepType,
    );
  }

  private setInitialHandoverType() {
    if (this.useBotModeForCloseHandover) {
      if (this.profile.botMode === BOT_ONLY_MODE || this.isGenericTriageSetToCloseConversation) {
        this.handoverType = 'close_conversation';
      } else {
        this.handoverType = 'route_to_inbox';
      }
      this.setInitialAssignee();
      this.setInitialBotOnlyMessageLocalizations();
    } else {
      let stepType =
        this.defaultGenericTriage.visualBuilderObject.groups.firstObject.steps.lastObject?.type;

      if (stepType === CLOSE_CONVERSATION_STEP_TYPE) {
        this.handoverType = 'close_conversation';
      } else {
        this.handoverType = 'route_to_inbox';
      }

      this.setInitialAssignee();
      this.setInitialCloseConversationLocalizations();
    }
  }

  private setInitialAssignee() {
    let assignConversationStep: $TSFixMe =
      this.getStep(this.defaultGenericTriage, ASSIGN_CONVERSATION_STEP_TYPE) ||
      AssignConversation.createNewStep(this.store);

    this.assigneeId = assignConversationStep.action?.actionData?.assignee_id;
  }

  private maybeSaveAssignmentHandoverGroup() {
    if (this.handoverType !== 'route_to_inbox') {
      return;
    }

    this.genericTriageLocalizations.forEach((localization: $TSFixMe) => {
      let assignConversationStep: $TSFixMe = AssignConversation.createNewStep(this.store);
      assignConversationStep.action.actionData.assignee_id = this.assigneeId;
      localization.visualBuilderObject.groups.firstObject.steps = [assignConversationStep];
      localization.enabled = true;
    });
  }

  private setInitialCloseConversationLocalizations() {
    this.genericTriageLocalizations.map((localization: $TSFixMe) => {
      let locale = localization.locale;

      let chatMessageSteps = localization.visualBuilderObject.groups.firstObject.steps.filter(
        (step: Step) => step.type === CHAT_MESSAGE_STEP_TYPE,
      );
      let closeConversationStep =
        this.getStep(localization, CLOSE_CONVERSATION_STEP_TYPE) ||
        CloseConversation.createNewStep(this.store);

      let group = this.store.createRecord('operator/visual-builder/group', {
        isStart: true,
      }) as Group;

      if (chatMessageSteps.length) {
        group.steps.addObjects(chatMessageSteps);
      } else {
        group.steps.addObject(ChatMessage.createNewStep(this.store));
      }
      group.steps.addObject(closeConversationStep);

      this.closeConversationLocalizations[locale] = group;
    });
  }

  /**
   * We used to store the closer messages and close step in a generic triage on the profile
   * for the close_conversation handover type
   * we don't do that anymore, we use the BotOnlyMessageLocalizations now
   * But customers still have setup profiles with their closer messages in the generic triage
   * So we need to extract their localized messages and put them in the BotOnlyMessageLocalizations
   */
  private maybeExtractHandoverMessagesFromGenericTriage() {
    this.genericTriageLocalizations.toArray().forEach((localization: $TSFixMe) => {
      if (!this.shouldExtractHandoverMessageFromGenericTriage(localization)) {
        return;
      }

      let locale = localization.locale;
      let chatMessageSteps = localization.visualBuilderObject.groups.firstObject.steps.filter(
        (step: Step) => step.type === CHAT_MESSAGE_STEP_TYPE,
      );

      this.closerMessages[locale] = chatMessageSteps.map((chatMessage: ChatMessage) =>
        this.store.createFragment('operator/bot-only-message/part', {
          blocks: copy(chatMessage.blocks, true),
        }),
      );
    });
  }

  private shouldExtractHandoverMessageFromGenericTriage(localization: $TSFixMe) {
    // generic triages we used to store the closer messages has only one possible shape.
    // so to convert them to bot only messages, the generic triage must :
    if (localization.visualBuilderObject.groups.length !== 1) {
      // have only one group
      return false;
    }
    let group = localization.visualBuilderObject.groups.firstObject;
    if (group.steps.lastObject?.type !== CLOSE_CONVERSATION_STEP_TYPE) {
      // finish with a close step
      return false;
    }
    let stepTypes = new Set(group.steps.map((step: Step) => step.type));
    if (!stepTypes.has(CHAT_MESSAGE_STEP_TYPE) || stepTypes.size !== 2) {
      // and have only chat messages and close steps
      return false;
    }

    return true;
  }

  private setInitialBotOnlyMessageLocalizations() {
    this.maybeExtractHandoverMessagesFromGenericTriage();

    this.profile.botOnlyMessageLocalizations.forEach((localization: BotOnlyMessageLocalization) => {
      let parts = localization.parts;
      let locale = localization.locale;

      // first we try getting the closer parts from the profile
      if (parts.length) {
        this.closerMessages[locale] = localization.parts.toArray();
        return;
      }

      // if not, maybe they were set in maybeExtractHandoverMessagesFromGenericTriage from the generic triage
      // in that case we have nothing to do
      if (this.closerMessages[locale]) {
        return;
      }

      // if we have neither of those, then we create en empty default part
      let defaultPart = this.store.createFragment('operator/bot-only-message/part', {
        blocks: [
          this.store.createFragment('common/blocks/paragraph', {
            type: 'paragraph',
            text: '',
          }),
        ],
      });

      this.closerMessages[locale] = [defaultPart];
    });

    this.selectedCloserParts = this.closerMessages[this.selectedLocale];
  }

  private maybeSaveCloseConversationHandoverGroup() {
    if (this.useBotModeForCloseHandover) {
      return;
    }
    if (this.handoverType !== 'close_conversation') {
      return;
    }

    this.genericTriageLocalizations.forEach((localization: $TSFixMe) => {
      localization.visualBuilderObject.groups = [
        this.closeConversationLocalizations[localization.locale],
      ];
    });
  }

  private buildHandoverGroup() {
    this.maybeSaveAssignmentHandoverGroup();
    this.maybeSaveCloseConversationHandoverGroup();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::IfFinCannotResolveTheConversation': typeof IfFinCannotResolveTheConversation;
  }
}
