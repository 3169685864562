/* import __COLOCATED_TEMPLATE__ from './paginated-activity-logs.hbs'; */
/* RESPONSIBLE TEAM: team-app-security */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import moment, { type Moment } from 'moment-timezone';
import { inject as service } from '@ember/service';
import ajax from 'embercom/lib/ajax';
import { ADMIN_EVENT_TYPES } from 'embercom/lib/admins/app-admin-events-constants';
import type Store from '@ember-data/store';
import type AppAdminEvent from 'embercom/models/app-admin-event';
import Admin from 'embercom/models/admin';
import { htmlToTextContent } from 'embercom/lib/html-unescape';
import { DATE_WITHOUT_TIME, CSV_EMPTY_LINE } from 'embercom/lib/reporting/export-helper';
import ExportHelper from 'embercom/lib/reporting/export-helper';
import AppAdminEventsHelper from 'embercom/lib/admins/app-admin-events-helper';
import { getOwner } from '@ember/application';
import Range from 'embercom/models/reporting/range';

interface FetchParams {
  from: number;
  to: number;
  page: number;
  per_page: number;
  admin_id?: number;
  event_type?: string;
}

interface LogsResponse {
  events: AppAdminEvent[];
  page: number;
  per_page: number;
  total_pages: number;
  next: string | null;
  total_count: number;
}

export default class ActivityLogs extends Component {
  @service appService: any;
  @service intl: any;
  @service store!: Store;
  @service csv: any;
  @tracked events: AppAdminEvent[] = [];
  @tracked csvEvents: AppAdminEvent[] = [];
  @tracked isLoading = false;
  @tracked currentPage = 1;
  @tracked totalCount = 0;
  @tracked perPage = 100;
  @tracked totalPages = 1;
  @tracked fromDate: Date;
  @tracked toDate: Date;
  @tracked nextPageUrl: string | null = null;
  @tracked filterByAdmin: any = null;
  @tracked filterByEventType = 'AppAdminEvents::All';

  private eventsHelper: AppAdminEventsHelper;
  private exportHelper: ExportHelper;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.fromDate = moment().subtract(7, 'days').toDate();
    this.toDate = new Date();
    this.eventsHelper = new AppAdminEventsHelper(getOwner(this));
    this.exportHelper = new ExportHelper(getOwner(this));
    this.loadEvents();
  }

  private async fetchEvents(url: string): Promise<void> {
    this.isLoading = true;
    try {
      let response: LogsResponse = await ajax({ url, type: 'GET' });
      this.store.pushPayload({ 'app-admin-events': response.events });
      this.events = this.populateAdminData(response);
      this.currentPage = response.page;
      this.perPage = response.per_page;
      this.totalPages = response.total_pages;
      this.totalCount = response.total_count;
      this.nextPageUrl = response.next;
    } catch (error) {
      console.error('Error loading app admin events:', error);
    } finally {
      this.isLoading = false;
    }
  }

  private async fetchCsvEvents(url: string): Promise<void> {
    try {
      let response = await ajax({ url, type: 'GET' });
      this.store.pushPayload({ 'app-admin-events': response.events });
      this.csvEvents = this.populateAdminData(response);
    } catch (error) {
      console.error('Error fetching CSV:', error);
    }
  }

  private async loadEvents(): Promise<void> {
    let params = this.buildParams();
    let url = this.buildUrlWithParams(params);
    await this.fetchEvents(url);
  }

  private populateAdminData(response: LogsResponse): AppAdminEvent[] {
    return response.events.map((eventData) => {
      let event = this.store.peekRecord('app-admin-event', eventData.id);
      // admin_id can be null or 0
      if (!event.admin && eventData.admin_id) {
        event.admin = Admin.peekAndMaybeLoad(this.store, eventData.admin_id.toString());
      }
      return event;
    });
  }

  private buildParams(): FetchParams {
    let params: FetchParams = {
      from: moment(this.fromDate).tz(this.app.timezone).unix(),
      to: moment(this.toDate).tz(this.app.timezone).unix(),
      page: this.currentPage,
      per_page: this.perPage,
    };

    if (this.filterByAdmin) {
      params.admin_id = this.filterByAdmin.id;
    }

    if (this.filterByEventType && this.filterByEventType !== 'AppAdminEvents::All') {
      params.event_type = this.filterByEventType;
    }

    return params;
  }

  private buildUrlWithParams(params: FetchParams): string {
    let query = new URLSearchParams(params as any).toString();
    return `/ember/app/${this.app.id}/admin-events.json?${query}`;
  }

  private buildCsvUrlWithParams(params: FetchParams): string {
    let query = new URLSearchParams(params as any).toString();
    return `/ember/app/${this.app.id}/admin-events/csv?${query}`;
  }

  private csvData() {
    return this.csvEvents.map((event: AppAdminEvent) => {
      let description = htmlToTextContent(this.eventsHelper.descriptionHTML(event));
      let date = this.intl.formatDate(event.created_at, {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        timeZone: this.app.timezone,
        timeZoneName: 'short',
      });
      return [description, date, event.admin_ip];
    });
  }

  private csvFilters() {
    return [
      [this.intl.t('settings.content-components.logs.filters')],
      [
        this.intl.t('settings.content-components.logs.teammate'),
        this.intl.t('settings.content-components.logs.activity'),
      ],
      [
        this.filterByAdmin?.name || this.intl.t('settings.content-components.logs.all'),
        this.intl.t(ADMIN_EVENT_TYPES.findBy('value', this.filterByEventType)?.text || ''),
      ],
    ];
  }

  private headerRow() {
    return [
      [this.intl.t('settings.content-components.logs.activity')],
      [this.intl.t('settings.content-components.logs.date')],
      [this.intl.t('settings.content-components.logs.ip-address')],
    ];
  }

  private filenameFormattedDate(date: Date) {
    return this.exportHelper.dateFormatter(moment(date), DATE_WITHOUT_TIME);
  }

  private formatDate(date: string | Moment) {
    // eslint-disable-next-line @intercom/intercom/no-bare-strings
    return moment(date).tz(this.app.timezone).format('MMM DD, YYYY, h:mm A z');
  }

  private buildEventType(eventType: any) {
    return {
      text: this.intl.t(eventType.text),
      value: eventType.value,
      isSelected: this.filterByEventType === eventType.value,
    };
  }

  get adminEventTypes() {
    let options = ADMIN_EVENT_TYPES.map((type) => this.buildEventType(type));
    return [
      {
        hasItemsMarkableAsSelected: true,
        items: options.sort((a, b) => a.text.localeCompare(b.text)),
      },
    ];
  }

  get tableData() {
    return this.events.map((event) => ({
      date: this.formatDate(event.created_at),
      ipAddress: event.admin_ip,
      event,
    }));
  }

  get app() {
    return this.appService.app;
  }

  @action
  async nextPage(): Promise<void> {
    if (this.nextPageUrl) {
      await this.fetchEvents(this.nextPageUrl);
    }
  }

  @action
  async previousPage(): Promise<void> {
    if (this.currentPage > 1) {
      this.currentPage--;
      await this.loadEvents();
    }
  }

  @action
  setFromDate(date: Date) {
    this.fromDate = moment(date).tz(this.app.timezone).toDate();
    this.applyFilters();
  }

  @action
  setToDate(date: Date) {
    this.toDate = moment(date).tz(this.app.timezone).toDate();
    this.applyFilters();
  }

  @action
  async applyFilters(): Promise<void> {
    this.currentPage = 1;
    await this.loadEvents();
  }

  @action
  setFilterByAdmin(adminId: number) {
    let admin = this.app.admins.find((admin: any) => admin.id === adminId);
    this.filterByAdmin = admin;
    this.applyFilters();
  }

  @action
  setFilterByEventType(eventType: string) {
    this.filterByEventType = eventType;
    this.applyFilters();
  }

  @action
  async exportCsv() {
    let params = this.buildParams();
    let url = this.buildCsvUrlWithParams(params);
    await this.fetchCsvEvents(url);

    let filename = `teammate_activity_logs_${this.filenameFormattedDate(this.fromDate)}_${this.filenameFormattedDate(this.toDate)}.csv`;
    let title = [[this.intl.t('apps.app.settings.logs.title-token')]];
    let range = Range.createFromParams(this.fromDate, this.toDate, this.app.timezone);
    let data = title
      .concat(CSV_EMPTY_LINE)
      .concat(this.exportHelper.csvHeaders(range))
      .concat(CSV_EMPTY_LINE)
      .concat(this.csvFilters())
      .concat(CSV_EMPTY_LINE)
      .concat(this.headerRow())
      .concat(this.csvData());
    return await this.csv.export(data, {
      fileName: filename,
      withSeparator: false,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Teammates::PaginatedActivityLogs': typeof ActivityLogs;
    'new-settings/workspace/teammates/paginated-activity-logs': typeof ActivityLogs;
  }
}
