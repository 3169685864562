/* import __COLOCATED_TEMPLATE__ from './template-preview.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';
import MESSAGE_CONSTANTS from 'embercom/models/data/messages/message-constants';

const REPLY_TYPES = {
  attribute_collector: MESSAGE_CONSTANTS.attributeCollectorReply,
};

export default class TemplatePreview extends Component {
  @service appService;
  @service store;
  @tracked selectedTemplateContentObject;

  constructor() {
    super(...arguments);

    if (!this.isSeries) {
      this.selectedTemplateContentObject = this.setSelectedTemplateContentObject();
    }
  }

  get app() {
    return this.appService.app;
  }

  get selectedObjectType() {
    return this.args.selectedTemplate.objectType;
  }

  get isSeries() {
    return this.selectedObjectType === objectTypes.series;
  }

  get isChat() {
    return this.selectedObjectType === objectTypes.chat;
  }

  get isPost() {
    return this.selectedObjectType === objectTypes.post;
  }

  get isCarousel() {
    return this.selectedObjectType === objectTypes.carousel;
  }

  get isEmail() {
    return this.selectedObjectType === objectTypes.email;
  }

  get isLegacyEmail() {
    return this.selectedObjectType === objectTypes.legacyMessageEmail;
  }

  get isPush() {
    return this.selectedObjectType === objectTypes.push;
  }

  get isInApp() {
    return this.selectedObjectType === objectTypes.legacyMessageInApp;
  }

  get isOutboundCustomBot() {
    return this.selectedObjectType === objectTypes.customBot;
  }

  get isInboundCustomBot() {
    return this.selectedObjectType === objectTypes.inboundCustomBot;
  }

  get isButtonCustomBot() {
    return this.selectedObjectType === objectTypes.buttonCustomBot;
  }

  get isCustomBot() {
    return this.isOutboundCustomBot || this.isInboundCustomBot || this.isButtonCustomBot;
  }

  get isBanner() {
    return this.selectedObjectType === objectTypes.banner;
  }

  get isTour() {
    return this.selectedObjectType === objectTypes.tour;
  }

  get isSurvey() {
    return this.selectedObjectType === objectTypes.survey;
  }

  get isSms() {
    return this.selectedObjectType === objectTypes.sms;
  }

  get isNewsItem() {
    return this.selectedObjectType === objectTypes.newsItem;
  }

  get isChecklist() {
    return this.selectedObjectType === objectTypes.checklist;
  }

  get isTooltipGroup() {
    return this.selectedObjectType === objectTypes.tooltipGroup;
  }

  setSelectedTemplateContentObject() {
    let { selectedTemplate } = this.args;

    if (!selectedTemplate) {
      return null;
    }

    if (this.isInboundCustomBot || this.isButtonCustomBot) {
      let customBotObject = selectedTemplate.ruleset.rulesetLinks.firstObject.object;
      customBotObject.type = selectedTemplate.contentObjects.firstObject.bot_type;
      return customBotObject;
    }

    if (
      this.isChat ||
      this.isPost ||
      this.isCarousel ||
      this.isEmail ||
      this.isBanner ||
      this.isSurvey ||
      this.isSms ||
      this.isNewsItem ||
      this.isChecklist ||
      this.isTooltipGroup
    ) {
      return selectedTemplate.ruleset.rulesetLinks.firstObject.object;
    }

    if (this.isPush) {
      // The format of the push template currently does not have a localizedPushContents array
      // so we need to create one if it does not exist.  When that changes, this check can be removed.
      let push = selectedTemplate.ruleset.rulesetLinks.firstObject.object;
      if (push.localizedPushContents.length === 0) {
        let content = this.store.createRecord(
          'mobile-push/localized-push-content',
          this.store.normalize(
            'mobile-push/localized-push-content',
            selectedTemplate.contentObjects.firstObject,
          ).data.attributes,
        );
        push.localizedPushContents.pushObject(content);
      }

      return push;
    }

    if (this.isTour) {
      let tourObject = selectedTemplate.contentObjects.firstObject;
      tourObject.from = this.app.currentAdmin;
      return tourObject;
    }

    return this._createMessageVariationFromTemplate(selectedTemplate);
  }

  _createMessageVariationFromTemplate(template) {
    let blocks = this._createBlocksFromTemplate(template);
    let { currentAdmin } = this.app;
    let messageVariationParams = {
      blocks,
      assignToId: currentAdmin.id,
      messageStyle: template.contentObjects.firstObject.message_style,
      state: 'draft',
      fromId: currentAdmin.id,
      emailTemplate: this.emailTemplate,
      replyType: this._getReplyTypeFromTemplate(template),
      replyOptions: template.contentObjects.firstObject.reply_options,
      reactions: this._createReactionsFromTemplate(template),
      subject: template.contentObjects.firstObject.subject,
    };
    return this.store.createRecord('message-variation', messageVariationParams);
  }

  get emailTemplate() {
    return this.store.peekRecord('email-template', 'personal');
  }

  _getReplyTypeFromTemplate(template) {
    if (template.replyType) {
      return REPLY_TYPES[template.replyType];
    }

    if (template.reactions) {
      return MESSAGE_CONSTANTS.reactionsReply;
    }

    return MESSAGE_CONSTANTS.textReply;
  }

  _createReactionsFromTemplate(template) {
    if (!template.reactions) {
      return null;
    }

    return template.reactions.map((reaction) => {
      return this.store.createFragment(`messages/reaction`, reaction);
    });
  }

  _createBlocksFromTemplate(template) {
    return template.contentObjects.firstObject.blocks.map((block) => {
      return this.store.createFragment(`common/blocks/${block['type']}`, block);
    });
  }
}
