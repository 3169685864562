/* import __COLOCATED_TEMPLATE__ from './template-list-with-preview.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import filterTemplates, {
  getTemplateTitle,
  isSwitchingFromBlockTemplateToLegacy,
  sortAndFilterCustomTemplates,
} from 'embercom/lib/outbound/email-template-helper';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import EmailPreviewBuilder from 'embercom/lib/outbound/email-preview-builder';
import { isPresent, isNone } from '@ember/utils';
import { templateSwitcherOptions } from 'embercom/models/data/outbound/constants';
import EmailTemplate from 'embercom/models/email-template';
import Translations from 'embercom/models/translations';

const TEMPLATES_PER_PAGE = 3;
export default class TemplateListWithPreview extends Component {
  @service appService;
  @service store;
  @service intercomEventService;
  @tracked previewEmail;
  @tracked emailTemplates = [];
  @tracked emailTemplatesFiltered = [];
  @tracked emailTemplateInputFilter = '';
  @tracked emailTemplateType;
  @tracked emailTemplateId;
  @tracked currentPageIndex = 0;

  previewBuilder = new EmailPreviewBuilder(this.store, this.app);

  constructor() {
    super(...arguments);
    this.createPreviewEmail.perform();
    this.loadEmailTemplates.perform();
  }

  get app() {
    return this.appService.app;
  }

  get canLoadNext() {
    return this.currentPageIndex + TEMPLATES_PER_PAGE < this.emailTemplatesFiltered.length;
  }

  get canLoadPrevious() {
    return this.currentPageIndex > 0;
  }

  get currentDisplayableEmailTemplates() {
    let start = this.currentPageIndex;
    let end = start + TEMPLATES_PER_PAGE;
    return this.emailTemplatesFiltered.slice(start, end);
  }

  get emailTemplateTitle() {
    return getTemplateTitle(this.emailTemplateType, this.emailTemplateId, this.store);
  }

  get showTemplateSwitcher() {
    return (
      this.emailTemplateTitle?.isBlockTemplate &&
      !this.emailTemplateTitle?.isPremade &&
      this.args.isSwitchingTemplate
    );
  }

  get emptyThumbnail() {
    return Array.from({
      length: TEMPLATES_PER_PAGE - this.currentDisplayableEmailTemplates.length,
    });
  }

  _isSwitchingToLegacyFromBlocksTemplate(localizedEmail, newPreviewTemplate) {
    let localizedEmailTemplate = getTemplateTitle(
      localizedEmail.emailTemplateType,
      localizedEmail.emailTemplateId,
      this.store,
    );

    return isSwitchingFromBlockTemplateToLegacy(localizedEmailTemplate, newPreviewTemplate);
  }

  @action
  filterTemplates() {
    if (this.emailTemplateInputFilter !== '') {
      this.emailTemplatesFiltered = this.emailTemplates.filter((template) =>
        template.name.toUpperCase().includes(this.emailTemplateInputFilter.toUpperCase()),
      );
      this._resetPageIndex();
    } else {
      this._setEmailTemplateTypeAndId();
      this.emailTemplatesFiltered = this.emailTemplates;
      this.emailTemplates.forEach((template, i) => {
        if (
          Number(template.outboundTemplateType) === this.emailTemplateType &&
          Number(template.outboundTemplateId) === this.emailTemplateId
        ) {
          this.emailTemplatesFiltered.splice(i, 1);
          this.emailTemplatesFiltered.unshift(template);
        }
      });
    }
  }

  @action loadNext() {
    this.currentPageIndex += TEMPLATES_PER_PAGE;
  }

  @action loadPrevious() {
    this.currentPageIndex -= TEMPLATES_PER_PAGE;
  }

  @action
  async setPreviewTemplate(emailTemplate) {
    let localizedEmail = this.previewEmail.localizedEmailContents.firstObject;
    let existingContentBlocks = this.args.localizedEmailContent?.blocks;

    if (this.args.isSwitchingTemplate) {
      let keepTemplateContent =
        this.args.templateSwitcherObject.switcherValue === templateSwitcherOptions.templateContent;

      if (
        (keepTemplateContent && !emailTemplate.isPremade) ||
        this._isSwitchingToLegacyFromBlocksTemplate(localizedEmail, emailTemplate)
      ) {
        existingContentBlocks = null;
      }
    }

    this.emailTemplateType = emailTemplate.outboundTemplateType;
    this.emailTemplateId = emailTemplate.outboundTemplateId;
    localizedEmail.emailTemplateType = this.emailTemplateType;
    localizedEmail.emailTemplateId = this.emailTemplateId;

    this.previewEmail.localizedEmailContents = [
      await this.previewBuilder.createLocalizedEmailContent(localizedEmail, existingContentBlocks),
    ];
    this._setSelectedTemplate();
    this.trackAnalyticsEvent('switch_template');
  }

  @task
  *loadEmailTemplates() {
    EmailTemplate.populateDefaultTemplates(this.app, Translations.create());
    let templates = yield this.store.peekAll('email-template-titles');
    // #272624 email-template-titles are not loaded in the /app route but can be accessed within the contacts list.
    // Ensure they are infact loaded so that the ruleset modal can correctly render.
    if (templates.length === 0) {
      templates = yield this.store.findAll('email-template-titles');
    }
    let premadeTemplates = templates.filter((template) => template.isPremade);
    let customTemplates = sortAndFilterCustomTemplates(templates);
    templates = [...premadeTemplates, ...customTemplates];
    templates = filterTemplates({
      templates,
      selectedTemplateId: this.args.localizedEmailContent?.emailTemplateId,
    });
    this.emailTemplates = templates;
    this.filterTemplates();
  }

  @task
  *createPreviewEmail() {
    this.previewEmail = yield this.previewBuilder.createPreviewEmail(
      this.args.localizedEmailContent,
    );
    let localizedEmail = this.previewEmail.localizedEmailContents.firstObject;
    this.emailTemplateType = localizedEmail.emailTemplateType;
    this.emailTemplateId = localizedEmail.emailTemplateId;
    this._setSelectedTemplate();
  }

  _setSelectedTemplate() {
    this.args.onSelect(`${this.emailTemplateType}:${this.emailTemplateId}`);
  }

  _setEmailTemplateTypeAndId() {
    if (isNone(this.emailTemplateId) || isNone(this.emailTemplateType)) {
      let { defaultTemplateId, defaultTemplateType } =
        this.previewBuilder._getDefaultTemplateIdAndType();
      this.emailTemplateId = isPresent(this.args.localizedEmailContent?.emailTemplateId)
        ? Number(this.args.localizedEmailContent.emailTemplateId)
        : defaultTemplateId;

      this.emailTemplateType = isPresent(this.args.localizedEmailContent?.emailTemplateType)
        ? Number(this.args.localizedEmailContent.emailTemplateType)
        : defaultTemplateType;
    }
  }

  _resetPageIndex() {
    this.currentPageIndex = 0;
  }

  trackAnalyticsEvent(object) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
    });
  }
}
