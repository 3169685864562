/* import __COLOCATED_TEMPLATE__ from './set-live-button.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface Args {
  isLive: boolean;
  onSelectItem: (isLive: boolean) => void;
  disabled?: boolean;
}

export default class SetLiveButton extends Component<Args> {
  @action
  onSelectItem(isLive: boolean) {
    if (isLive === this.args.isLive) {
      return;
    }

    this.args.onSelectItem(isLive);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpcenter::WorkspaceHelpcenter::SetLiveButton': typeof SetLiveButton;
    'new-settings/helpcenter/workspace-helpcenter/set-live-button': typeof SetLiveButton;
  }
}
