/* import __COLOCATED_TEMPLATE__ from './custom-report.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import { outboundCustomReportTests } from 'embercom/models/data/outbound/constants';

export default class CustomReport extends Component {
  @service appService;
  @service intl;

  TEST_A_REPORT_INDEX = 0;
  TEST_B_REPORT_INDEX = 1;

  get app() {
    return this.appService.app;
  }

  get buttonText() {
    if (this.args.contentType === objectTypes.survey) {
      return this.intl.t('outbound.surveys.survey-report.text');
    }
  }

  get outboundCustomReportTests() {
    return outboundCustomReportTests;
  }

  get testReportIndex() {
    if (this.args.showTest === outboundCustomReportTests.testB) {
      return this.TEST_B_REPORT_INDEX;
    }

    return this.TEST_A_REPORT_INDEX;
  }
}
