/* import __COLOCATED_TEMPLATE__ from './solve-onboarding-accordion-side-bar.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class SolveSideBarOnboardingAccordion extends Component {
  @tracked isOpen = true;

  @action
  toggleAccordion() {
    this.isOpen = !this.isOpen;
  }
}
