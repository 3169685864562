/* import __COLOCATED_TEMPLATE__ from './lead-qualification.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface Args {
  qualificationAttributes: any;
}

export default class LeadQualificationComponent extends Component<Args> {
  @service declare appService: any;

  @tracked isSaving = false;

  get app() {
    return this.appService.app;
  }

  @action
  setIsSaving(isSaving: boolean) {
    this.isSaving = isSaving;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::People::LeadQualification': typeof LeadQualificationComponent;
  }
}
