/* import __COLOCATED_TEMPLATE__ from './banner-wrapper.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import RouteRegexes from 'embercom/lib/route-regexes';
import { timeout } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import storage from 'embercom/vendor/intercom/storage';
import { tracked } from '@glimmer/tracking';

export default class BannerWrapper extends Component {
  @service appService;
  @service intercomEventService;
  @service router;
  @service onboardingHomeService;
  @service onboardingHomeExternalStepService;

  @tracked isAnyBannerDismissed = false;
  availableBannerText = { available: 'finish-setting-up-your-workspace' };

  constructor() {
    super(...arguments);

    if (!this.appService.app) {
      return;
    }

    this.onboardingHomeService.findOrLoadGuide(false);
  }

  get guide() {
    return this.onboardingHomeService.guide;
  }

  get inRouteWithoutBanners() {
    return this.router.currentRouteName.match(RouteRegexes.excludedFromOnboardingRoutes);
  }

  get inOnboardingHomeRoute() {
    return this.router.currentRouteName.match(RouteRegexes.onboardingHome);
  }

  get externalStep() {
    return this.onboardingHomeExternalStepService.externalStep;
  }

  get levelCompleted() {
    return this.externalStep?.level?.get('allStepsComplete');
  }

  get levelNotCompleted() {
    return !this.levelCompleted;
  }

  get shouldShowStepBanner() {
    if (localStorage.getItem('show-banner-for-onboarding-step')) {
      return true;
    }
    return false;
  }

  get installMessengerStep() {
    return this.guide.installMessengerStep;
  }

  get installVisitorsMessengerStep() {
    return this.guide.installVisitorsMessengerStep;
  }

  get installLoggedInMessengerStep() {
    return this.guide.installLoggedInMessengerStep;
  }

  get inviteTeammatesStep() {
    return (
      this.guide.findStepByIdentifier('invite_teammates') ||
      this.guide.findStepByIdentifier('invite_teammates_exp_one')
    );
  }

  get nextAvailableOnboardingHomeStep() {
    return this.guide.latestAvailableOrPendingCompletedStep;
  }

  get showInstallMessengerStep() {
    return (
      this.installMessengerStep?.available && this.installMessengerTaskRunningOrBannerNotDismissed
    );
  }

  get showInstallVisitorsMessengerStep() {
    return (
      this.installVisitorsMessengerStep?.available &&
      this.installVisitorsMessengerTaskRunningOrBannerNotDismissed
    );
  }

  get showInstallLoggedInMessengerStep() {
    return (
      this.installLoggedInMessengerStep?.available &&
      this.installLoggedInMessengerTaskRunningOrBannerNotDismissed
    );
  }

  get showInviteTeammatesStep() {
    return (
      this.inviteTeammatesStep?.available &&
      this.inviteTeammatesDismissTaskRunningOrBannerNotDismissed
    );
  }

  get installMessengerTaskRunningOrBannerNotDismissed() {
    return this.installMessengerBannerNotDismissed || this.dismissInstallMessengerBanner.isRunning;
  }

  get installVisitorsMessengerTaskRunningOrBannerNotDismissed() {
    return (
      this.installVisitorsMessengerBannerNotDismissed ||
      this.dismissInstallVisitorsMessengerBanner.isRunning
    );
  }

  get installLoggedInMessengerTaskRunningOrBannerNotDismissed() {
    return (
      this.installLoggedInMessengerBannerNotDismissed ||
      this.dismissInstallLoggedInMessengerBanner.isRunning
    );
  }

  get availableOnboardingHomeTaskRunningOrBannerNotDismissed() {
    return (
      this.availableOnBoardingHomeBannerNotDismissed ||
      this.dismissAvailableOnboardingHomeStepBanner.isRunning
    );
  }

  get inviteTeammatesDismissTaskRunningOrBannerNotDismissed() {
    return this.inviteTeammatesBannerNotDismissed || this.dismissInviteTeammatesBanner.isRunning;
  }

  get installMessengerBannerDismissed() {
    return this._isBannerDismissed(this.installMessengerStep.identifier);
  }

  get installMessengerBannerNotDismissed() {
    return !this.installMessengerBannerDismissed;
  }

  get installVisitorsMessengerBannerDismissed() {
    return this._isBannerDismissed(this.installVisitorsMessengerStep.identifier);
  }

  get installVisitorsMessengerBannerNotDismissed() {
    return !this.installVisitorsMessengerBannerDismissed;
  }

  get installLoggedInMessengerBannerDismissed() {
    return this._isBannerDismissed(this.installLoggedInMessengerStep.identifier);
  }

  get installLoggedInMessengerBannerNotDismissed() {
    return !this.installLoggedInMessengerBannerDismissed;
  }

  get availableOnboardingHomeStepBannerDismissed() {
    return this._isBannerDismissed(this.nextAvailableOnboardingHomeStep.identifier);
  }

  get availableOnBoardingHomeBannerNotDismissed() {
    return !this.availableOnboardingHomeStepBannerDismissed;
  }

  get inviteTeammatesBannerDismissed() {
    return this._isBannerDismissed(this.inviteTeammatesStep.identifier);
  }

  get inviteTeammatesBannerNotDismissed() {
    return !this.inviteTeammatesBannerDismissed;
  }

  get finPhase2ExperienceBannerDismissed() {
    return this._isBannerDismissed(null);
  }

  get hasAvailableSteps() {
    return this.guide.hasAvailableSteps;
  }

  get noBannersDismissed() {
    return !this.isAnyBannerDismissed;
  }

  get isInFinPhase2OnboardingExperience() {
    return this.guide?.identifier === 'fin_phase_two';
  }

  @dropTask
  *dismissInstallMessengerBanner() {
    yield this.dismissBanner.perform(this.installMessengerStep);
  }

  @dropTask
  *dismissInstallVisitorsMessengerBanner() {
    yield this.dismissBanner.perform(this.installVisitorsMessengerStep);
  }

  @dropTask
  *dismissInstallLoggedInMessengerBanner() {
    yield this.dismissBanner.perform(this.installLoggedInMessengerStep);
  }

  @dropTask
  *dismissAvailableOnboardingHomeStepBanner() {
    yield this.dismissBanner.perform(this.nextAvailableOnboardingHomeStep);
  }

  @dropTask
  *dismissInviteTeammatesBanner() {
    yield this.dismissBanner.perform(this.inviteTeammatesStep);
  }

  @dropTask
  *dismissBanner(step) {
    this.isAnyBannerDismissed = true;
    if (step) {
      storage.set(this._storageKeyForStep(step.identifier), true);
    } else {
      storage.set(`onboarding_home_banner_${this.appService.app.id}_dismissed`, true);
    }
    yield timeout(ENV.APP._7000MS);
  }

  trackBannerClick(step, action, object) {
    this.intercomEventService.trackAnalyticsEvent({
      ...step.analyticsMetadata,
      action,
      object,
      context: 'onboarding_home_banner',
    });
  }

  _storageKeyForStep(stepIdentifier) {
    return `onboarding_home_banner_${this.appService.app.id}_${stepIdentifier}`;
  }

  _isBannerDismissed(stepIdentifier) {
    if (stepIdentifier) {
      return storage.get(this._storageKeyForStep(stepIdentifier));
    } else {
      return storage.get(`onboarding_home_banner_${this.appService.app.id}_dismissed`);
    }
  }
}
