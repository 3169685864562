/* import __COLOCATED_TEMPLATE__ from './ask-for-more-information-before-handover.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import type FinWorkflowPreview from 'embercom/lib/workflows/fin-workflow-preview';

interface Signature {
  Args: {
    accordion: $TSFixMe;
    finWorkflowPreview: FinWorkflowPreview;
  };
}

const AskForMoreInformationBeforeHandover = templateOnlyComponent<Signature>();
export default AskForMoreInformationBeforeHandover;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::WorkflowSections::AskForMoreInformationBeforeHandover': typeof AskForMoreInformationBeforeHandover;
    'operator/fin/setup/workflow-sections/ask-for-more-information-before-handover': typeof AskForMoreInformationBeforeHandover;
  }
}
