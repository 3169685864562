/* import __COLOCATED_TEMPLATE__ from './qualify-leads.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import EmberObject, { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import Component from '@glimmer/component';
import { loadMessengerSettings } from 'embercom/lib/home/load-messenger-settings';

export default class QualifyLeads extends Component {
  @service router;
  @service onboardingHomeExternalStepService;
  @service permissionsService;
  @service intercomEventService;
  @tracked messengerSettings = this.modelData?.messengerSettings;

  constructor() {
    super(...arguments);
    this.loadMessengerSettings.perform();

    if (this.qualifyLeads?.data?.enabled) {
      this.markStepAsCompleted();
    }
  }

  get step() {
    return this.args.step;
  }

  get modelData() {
    return this.step.modelData;
  }

  get identifier() {
    return this.step.identifier;
  }

  get qualifyLeads() {
    return this.modelData.qualifyLeads;
  }

  get settings() {
    return EmberObject.create({ qualifyLeads: this.qualifyLeads });
  }

  @action
  async setupLeadQualification() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'tour_started',
      object: 'qualify_leads',
      ...this.step.analyticsMetadata,
    });
    this.onboardingHomeExternalStepService.startAfterTransition(this.identifier, {
      source: 'home/qualify-leads',
      method: 'startAfterTransition',
    });
    this.onboardingHomeExternalStepService.completeExternalStep();
    this.router.transitionTo('apps.app.operator.task-bots.leads', {
      queryParams: { section: 'qualify-leads', for: 'leads' },
    });
  }

  @action
  markStepAsCompleted() {
    if (this.args.step.completed) {
      return;
    }
    this.onboardingHomeExternalStepService.completeExternalStep();
    this.args.completionCallback();
  }

  @dropTask
  *loadMessengerSettings() {
    if (this.messengerSettings) {
      return;
    }
    this.messengerSettings = yield loadMessengerSettings(this);
  }
}
