/* import __COLOCATED_TEMPLATE__ from './define-the-data-you-need.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { scheduleOnce } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { task } from 'ember-concurrency';
import ajax from 'embercom/lib/ajax';
import validation from 'embercom/lib/cda-onboarding/validation';
import { filter } from '@ember/object/computed';

export const DEFAULT_ATTRIBUTES = [{ name: '', type: 'String' }];

function generateDefaultAttributes() {
  return [{ name: '', type: 'String' }];
}

export default Component.extend({
  router: service(),
  appService: service(),
  attributeService: service(),
  notificationsService: service(),
  intercomEventService: service(),
  onboardingHomeService: service(),
  app: readOnly('appService.app'),
  store: service(),
  step: null,
  attributes: null,
  completionCallback: null,
  dependentStepIdentifier: computed('onboardingHomeService.guide.identifier', function () {
    if (this.onboardingHomeService.guide.identifier === 'fin_general_release') {
      return 'choose_target_audience_go_live_ga';
    } else if (this.onboardingHomeService.guide.identifier === 'fin_phase_two') {
      return 'choose_target_audience_go_live';
    }
    return 'set_up_messenger_logged_in';
  }),
  setUpMessengerLoggedInStep: computed(
    'dependentStepIdentifier',
    'onboardingHomeService.guide.steps.@each.identifier',
    function () {
      return this.get('onboardingHomeService.guide').findStepByIdentifier(
        this.dependentStepIdentifier,
      );
    },
  ),
  setUpMessengerLoggedInStepCompleted: readOnly('setUpMessengerLoggedInStep.completed'),
  erroredCdas: [],
  duplicateCdas: [],
  existingCdas: [],

  filledCdas: filter('attributes.@each.name', function (cda) {
    return cda.name;
  }),

  filledCdaCount: readOnly('filledCdas.length'),

  analyticsMetadata: computed('step.analyticsMetadata', function () {
    return {
      ...this.step.analyticsMetadata,
    };
  }),

  init() {
    this._super(...arguments);
    this.set('attributes', generateDefaultAttributes());
  },

  trackClickedAnalyticsEvent(object, params = {}) {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.step.analyticsMetadata,
      ...params,
      action: 'clicked',
      object,
    });
  },

  focusLastAttributeDescriptionInput() {
    let attributeWrappers = this.element.querySelectorAll('[data-attribute-input-description]');
    attributeWrappers.item(attributeWrappers.length - 1).focus();
  },

  createCda: task(function* () {
    let erroredCdas = validation.getInvalidNames(this.filledCdas);
    let duplicateCdas = validation.getDuplicateNames(this.filledCdas);

    this.set('erroredCdas', erroredCdas);
    this.set('duplicateCdas', duplicateCdas);
    if (erroredCdas.length > 0) {
      return this.notificationsService.notifyError(validation.getInvalidErrorMessage(erroredCdas));
    }

    if (duplicateCdas.length > 0) {
      return this.notificationsService.notifyError(
        validation.getDuplicateErrorMessage(duplicateCdas),
      );
    }

    let promise = yield ajax({
      url: '/ember/untracked_custom_data_attributes',
      type: 'POST',
      data: JSON.stringify({
        app_id: this.get('app.id'),
        custom_data_attributes: this.filledCdas,
      }),
    })
      .then((response) => {
        this.intercomEventService.trackEvent('untracked-attribute-added', {
          action: 'added',
          object: this.filledCdas.mapBy('name'),
          place: 'home_cda_onboarding_step',
          owner: 'onboarding',
        });
        this.store.pushPayload({ attributes: response });
        let attributes = response.map((attribute) =>
          this.store.peekRecord('attribute', attribute.identifier),
        );
        this.attributeService.attributes.pushObjects(attributes);
        this.completionCallback();
      })
      .catch((error) => {
        let response = error.jqXHR.responseJSON;

        if (response && response.errors.type === 'duplicate') {
          let erroredCdas = response.errors.cdas;

          this.set('existingCdas', erroredCdas);
          this.notificationsService.notifyError(
            validation.getAlreadyExistsErrorMessage(erroredCdas),
          );
        } else if (response && response.errors.type === 'cda_limit') {
          this.notificationsService.notifyError(response.errors.message);
        } else if (response && response.errors && error.jqXHR.status === 403) {
          this.notificationsService.notifyError(response.errors);
        } else {
          this.notificationsService.notifyError(error.jqXHR.statusText);
        }
      });

    return promise;
  }).drop(),

  addAttribute: action(function () {
    this.attributes.pushObject({ name: '', type: 'String' });
    scheduleOnce('afterRender', this, this.focusLastAttributeDescriptionInput);
    this.trackClickedAnalyticsEvent('add_attribute_button');
  }),
});
