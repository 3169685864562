/* import __COLOCATED_TEMPLATE__ from './ticket-data.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import type AttributeMapping from 'embercom/models/crm/attribute-mapping';
import { type MappingVisibility } from 'embercom/models/crm/attribute-mapping';
import type ImportConfigurationService from 'embercom/services/data/import-configuration-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export interface Args {
  integrationCode: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class TicketData extends Component<Signature> {
  @service declare intl: IntlService;
  @service('data/import-configuration-service')
  declare importConfigurationService: ImportConfigurationService;

  @tracked scrollToBackoffice = false;

  createCustomerTicketUrl = 'apps.app.settings.helpdesk.tickets.index';
  createBackofficeTicketUrl = 'apps.app.settings.helpdesk.tickets.index';

  ticketTypeId = (visibility: MappingVisibility) => {
    return this.importConfigurationService.getTicketTypeId(visibility);
  };

  ticketTypeName = (visibility: MappingVisibility) => {
    if (visibility === 'public') {
      return this.importConfigurationService.publicTicketType?.name;
    } else {
      return this.importConfigurationService.privateTicketType?.name;
    }
  };

  requiredTicketMappings = (visibility: MappingVisibility) => {
    let ticketTypeId = this.ticketTypeId(visibility);
    if (ticketTypeId === null) {
      return [] as AttributeMapping[];
    }

    return this.importConfigurationService.listRequiredTicketMappings(ticketTypeId, visibility);
  };

  @action scrollToBackofficeSection() {
    this.scrollToBackoffice = true;
  }

  translation = (path: string, options = {}) => this.intl.t(this.translationKey(path), options);
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.sections.ticket.ticket-data.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Ticket::TicketData': typeof TicketData;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/ticket/ticket-data': typeof TicketData;
  }
}
