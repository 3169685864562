/* import __COLOCATED_TEMPLATE__ from './banner.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface Args {
  icon: InterfaceIconName;
  text: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

const Banner = templateOnlyComponent<Signature>();
export default Banner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Lib::Banner': typeof Banner;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/lib/banner': typeof Banner;
  }
}
