/* import __COLOCATED_TEMPLATE__ from './fin-preview.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { TEAMMATE_APP_URL } from 'embercom/lib/teammate-app';
import { inject as service } from '@ember/service';
import { FIN_GENERAL_RELEASE } from 'embercom/lib/home/guides';
import { post } from 'embercom/lib/ajax';
import { dropTask } from 'ember-concurrency-decorators';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type ContentImportService from 'embercom/services/content-import-service';
import type { Task, TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { taskFor } from 'ember-concurrency-ts';
import { action } from '@ember/object';

interface Signature {
  Element: never;
  Args: {
    isFromWizard?: boolean;
  };
}

export default class FinPreview extends Component<Signature> {
  @service declare appService: any;
  @service declare contentImportService: ContentImportService;
  @service declare OnboardingHomeService: any;
  @service declare intercomEventService: any;

  @tracked messengerInteracted = false;
  @tracked eventsFiredCount = 0;

  getShareableLinkTokenTask: Task<any, any> = taskFor(this.getShareableLinkToken);

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.contentImportService.subscribeToContentImportRunStatusUpdates();
    this.contentImportService.fetchContentImportSources();
    window.addEventListener('blur', this.trackCurrentElement);
  }

  willDestroy() {
    super.willDestroy();
    window.removeEventListener('blur', this.trackCurrentElement);
  }

  get finUrl() {
    return `${TEAMMATE_APP_URL}/client/onboarding_fin_preview/${this.appService.app.id}`;
  }

  get showFinPreview() {
    return this.contentImportService.successfulRuns?.length > 0;
  }

  get showFinFailedImport() {
    return (
      this.contentImportService.failedRuns.length > 0 &&
      this.contentImportService.successfulRuns.length === 0
    );
  }

  get showFinLoadingState() {
    return (
      this.contentImportService.inProgressRunExists &&
      this.contentImportService.successfulRuns.length === 0
    );
  }

  get isFinGeneralReleaseGuide() {
    return (
      this.OnboardingHomeService.guide.identifier === FIN_GENERAL_RELEASE ||
      this.OnboardingHomeService.guide.identifier === 'default_guide_library'
    );
  }

  @action
  trackCurrentElement() {
    if (
      document.activeElement?.tagName === 'IFRAME' &&
      document.activeElement?.id === 'hosted-messenger-fin-demo-preview' &&
      this.eventsFiredCount > 0
    ) {
      this.messengerInteracted = true;
      window.removeEventListener('blur', this.trackCurrentElement);
    }
    this.eventsFiredCount++;
  }

  @dropTask
  *getShareableLinkToken(): TaskGenerator<void> {
    let { token } = yield post('/ember/onboarding/shareable_fin_preview/', {
      app_id: this.appService?.app?.id,
    });
    let PREVIEW_URL = `${window.location.origin}/a/preview-fin?token=${token}&source=onboardinghome`;
    safeWindowOpen(PREVIEW_URL, '_blank');
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'create_shareable_fin_preview',
      context: this.args.isFromWizard
        ? 'onboarding_guide_library.wizard.ai_bot.fin_preview'
        : 'operator_fin_preview',
      place: this.args.isFromWizard ? 'ai_bot_wizard' : 'onboarding_home',
      appId: this.appService?.app?.id,
      section: this.args.isFromWizard ? 'wizard.fin-preview' : 'operator_fin_preview',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    FinPreview: typeof FinPreview;
    'Onboarding::Home::FinPreview': typeof FinPreview;
  }
}
