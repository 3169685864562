/* import __COLOCATED_TEMPLATE__ from './newsfeed-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class NewsfeedComponent extends Component {
  @service newsfeedsService;
  @service intl;
  @service appService;

  @tracked selectedValues = [];

  constructor() {
    super(...arguments);
    this.selectedValues = this.args.additionalSearchableData?.newsfeed_ids || [];
  }

  get app() {
    return this.appService.app;
  }

  @action
  changeSelectedValues() {
    this.selectedValues = this.args.additionalSearchableData?.newsfeed_ids || [];
  }

  get isOpen() {
    return this.args.activeFilter === 'newsfeed';
  }

  get additionalSearchableData() {
    return this.args.additionalSearchableData || {};
  }

  get newsfeeds() {
    return this.newsfeedsService.newsfeeds;
  }

  get targetedNewsfeeds() {
    return this.newsfeedsService.targetedNewsfeeds;
  }

  get newsfeedOptions() {
    return this.targetedNewsfeeds.map((newsfeed) => ({
      text: newsfeed.nameForDisplay,
      value: newsfeed.id,
      isSelected: this.selectedValues.includes(newsfeed.id),
    }));
  }

  get selectedNewsfeeds() {
    return this.newsfeeds.filter((newsfeed) => this.selectedValues.includes(newsfeed.id));
  }

  get name() {
    let selectedCount = this.selectedNewsfeeds.length;
    return this.intl.t('outbound.news-items.list.filters.newsfeeds.name', {
      name: selectedCount > 0 ? this.selectedNewsfeeds[0].nameForDisplay : 'any',
      selectedCount: selectedCount - 1 > 0 ? selectedCount - 1 : 0,
    });
  }

  @action
  filterByNewsfeeds(newsfeedIds) {
    this.selectedValues = newsfeedIds;
  }

  @action
  onClose() {
    this.args.filterContent('additionalSearchableData', {
      ...this.additionalSearchableData,
      newsfeed_ids: this.selectedValues,
    });
  }

  @action removeFilter() {
    let searchableData = { ...this.additionalSearchableData, newsfeed_ids: [] };
    this.args.filterContent('additionalSearchableData', searchableData);
    this.args.removeFromActiveFilter('newsfeed');
  }
}
