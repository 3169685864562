/* import __COLOCATED_TEMPLATE__ from './all.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AllChannels extends Component {
  @service declare intercomEventService: $TSFixMe;
  @service declare guideLibraryService: $TSFixMe;

  @action
  showChannelsArticle() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'channel_page_help_link',
      place: 'all_channels_settings_discovery_banner',
      section: 'settings',
    });
    window.Intercom('showArticle', 9955432); // https://www.intercom.com/help/en/articles/9955432-channels-explained
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::All': typeof AllChannels;
  }
}
