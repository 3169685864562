/* import __COLOCATED_TEMPLATE__ from './guide-assistant-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type GuideLibraryService from 'embercom/services/guide-library-service';

interface Signature {
  Args: {
    sizeClass: string;
    wizardStarted?: boolean;
  };
  Element: never;
}

export default class GuideAssistantComponent extends Component<Signature> {
  @service declare appService: any;
  @service declare onboardingHomeService: any;
  @service declare guideAssistantService: any;
  @service declare guideLibraryService: GuideLibraryService;
  @tracked sizeClass = null;

  get onboardingAssistant() {
    return this.guideAssistantService.onboardingAssistant;
  }

  get app() {
    return this.appService.app;
  }

  get currentAdmin() {
    return this.app.currentAdmin;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::GuideAssistantComponent': typeof GuideAssistantComponent;
  }
}
