/* import __COLOCATED_TEMPLATE__ from './response-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import { keywordResponseTypes } from 'embercom/models/data/sms/constants';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { type TaskGenerator } from 'ember-concurrency';

interface Args {
  smsOutboundSettings: any;
  canEditKeywordResponse: boolean;
  editRoute: any;
}

export default class ResponseSettings extends Component<Args> {
  keywordResponseTypes = keywordResponseTypes;

  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare notificationsService: $TSFixMe;
  @service declare appService: $TSFixMe;

  @tracked selectedResponseBehaviour: any;
  @tracked smsOutboundSettings = this.args.smsOutboundSettings;
  @tracked keywordResponseGroup: any[] = [];

  @dropTask
  *fetchSmsKeywordResponses(): TaskGenerator<void> {
    let keywordResponsesList = yield this.store.findAll('sms/keyword-response');
    let keywordResponses = keywordResponsesList || this.store.createRecord('sms/keyword-response');
    this.keywordResponseGroup = [
      ...keywordResponses.toArray(),
      {
        keywordType: keywordResponseTypes.carrierStop,
        keywordText: 'STOP',
        text: this.intl.t(
          'outbound.settings.sms.accordion.response-settings.stop-keyword-response-text',
        ),
        tooltip: this.intl.t(
          'outbound.settings.sms.accordion.response-settings.keyword-response-row-tooltips.carrier-stop',
        ),
        isCarrierStopResponse: true,
        isDefaultResponse: false,
        isCustomResponse: false,
      },
    ];
  }

  @action
  deleteCustomKeywordResponse(keywordResponse: any) {
    try {
      keywordResponse.destroyRecord();
      this.keywordResponseGroup = this.keywordResponseGroup.filter(
        (k) => k.id !== keywordResponse.id,
      );
      this.notificationsService.notifyConfirmation(
        this.intl.t('outbound.settings.sms.response-settings.notify-keyword-response-deleted'),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t(
          'outbound.settings.sms.response-settings.notify-keyword-response-deletion-error',
        ),
      );
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::Settings::Sms::ResponseSettings': typeof ResponseSettings;
  }
}
