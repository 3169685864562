/* import __COLOCATED_TEMPLATE__ from './auto-message-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { MESSAGES_ESSENTIAL_ID } from 'embercom/lib/billing';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { action } from '@ember/object';

let userRole = {
  attribute: 'role',
  type: 'role',
  comparison: 'in',
  value: ['user_role'],
};

let visitorRole = {
  attribute: 'role',
  type: 'role',
  comparison: 'in',
  value: ['contact_role', 'visitor_role'],
};

export default class AutoMessageComponent extends Component {
  @service appService;
  @service store;
  @service notificationsService;
  @service intercomEventService;
  @service onboardingHomeService;

  @tracked selectedAudience;

  constructor() {
    super(...arguments);
    this._setSelectedAudience();
  }

  get app() {
    return this.appService.app;
  }

  get guide() {
    return this.onboardingHomeService.guide;
  }

  get isOnFree() {
    return this.app.isOnFree;
  }

  get chatRuleset() {
    return this.args.step?.modelData?.chatRuleset;
  }

  get chat() {
    return this.chatRuleset?.rulesetLinks?.firstObject?.object;
  }

  get adminCanSendMessages() {
    return this.app.currentAdmin.canSendMessages;
  }

  get loggedOutInstallTimestamp() {
    return this.app.first_anonymous_request_at || this.app.verifiedLoggedOutInstallAt;
  }

  get noLoggedOutInstall() {
    return !this.loggedOutInstallTimestamp;
  }

  get loggedInInstallTimestamp() {
    return this.app.first_identified_request_at || this.app.verifiedLoggedInInstallAt;
  }

  get noLoggedInInstall() {
    return !this.loggedInInstallTimestamp;
  }

  get audienceTypes() {
    return ['visitors', 'users'];
  }

  get messagesPlanId() {
    return MESSAGES_ESSENTIAL_ID;
  }

  _setSelectedAudience() {
    if (this.chatRuleset && this.chatRuleset.userType === 'non_user') {
      this.selectedAudience = 'visitors';
    } else {
      this.selectedAudience = 'users';
    }
  }

  async _fetchInstallMessengerStepData() {
    let installMessengerStep = this.guide.installMessengerStep;
    if (installMessengerStep) {
      await installMessengerStep.loadModelData();
    }
  }

  @dropTask
  *saveAndSetLive() {
    let selectedRolePredicate = this.selectedAudience === 'visitors' ? visitorRole : userRole;
    let rolePredicate = this.store.createFragment('predicates/predicate-group', {
      predicates: [selectedRolePredicate],
    });
    this.chatRuleset.set('rolePredicateGroup', rolePredicate);

    try {
      yield this.chatRuleset.save();
    } catch (e) {
      this.notificationsService.notifyError(
        'Something went wrong and we couldn’t save your message. Please try again.',
      );
      return;
    }

    try {
      yield this.chatRuleset.activate();
    } catch (e) {
      this.notificationsService.notifyError(
        'Something went wrong and we couldn’t set your message live. Please try again.',
      );
      return;
    }
    yield this._fetchInstallMessengerStepData();

    yield this.args.completionCallback({
      analyticsMetadata: {
        context: this.selectedAudience,
        assignment: 'auto',
      },
    });
  }

  @action
  closeSection() {
    this.args.accordionAPI.closeSection();
  }

  @action
  setSelectedAudience(audience) {
    this.intercomEventService.trackAnalyticsEvent({
      section: 'inline_step',
      object: 'send_auto_message',
      action: 'toggled',
      context: audience,
    });

    this.selectedAudience = audience;
  }

  @action
  openStep(stepIdentifier) {
    this.args.openStepCallback(stepIdentifier);
  }
}
