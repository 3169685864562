/* import __COLOCATED_TEMPLATE__ from './locale-selector.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

export default class LocaleSelector extends Component {
  get localeOptions() {
    return this.args.localizations.map((locale) => ({
      locale_name: locale.name,
      locale_id: `(${locale.locale.toUpperCase()})`,
      value: locale.locale,
      state: locale.enabled,
      default: locale.default,
      component: 'operator/generic-triage/locale-option',
    }));
  }

  get selectedLocalization() {
    return this.args.localizations.find(
      (localization) => localization.locale === this.args.selectedLocale,
    );
  }
}
