/* import __COLOCATED_TEMPLATE__ from './invite-teammates.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */

import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { A } from '@ember/array';
import { DEFAULT_LOCALE, SUPPORTED_LOCALES } from 'embercom/services/intl';
import { post } from 'embercom/lib/ajax';
import { isPresent } from '@ember/utils';

export default class InviteTeammates extends Component {
  @service intercomEventService;
  @service onboardingHomeExternalStepService;
  @service router;
  @service permissionsService;
  @service notificationsService;
  @service intl;
  @tracked newEmail = '';
  @service appService;
  @service store;

  @action
  async startTour() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.step.analyticsMetadata,
      action: 'clicked',
      object: `${this.args.step.identifier}_tour_${this.args.step.productTourId}`,
    });

    await this.permissionsService.checkPermission('can_manage_teammates');

    this.onboardingHomeExternalStepService.startAfterTransition(this.args.step.identifier, {
      source: 'invite_teammates',
      method: 'startAfterTransition',
    });

    this.onboardingHomeExternalStepService.completeExternalStep();

    return this.router.transitionTo('apps.app.settings.workspace.teammates.invite.new');
  }

  @action
  focusNewEmailInput({ target, currentTarget }) {
    if (currentTarget === target) {
      let inputElement = currentTarget.getElementsByTagName('input')[0];
      inputElement.focus();
    }
  }

  _getPermissionsObject() {
    let permissionsObject = this.store.createRecord('permission', {
      app_id: this.appService.app.id,
    });
    permissionsObject.setAllPermissions(true);
    permissionsObject.set('has_inbox_access', true);
    return permissionsObject;
  }

  _getLocaleFromLocalStorage() {
    let storedLocale = localStorage.getItem('localeForInvite');
    if (storedLocale && SUPPORTED_LOCALES.includes(storedLocale)) {
      return storedLocale;
    }
    return DEFAULT_LOCALE;
  }

  @action
  async sendInvite() {
    try {
      let permissions = this._getPermissionsObject().serialize();
      let invitee = {
        email: this.newEmail,
        seat_types: A(),
        locale: this._getLocaleFromLocalStorage(),
        permissions,
      };

      await post('/ember/invite_admin_groups', {
        invitees: [invitee],
        app_id: this.appService.app.id,
      });

      this.intercomEventService.trackAnalyticsEvent({
        action: 'created',
        object: 'teammate-invite',
        place: 'onboarding-home',
        numberOfInvites: 1,
      });
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.teammates.invite.notification.invite-success', {
          inviteCount: 1,
          appName: this.appService.app.name,
        }),
      );

      this.onboardingHomeExternalStepService.completeExternalStep();
      this.args.completionCallback();
    } catch (error) {
      if (error.jqXHR?.responseJSON?.error_code === 'seat_limit_reached') {
        if (
          this.appService.app.cannotAssignWiderAccess &&
          isPresent(error?.jqXHR?.responseJSON?.tokens)
        ) {
          this.notificationsService.notifyError(
            this.intl.t(
              'settings.error_message.cannot_modify_unowned_permissions_invite_creation_error',
            ),
          );
        } else {
          this.notificationsService.notifyError(
            this.intl.t('settings.error_message.cannot_send_invites_error'),
          );
        }
      } else if (error.jqXHR?.responseJSON?.error_code === 'invite_limit_reached') {
        this.notificationsService.notifyError(
          this.intl.t('settings.error_message.cannot_send_invites_limit_error'),
        );
      } else {
        this.notificationsService.notifyError(
          this.intl.t('settings.error_message.cannot_send_invites_error'),
        );
      }
      console.error(error);
    }
  }

  @action
  transitionToRoute(route) {
    this.router.transitionTo(route, {
      queryParams: { shouldShowStepBanner: true },
    });
  }
}
