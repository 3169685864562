/* import __COLOCATED_TEMPLATE__ from './all-help-centers.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */
import { action } from '@ember/object';
import { type Router } from '@ember/routing';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type HelpCenterSite from 'embercom/models/help-center-site';

interface Args {
  model: $TSFixMe;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class AllHelpCenters extends Component<Signature> {
  @service declare router: Router;
  @service declare helpCenterService: $TSFixMe;
  @service declare appService: $TSFixMe;

  @tracked showCreateHelpCenterModal = false;

  get maxHelpCenters() {
    return this.helpCenterService.maxNumberOfSites;
  }

  get hasMaxHelpCenters() {
    return this.args.model.helpCenters.length >= this.maxHelpCenters;
  }

  @action
  toggleNewHelpCenterModal() {
    this.showCreateHelpCenterModal = !this.showCreateHelpCenterModal;
  }

  @action
  transitionToSettingsPage(helpCenter: HelpCenterSite) {
    this.router.transitionTo(
      'apps.app.settings.helpcenter.workspace-helpcenter',
      this.appService.app.id,
      helpCenter.id,
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpcenter::AllHelpCenters': typeof AllHelpCenters;
    'new-settings/helpcenter/all-help-centers': typeof AllHelpCenters;
  }
}
