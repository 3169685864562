/* import __COLOCATED_TEMPLATE__ from './localization-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { action } from '@ember/object';
import Component from '@glimmer/component';

export default class LocalizationEditor extends Component {
  @action
  reorderParts(localizedBotIntro, parts) {
    localizedBotIntro.set('parts', parts);
    this.args.localizedBotIntro.usingDefault = false;
  }

  @action
  removePart(part) {
    this.args.localizedBotIntro.parts.removeObject(part);
    this.args.localizedBotIntro.usingDefault = false;
  }

  @action
  addPart() {
    this.args.localizedBotIntro.parts.createFragment();
    this.args.localizedBotIntro.usingDefault = false;
  }
}
