/* import __COLOCATED_TEMPLATE__ from './thumbnail.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import EmailPreviewBuilder from 'embercom/lib/outbound/email-preview-builder';

export default class Thumbnail extends Component {
  @service appService;
  @service store;
  @tracked thumbnailEmail;

  previewBuilder = new EmailPreviewBuilder(this.store, this.app);

  constructor() {
    super(...arguments);
    this.createPreviewThumbnail.perform();
  }

  get app() {
    return this.appService.app;
  }

  @task
  *createPreviewThumbnail() {
    let emailTemplate = this.args.emailTemplate;
    let previewThumbnail = yield this.previewBuilder.createPreviewEmail();
    let localizedEmail = previewThumbnail.localizedEmailContents.firstObject;
    localizedEmail.emailTemplateType = emailTemplate.outboundTemplateType;
    localizedEmail.emailTemplateId = emailTemplate.outboundTemplateId;
    previewThumbnail.localizedEmailContents = [
      yield this.previewBuilder.createLocalizedEmailContent(localizedEmail),
    ];
    this.thumbnailEmail = previewThumbnail;
  }
}
