/* import __COLOCATED_TEMPLATE__ from './sender-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { senderTypes } from 'embercom/models/data/outbound/constants';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

export default class SenderComponent extends Component {
  @service appService;
  @service outboundHomeService;

  get app() {
    return this.appService.app;
  }

  get isOpen() {
    return this.args.activeFilter === 'sender';
  }

  get selectedSender() {
    return this.senders.find((sender) => sender.value === this.args.selectedSenderValue);
  }

  get selectedName() {
    return this.selectedSender.text;
  }

  get senders() {
    let defaultSender = { name: 'Any', id: null };
    let allSenders = [defaultSender];

    let owner = { name: 'Owner', id: -1 };
    allSenders.push(owner);

    let operator = this.app.get('operatorBot');
    allSenders.push(operator);

    let admins = this.app.get('humanAdmins');
    allSenders.push(admins);

    let teams = this.app.get('teams');
    allSenders.push(teams);

    let emails = this.app.get('verifiedCustomEmailAddresses');
    allSenders.push(emails);

    return this._mapSendersToDropdownItems(allSenders.flat());
  }

  get name() {
    if (this.outboundHomeService.activeList === objectTypes.series) {
      return 'Created by';
    }
    return 'Sender is';
  }

  _mapSendersToDropdownItems(senders) {
    return senders.map((sender) => {
      if (sender.type === 'admin') {
        let senderType = sender.isTeam ? senderTypes.team : senderTypes.admin;
        return {
          text: sender.name,
          value: `${senderType}_${sender.id}`,
          avatarData: sender.avatarData,
          component: 'common/avatar-and-name-dropdown-item',
          componentAttrs: {
            model: sender,
            modelDisplayName: sender.name,
            avatarSize: 'xs',
          },
        };
      } else if (sender.id === null) {
        return {
          text: sender.name,
          value: null,
        };
      } else if (sender.id === -1) {
        return {
          text: sender.name,
          value: `${senderTypes.owner}_`,
          icon: 'owner',
        };
      } else {
        return {
          text: `${sender.name} <${sender.email}>`,
          value: `${senderTypes.customEmailAddress}_${sender.id}`,
          icon: 'email',
        };
      }
    });
  }

  @action
  filterBySender(senderId) {
    this.args.filterContent('selectedSenderValue', senderId);
  }

  @action removeFilter() {
    this.args.filterContent('selectedSenderValue', null);
    this.args.removeFromActiveFilter('sender');
  }
}
