/* import __COLOCATED_TEMPLATE__ from './news-inserter.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class NewsInserter extends Component {
  @action
  insertMessengerCard(cardJSON) {
    this.args.inserterApi.createBlock('messengerCard', cardJSON);
  }

  closePopover() {}
}
