/* import __COLOCATED_TEMPLATE__ from './metric-card.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Blocks: {
    default: [];
  };
  Args: {
    app: any;
    title: string;
    tooltipContent?: string;
    route: string;
    trackAnalyticsEvent: string;
    buttonLabel: string;
  };
}

const MetricCardComponent = templateOnlyComponent<Signature>();
export default MetricCardComponent;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::MetricCard': typeof MetricCardComponent;
  }
}
