/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { inject as service } from '@ember/service';
import { setDiff, filter } from '@ember/object/computed';
import { computed } from '@ember/object';

export default Component.extend({
  tagName: '',
  store: service(),
  teams: [],
  selectedTeams: filter('teams', ['selectedTeamIds.[]'], function (team) {
    return this.selectedTeamIds.includes(team.id);
  }),
  selectableTeams: setDiff('teams', 'selectedTeams'),
  groupList: computed('selectableTeams', function () {
    let teamItems = this.selectableTeams.map((team) => {
      return {
        text: team.name,
        value: team.id,
        component: 'office-hours/team-selector-dropdown-item',
        componentAttrs: {
          emoji: team.emoji,
          scheduleName: team.get('currentSchedule.name'),
        },
      };
    });
    return [{ isFilterable: true, items: teamItems }];
  }),
  actions: {
    onSelectTeam(teamId) {
      // add team id to selected schedule
      let newSelectedTeamIds = this.selectedTeamIds.slice(0);
      newSelectedTeamIds.pushObject(teamId);
      this.set('selectedTeamIds', newSelectedTeamIds);
    },
    onRemoveTeam(teamId) {
      let newSelectedTeamIds = this.selectedTeamIds.slice(0);
      newSelectedTeamIds.removeObject(teamId);
      this.set('selectedTeamIds', newSelectedTeamIds);
    },
  },
});
