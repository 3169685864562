/* import __COLOCATED_TEMPLATE__ from './let-fin-collect-additional-context.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { type FinSetupBehaviorSaveParams } from 'embercom/lib/operator/fin/types';
import type Profile from 'embercom/models/ai-agent/profile';
import { inject as service } from '@ember/service';

interface Signature {
  Args: {
    accordion: $TSFixMe;
    ruleset: $TSFixMe;
    partialSave: (params: FinSetupBehaviorSaveParams) => void;
  };
}

export default class LetFinCollectAdditionalContext extends Component<Signature> {
  @service declare appService: $TSFixMe;

  get profile(): Profile {
    return this.args.ruleset.rulesetLinks.firstObject.object;
  }

  @action
  async updatePreHandover() {
    let pre_handover_settings = {
      enabled: this.profile.preHandoverAnswerEnabled,
    };

    this.args.partialSave({ pre_handover_settings });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Sections::LetFinCollectAdditionalContext': typeof LetFinCollectAdditionalContext;
    'operator/fin/setup/sections/let-fin-collect-additional-context': typeof LetFinCollectAdditionalContext;
  }
}
