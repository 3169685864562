/* import __COLOCATED_TEMPLATE__ from './font-size-input.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class FontSizeInput extends Component {
  @tracked fontSize;

  constructor() {
    super(...arguments);
    this.initialize();
  }

  checkMinFontSizeLimit() {
    if (this.fontSize < 8) {
      this.fontSize = 8;
    }
  }

  checkMaxFontSizeLimit() {
    if (this.fontSize > 48) {
      this.fontSize = 48;
    }
  }

  @action
  initialize() {
    this.fontSize = this.args.fontSize;
  }

  @action
  onChange() {
    this.checkMinFontSizeLimit();
    this.checkMaxFontSizeLimit();
    this.args.onChange(this.fontSize);
  }
}
