/* import __COLOCATED_TEMPLATE__ from './add-fin-content-button.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { EntityType } from 'embercom/models/data/entity-types';
import type Router from '@ember/routing/router-service';
import type Session from 'embercom/services/session';

interface Signature {
  Element: HTMLButtonElement;
  Args: AddFinContentButtonArgs;
}

export interface AddFinContentButtonArgs {
  reloadModel: () => void;
}

export default class AddFinContentButton extends Component<Signature> {
  @service declare router: Router;
  @service declare session: Session;
  @service declare appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;

  @tracked showFinContentModal = false;
  @tracked showFileUploadModal = false;
  @tracked showUrlSourceModal = false;

  externalContentEntityType: EntityType = EntityType.ExternalContent;
  snippetEntityType: EntityType = EntityType.ContentSnippet;
  articleEntityType: EntityType = EntityType.ArticleContent;
  fileEntityType: EntityType = EntityType.FileSourceContent;

  get app() {
    return this.appService.app;
  }

  @action openFinContentModal(): void {
    this.showFinContentModal = true;
    this.trackAnalyticsEvent('clicked', 'add_content_button');
  }

  @action handleModalBackBtnClick(): void {
    this.showFinContentModal = true;
    this.showFileUploadModal = false;
    this.showUrlSourceModal = false;
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      place: 'overview_optimize',
      section: 'ai_chatbot',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Optimize::AddFinContentButton': typeof AddFinContentButton;
  }
}
