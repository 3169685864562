/* import __COLOCATED_TEMPLATE__ from './source-pill.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import type IntlService from 'embercom/services/intl';
//@ts-ignore
import { sanitizeUrl } from '@intercom/pulse/lib/sanitize';

interface Args {
  sourceId: number | undefined;
}

export default class SourcePill extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;

  get sourceLabel(): string {
    if (!this.source) {
      return this.intl.t('operator.external-content.source.unknown');
    }
    let sanitizedUrl = sanitizeUrl(this.source.url);
    return sanitizedUrl.string.replace(/^http(?:s?):\/\//, '');
  }

  private get source(): ContentImportSource | null {
    if (!this.args.sourceId) {
      return null;
    }
    return this.store.peekRecord('content-service/content-import-source', this.args.sourceId);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::ExternalContent::Sources::SourcePill': typeof SourcePill;
  }
}
