/* import __COLOCATED_TEMPLATE__ from './statistic-cell.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import { inject as service } from '@ember/service';
import { isEmpty, isPresent } from '@ember/utils';
import Component from '@glimmer/component';
import { characterIndex } from 'embercom/helpers/character-index';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';
import {
  contentWrapperTypes,
  statisticKeys,
  statisticTypes,
} from 'embercom/models/data/outbound/constants';
import { stats } from 'embercom/models/data/stats-system/stats-constants';
import _ from 'underscore';

export default class StatisticCell extends Component {
  @service appService;

  get statistic() {
    if (this.contentTypeUsesFailure) {
      return this._failureStats;
    }
    return this.args.content.stats.find((stat) => stat.key === this.args.key);
  }

  get contentTypeUsesFailure() {
    return (
      this.contentTypesWithFailureCategory.has(this.args.content.contentType) &&
      this.args.key === statisticKeys.failures &&
      isPresent(this.args.failureCategory)
    );
  }

  get contentTypesWithFailureCategory() {
    return new Set([objectTypes.email, objectTypes.sms]);
  }

  get statisticTypes() {
    return statisticTypes;
  }

  get statisticCellId() {
    return this.args.key + this._versionQueryParam;
  }

  get colorClass() {
    if (this.args.noColor) {
      return '';
    }
    let colorMap = {
      [statisticKeys.receipts]: 'outbound__recipients-stat',
      [statisticKeys.clicks]: 'outbound__clicks-stat',
      [statisticKeys.goals]: 'outbound__goal-stat',
      [statisticKeys.replies]: 'outbound__replies-stat',
      [statisticKeys.opens]: 'outbound__opens-stat',
    };
    return colorMap[this.args.key];
  }
  get _versionQueryParam() {
    return characterIndex([this.args.index]);
  }

  get _viewingQueryParam() {
    let viewingParams = {
      [statisticKeys.receipts]: 'sent',
      [statisticKeys.clicks]: 'clicked',
      [statisticKeys.goals]: 'goal',
      [statisticKeys.replies]: 'conversations',
      [statisticKeys.opens]: 'opened',
      [statisticKeys.emailFailures]: 'failed',
    };
    return viewingParams[this.args.key];
  }

  get _repliesOrReactionStats() {
    let repliesStats = this.args.content.stats.find((stat) => stat.key === statisticKeys.replies);
    let reactionStats = this.args.content.stats.find(
      (stat) => stat.key === statisticKeys.reactions,
    );
    return {
      id: repliesStats.id,
      denominator: repliesStats.denominator,
      value: reactionStats.value + repliesStats.value,
      type: repliesStats.type,
      key: repliesStats.key,
    };
  }

  get _failureStats() {
    let failureStat = this.args.content.stats.find(
      (stat) => stat.key === statisticKeys.emailFailures,
    );

    let failureCategoryStatCount;
    if (!_.isEmpty(failureStat.subaggregations)) {
      let failureCategoryStat = failureStat.subaggregations.find(
        (subagg) => subagg.value === this.args.failureCategory,
      );

      failureCategoryStatCount = failureCategoryStat?.count;
    }

    return {
      id: failureStat.id,
      denominator: failureStat.denominator,
      value: failureCategoryStatCount || 0,
      type: failureStat.type,
      key: failureStat.key,
    };
  }

  get _getViewKey() {
    let reactionStats = this.args.content.stats.find(
      (stat) => stat.key === statisticKeys.reactions,
    );
    if (this.args.key === statisticKeys.replies && reactionStats && reactionStats.value) {
      return statisticKeys.reactions;
    } else {
      return this.args.key;
    }
  }

  get queryParams() {
    let contentType = this.args.content.contentType;
    if (contentType >= 12 && contentType <= 15) {
      return {
        viewing: this._optionalViewingQueryParam,
        version: this._versionQueryParam,
      };
    } else if (contentType === objectTypes.tour) {
      let statParam = {
        [statisticKeys.receipts]: stats.receipt,
        [statisticKeys.completions]: stats.completion,
        [statisticKeys.goals]: stats.goalSuccess,
        [statisticKeys.tourStepFailures]: stats.tourStepFailure,
      };
      return {
        stat: statParam[this.args.key],
      };
    } else if (this.args.contentWrapper?.relations?.series) {
      let contentEditorView = Object.keys(statisticKeys).find(
        (key) => statisticKeys[key] === this._getViewKey,
      );
      return {
        test: this.args.content.rulesetLinkId,
        contentEditorView,
        view: 'overview',
      };
    } else {
      let view = Object.keys(statisticKeys).find((key) => statisticKeys[key] === this._getViewKey);
      return {
        test: this.args.content.rulesetLinkId,
        view,
      };
    }
  }

  get sentStatistic() {
    return this.args.content.stats.find((stat) => stat.key === statisticKeys.receipts);
  }

  get _optionalViewingQueryParam() {
    return this.sentStatistic.value === 0 ? null : this._viewingQueryParam;
  }

  get hasSent() {
    return !isEmpty(this.sentStatistic?.value);
  }

  get shouldLinkStat() {
    if (this.args.shouldLinkStat !== undefined) {
      return this.args.shouldLinkStat;
    }

    if (!this.hasSent) {
      return false;
    }

    if (this.args.contentWrapper?.contentWrapperType === contentWrapperTypes.selectionSet) {
      return this.args.key !== statisticKeys.goals;
    }

    return true;
  }
}
