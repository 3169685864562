/* import __COLOCATED_TEMPLATE__ from './conversation-history.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { enqueueTask } from 'ember-concurrency-decorators';
import moment from 'moment-timezone';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';

const SETTING_DEFAULT_BACKFILL_DATE = moment('2022-10-20T02:10:00.000Z');

interface Args {
  settings: $TSFixMe;
}

export default class ConversationHistory extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intercomEventService: $TSFixMe;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.trackOpenAnalytics();
  }

  @enqueueTask
  *updateSetting() {
    try {
      yield this.args.settings.save();

      this.trackUpdateAnalytics();

      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.args.settings.toggleProperty('removeConversationHistory');
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  toggleRemoveConversationHistory() {
    this.args.settings.toggleProperty('removeConversationHistory');
    taskFor(this.updateSetting).perform();
  }

  trackOpenAnalytics() {
    let appDefault = moment(this.appService.app.created_at).isAfter(SETTING_DEFAULT_BACKFILL_DATE);

    this.trackAnalytics('opened', appDefault);
  }

  trackUpdateAnalytics() {
    let action = this.args.settings.removeConversationHistory ? 'turned_on' : 'turned_off';

    let appDefault = moment(this.appService.app.created_at).isAfter(SETTING_DEFAULT_BACKFILL_DATE);

    this.trackAnalytics(action, appDefault);
  }

  trackAnalytics(action: string, appDefault: boolean) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object: 'email_remove_conversation_history_settings',
      app_default: appDefault,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::ConversationHistory': typeof ConversationHistory;
  }
}
