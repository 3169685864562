/* import __COLOCATED_TEMPLATE__ from './sections-accordion.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
  type SectionName,
  type Sections,
} from 'embercom/components/new-settings/data/imports-exports/import-zendesk/config-import';

export interface Args {
  integrationCode: string;
  toggleSectionActive: (section: SectionName) => void;
  sections: Sections;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class SectionsAccordion extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare intercomEventService: $TSFixMe;

  @tracked currentOpenSectionId: null | SectionName = null;

  constructor(owner: unknown, args: any) {
    super(owner, args);
  }

  get openSectionId() {
    if (
      this.currentOpenSectionId !== null &&
      !this.args.sections[this.currentOpenSectionId].active
    ) {
      return undefined;
    }
    return this.currentOpenSectionId;
  }

  @action onOpenSectionChange(sectionId: null | SectionName) {
    this.currentOpenSectionId = sectionId;
  }

  @action toggleSectionActive(section: SectionName) {
    if (this.currentOpenSectionId === section) {
      this.currentOpenSectionId = null;
    }

    this.args.toggleSectionActive(section);
  }

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.sections-accordion.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::SectionsAccordion': typeof SectionsAccordion;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections-accordion': typeof SectionsAccordion;
  }
}
