/* import __COLOCATED_TEMPLATE__ from './series-filter.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { capitalize } from '@ember/string';

const SERIES_DROPDOWN = {
  inSeries: 'in-series',
  notInSeries: 'not-in-series',
};
const inSeries = 'inSeries';
const notInSeries = 'notInSeries';

export default class SeriesFilter extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get isOpen() {
    return this.args.activeFilter === 'series';
  }

  get seriesValue() {
    if (this.args.hideSeries) {
      return notInSeries;
    } else if (this.args.hideSeries === false) {
      return inSeries;
    }
  }

  get seriesFilterName() {
    if (this.args.hideSeries) {
      return `${this.intl.t('outbound.series.filters.message')} ${this.intl.t(
        'outbound.series.filters.not-in-series',
      )}`;
    } else if (this.args.hideSeries === false) {
      return `${this.intl.t('outbound.series.filters.message')} ${this.intl.t(
        'outbound.series.filters.in-series',
      )}`;
    }
    return this.intl.t('outbound.all.filters.series');
  }

  get seriesFilterDropdown() {
    return Object.keys(SERIES_DROPDOWN).map((name) => ({
      text: capitalize(this.intl.t('outbound.series.filters.'.concat(SERIES_DROPDOWN[name]))),
      value: name,
    }));
  }

  @action
  filterSeriesMessages(value) {
    this.args.filterContent('hideSeries', value === notInSeries);
  }

  @action removeFilter() {
    this.args.filterContent('hideSeries', null);
    this.args.removeFromActiveFilter('series');
  }
}
