/* import __COLOCATED_TEMPLATE__ from './ew-chatbot-fin-fallback.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type TaskGenerator } from 'ember-concurrency';
import { dropTask } from 'ember-concurrency-decorators';
import { perform } from 'ember-concurrency-ts';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import LoadFinProfileModule from 'embercom/lib/home/load-fin-profile';
import { type StepData } from '../../wizard';
import ajax from 'embercom/lib/ajax';
import type ContentImportService from 'embercom/services/content-import-service';

interface Signature {
  Element: never;
  Args: {
    step: any;
    stepData: StepData;
  };
}

interface SideBySideConfiguration {
  activeTool: any;
  id: string;
  zendeskConfiguration: any;
}

enum FallbackOptions {
  pass_to_team = 'pass_to_team',
  hand_off = 'hand_off',
  close_conversation = 'close_conversation',
}

export default class EwChatbotFinFallback extends Component<Signature> {
  @service declare store: Store;
  @service declare appService: any;
  @service declare intl: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare contentImportService: ContentImportService;

  @tracked selectedFallbackOption: FallbackOptions | null = null;
  @tracked ruleset: any = null;
  @tracked sideBySideConfiguration: SideBySideConfiguration | null = null;
  @tracked disableAllContinueButtons = false;

  localStorageKey = '';

  constructor(owner: unknown, args: any) {
    super(owner, args);
    perform(this.loadSideBySideConfiguration);
    perform(this.loadFinProfile);
    this.localStorageKey = `onboardingHomeRouteConversationToHumanCompletedKey_${this.app.id}`;
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'fin_fallback_step',
      context: `onboarding_guide_library.wizard.ai_bot.fin_fallback`,
      place: 'ai_bot_wizard',
      section: 'ai_bot_fin_fallback',
    });
  }

  get passToTeamIsSelected(): boolean {
    return this.selectedFallbackOption === FallbackOptions.pass_to_team;
  }

  get handOffIsSelected(): boolean {
    return this.selectedFallbackOption === FallbackOptions.hand_off;
  }

  get closeConversationIsSelected(): boolean {
    return this.selectedFallbackOption === FallbackOptions.close_conversation;
  }

  get resolutionBotBehaviour(): any {
    return this.ruleset?.rulesetLinks?.firstObject.object;
  }

  get app(): any {
    return this.appService.app;
  }

  get isPassToInboxSelectedInRuleset(): boolean {
    return this.resolutionBotBehaviour?.botMode === 'looping' && !this.isHandOffSelectedInRuleset;
  }

  get isHandOffSelectedInRuleset(): boolean {
    return this.sideBySideConfiguration?.activeTool === 'zendesk';
  }

  get isCloseConversationSelectedInRuleset(): boolean {
    return this.resolutionBotBehaviour?.botMode === 'bot_only';
  }

  get defaultLocale() {
    return this.app.locale || 'en';
  }

  get hasAtleastOneSuccessfulImport(): boolean {
    return Boolean(this.contentImportService.successfulRuns.length > 0);
  }

  @dropTask
  *loadSideBySideConfiguration(): TaskGenerator<void> {
    this.sideBySideConfiguration = yield this.store.findRecord(
      'side-by-side/handover/configuration',
      this.app.id,
    );
  }

  @dropTask
  *loadFinProfile(): TaskGenerator<void> {
    if (!this.ruleset) {
      this.ruleset = yield LoadFinProfileModule.loadFinProfile(this);
      if (!this.resolutionBotBehaviour?.useAiAnswers) {
        this.resolutionBotBehaviour.useAiAnswers = true;
        this.resolutionBotBehaviour.botMode = 'looping';
        yield this.ruleset.save();
      }
      if (this.resolutionBotBehaviour.botMode === 'one_time') {
        this.resolutionBotBehaviour.botMode = 'bot_only';
      }
      if (this.resolutionBotBehaviour.botMode === 'bot_only') {
        this.populateCloseConversationDefaultMessage();
      }
    }

    if (this.selectedFallbackOption === null) {
      if (this.isHandOffSelectedInRuleset) {
        this.selectedFallbackOption = FallbackOptions.hand_off;
      } else if (this.isPassToInboxSelectedInRuleset) {
        this.selectedFallbackOption = FallbackOptions.pass_to_team;
      } else {
        this.selectedFallbackOption = FallbackOptions.close_conversation;
      }
    }
    if (this.args.step.completed) {
      let completedKey = localStorage.getItem(this.localStorageKey);
      if (!completedKey) {
        localStorage.setItem(this.localStorageKey, this.selectedFallbackOption);
      }
    }
  }

  @action
  triggerSaveResolutionChoice() {
    if (this.resolutionBotBehaviour) {
      if (this.selectedFallbackOption === FallbackOptions.pass_to_team) {
        this.resolutionBotBehaviour.botMode = 'looping';
      } else if (this.selectedFallbackOption === FallbackOptions.close_conversation) {
        this.resolutionBotBehaviour.botMode = 'bot_only';
      }
      this.disableAllContinueButtons = true;
      this.saveRuleset();
      if (
        this.sideBySideConfiguration?.activeTool &&
        this.selectedFallbackOption !== FallbackOptions.hand_off
      ) {
        this.deleteSideBySideConfiguration();
      }
    }
  }

  @action
  setFallbackOption(data: FallbackOptions) {
    this.selectedFallbackOption = data;
    if (
      this.selectedFallbackOption === FallbackOptions.close_conversation &&
      this.isContentEmpty()
    ) {
      this.populateCloseConversationDefaultMessage();
    }
  }

  @action
  async saveRuleset() {
    try {
      await this.ruleset.save();

      let selection;
      if (this.passToTeamIsSelected) {
        selection = 'route_to_inbox';
      } else if (this.closeConversationIsSelected) {
        selection = 'close_conversation';
      } else {
        selection = 'hand_off_to_zendesk';
      }
      this.intercomEventService.trackAnalyticsEvent({
        action: 'saved',
        object: `fin_fallback.${selection}`,
        place: 'ai_bot_wizard',
        context: 'onboarding_guide_library.wizard.ai_bot.fin_fallback',
        section: 'ai_bot_fin_fallback',
      });
      this.disableAllContinueButtons = false;

      if (
        (this.passToTeamIsSelected || this.closeConversationIsSelected) &&
        this.sideBySideConfiguration !== null
      ) {
        this.sideBySideConfiguration.activeTool = null;
      }
      this.markAsCompleted();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.route-conversation-to-human.error-saving'),
      });
    }
  }

  @action
  markAsCompleted() {
    if (this.selectedFallbackOption) {
      localStorage.setItem(this.localStorageKey, this.selectedFallbackOption.toString());
    }
    if (this.hasAtleastOneSuccessfulImport) {
      this.args.stepData.continueCallback();
    } else {
      this.args.stepData.completeAndExitToGuideLibraryCallback();
    }
  }

  private populateCloseConversationDefaultMessage() {
    this.resolutionBotBehaviour?.botOnlyMessageLocalizations?.forEach((messages: any) => {
      if (messages?.parts.length === 0) {
        messages?.parts.createFragment();
      }
      if (messages?.parts.firstObject.blocks.length === 0) {
        messages.parts.firstObject.blocks = [
          createFragmentFromBlock(this.store, {
            text: this.intl.t(
              'onboarding.home.steps.route-conversation-to-human.close-the-conversation-default-message',
              {
                htmlSafe: true,
              },
            ),
            type: 'paragraph',
          }),
        ];
      }
    });
  }

  private async deleteSideBySideConfiguration() {
    try {
      await ajax({
        url: `/ember/side_by_side/handover/configurations/${this.app.id}`,
        type: 'DELETE',
        data: JSON.stringify({
          app_id: this.app.id,
          subdomain: this.sideBySideConfiguration?.zendeskConfiguration?.subdomain,
        }),
      });

      this.sideBySideConfiguration = null;
      let recordToDelete = this.store.peekRecord(
        'side-by-side/handover/configuration',
        this.app.id,
      );
      this.store.unloadRecord(recordToDelete);
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.route-conversation-to-human.error-saving'),
      });
    }
  }

  private isContentEmpty() {
    let currentMessage = this.resolutionBotBehaviour?.botOnlyMessageLocalizations?.filter(
      (messages: any) => messages.locale === this.defaultLocale,
    );
    let isContentEmpty =
      currentMessage?.firstObject?.parts?.length === 0 ||
      currentMessage?.firstObject?.parts?.firstObject?.blocks.length === 0 ||
      currentMessage?.firstObject?.parts?.firstObject?.blocks?.firstObject.text === '';
    return isContentEmpty;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::EwChatbotFinFallback': typeof EwChatbotFinFallback;
  }
}
