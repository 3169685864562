/* import __COLOCATED_TEMPLATE__ from './step-available.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  router: service(),
  session: service(),

  isInFinPhase2OnboardingExperience: readOnly('guideIdentifier'),

  goToHome: action(function () {
    this.trackBannerClick('clicked', 'step_available_banner');
    if (this.session.isWorkspaceLoaded) {
      return this.router.transitionTo('apps.app.home', this.session.workspace.id, {
        queryParams: { step: this.stepIdentifier },
      });
    }

    return this.router.transitionTo('apps.app.home', {
      queryParams: { step: this.stepIdentifier },
    });
  }),

  actions: {
    dismissBanner() {
      this.trackBannerClick('dismissed', 'step_available_banner');
      this.dismissBannerTask.perform();
    },
  },
});
