/* import __COLOCATED_TEMPLATE__ from './insertable-cell.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';

export default class InsertableCell extends Component {
  get contents() {
    return this.args.contents;
  }

  get showAutomatically() {
    return this.contents.every((content) => content.showAutomatically);
  }

  get insertable() {
    return this.contents.every((content) => content.insertable);
  }

  get triggerable() {
    return this.contents.every((content) => content.triggerable);
  }
}
