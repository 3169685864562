/* import __COLOCATED_TEMPLATE__ from './progress-bar.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import Component from '@glimmer/component';

interface Args {
  totalSteps: number;
  currentStep: number;
}

interface Signature {
  Element: never;
  Args: Args;
}

export default class ProgressBar extends Component<Signature> {
  get stepCounts(): Array<string> {
    return new Array(this.args.totalSteps).fill('active', 0, this.args.currentStep);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::ProfilingSurvey::ProgressBar': typeof ProgressBar;
  }
}
