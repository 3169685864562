/* import __COLOCATED_TEMPLATE__ from './customize-messenger-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';
import { findByProperty } from '@intercom/pulse/lib/computed-properties';
import { dropTask } from 'ember-concurrency-decorators';
import { loadMessengerSettings } from 'embercom/lib/home/load-messenger-settings';

export default class CustomizeMessengerComponent extends Component {
  @service store;
  @service appService;
  @service notificationsService;
  @service intl;

  colorOptions = DEFAULT_MESSENGER_COLORS;

  get app() {
    return this.appService.app;
  }

  get messengerSettings() {
    let messengerSettingsFromModel = this.args.step.modelData?.messengerSettings;
    if (messengerSettingsFromModel) {
      return messengerSettingsFromModel;
    }
    return loadMessengerSettings(this);
  }

  get lookAndFeelSettings() {
    return this.messengerSettings.lookAndFeel;
  }

  get languageSettings() {
    return this.messengerSettings.languages;
  }

  get adminCanChangeMessengerSettings() {
    return this.app.currentAdmin.currentAppPermissions.can_manage_messenger_settings;
  }

  get settingsAreDirty() {
    return this.lookAndFeelSettings.hasDirtyAttributes || this.languageSettings.hasDirtyAttributes;
  }

  get isSaveEnabled() {
    if (!this.adminCanChangeMessengerSettings) {
      return false;
    }

    if (!(this.languageSettings.isValid && this.lookAndFeelSettings.isValid)) {
      return false;
    }

    return this.args.step.available || this.settingsAreDirty;
  }

  get defaultLocaleObject() {
    return findByProperty(
      'languageSettings.supportedLocales',
      'localeId',
      'languageSettings.defaultLocale',
    );
  }

  @dropTask
  *completeStep() {
    yield this.saveMessengerChanges.perform();
    if (this.args.step.completed) {
      return this.args.completionCallback({
        stepCompletedText: this.intl.t(
          'onboarding.home.steps.customize-messenger-component.settings-have-been-saved',
        ),
      });
    }

    yield this.args.completionCallback();
  }

  @dropTask
  *saveMessengerChanges() {
    try {
      this.lookAndFeelSettings.expandColors();
      yield this.lookAndFeelSettings.save();
      yield this.languageSettings.save();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.customize-messenger-component.default-error'),
      });
    }
  }

  @action
  setSecondaryColor(color) {
    this.lookAndFeelSettings.secondaryColor = color;
    if (this.lookAndFeelSettings.activeBrand) {
      this.lookAndFeelSettings.activeBrand.messengerBackgroundColor = color;
    }
  }

  @action
  setPrimaryColor(color) {
    this.lookAndFeelSettings.primaryColor = color;
    if (this.lookAndFeelSettings.activeBrand) {
      this.lookAndFeelSettings.activeBrand.messengerActionColor = color;
    }
  }
}
