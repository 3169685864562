/* import __COLOCATED_TEMPLATE__ from './email-verification.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { cached } from 'tracked-toolbox';
import { AsyncData } from 'embercom/resources/utils/async-data';
import type Store from '@ember-data/store';
import { use } from 'ember-resources/util/function-resource';
import ajax from 'embercom/lib/ajax';
import type IntlService from 'ember-intl/services/intl';
import { type DomainEmailAddress } from './tab';

interface Args {
  customSenderAddresses: DomainEmailAddress[];
  domainName: string;
}

export default class EmailVerification extends Component<Args> {
  @service appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service notificationsService: $TSFixMe;
  @service declare intl: IntlService;
  @service declare store: Store;

  @tracked showAddCustomSenderModal = false;

  get app() {
    return this.appService.app;
  }

  get hasPermissionForCustomAddresses() {
    return this.app.currentAdmin.currentAppPermissions.can_send_from_custom_addresses;
  }

  @cached
  get entities() {
    return this.args.customSenderAddresses.map((address) => ({
      text: address.email,
      value: address.id || address.senderEmailAddressSettings?.id,
    }));
  }

  @use
  selectedEmailIdLoader = AsyncData<string>(() => {
    return this.entities.length ? this.entities[0].value : '';
  });

  @action
  onEmailChange(emailId: string) {
    this.selectedEmailIdLoader.update(emailId);
  }

  @action
  toggleDisplayCustomSenderModal() {
    this.showAddCustomSenderModal = !this.showAddCustomSenderModal;
    if (this.showAddCustomSenderModal) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'create_custom_sender_address',
        context: 'from_domains_page',
      });
    }
  }

  @action
  async resendVerificationLink() {
    try {
      let customAddress = this.domainEmailAddressFromLoader();
      // if the address is a company address, we need to create a new sender email address settings record
      // otherwise we can just resend the verification link from the existing settings record
      // this is because company addresses don't have their own settings record
      // creating a new settings record automatically sends a verification email
      if (customAddress && this.isCompanyEmailAddress(customAddress)) {
        await this.createSenderEmailAddressSettings(customAddress.email);
      } else {
        await ajax({
          url: `/ember/sender_email_address_settings/${this.selectedEmailIdLoader.value}/resend_verification_email`,
          type: 'POST',
          data: JSON.stringify({
            app_id: this.appService.app.id,
            admin_id: this.appService.app.currentAdmin.id,
          }),
        });
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t('settings.resend-verification-email.success'),
      );
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('settings.resend-verification-email.error'),
      );
    }
  }

  private async createSenderEmailAddressSettings(email: string) {
    let settings = this.store.createRecord('sender-email-address-settings', {
      email,
      senderEmailAddresses: [],
    });
    await settings.save();
    return settings;
  }

  private domainEmailAddressFromLoader(): DomainEmailAddress | undefined {
    return this.args.customSenderAddresses.find(
      (address) => address.id.toString() === this.selectedEmailIdLoader.value!.toString(),
    );
  }

  private isCompanyEmailAddress(customAddress?: DomainEmailAddress): boolean {
    if (!customAddress) {
      return false;
    }
    return !customAddress?.senderEmailAddressSettings;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Addresses::EmailVerification': typeof EmailVerification;
  }
}
