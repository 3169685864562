/* import __COLOCATED_TEMPLATE__ from './delete-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class DeleteButton extends Component {
  @service notificationsService;

  @action
  async delete() {
    await this.args.optOut.destroyRecord();
    this.notificationsService.notifyConfirmation(`${this.args.optOut.domain} deleted`);
  }
}
