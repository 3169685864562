/* import __COLOCATED_TEMPLATE__ from './sender-domain.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type Owner from '@ember/owner';

interface Args {}

export default class SenderDomain extends Component<Args> {
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare intl: $TSFixMe;

  @tracked senderDomainValue = false;
  @tracked replyAddressValue = '';

  constructor(owner: Owner, args: Args) {
    super(owner, args);

    this.senderDomainValue = this.app.use_admin_app_email;
    if (this.senderDomainValue) {
      this.replyAddressValue = this.app.admin_reply_default_address_id ? 'inbound' : 'teammate';
    }
  }

  get app() {
    return this.appService.app;
  }

  get isSenderDomainDisabled() {
    return this.verifiedAdminEmailDomains.length === 0;
  }

  get isReplyAddressDisabled() {
    return !this.senderDomainValue;
  }

  get isReplyAddressInboundDisabled() {
    return this.verifiedCustomEmailAddresses.length === 0;
  }

  get verifiedAdminEmailDomains() {
    // This logic is lifted straight from `app/components/settings/senders/default-sender-address.js`.
    // It would probably be better to extract out the grouping logic from
    // `app/components/new-settings/channels/email/addresses/tab.ts` into a helper file, and import it here.
    let verifiedCustomBounceDomains = this.app.customBounceSettings.filter(
      (settings: $TSFixMe) => settings.validRecordExists,
    );
    return this.app.adminEmailDKIMSettings.filter(
      (dkimSetting: $TSFixMe) =>
        dkimSetting.validRecordExists &&
        verifiedCustomBounceDomains.find(
          (customDomain: $TSFixMe) => customDomain.rootDomain === dkimSetting.domain,
        ),
    );
  }

  get verifiedCustomEmailAddresses() {
    return this.app.customEmailAddresses.filter((address: $TSFixMe) => address.verified);
  }

  @action
  async handleClickSenderDomain() {
    this.senderDomainValue = !this.senderDomainValue;
    if (this.senderDomainValue) {
      this.replyAddressValue = 'teammate';
    } else {
      this.replyAddressValue = '';
    }
    await this.update();
  }

  @action
  async handleClickTeammate() {
    this.replyAddressValue = 'teammate';
    await this.update();
  }

  @action
  async handleClickInbound() {
    this.replyAddressValue = 'inbound';
    await this.update();
  }

  async update() {
    try {
      await this.app.updateDefaultEmailSenderSetting(
        this.senderDomainValue,
        this.replyAddressValue === 'inbound' ? this.verifiedCustomEmailAddresses[0].id : '',
      );
    } catch (error) {
      console.error('update error', error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'new-settings.channels.email.customisation.sender-domain.update-error',
        ),
      });
      return;
    }
    this.notificationsService.notifyConfirmation(
      this.intl.t('new-settings.channels.email.customisation.sender-domain.update-success'),
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::SenderDomain': typeof SenderDomain;
  }
}
