/* import __COLOCATED_TEMPLATE__ from './prosemirror-email-template.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import AttributeInfoResolver, {
  EMAIL_TEMPLATE_ATTRIBUTES,
} from 'embercom/lib/common/template-attribute-resolver';
import baseTemplate from 'embercom/templates-raw/email-templates/email-templates-base';
import foundationStylesTemplate from 'embercom/templates-raw/email-templates/partials/foundations-styles';
import { substituteValues } from 'embercom/lib/outbound/email-template-helper';
import emailStyles from 'embercom/lib/outbound/email-styles-helper';
import { SECTION_TYPE } from '@intercom/interblocks.ts';
import EmailTemplateComposerConfig from 'embercom/lib/outbound/email-template-composer-config';

const templateContentSectionWithContentTag = {
  type: 'table',
  container: true,
  sectionType: SECTION_TYPE.content.toString(),
  rows: [
    {
      cells: [
        {
          content: [{ type: 'paragraph', text: '{{content}}' }],
        },
      ],
    },
  ],
};

export default class ProsemirrorEmailTemplateComposer extends Component {
  templateBlocksDoc;
  populatedBlocks;

  @service appService;
  @service attributeService;
  @service intercomEventService;

  constructor() {
    super(...arguments);
    let blocks = this.args.template.blocks;

    let headers = this.getSectionsByType(blocks, SECTION_TYPE.header);
    let contentBlocks = this.args.template.contentBlocks;
    let footers = this.getSectionsByType(blocks, SECTION_TYPE.footer);

    this.populatedBlocks = [...headers, ...contentBlocks, ...footers];

    this.templateBlocksDoc = new BlocksDocument([...headers, ...contentBlocks, ...footers]);
  }

  get app() {
    return this.appService.app;
  }

  get emailTemplate() {
    return substituteValues(baseTemplate({}), {
      foundationStyles: foundationStylesTemplate,
    });
  }

  get templateComposerConfig() {
    return new EmailTemplateComposerConfig(this.app, this.intercomEventService, this.resolver);
  }

  get resolver() {
    return new AttributeInfoResolver({
      attributes: this.attributeService.userEditorAndMetadataAttributes,
      includeAppAttributes: true,
      manualAppAttributes: EMAIL_TEMPLATE_ATTRIBUTES,
    });
  }

  get messageLevelStyles() {
    return emailStyles({ template: this.args.template });
  }

  @action
  updateTemplatesBlocks(blocksDoc) {
    let blocks = blocksDoc.blocks;
    this.args.template.contentBlocks = this.getSectionsByType(blocks, SECTION_TYPE.content);
    let headers = this.getSectionsByType(blocks, SECTION_TYPE.header);
    let footers = this.getSectionsByType(blocks, SECTION_TYPE.footer);
    this.args.template.blocks = [...headers, templateContentSectionWithContentTag, ...footers];
  }

  getSectionsByType(blocks, sectionType) {
    return blocks.filter((block) => {
      return block.sectionType === sectionType.toString();
    });
  }
}
