/* import __COLOCATED_TEMPLATE__ from './level-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import confetti from '@intercom/interconfetti';
import ScrollToLevelUtils from 'embercom/lib/home/scroll-to-level';
import ENV from 'embercom/config/environment';
import { FIN_GENERAL_RELEASE } from '../../../lib/home/guides';
import { timeout } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { ref } from 'ember-ref-bucket';

const CONFETTI_CONFIG = {
  particleCount: 150,
  spread: 100,
  ticks: 1000,
  origin: {
    y: 0.7,
  },
};

const ANIMATION_DELAY = {
  afterConfetti: 1900,
  afterScript: ENV.APP._300MS,
  beforeScript: ENV.APP._200MS,
  accordionSection: ENV.APP._500MS,
};

const FIN_LEVEL_TWO_IDENTIFIER = 'get_started_fin_general_release';

export default class LevelComponent extends Component {
  @service notificationsService;
  @service guideAssistantService;
  @service onboardingHomeService;
  @service intercomEventService;
  @service appService;
  @service store;
  @service router;

  @tracked initiallyOpenStep;
  @ref('confetti-canvas') confettiCanvas;

  constructor() {
    super(...arguments);
    this.initiallyOpenStep = this.args.initiallyOpenStep;
  }

  get app() {
    return this.appService.app;
  }

  get guide() {
    return this.onboardingHomeService.guide;
  }

  get latestAvailableLevel() {
    return this.guide.latestAvailableLevel;
  }

  get identifier() {
    return this.args.level.identifier;
  }

  get levelData() {
    return this.args.level.levelData;
  }

  get icon() {
    return this.args.level.icon;
  }

  get latestAvailableStepIdentifier() {
    return this.args.level.allStepsComplete
      ? null
      : this.args.level.availableSteps.firstObject.identifier;
  }

  get showTimeToComplete() {
    return this.guide.identifier === FIN_GENERAL_RELEASE
      ? false
      : this.levelData?.showTimeToComplete;
  }

  get isFinGeneralReleaseFinLevel() {
    this.guide.identifier === FIN_GENERAL_RELEASE && this.identifier === FIN_LEVEL_TWO_IDENTIFIER;
  }

  get initiallyOpenStepInLevel() {
    return this.initiallyOpenStep?.level.get('identifier') === this.args.level.identifier;
  }

  get showUnseenBadge() {
    return this.onboardingHomeService.incompleteAndUnseenLevels.includes(this.args.level);
  }

  @dropTask
  *onStepComplete(accordionAPI) {
    yield this.performAnimationsAndCallback.perform(accordionAPI);
  }

  @dropTask
  *performAnimationsAndCallback(accordionAPI) {
    if (this.args.level.allStepsComplete) {
      yield this.levelCompleteAnimation.perform(accordionAPI);
      yield this.nextLevelOpenAnimation.perform(accordionAPI);
    }
    this.args.completionCallback();
  }

  @dropTask
  *levelCompleteAnimation(accordionAPI) {
    yield timeout(ANIMATION_DELAY.beforeScript);
    if (
      this.isFinGuideFirstLevel &&
      window.document.querySelector('#onboarding-home-level-header-0')
    ) {
      window.document
        .querySelector('#onboarding-home-level-header-0')
        .scrollIntoView({ behavior: 'smooth' });
      yield timeout(ANIMATION_DELAY.accordionSection);
    }
    this.guideAssistantService.triggerTransitionalScript();
    yield timeout(ANIMATION_DELAY.afterScript);
    confetti({
      canvas: this.confettiCanvas,
      ...CONFETTI_CONFIG,
    });
    yield timeout(ANIMATION_DELAY.afterConfetti);
    accordionAPI.closeSection(this.identifier);
  }

  @dropTask
  *nextLevelOpenAnimation(accordionAPI) {
    if (this.args.level.nextAvailableLevel) {
      let levelIdentifier = this.args.level.nextAvailableLevel.identifier;
      yield timeout(ANIMATION_DELAY.accordionSection);
      accordionAPI.openSection(levelIdentifier);
      yield ScrollToLevelUtils.scrollToLevel(levelIdentifier, ANIMATION_DELAY.accordionSection);
    }
  }

  @dropTask
  *openStepInDifferentLevel(accordionAPI, stepToOpen) {
    accordionAPI.closeSection(this.identifier);
    yield timeout(ANIMATION_DELAY.accordionSection);
    this.initiallyOpenStep = stepToOpen;
    let levelIdentifier = stepToOpen.level.get('identifier');
    accordionAPI.openSection(levelIdentifier);
    yield ScrollToLevelUtils.scrollToLevel(levelIdentifier, ANIMATION_DELAY.accordionSection);
  }

  @dropTask
  *resetLevel() {
    let stepIdentifiers = this.args.level.steps.mapBy('identifier');
    yield this.onboardingHomeService.resetGuide({
      levels: [this.identifier],
      steps: stepIdentifiers,
    });
  }

  @action
  sectionDidOpen(sectionName) {
    this.router.replaceWith({ queryParams: { step: sectionName } });
    let currentLevelHeaderElement = window.document.querySelector(
      `[data-intercom-target="${this.identifier}"`,
    );

    if (currentLevelHeaderElement) {
      currentLevelHeaderElement.scrollIntoView({
        behavior: 'smooth',
      });
    }

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: sectionName,
      step_identifier: sectionName,
      level_identifier: this.identifier,
      guide_identifier: this.guide.identifier,
      section: 'inline_step',
    });
  }

  @action
  openCustomizeMessengerStep(accordionAPI) {
    let stepToOpen = this.store.peekRecord('onboarding/home/step', 'customize_messenger_exp_one');
    this.openStepInDifferentLevel.perform(accordionAPI, stepToOpen);
  }

  @action
  sectionDidClose(sectionName) {
    this.router.replaceWith({ queryParams: { step: null } });

    if (!this.initiallyOpenStep) {
      return;
    }

    if (sectionName === this.args.initiallyOpenStep.identifier) {
      this.initiallyOpenStep = null;
    }
  }
}
