/* import __COLOCATED_TEMPLATE__ from './match-behavior-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { behaviorMapDynamicFixed } from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class MatchBehaviorComponent extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get isOpen() {
    return this.args.activeFilter === 'match-behavior';
  }

  get matchBehaviors() {
    return Object.keys(behaviorMapDynamicFixed).map((name) => ({
      text: capitalize(name),
      value: name,
    }));
  }

  get selectedMatchBehavior() {
    let behaviorText = Object.keys(behaviorMapDynamicFixed).find(
      (key) =>
        JSON.stringify(behaviorMapDynamicFixed[key]) ===
        JSON.stringify(this.args.selectedMatchBehaviorValues),
    );
    return `Audience is ${behaviorText ? capitalize(behaviorText) : 'Any'}`;
  }

  @action
  filterByMatchBehavior(behavior) {
    this.args.filterContent('selectedMatchBehaviorValues', behaviorMapDynamicFixed[behavior]);
  }

  @action removeFilter() {
    this.args.filterContent('selectedMatchBehaviorValues', []);
    this.args.removeFromActiveFilter('match-behavior');
  }
}
