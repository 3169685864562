/* import __COLOCATED_TEMPLATE__ from './forward-emails.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { timeout } from 'ember-concurrency';
import ENV from 'embercom/config/environment';
import ajax from 'embercom/lib/ajax';
import { captureException } from 'embercom/lib/sentry';
import storage from 'embercom/vendor/intercom/storage';
import { isValidEmail } from 'embercom/lib/email';

const LOCAL_STORAGE_EMAIL_KEY = 'forward-emails-component-email';

export default class ForwardEmailsComponent extends Component {
  @service store;
  @service realTimeEventService;
  @service intercomEventService;
  @service appService;

  @tracked email = '';
  @tracked emailSet = false;
  @tracked showForwardingPanel = false;
  @tracked verification = null;
  @tracked maxRetryAttempts = 10;
  @tracked retryAttempts = 0;
  @tracked forwardMailSetupAttempt = null;

  constructor() {
    super(...arguments);
    this.restoreEmailFromStorage();
    this.realTimeEventService.on('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
  }

  willDestroy() {
    super.willDestroy();
    this.realTimeEventService.off('ForwardMailVerified', this, '_handleForwardMailVerifiedMessage');
  }

  get app() {
    return this.appService.app;
  }

  get isValidEmail() {
    return isValidEmail(this.email);
  }

  get hasForwardingEmail() {
    return this.email !== '';
  }

  get inboxEmailAddress() {
    return this.app.inbox_email;
  }

  restoreEmailFromStorage() {
    if (this.email !== '') {
      return;
    }
    let email = storage.get(LOCAL_STORAGE_EMAIL_KEY);
    if (!email) {
      return;
    }
    this.email = email;
  }

  @dropTask
  *verifyEmailForwarding() {
    this._trackAnalyticsEvent({ action: 'clicked', object: 'send_test_email' });

    yield this._createSetupAttempt();
    yield this._sendForwardingEmail();

    let verification = yield this._verifyEmailForwarding();
    try {
      while (!verification && this.retryAttempts < this.maxRetryAttempts) {
        timeout(ENV.APP._1000MS); // wait for nexus event
        this.retryAttempts++;
        verification = yield this._verifyEmailForwarding();
      }
    } catch (e) {
      captureException(e, {
        fingerprint: ['forward-emails', 'verify_email_forwarding'],
      });
      this.retryAttempts = 0;
    }

    if (!this.verification) {
      this._updateVerification(verification);
      this._trackAnalyticsEvent({
        action: verification ? 'completed' : 'failed',
        context: 'verify_endpoint',
        object: 'send_test_email',
      });
    }
  }

  async _createSetupAttempt() {
    let forwardMailSetupAttempt = await this.store
      .createRecord('forward-mail-setup-attempt', {
        appId: this.app.id,
        forwardingEmailAddress: this.email,
      })
      .save();

    this.forwardMailSetupAttempt = forwardMailSetupAttempt;
  }

  async _sendForwardingEmail() {
    await ajax({
      url: '/ember/forward_emails',
      data: JSON.stringify({
        app_id: this.app.id,
        email: this.email,
      }),
      type: 'POST',
      contentType: 'application/json',
      dataType: 'JSON',
    });
  }

  async _verifyEmailForwarding() {
    return await ajax({
      url: '/ember/forward_mail_setup_attempts/verify',
      type: 'GET',
      data: {
        app_id: this.app.id,
        forwarding_email_address: this.email,
      },
    });
  }

  _handleForwardMailVerifiedMessage() {
    this._updateVerification(true);
    this._trackAnalyticsEvent({
      action: 'completed',
      object: 'send_test_email',
      context: 'nexus_event',
    });
  }

  _updateVerification(status) {
    this.verification = status;
    if (this.verification) {
      this.args.completionCallback?.();
    }
  }

  _trackAnalyticsEvent(options) {
    this.intercomEventService.trackAnalyticsEvent({
      email: this.email,
      ...options,
      ...this.args.step.analyticsMetadata,
    });
  }

  @action
  setEmail() {
    if (this.isValidEmail) {
      storage.set(LOCAL_STORAGE_EMAIL_KEY, this.email);
      this.emailSet = true;
      this.showForwardingPanel = true;
      this._trackAnalyticsEvent({
        action: 'clicked',
        object: 'set_email',
      });
    }
  }

  @action
  unsetEmail() {
    storage.remove(LOCAL_STORAGE_EMAIL_KEY);
    this.verification = null;
    this.emailSet = false;
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'unset_email',
    });
  }

  @action
  markAsCompleted() {
    this.args.completionCallback?.('completed');
    this._trackAnalyticsEvent({
      action: 'clicked',
      object: 'mark_as_completed',
    });
  }
}
