/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';

export default class NewsfeedSiteHeaderPreview extends Component {
  get site() {
    return this.args.site;
  }

  get headerBackgroundImageStyle() {
    return `background-image: url(${this.args.headerBackgroundUrl})`;
  }

  get safeMediaStyle() {
    return sanitizeHtml(this.headerBackgroundImageStyle);
  }

  get headerColorStyle() {
    return `background-color: #${this.site.themeColor}`;
  }

  get headerStyle() {
    return `${this.headerBackgroundImageStyle}; ${this.headerColorStyle}`;
  }

  get headerSafeStyle() {
    return sanitizeHtml(this.headerStyle);
  }
}
