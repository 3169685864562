/* import __COLOCATED_TEMPLATE__ from './attachment.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { restartableTask } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  settings: $TSFixMe;
}

export default class Attachment extends Component<Args> {
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  @tracked attachUploadsInline = this.args.settings.attachUploadsInline ? 'enabled' : 'disabled';

  @action
  disableUpdateAttachUploadsInline() {
    if (this.attachUploadsInline === 'enabled') {
      this.args.settings.set('attachUploadsInline', false);
      taskFor(this.saveTask).perform();
    }
  }

  @action
  enableUpdateAttachUploadsInline() {
    if (this.attachUploadsInline === 'disabled') {
      this.args.settings.set('attachUploadsInline', true);
      taskFor(this.saveTask).perform();
    }
  }

  @restartableTask
  *saveTask() {
    try {
      yield this.args.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('apps.app.settings.attachment-settings.trusted-domains-error-message'),
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::Attachment': typeof Attachment;
  }
}
