/* import __COLOCATED_TEMPLATE__ from './appearance-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class NewsfeedsSiteSettingsAppearanceComponent extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get site() {
    return this.args.site;
  }

  get hasUnsavedChanges() {
    return this.site.hasDirtyAttributes || this.args.uploadHelper.hasUpdates;
  }

  get pickerColor() {
    return `#${this.site.themeColor}`;
  }

  @action
  setColor(color) {
    this.site.themeColor = color.substring(1);
  }
}
