/* import __COLOCATED_TEMPLATE__ from './hosted-messenger-opener.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { action } from '@ember/object';
import openCenteredWindow from 'embercom/lib/open-centered-window';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';

export default Component.extend({
  tagName: '',
  hostedMessengerWindowOpen: false,
  onboardingHome: false,
  router: service(),

  waitForChildWindow(childWindow) {
    if (childWindow.closed) {
      this.set('hostedMessengerWindowOpen', false);
      if (typeof this.onHostedMessengerClose === 'function') {
        this.onHostedMessengerClose();
      }
    } else {
      later(this.waitForChildWindow.bind(this, childWindow), 300);
    }
  },

  openHostedMessengerWindow: action(function () {
    let url = this.router.urlFor(`apps.app.test-conversation`, {
      queryParams: {
        onboardingHome: this.onboardingHome,
        newMessengerPreview: this.newMessengerPreview,
      },
    });
    let window = openCenteredWindow(url, 800, 1280, 'Hosted Messenger');

    this.waitForChildWindow(window);
    window.focus();
    this.set('hostedMessengerWindowOpen', true);
    if (typeof this.onHostedMessengerOpen === 'function') {
      this.onHostedMessengerOpen();
    }
  }),
});
