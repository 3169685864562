/* import __COLOCATED_TEMPLATE__ from './bulk-tag.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';
import { task } from 'ember-concurrency';
import { put } from 'embercom/lib/ajax';
import { inject as service } from '@ember/service';
import { readOnly } from '@ember/object/computed';

export default Component.extend({
  appService: service(),
  app: readOnly('appService.app'),
  notificationsService: service(),
  bulkTaggingUrl: '/ember/content_service/contents/bulk_tag',
  tagModalTitle: computed('selectedContentWrappers.length', function () {
    if (this.selectedContentWrappers.length === 1) {
      return 'Tag this message';
    }
    return `Tag these ${this.selectedContentWrappers.length} messages`;
  }),
  taggable: computed(function () {
    return {
      tags: [],
      taggings: [],
      type: 'content',
      updateTaggings: (admin, addedTags, removedTags) =>
        this.updateTaggingsTask.perform(addedTags, removedTags),
    };
  }),
  updateTaggingsTask: task(function* (addedTags, removedTags) {
    yield put(this.bulkTaggingUrl, {
      app_id: this.get('app.id'),
      added_tag_ids: addedTags.map((tag) => tag.get('id')),
      removed_tag_ids: removedTags.map((tag) => tag.get('id')),
      content_wrapper_ids: this.selectedContentWrappers.map((contentWrapper) =>
        contentWrapper.get('id'),
      ),
    });
  }),

  actions: {
    afterTagSave() {
      this.set('showTagModal', false);
      this.notificationsService.notifyConfirmation('Messages tagged!');
      if (this.onAfterSave) {
        this.onAfterSave();
      }
    },
  },
});
