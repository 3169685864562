/* import __COLOCATED_TEMPLATE__ from './settings-component.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import EmberObject, { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

const SECTION_MODEL_MAP = {
  'bot-identity': 'identity',
  'operator-inbox': 'botInboxSettings',
  'custom-bot': 'customBotsSettings',
};
export default class SettingsComponent extends Component {
  @service intercomEventService;
  @service intercomConfirmService;
  @service notificationsService;
  @service appService;
  @service permissionsService;
  @service intl;

  @tracked hasRestoreDefaultButton;
  @tracked previewUrl;

  constructor() {
    super(...arguments);
    this.hasRestoreDefaultButton = this._hasRestoreDefaultButton;
  }

  get app() {
    return this.appService.app;
  }

  get avatarUrl() {
    return this.previewUrl || this.args.identity.avatar.image_urls.square_128;
  }

  get identityPreview() {
    let identity = this.args.identity.getProperties('name', 'isOperator', 'avatarData');
    identity = EmberObject.create(identity);
    identity.set('avatarData', {
      url: this.avatarUrl,
      avatarShape: this.args.identity.avatarData.avatarShape,
    });
    return identity;
  }

  get previewConversationParts() {
    return [
      {
        app_id: this.app.id,
        admin: this.identityPreview,
        sub_type: 'comment',
        blocks: [
          {
            type: 'paragraph',
            text: this.intl.t('operator.settings.choose-identity-for-bots.preview'),
          },
        ],
      },
    ];
  }

  get isSaving() {
    this.args.identity.isSaving;
  }

  get hasCustomValues() {
    return (
      this.args.identity.name !== this.defaultName || this.args.identity.avatarType !== 'default'
    );
  }

  get identitySubheading() {
    return this.intl.t('operator.settings.choose-identity-for-bots.sub-title');
  }

  get botInboxSubheading() {
    return this.intl.t('operator.settings.turn-on-bot-inbox-channel.sub-title');
  }

  get defaultName() {
    if (this.app.hasFinIdentity) {
      return this.intl.t('operator.settings.fin');
    } else {
      return this.intl.t('operator.settings.operator');
    }
  }

  get _hasRestoreDefaultButton() {
    return this.hasCustomValues;
  }

  @action
  sectionDidOpen(sectionName) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'opened',
      context: 'operator_settings',
      object: `${sectionName}-section`,
    });
  }

  @action
  sectionDidClose(sectionName) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'closed',
      context: 'operator_settings',
      object: `${sectionName}-section`,
    });
  }

  @action
  async sectionCanClose(sectionName) {
    let model = SECTION_MODEL_MAP[sectionName];
    let confirmNotSaved = {
      title: this.intl.t('operator.settings.confirm-not-saved.close-without-saving'),
      primaryButtonType: 'primary-destructive',
      confirmButtonText: this.intl.t('operator.settings.confirm-not-saved.continue-without-saving'),
      cancelButtonText: this.intl.t('operator.settings.confirm-not-saved.keep-editing'),
      body: this.intl.t('operator.settings.confirm-not-saved.body'),
    };

    if (!model || !model.hasDirtyAttributes) {
      return true;
    }

    let confirmed = await this.intercomConfirmService.confirm(confirmNotSaved);

    if (confirmed) {
      model.rollbackAttributes();
      return true;
    } else {
      return false;
    }
  }

  @action
  async save() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'update',
      object: 'operator-identity',
      place: 'operator-settings',
      avatar_type: this.args.identity.get('avatarType'),
      operator_name: this.args.identity.get('name'),
    });

    try {
      await this.args.identity.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.settings.saved-successfully'),
      );
      this.hasRestoreDefaultButton = this._hasRestoreDefaultButton;
    } catch (error) {
      this.notificationsService.notifyError(this.intl.t('operator.settings.error'));
    }
  }

  @action
  toggleOperatorInboxSetting() {
    this.args.botInboxSettings.toggleProperty('assignConversationsToBots');

    if (!this.args.botInboxSettings.assignConversationsToBots) {
      this.args.botInboxSettings.deferSlas = false;
    }
  }

  @action
  trackOperatorInboxSettingsChange() {
    this.intercomEventService.trackAnalyticsEvent({
      context: 'bot_inbox_settings',
      object: 'bot_inbox_toggle',
      action: this.args.botInboxSettings.assignConversationsToBots ? 'turned_on' : 'turned_off',
    });
  }

  @action
  cancel() {
    this.args.identity.rollbackAttributes();
    this.previewUrl = null;
    this.hasRestoreDefaultButton = this._hasRestoreDefaultButton;
  }

  @action
  setCustomAvatar(dataUrl) {
    if (this.isDestroying) {
      return;
    }
    this.previewUrl = dataUrl;
  }

  @action
  async restoreDefaults() {
    let confirmOptions = {
      title: this.intl.t('operator.settings.restore-defaults.bot-identity'),
      confirmButtonText: this.intl.t('operator.settings.restore-defaults.restore-default'),
      cancelButtonText: this.intl.t('operator.settings.restore-defaults.cancel'),
      body: this.intl.t('operator.settings.restore-defaults.body', { name: this.defaultName }),
    };

    let isConfirmed = await this.intercomConfirmService.confirm(confirmOptions);
    if (!isConfirmed) {
      return;
    }
    await this._doRestoreDefaults();
    this.hasRestoreDefaultButton = this._hasRestoreDefaultButton;
    this.notificationsService.notifyConfirmation(
      this.intl.t('operator.settings.saved-successfully'),
    );
  }

  @action
  trackCustomBotAutoCloseSettings() {
    let settingSnapshot = this.args.customBotsSettings.getProperties(
      'autoClosingForUsers',
      'autoClosingForVisitors',
      'autoClosingFromButton',
      'autoClosingFromNewConversationUsers',
      'autoClosingFromNewConversationVisitors',
      'autoClosingConversationStarted',
      'autoClosingTimerMinutes',
    );

    this.intercomEventService.trackAnalyticsEvent({
      context: 'bot_inbox_settings',
      object: 'custom_bot_auto_close_settings',
      action: 'save',
      ...settingSnapshot,
    });
  }

  async _doRestoreDefaults() {
    this.previewUrl = null;
    return this.args.identity
      .revert()
      .catch(() => this.notificationsService.notifyError(this.intl.t('operator.settings.error')));
  }
}
