/* import __COLOCATED_TEMPLATE__ from './divider-options.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

export default class DividerOptions extends Component {
  @tracked width;
  @tracked borderWidth;
  @tracked borderStyle;

  constructor() {
    super(...arguments);
    this.initialize();
  }

  @action
  initialize() {
    this.width = this.args.composerApi?.composer.state.selectionAnchorNodeWidth.slice(0, -1);
    this.borderWidth = this.args.composerApi?.composer.state.selectionAnchorNodeBorderWidth.slice(
      0,
      -2,
    );
    this.borderStyle = this.args.composerApi?.composer.state.selectionAnchorNodeBorderStyle;
  }

  get selectedBlock() {
    return this.args.composerApi?.composer.state.selectionAnchorNode;
  }

  @action
  widthChange() {
    this.checkMinWidthLimit();
    this.checkMaxWidthLimit();
    this.args.composerApi.composer.commands.updateNodeWidth(`${this.width}%`);
  }

  @action
  borderWidthChange() {
    this.checkMinBorderWidthLimit();
    this.checkMaxBorderWidthLimit();
    this.args.composerApi.composer.commands.updateBorderWidth(`${this.borderWidth}px`);
  }

  @action
  borderStyleChange(newBorderStyle) {
    this.args.composerApi.composer.commands.updateBorderStyle(newBorderStyle);
    this.borderStyle = newBorderStyle;
  }

  checkMinBorderWidthLimit() {
    if (this.borderWidth < 1) {
      this.borderWidth = 1;
    }
  }

  checkMaxBorderWidthLimit() {
    if (this.borderWidth > 100) {
      this.borderWidth = 100;
    }
  }

  checkMinWidthLimit() {
    if (this.width < 5) {
      this.width = 5;
    }
  }

  checkMaxWidthLimit() {
    if (this.width > 100) {
      this.width = 100;
    }
  }
}
