/* import __COLOCATED_TEMPLATE__ from './labels.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

export default class NewsLabelsComponent extends Component {
  @service store;
  @service newsLabelsService;

  constructor() {
    super(...arguments);
    this.newsLabelsService.loadLabels.perform();
  }

  get labels() {
    return this.newsLabelsService.labels;
  }

  get labelIds() {
    return this.args.newsItem.labelIds || [];
  }

  get selectedLabels() {
    return this.labelIds.map((id) => this.labels.find((label) => Number(label.id) === id));
  }
}
