/* import __COLOCATED_TEMPLATE__ from './create-or-update-modal.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable ember/use-brace-expansion */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { readOnly, not } from '@ember/object/computed';
import { task } from 'ember-concurrency';
import { inject as service } from '@ember/service';
import { typeOf, isEmpty } from '@ember/utils';
import { computed, action } from '@ember/object';

export default Component.extend({
  store: service(),
  intl: service(),
  notificationsService: service(),
  tagName: '',
  schedule: null,
  settings: null,
  dependentObjects: null,
  appService: service(),
  app: readOnly('appService.app'),
  isEditingSchedule: not('scheduleToUpdate.isNew'),

  didReceiveAttrs() {
    this._super(...arguments);
    if (this.schedule !== this.scheduleToUpdate) {
      this.set('schedule', this.scheduleToUpdate);
      this.setDefaultTimeZone.perform();
    }
  },

  saveDisabled: computed(
    'schedule.{hasDirtyAttributes,hasInvalidInterval,name}',
    'settings.hasDirtyAttributes',
    function () {
      if (isEmpty(this.schedule.name) || this.schedule.hasInvalidInterval) {
        return true;
      }

      return !this.schedule.hasDirtyAttributes && !this.settings?.hasDirtyAttributes;
    },
  ),

  closeModal() {
    this.set('dependentObjects', null);
    if (typeOf(this.onModalClose) === 'function') {
      this.onModalClose();
    }
  },

  saveModal() {
    if (typeOf(this.onModalSave) === 'function') {
      this.onModalSave(this.schedule);
    }
  },

  setDefaultTimeZone: task(function* () {
    if (this.scheduleToUpdate && !this.scheduleToUpdate.timeZoneName) {
      let generalSettings = yield this.store.findRecord('general-app-settings', this.app.id);
      this.set('scheduleToUpdate.timeZoneName', generalSettings.time_zone_name);
    }
  }),

  save: task(function* () {
    try {
      yield this.settings?.save();
      yield this.schedule.save();
      this.closeModal();
      this.saveModal();
      this.notificationsService.notifyConfirmation(
        this.intl.t('components.settings.office-hours.modal.notifications.confirmation'),
      );
    } catch (err) {
      this.notificationsService.notifyError(
        err && err.jqXHR && err.jqXHR.responseJSON && err.jqXHR.responseJSON.errors
          ? err.jqXHR.responseJSON.errors
          : this.intl.t('components.settings.office-hours.modal.notifications.error'),
      );
    }
  }),

  closeModalAction: action(function () {
    this.closeModal();
  }),
});
