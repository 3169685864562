/* import __COLOCATED_TEMPLATE__ from './display-only-step-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import Component from '@glimmer/component';
import type Router from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import type ContentImportService from 'embercom/services/content-import-service';
import type IntlService from 'ember-intl/services/intl';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import { scheduleOnce } from '@ember/runloop';

interface Args {
  step: any;
  level: any;
  place: string;
  shouldAnimate?: boolean;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class DisplayOnlyStepComponent extends Component<Signature> {
  @service declare router: Router;
  @service intercomEventService: any;
  @service declare contentImportService: ContentImportService;
  @service intl!: IntlService;
  @service declare onboardingHomeExternalStepService: any;
  @service declare appService: any;
  @service declare guideLibraryService: GuideLibraryService;

  constructor(owner: any, args: Args) {
    super(owner, args);
    if (args.shouldAnimate && this.hasAccessToIASettingsHub) {
      scheduleOnce('afterRender', this, this.scrollStepIntoView);
    }
  }

  scrollStepIntoView() {
    let container = window.document.querySelector(
      '[data-intercom-target="checklist-steps-container"]',
    );

    container
      ?.querySelector(`[data-intercom-target="${this.args.step.stepData.translationKey}"]`)
      ?.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
  }

  _getDependentStepState(stepData: any) {
    if (stepData.dependentStepIdentifier) {
      let dependentStep = this.args.level.steps.find(
        (step: any) => step.identifier === stepData.dependentStepIdentifier,
      );
      if (!dependentStep) {
        return null;
      }
      return dependentStep.state;
    }
    return null;
  }

  get hasAccessToIASettingsHub() {
    return this.appService.app.canSeeNewIASettingsHub;
  }

  get isDisabled() {
    let dependentStepState = this._getDependentStepState(this.args.step.stepData);
    if (dependentStepState) {
      return dependentStepState !== 'completed';
    }
    return false;
  }

  get hasImportsInProgress(): boolean {
    return this.contentImportService.inProgressRunExists;
  }

  get hasFailedImports(): boolean {
    return Boolean(this.contentImportService.failedRuns.length > 0);
  }

  get stampData() {
    return this.args.step.stepData.stampData || this._internalStampData();
  }

  get withTooltip() {
    return Boolean(this.args.step.stepData.tooltipData) && this.isDisabled;
  }

  get modifiedLevelIdentifier() {
    return this.args.level.identifier
      .replaceAll('_tickets', '')
      .replaceAll('_v2', '')
      .replaceAll('_', '-');
  }

  get stepTitle() {
    let titleKey =
      this.args.step.stepData.translationKey === 'meet_the_helpdesk' ? 'title-copilot' : 'title';
    return this.intl.t(
      `onboarding.guide-library.next_steps.${this.args.level.identifier}.${this.args.step.stepData.translationKey}.${titleKey}`,
    );
  }

  _internalStampData() {
    if (this.args.step.identifier === 'display_only_setup_fin') {
      if (this.hasImportsInProgress) {
        return {
          color: 'sky',
          translationKey: 'onboarding.guide-library.stamp-status.importing',
        };
      }
      if (this.hasFailedImports) {
        return {
          color: 'red',
          translationKey: 'onboarding.guide-library.stamp-status.failed',
        };
      }
    }
    return null;
  }

  get showStamp() {
    return Boolean(!this.isDisabled && this.args.step.state !== 'completed' && this.stampData);
  }

  @action async handleClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'step_action',
      place: this.args.place,
      section: 'onboarding',
      context: `onboarding_guide_library.wizards.empty_state.${this.args.step.identifier}`,
      step_identifier: this.args.step?.identifier,
    });
    this.intercomEventService.trackEvent('guide-interacted', {
      action: 'clicked',
      object: 'step_action',
      place: this.args.place,
      section: 'onboarding',
      step_identifier: this.args.step?.identifier,
    });
    if (this.args.step?.stepData?.displayOnlyCallback) {
      if (this.args.step.identifier === 'display_only_setup_your_help_center') {
        this.onboardingHomeExternalStepService.startAfterTransition(this.args.step.identifier, {
          source: 'guide-library',
          method: 'startAfterTransition',
        });
      }
      await this.args.step.stepData.displayOnlyCallback(
        this.router,
        this.args.step,
        this.appService.app,
        this.guideLibraryService,
      );
      this.guideLibraryService.closeChecklistPopover();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::DisplayOnlyStepComponent': typeof DisplayOnlyStepComponent;
  }
}
