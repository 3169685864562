/* import __COLOCATED_TEMPLATE__ from './settings-sub-section.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */

import Component from '@glimmer/component';
import {
  type NavSection,
  SETTINGS_NAV_ITEMS,
  type NavItem,
} from 'embercom/components/new-settings/navigation/submenu-items';
import { inject as service } from '@ember/service';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import { action } from '@ember/object';
import type IntlService from 'ember-intl/services/intl';

interface SettingsSubSectionArgs {
  config: NavSection;
}

interface Signature {
  Element: HTMLDivElement;
  Args: SettingsSubSectionArgs;
}

export default class SettingsSubSection extends Component<Signature> {
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get settingsStructure() {
    let structure = SETTINGS_NAV_ITEMS;
    structure.shift();
    return structure;
  }

  get sectionTitle() {
    if (
      this.args.config.keyword === 'proactive-support' &&
      this.appService.app.hasOptedInForProductIa
    ) {
      return this.intl.t('new-settings.submenu.proactive-support.outbound-title');
    }
    return this.intl.t(`new-settings.submenu.${this.args.config.keyword}.title`);
  }

  shouldShowSecurityWarning() {
    return true;
  }

  conditionalIcon(icon = 'alert'): InterfaceIconName {
    return icon as InterfaceIconName;
  }

  @action
  shouldShow(item: NavItem): boolean {
    if (item.hideOnFeatureFlag) {
      return !this.appService.app.canUseFeature(item.hideOnFeatureFlag);
    }
    if (item.showOnFeatureFlag) {
      return this.appService.app.canUseFeature(item.showOnFeatureFlag);
    }
    return item.customDisplayCondition?.(this.appService.app) ?? true;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Home::SettingsSubSection': typeof SettingsSubSection;
    'new-settings/home/SettingsSubSection': typeof SettingsSubSection;
  }
}
