/* import __COLOCATED_TEMPLATE__ from './convert-inbound-emails-to-tickets.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { dropTask } from 'ember-concurrency-decorators';
import { TicketCategory } from 'embercom/objects/inbox/ticket';
import type TicketType from 'embercom/models/inbox/ticket-type';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  settings: $TSFixMe;
  hasLiveConvertToTicketWorkflows: $TSFixMe;
}

export default class ConvertInboundEmailsToTickets extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @tracked ticketTypes: TicketType[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.fetchTicketTypes).perform();
  }

  get settings() {
    return this.args.settings;
  }

  get selectedTicketType() {
    if (!this.ticketTypes) {
      return null;
    }

    let selectedTicketTypeById = this.ticketTypes.find(
      (item) => item.id === `${this.settings.ticketTypeId}`,
    );

    if (selectedTicketTypeById) {
      return selectedTicketTypeById;
    }

    let defaultTicketType = this.ticketTypes.find((ticketType) =>
      ticketType.userVisibleDescriptors.every(
        ({
          requiredToCreate,
          requiredToCreateForUsers,
        }: {
          requiredToCreate: boolean;
          requiredToCreateForUsers: boolean;
        }) => !requiredToCreate && !requiredToCreateForUsers,
      ),
    );

    return defaultTicketType || null;
  }

  get selectListItems() {
    if (!this.ticketTypes) {
      return [];
    }

    return this.ticketTypes.map((type) => {
      let isDisabled = type.userVisibleDescriptors.any(
        (obj: $TSFixMe) => obj.requiredToCreate || obj.requiredToCreateForUsers,
      );

      return {
        text: `${type.emoji} ${type.name}`,
        value: type.id,
        isDisabled,
        component: 'workflows/graph-editor/node-items/steps/convert-to-ticket-item',
      };
    });
  }

  get hasError() {
    return (
      taskFor(this.fetchTicketTypes).isRunning &&
      (!this.ticketTypes || this.ticketTypes.length === 0)
    );
  }

  @dropTask
  *fetchTicketTypes(): TaskGenerator<any> {
    let fetchedTicketTypes = yield this.store.findAll('inbox/ticket-type');
    fetchedTicketTypes = fetchedTicketTypes.filter(
      ({ archived, category }: { archived: $TSFixMe; category: $TSFixMe }) =>
        !archived && category === TicketCategory.Request,
    );
    this.ticketTypes = fetchedTicketTypes;
  }

  @action
  toggleAutoConversion() {
    let allowAutoConversation = this.settings.toggleProperty('allowAutoConversion');
    if (allowAutoConversation && this.selectedTicketType) {
      this.settings.ticketTypeId = this.selectedTicketType.id;
    } else {
      this.settings.ticketTypeId = '';
    }
    taskFor(this.updateConvertSetting).perform();
  }

  @dropTask
  *updateConvertSetting(): TaskGenerator<any> {
    try {
      yield this.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.settings.toggleProperty('allowAutoConversion');
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  onSelectedTicketType(selectedTicketTypeId: number) {
    this.settings.ticketTypeId = selectedTicketTypeId;
    if (this.settings.allowAutoConversion) {
      taskFor(this.updateConvertSetting).perform();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::ConvertInboundEmailsToTickets': typeof ConvertInboundEmailsToTickets;
  }
}
