/* import __COLOCATED_TEMPLATE__ from './progress-step.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class ProgressStep extends Component {
  @service notificationsService;
  @tracked currentStep = this.args.currentStep;

  get stepsWithIndex() {
    let progressSteps = [];
    Object.entries(this.args.progressStepList).forEach(([stepKey, stepValue], stepIndex) => {
      progressSteps.push({
        displayIndex: stepIndex + 1,
        key: stepKey,
        value: stepValue,
      });
    });
    return progressSteps;
  }
}
