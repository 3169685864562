/* import __COLOCATED_TEMPLATE__ from './newsfeeds-cell.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { isPresent } from '@ember/utils';

export default class NewsfeedsCell extends Component {
  @service newsfeedsService;

  get targetedNewsfeeds() {
    return this.newsfeedsService.targetedNewsfeeds;
  }

  get newsfeedIds() {
    return this.args.content?.contentData?.newsfeed_ids;
  }

  get newsfeedNames() {
    if (isPresent(this.newsfeedIds)) {
      return this.targetedNewsfeeds
        .filter(({ id }) => this.newsfeedIds.includes(Number(id)))
        .map((newsfeed) => newsfeed.nameForDisplay)
        .join(', ');
    }
    return null;
  }
}
