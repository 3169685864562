/* import __COLOCATED_TEMPLATE__ from './migration-history.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Session from 'embercom/services/session';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';
import type Store from '@ember-data/store';
import { ZendeskObjectTypes } from 'embercom/components/settings/data-import/migrate-from-zendesk';
import type Log from 'embercom/models/crm/log';
import type CrmLogsService from 'embercom/services/data/crm-logs-service';

interface Args {
  integrationCode: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export enum CrmLogStatus {
  Success = 'success',
  Failed = 'failed',
}

export const PAGE_SIZE = 25;

export default class MigrationHistory extends Component<Signature> {
  @service declare session: Session;
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare store: Store;
  @service('data/crm-logs-service')
  declare crmLogsService: CrmLogsService;

  @tracked records: Log[] = [];
  @tracked currentPage = 1;
  @tracked lastResponse: any = null;
  @tracked selectedStatus = CrmLogStatus.Success;
  @tracked selectedObjectTypes = Object.values(ZendeskObjectTypes);

  pageSize = PAGE_SIZE;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    taskFor(this.loadRecords).perform(
      this.currentPage,
      this.selectedStatus,
      this.selectedObjectTypes,
    );
  }

  get isLoading() {
    return taskFor(this.loadRecords).isRunning && this.currentPage === 1;
  }

  get isLoadingNextPage() {
    return taskFor(this.loadRecords).isRunning && this.currentPage > 1;
  }

  get displayEmptyState() {
    return this.records.length === 0;
  }

  get canLoadMore() {
    return this.lastResponse && this.lastResponse.total_pages > this.currentPage;
  }

  get crmLogStatuses() {
    return Object.values(CrmLogStatus);
  }

  get showErrors() {
    return this.selectedStatus === CrmLogStatus.Failed;
  }

  get columns() {
    let cols = [
      {
        label: this.translation('columns.object'),
        valuePath: 'object',
      },
      {
        label: this.translation('columns.status'),
        valuePath: 'status',
      },
      {
        label: this.translation('columns.zendesk-id'),
        valuePath: 'zendesk-id',
      },
    ];

    if (!this.showErrors) {
      cols.pushObject({
        label: this.translation('columns.intercom-id'),
        valuePath: 'intercom-id',
      });
    }

    cols.pushObject({
      label: this.translation('columns.last-imported'),
      valuePath: 'last-imported',
    });

    if (this.showErrors) {
      cols.pushObject({
        label: this.translation('columns.error'),
        valuePath: 'error',
      });
    }

    return cols;
  }

  @action changeSelectedStatus(status: CrmLogStatus) {
    this.selectedStatus = status;
    this.resetRecords();
    taskFor(this.loadRecords).perform(
      this.currentPage,
      this.selectedStatus,
      this.selectedObjectTypes,
    );
  }

  @action changeSelectedObjectTypes(objectType: ZendeskObjectTypes | '') {
    if (objectType === '') {
      this.selectedObjectTypes = Object.values(ZendeskObjectTypes);
    } else {
      this.selectedObjectTypes = [objectType];
    }
    this.resetRecords();
    taskFor(this.loadRecords).perform(
      this.currentPage,
      this.selectedStatus,
      this.selectedObjectTypes,
    );
  }

  @task({ enqueue: true })
  *loadRecords(
    page: number,
    status: CrmLogStatus,
    sourceObjectTypes: string[],
  ): TaskGenerator<void> {
    let response = yield this.crmLogsService.loadCrmLogs(
      this.args.integrationCode,
      page,
      status,
      sourceObjectTypes,
    );
    this.lastResponse = response;
    this.records.pushObjects(response.items.toArray());
  }

  @task({ enqueue: true })
  *nextPageTask(): TaskGenerator<void> {
    if (!this.lastResponse || this.canLoadMore) {
      this.currentPage += 1;
      yield taskFor(this.loadRecords).perform(
        this.currentPage,
        this.selectedStatus,
        this.selectedObjectTypes,
      );
    }
  }

  resetRecords = () => {
    this.records = [];
    this.currentPage = 1;
    this.lastResponse = null;
    let scrollableTableWrapper = document.getElementById('scrollable-table-wrapper');
    if (scrollableTableWrapper) {
      scrollableTableWrapper.scrollTop = 0;
    }
  };

  statusColor = (status: CrmLogStatus) => {
    switch (status) {
      case CrmLogStatus.Success:
        return 'green' as const;
      case CrmLogStatus.Failed:
        return 'red' as const;
      default:
        return undefined;
    }
  };

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.migration-history.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::MigrationHistory': typeof MigrationHistory;
    'new-settings/data/imports-exports/import-zendesk/migration-history': typeof MigrationHistory;
  }
}
