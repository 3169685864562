/* import __COLOCATED_TEMPLATE__ from './subscription-type-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';
import { action, get } from '@ember/object';
import { inject as service } from '@ember/service';

export default class SubscriptionTypeComponent extends Component {
  @service appService;
  @service store;

  get app() {
    return this.appService.app;
  }

  get isOpen() {
    return this.args.activeFilter === 'subscription-type';
  }

  get selectedSubscriptionTypeValue() {
    return this.args.selectedSubscriptionTypeValue;
  }

  get subscriptionTypeValues() {
    return this.args.subscriptionTypes
      .filter((subscriptionType) => subscriptionType.isLive || subscriptionType.isArchived)
      .map((subscriptionType) => {
        return {
          text: get(subscriptionType.translationForTeammate, 'name'),
          value: get(subscriptionType, 'id'),
        };
      });
  }

  get subscriptionTypeOptions() {
    if (!this.hasSubscriptionTypes) {
      return this.emptySubscriptionTypeComponent;
    }

    return [{ text: 'Any', value: null }].concat(this.subscriptionTypeValues);
  }

  get hasSubscriptionTypes() {
    return this.args.subscriptionTypes ? this.args.subscriptionTypes.length > 0 : false;
  }

  get subscriptionTypeName() {
    if (isPresent(this.selectedSubscriptionTypeValue)) {
      return `Subscription type is ${this.selectedSubscriptionTypeName}`;
    }
    return 'Subscription type is any';
  }

  get emptySubscriptionTypeComponent() {
    return [
      {
        text: 'No subscription types present',
        value: 'name',
        component: 'outbound/filters/empty-subscription-types-component',
        isDisabled: true,
      },
    ];
  }

  get selectedSubscriptionTypeName() {
    return this.store.peekRecord(
      'outbound-subscriptions/subscription-type',
      this.selectedSubscriptionTypeValue,
    ).translationForTeammate.name;
  }

  @action
  filterBySubscriptionTypes(selectedValue) {
    this.args.filterContent('selectedSubscriptionTypeValue', selectedValue);
  }

  @action removeFilter() {
    this.args.filterContent('selectedSubscriptionTypeValue', null);
    this.args.removeFromActiveFilter('subscription-type');
  }
}
