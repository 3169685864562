/* import __COLOCATED_TEMPLATE__ from './channel-targeting.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ChannelTargeting extends Component {
  @service contentEditorService;
  @service router;

  get targetChannels() {
    return this.contentEditorService.activeObject.targetChannels;
  }

  @action
  updateTargetChannel(channel) {
    if (this.targetChannels.includes(channel)) {
      this.removeChannel(channel);
    } else {
      this.addChannel(channel);
    }
    this.resetClientPredicateGroup();
  }

  @action
  addChannel(channel) {
    this.contentEditorService.activeObject.set('targetChannels', [...this.targetChannels, channel]);
  }

  @action
  removeChannel(channel) {
    let updatedTargetChannels = this.targetChannels.filter(
      (previousChannel) => channel !== previousChannel,
    );
    this.contentEditorService.activeObject.set('targetChannels', updatedTargetChannels);
  }

  @action
  resetClientPredicateGroup() {
    this.contentEditorService.ruleset.set('clientPredicateGroup', { predicates: [] });
  }
}
