/* import __COLOCATED_TEMPLATE__ from './button-input.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { inject as service } from '@ember/service';

export default class ButtonInput extends Component {
  @service contentEditorService;
  @service attributeService;
  previousPosition;

  @action
  refreshInputField() {
    if (this.args.currentPosition === this.previousPosition) {
      return;
    }

    //To make sure the input field is not reinitialized when previousPosition is undefined
    if (this.previousPosition === undefined) {
      this.previousPosition = this.args.currentPosition;
      return;
    }

    let blocksDoc = new BlocksDocument(this.valueAsBlock());
    this.updateInputFieldBlocks(blocksDoc);

    this.previousPosition = this.args.currentPosition;
  }

  updateInputFieldBlocks(blocksDoc) {
    this.inputFieldComposerApi.composer.loadNewBlocks(blocksDoc);
  }

  valueAsBlock() {
    return [{ type: 'paragraph', text: this.args.value, modelKey: 'common/blocks/paragraph' }];
  }

  get availableButtonAttributes() {
    return this.attributeService.userEditorAndMetadataAttributes;
  }

  get selectedEvents() {
    let selectedEvent = this.contentEditorService.selectedEvent;
    return selectedEvent ? [selectedEvent] : [];
  }
}
