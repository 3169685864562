/* import __COLOCATED_TEMPLATE__ from './sms-keyword-response-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { BaseConfig, BlocksDocument } from '@intercom/embercom-prosemirror-composer';
import { action } from '@ember/object';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';

class ComposerConfig extends BaseConfig {
  placeholder = 'Your response...';
  autoFocus = false;
  textDirection = 'ltr';
  allowedBlocks = ['paragraph'];
  allowedInline = [];
  allowTextAlignment = false;
  allowImplicitMarginParagraphs = true;
  tools = [];
  experimental = {
    hideInsertersOnMouseOut: true,
  };
}

export default class SmsKeywordResponseEditor extends Component {
  @tracked blocksDoc;
  @tracked keywordInput;
  @tracked keywordResponseModel;

  @service appService;
  @service store;

  constructor() {
    super(...arguments);

    this.blocksDoc = new BlocksDocument(this.serializedBlocks);
    this.keywordResponseModel = this.args.keywordResponse;
    this.keywordInput = '';
  }

  get serializedBlocks() {
    return this.args.keywordResponse.jsonBlocks.serialize();
  }

  get composerConfig() {
    return new ComposerConfig();
  }

  get keywords() {
    return this.args.keywordResponse.keywords;
  }

  @action
  updateBlocks(blocksDoc) {
    let blockFragments = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
    this.args.keywordResponse.jsonBlocks = blockFragments;
    this.blocksDoc = blocksDoc;
    this.keywordResponseModel = this.args.keywordResponse;
  }

  @action
  onKeywordUpdate(text) {
    if (text.endsWith(' ')) {
      this.store.createRecord('sms/keyword', {
        keywordResponse: this.args.keywordResponse,
        name: text.trim(),
      });
      this.keywordInput = '';
    } else {
      this.keywordInput = text;
    }
  }

  @action
  deleteKeyword(keyword) {
    keyword.deleteRecord();
  }
}
