/* import __COLOCATED_TEMPLATE__ from './step-completed.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { captureException } from 'embercom/lib/sentry';

export default class StepCompleted extends Component {
  @service router;
  @service onboardingHomeExternalStepService;

  @dropTask
  *startNextTourOrGoToHome() {
    this.args.trackBannerClick('clicked', 'step_completed_banner');
    if (this.args.nextStep && this.args.nextStep.productTourId) {
      if (this.args.nextStep.hasGenerateDataCommand) {
        try {
          yield this.onboardingHomeExternalStepService.generateExampleDataForStep(
            this.args.nextStep.identifier,
          );
        } catch (e) {
          captureException(e, {
            fingerprint: ['generate_example_data', 'step-completed', this.args.stepIdentifier],
          });
          this.router.transitionTo('apps.app.home', {
            queryParams: { step: this.args.stepIdentifier },
          });
          return;
        }
      }
      this.onboardingHomeExternalStepService.start(this.args.nextStep.identifier, {
        source: 'banner',
      });
    } else {
      this.router.transitionTo('apps.app.home', {
        queryParams: { step: this.args.stepIdentifier },
      });
    }
  }
}
