/* import __COLOCATED_TEMPLATE__ from './list-header.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class ListHeader extends Component {
  @service intercomEventService;
  @service appService;

  get app() {
    return this.appService.app;
  }

  @action
  showGetStartedWithOutboundArticle() {
    window.Intercom('showArticle', 3292835); // https://www.intercom.com/help/en/articles/3292835-get-started-with-outbound
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'get_started_with_outbound_help_link',
      context: 'discovery-banner',
      place: 'outbound.list-header',
      section: 'discovery-banner',
    });
  }
}
