/* import __COLOCATED_TEMPLATE__ from './save-view-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import OutboundSavedViewData from 'embercom/objects/content-service/saved-views/outbound-saved-view-data';
import { Scope } from 'embercom/objects/content-service/saved-views/constants/scopes';
import { inject as service } from '@ember/service';

export default class SaveViewButton extends Component {
  @service outboundHomeService;
  @service router;

  constructor(owner, args) {
    super(owner, args);
  }

  get savedViewData() {
    return new OutboundSavedViewData({
      query: this.outboundHomeService.viewsFilterQuery,
      columns: this.outboundHomeService.viewsColumns,
    });
  }

  get scope() {
    return Scope.OUTBOUND;
  }

  @action onSave({ savedView }) {
    this.router.transitionTo('apps.app.outbound.views', savedView.id, {
      queryParams: { id: savedView.id },
    });
  }

  get isSaveEnabled() {
    if (this.args.isPresetViewChanged) {
      return true;
    } else if (this.outboundHomeService.view) {
      return !this.savedViewData.equals(
        new OutboundSavedViewData(this.outboundHomeService.view.savedViewData),
      );
    }
    return false;
  }
}
