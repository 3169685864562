/* import __COLOCATED_TEMPLATE__ from './delete-segment.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import SegmentService from 'embercom/lib/segments/segment-service';

interface Args {
  row: any;
}

export default class DeleteSegmentComponent extends Component<Args> {
  @service declare appService: any;
  @service declare modalService: any;

  @action
  requestDeletion() {
    let modalComponent = 'settings/modals/checking-dependents';
    let segment = this.args.row.data;
    let segmentService = SegmentService.create({
      appId: this.appService.app.id,
      segment,
    });
    let options = { dependentObjectService: segmentService };
    this.modalService.openModal(modalComponent, segment, options);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::People::DeleteSegment': typeof DeleteSegmentComponent;
  }
}
