/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class Preview extends Component {
  @service router;

  @action
  redirectToTollFreeVerificationForm() {
    return this.router.transitionTo('apps.app.settings.channels.sms.toll-free-verification', {
      queryParams: {
        showTollFreeVerificationForm: true,
      },
    });
  }
}
