/* import __COLOCATED_TEMPLATE__ from './integrate-with-crms.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { action } from '@ember/object';
import { readOnly } from '@ember/object/computed';
import { inject as service } from '@ember/service';

export default Component.extend({
  intercomEventService: service(),
  salesforceAppPackage: readOnly('step.modelData.salesforceAppPackage'),
  hubspotAppPackage: readOnly('step.modelData.hubspotAppPackage'),
  pipedriveAppPackage: readOnly('step.modelData.pipedriveAppPackage'),
  salesforceModalOpen: false,
  hubspotModalOpen: false,
  pipedriveModalOpen: false,
  selectedAppPackage: null,

  openModal: action(function (appPackage) {
    this.set('selectedAppPackage', appPackage);
    if (this.selectedAppPackage === this.salesforceAppPackage) {
      this.set('salesforceModalOpen', true);
    }
    if (this.selectedAppPackage === this.hubspotAppPackage) {
      this.set('hubspotModalOpen', true);
    }
    if (this.selectedAppPackage === this.pipedriveAppPackage) {
      this.set('pipedriveModalOpen', true);
    }
  }),

  closeModal: action(function () {
    this.set('salesforceModalOpen', false);
    this.set('hubspotModalOpen', false);
    this.set('pipedriveModalOpen', false);

    if (
      this.salesforceAppPackage.isInstalled ||
      this.hubspotAppPackage.isInstalled ||
      this.pipedriveAppPackage.isInstalled
    ) {
      this.intercomEventService.trackAnalyticsEvent({
        context: this.selectedAppPackage.id,
        action: 'app_package_installed',
        object: 'integrate_with_crms',
        ...this.step.analyticsMetadata,
      });
      this.completionCallback();
    }
  }),

  actions: {
    markAsCompleted() {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'step_skipped',
        object: 'integrate_with_crms',
        ...this.step.analyticsMetadata,
      });
      this.completionCallback();
    },
  },
});
