/* import __COLOCATED_TEMPLATE__ from './icon.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { ternary } from '@intercom/pulse/lib/computed-properties';
import { equal } from '@ember/object/computed';

export default Component.extend({
  state: null,
  iconName: null,
  number: null,
  iconSize: ternary('iconName', 24, 20),
  completed: equal('state', 'completed'),
  failed: equal('state', 'failed'),
});
