/* import __COLOCATED_TEMPLATE__ from './newsfeeds-select.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

export default class NewsfeedsSelect extends Component {
  @service newsfeedsService;
  @service intl;

  constructor() {
    super(...arguments);
    this.newsfeedsService.loadNewsfeeds.perform();
  }

  get targetedNewsfeeds() {
    return this.newsfeedsService.targetedNewsfeeds;
  }

  get selectedNewsfeedIds() {
    return this.args.newsItem.newsfeedIds || [];
  }

  get targetedNewsfeedsIds() {
    return this.selectedNewsfeedIds.filter((id) => Number(this.newsfeedWeb.id) !== id);
  }

  get items() {
    return this.targetedNewsfeeds.map(({ nameForDisplay, id }) => ({
      text: nameForDisplay,
      value: Number(id),
      isSelected: this.selectedNewsfeedIds.includes(Number(id)),
    }));
  }

  get newsfeedWeb() {
    return this.newsfeedsService.newsfeedWeb;
  }

  get assignedToNewsfeedWeb() {
    return this.newsfeedsService.assignedToNewsfeedWeb(this.args.newsItem);
  }

  get label() {
    if (this.targetedNewsfeedsIds.length > 0) {
      return this.intl.t('outbound.news-items.edit.sidebar.newsfeeds.selected', {
        count: this.targetedNewsfeedsIds.length,
      });
    }
    return this.intl.t('outbound.news-items.edit.sidebar.newsfeeds.placeholder');
  }

  @action
  onSelectItem(newsfeedIds) {
    if (this.assignedToNewsfeedWeb) {
      this.args.newsItem.newsfeedIds = [...newsfeedIds, Number(this.newsfeedWeb.id)];
    } else {
      this.args.newsItem.newsfeedIds = newsfeedIds;
    }
  }
}
