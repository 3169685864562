/* import __COLOCATED_TEMPLATE__ from './header.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { sendIntercomUpdate } from 'embercom/lib/intercom-widget-helper';
import { all } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type MessengerSettingsService from 'embercom/services/messenger-settings-service';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import safeWindowOpen from 'embercom/lib/safe-window-open';
import type MessengerInstallation from 'embercom/services/messenger-installation';
import RouteRegexes from 'embercom/lib/route-regexes';

interface Signature {
  Args: {
    model: {
      settings: any;
    };
    selectedTab?: string;
  };
}

export default class Header extends Component<Signature> {
  @service declare notificationsService: any;
  @service declare intl: any;
  @service declare appService: any;
  @service declare iamService: any;
  @service declare router: any;
  @service declare messengerSettingsService: MessengerSettingsService;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare messengerInstallation: MessengerInstallation;

  @tracked showEmptySlotGroupConfirmModal = false;

  get app() {
    return this.appService.app;
  }

  get activeBrand() {
    return this.args.model.settings.lookAndFeel.activeBrand;
  }

  get homeSettings() {
    return this.args.model.settings.home;
  }

  get isDisabled() {
    return this.isSaveRunning || !this.args.model?.settings.languages.isValid;
  }

  get emptySlotGroup() {
    return this.homeSettings.slotGroups.find((s: any) => s.isEmpty);
  }

  get emptyHubSpaces() {
    if (this.emptySlotGroup.targetUserType === 'mobile_users') {
      return this.args.model.settings.spaces.spacesForMobileUsers;
    } else if (this.emptySlotGroup.targetUserType === 'users') {
      return this.args.model.settings.spaces.spacesForUsers;
    } else {
      return this.args.model.settings.spaces.spacesForVisitors;
    }
  }

  get emptyHub() {
    return this.emptyHubSpaces.find((space: any) => space.isHub);
  }

  get isCompact() {
    return !!this.args.model?.settings?.form?.isCompact;
  }

  get isMobileRouteAndHasEmptySlotGroup() {
    return (
      this.emptySlotGroup.targetUserType === 'mobile_users' &&
      this.router.currentRouteName === 'apps.app.messenger.mobile-sdk'
    );
  }

  get isWebRouteAndHasEmptySlotGroup() {
    return (
      this.emptySlotGroup.targetUserType !== 'mobile_users' &&
      this.router.currentRouteName === 'apps.app.messenger.web'
    );
  }

  get messengerProductTourId() {
    return 530135;
  }

  get activeRouteHasEmptySlotGroup() {
    return this.isMobileRouteAndHasEmptySlotGroup || this.isWebRouteAndHasEmptySlotGroup;
  }

  get showOptInButton() {
    return (
      !!this.args.selectedTab &&
      this.app.canOptInToNewMessengerBeta &&
      !this.app.isOptedIntoConversationalMessengerBeta &&
      !this.isOptInRoute &&
      this.isMessengerInstalled
    );
  }

  get isMessengerInstalled() {
    return (
      this.messengerInstallation.isInstalledForWeb ||
      this.messengerInstallation.isInstalledForIos ||
      this.messengerInstallation.isInstalledForAndroid
    );
  }

  get isOptInRoute() {
    return !!this.router.currentRouteName?.match(RouteRegexes.newSettings.messenger.optIn);
  }

  get isConversationalGreetingsRoute() {
    return !!this.router.currentRouteName?.match(
      RouteRegexes.newSettings.messenger.conversational.greeting,
    );
  }

  get showSaveButton() {
    return (
      !!this.args.selectedTab &&
      this.args.selectedTab !== 'install' &&
      this.args.selectedTab !== 'opt-in'
    );
  }

  get hideLearnMoreButton() {
    return !this.args.selectedTab || this.args.selectedTab === 'opt-in';
  }

  get showOptOutButton() {
    return (
      !!this.args.selectedTab &&
      this.app.isOptedIntoConversationalMessengerBeta &&
      !this.isOptInRoute
    );
  }

  get isSwitchMessengerRoute() {
    return !!this.router.currentRouteName?.match(
      RouteRegexes.newSettings.messenger.switchMessenger,
    );
  }

  get hideSwitchMessengerButton() {
    return !this.app.canUseNewMessengerSettingGA || this.isSwitchMessengerRoute;
  }

  @action
  async submit() {
    if (
      !this.isCompact &&
      this.homeSettings.hasEmptySlotGroup &&
      this.emptyHub.enabled &&
      this.activeRouteHasEmptySlotGroup
    ) {
      this.showEmptySlotGroupConfirmModal = true;
    } else {
      await taskFor(this.save).perform();
      if (this.guideLibraryService.canUseGuideLibraryService) {
        await this.guideLibraryService.markStepCompleted(
          'guide_library_foundational_steps_customise_messenger',
        );
      }
    }
  }

  @action
  navigateToOptIn() {
    this.router.transitionTo('apps.app.settings.channels.messenger.opt-in');
  }

  @action
  navigateToSwitchMessengerPage() {
    this.router.transitionTo('apps.app.settings.channels.messenger.switch-messenger');
  }

  @action
  showTour() {
    window.Intercom('startTour', this.messengerProductTourId);
  }

  @action
  showManageArticle() {
    window.Intercom('showArticle', 6612589); // https://www.intercom.com/help/en/articles/6612589-set-up-and-customize-the-messenger
  }

  @action
  showMessengerBetaArticle() {
    window.Intercom('showArticle', 9319961); //https://www.intercom.com/help/en/articles/9319961-the-new-messenger-beta
  }

  @action
  openCustomizeMessengerCollecton() {
    safeWindowOpen(
      'https://www.intercom.com/help/en/collections/2094771-getting-started',
      '_blank',
    );
  }

  @task({ drop: true })
  *save(): TaskGenerator<void> {
    yield this.beforeSave();
    try {
      yield taskFor(this.saveSettings).perform();
      this.afterSave();
    } catch (e) {
      //Do nothing
    }
  }

  get isSaveRunning(): boolean {
    return taskFor(this.save).isRunning;
  }

  @task({ drop: true })
  *saveSettings(): TaskGenerator<void> {
    try {
      yield this.args.model.settings.save();
      this.notificationsService.notifyConfirmation(
        this.intl.t('messenger.settings-layout.header.success-notification'),
      );
    } catch (error) {
      if (error) {
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('messenger.settings-layout.header.failure-notification'),
        });
      }
      throw error;
    }
  }

  @action
  async beforeSave() {
    this.homeSettings.slotGroups.forEach((slotgroup: any) => {
      slotgroup.resetSlotDisplayOrder(slotgroup, slotgroup.slots);
    });

    if (this.args.model.settings.security.hasUnsavedChanges) {
      this.args.model.settings.security.updateParsedAllowedDomains();
    }

    let defaultBrand = this.args.model.settings.lookAndFeel.brands.find(
      (brand: any) => brand.isDefault,
    );
    if (defaultBrand.hasUnsavedChanges) {
      defaultBrand.copyM5SettingsToLookAndFeel();
    }
    await taskFor(this.saveBrandsWithChanges).perform();

    if (this.args.model.settings.lookAndFeel.hasUnsavedChanges) {
      this.args.model.settings.lookAndFeel.expandColors();
    }

    if (this.isConversationalGreetingsRoute) {
      this.args.model.settings.languages.isConversationalSettingsUpdate = true;
    }
  }

  @action
  afterSave() {
    if (this.isConversationalGreetingsRoute) {
      this.args.model.settings.languages.refresh();
    }
    if (this.args.model.settings.security.hasUnsavedChanges) {
      this.args.model.settings.security.refresh();
    }
    this.homeSettings.slotGroups.forEach((slotGroup: any) => {
      slotGroup.removeExternalLinksWithoutIds();
      slotGroup.removeTicketLinksWithoutIds();
    });

    if (this.homeSettings.hasUnsavedChanges) {
      let deleteSlotsWithNoId = (slotGroup: any) => {
        slotGroup.slots.filterBy('id', null).forEach((slot: any) => slot.deleteRecord());
        slotGroup.set('slotsWereDeleted', false);
      };
      this.homeSettings.slotGroups.forEach(deleteSlotsWithNoId);

      this.homeSettings.slotGroups.forEach((slotGroup: any) => {
        slotGroup.resetExternalLinksWereDeleted();
        slotGroup.resetTicketLinksWereDeleted();
      });
    }
    let spaces = this.args.model.settings.spaces;
    this.args.model.settings.notifyPropertyChange('hasM5UnsavedChanges');
    if (this.iamService.companyId) {
      sendIntercomUpdate({
        can_manage_messenger_settings: true,
        company: {
          id: this.iamService.companyId,
          widget_version: 5,
          messenger_spaces_enabled_for_users: spaces.userEnabledSpacesForCda,
          messenger_spaces_enabled_for_visitors: spaces.visitorEnabledSpacesForCda,
          messenger_spaces_enabled_for_mobile_users: spaces.mobileUserEnabledSpacesForCda,
        },
      });
    }
  }

  @task({ drop: true })
  *saveBrandsWithChanges() {
    let saveBrands: any[] = [];
    this.args.model.settings.lookAndFeel.brands
      .reject((brand: any) => brand.isDefault)
      .filter((brand: any) => brand.hasUnsavedChanges)
      .forEach((brand: any) => {
        brand.expandColors();
        saveBrands.push(taskFor(this.saveBrand).perform(brand));
      });

    yield all(saveBrands);
  }

  @task({ enqueue: true, maxConcurrency: 1 })
  *saveBrand(brand: any): TaskGenerator<any> {
    return yield brand.save();
  }

  @task({ drop: true })
  *saveActiveBrand() {
    yield this.activeBrand.save();
  }

  @action
  addApp() {
    this.messengerSettingsService.navigate('content', 'add-apps');
    this.showEmptySlotGroupConfirmModal = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Messenger::Header': typeof Header;
    'new-settings/channels/messenger/header': typeof Header;
  }
}
