/* import __COLOCATED_TEMPLATE__ from './set-fin-live.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import LoadFinProfileModule from 'embercom/lib/home/load-fin-profile';
import { dropTask } from 'ember-concurrency-decorators';

export default class SetFinLive extends Component {
  @service contentImportService;
  @service appService;
  @tracked ruleset = null;
  @service finOptInService;

  constructor() {
    super(...arguments);
    this.openStepCallback = this.args.openStepCallback || (() => {});
    this.loadFinProfile.perform();
  }

  get app() {
    return this.appService.app;
  }

  get hasNoSuccessfulContentImportRuns() {
    return !(this.contentImportService.successfulRuns.length > 0) && !this.args.step.completed;
  }

  get isMessengerNotInstalled() {
    return (
      !(this.app.first_identified_request_at || this.app.verifiedLoggedInInstallAt) &&
      !this.args.step.completed
    );
  }

  get isButtonDisabled() {
    return (
      this.isMessengerNotInstalled ||
      this.hasNoSuccessfulContentImportRuns ||
      this.args.step.completed
    );
  }

  @dropTask
  *loadFinProfile() {
    this.ruleset = yield LoadFinProfileModule.loadFinProfile(this);
  }

  @dropTask
  *setFinLive() {
    try {
      let ruleset = this.ruleset;
      if (!ruleset) {
        return;
      }

      yield ruleset.activate();
      if (!this.finOptInService.hasOptedInToFin) {
        yield this.finOptInService.postAcceptOptIn.perform();
      }
      this.args.completionCallback();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.set-fin-live.error-saving'),
      });
    }
  }
}
