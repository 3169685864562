/* import __COLOCATED_TEMPLATE__ from './get-started-with-series.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class GetStartedWithSeries extends Component {
  @service router;
  @service onboardingHomeExternalStepService;
  @service permissionsService;

  @action
  async getStarted() {
    await this.permissionsService.checkPermission('can_send_messages');

    this.onboardingHomeExternalStepService.start('get_started_with_series');
    this.router.transitionTo('apps.app.outbound.series');
  }
}
