/* import __COLOCATED_TEMPLATE__ from './default-help-center-option.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-and-data-setup */

import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';
import type HelpCenterSite from 'embercom/models/help-center-site';
import TruncateString from 'embercom/lib/truncate-string';

interface Args {
  icon: InterfaceIconName;
  type: 'card' | 'menu-item';
}

interface Signature {
  Element: HTMLElement;
  Args: Args;
}

export default class DefaultHelpCenterOption extends Component<Signature> {
  @service declare helpCenterService: $TSFixMe;
  @service declare router: RouterService;
  @service declare appService: $TSFixMe;
  @service intercomEventService: $TSFixMe;

  @tracked fetchingHelpCenters = true;
  @tracked defaultHelpCenter: HelpCenterSite | null = null;

  constructor(owner: $TSFixMe, args: Args) {
    super(owner, args);
    this.init();
  }

  async init() {
    await this.helpCenterService.maybeFetchSite(undefined, false);
    this.defaultHelpCenter = this.helpCenterService.sites.firstObject;
    this.fetchingHelpCenters = false;
  }

  get isActive() {
    if (!this.defaultHelpCenter) {
      return false;
    }
    let regex = new RegExp(
      String.raw`/apps\/${this.appService.app.id}/settings/helpcenter/${this.defaultHelpCenter.id}/(collections|settings)`,
    );
    return Boolean(this.router.currentURL.match(regex));
  }

  get defaultHelpCenterName() {
    return this.defaultHelpCenter?.name ?? '';
  }

  get truncatedDefaultHelpCenterName() {
    return TruncateString(this.defaultHelpCenterName, 25);
  }

  @action
  onClick() {
    if (this.defaultHelpCenter) {
      this.router.transitionTo(
        'apps.app.settings.helpcenter.workspace-helpcenter.collections',
        this.appService.app.id,
        this.defaultHelpCenter.id,
      );
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'default_help_center',
        place: 'new_settings',
      });
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::HelpCenter::DefaultHelpCenterOption': typeof DefaultHelpCenterOption;
    'new-settings/helpcenter/default-help-center-option': typeof DefaultHelpCenterOption;
  }
}
