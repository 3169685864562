/* import __COLOCATED_TEMPLATE__ from './ew-chatbot-set-fin-live.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { type StepData } from '../../wizard';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { dropTask } from 'ember-concurrency-decorators';
import LoadFinProfileModule from 'embercom/lib/home/load-fin-profile';
import type IntlService from 'ember-intl/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { type TaskGenerator } from 'ember-concurrency';
import type ContentImportService from 'embercom/services/content-import-service';
import { action } from '@ember/object';
// @ts-ignore
import { globalRef } from 'ember-ref-bucket';
import confetti from 'canvas-confetti';
import ENV from 'embercom/config/environment';
import { get } from 'embercom/lib/ajax';
import { assetUrl } from '@intercom/pulse/helpers/asset-url';

interface Signature {
  Element: never;
  Args: Args;
}

interface Args {
  step: any;
  stepData: StepData;
}

export default class EwChatbotSetFinLive extends Component<Signature> {
  @service declare appService: any;
  @service finOptInService: any;
  @service notificationsService: any;
  @service intercomEventService: any;
  @service intl!: IntlService;
  @service router: any;
  @service declare contentImportService: ContentImportService;
  @service onboardingHomeExternalStepService: any;

  @tracked ruleset: any = null;
  @tracked sampleQuestions: any = undefined;
  @tracked showConfettiCanvas = false;

  @globalRef('guide-library-fin-confetti-canvas') declare confettiCanvas?: HTMLCanvasElement;

  setFinLiveTask: any = null;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    taskFor(this.loadFinProfile).perform();
    taskFor(this.loadSampleQuestions).perform();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'set_fin_live_step',
      context: `onboarding_guide_library.wizard.ai_bot.set_fin_live`,
      place: 'ai_bot_wizard',
      section: 'ai_bot_set_fin_live_step',
    });
  }

  get hasSampleQuestions(): boolean {
    return this.sampleQuestions?.length > 0;
  }

  get isFinSetLiveRunning(): boolean {
    return (taskFor(this.setFinLive) as any).isRunning;
  }

  get hasImportsInProgress(): boolean {
    return this.contentImportService.inProgressRunExists;
  }

  get hasFailedImports(): boolean {
    return Boolean(this.contentImportService.failedRuns.length > 0);
  }

  get hasAtleastOneSuccessfulImport(): boolean {
    return Boolean(this.contentImportService.successfulRuns.length > 0);
  }

  get hasSupportContentImports(): boolean {
    return Boolean(this.contentImportService.contentImportSources?.length);
  }

  get disableSetFinLiveButton(): boolean {
    if (this.hasAtleastOneSuccessfulImport && !this.hasImportsInProgress) {
      return false;
    }
    return true;
  }

  get hasOptedInToFin(): boolean {
    return this.finOptInService.hasOptedInToFin;
  }

  get isRulesetLive(): boolean {
    let ruleset = this.ruleset;
    if (!ruleset) {
      return false;
    }
    let rulesetLink = ruleset?.rulesetLinks?.firstObject?.object || {};
    if (!rulesetLink) {
      return false;
    }

    return rulesetLink.useAiAnswers && ruleset.isLive;
  }

  get isPauseFinRunning(): boolean {
    return (taskFor(this.pauseFin) as any).isRunning;
  }

  @dropTask
  *pauseFin(): any {
    let ruleset = this.ruleset;
    if (!ruleset) {
      return;
    }
    let rulesetLink = ruleset?.rulesetLinks?.firstObject?.object || {};
    if (!rulesetLink) {
      return;
    }

    yield ruleset.deactivate();
    rulesetLink.useAiAnswers = false;
    yield ruleset.save();
  }

  @dropTask
  *loadFinProfile(): TaskGenerator<void> {
    this.ruleset = yield LoadFinProfileModule.loadFinProfile(this);
  }

  @dropTask
  *loadSampleQuestions(): TaskGenerator<void> {
    let questions = yield get('/ember/onboarding/fin_sample_questions', {
      app_id: this.appService.app.id,
    });

    if (questions?.length > 0) {
      this.sampleQuestions = questions.slice(0, 3).map((text: string, index: number) => ({
        imageLocation: assetUrl(
          `/assets/images/onboarding/fin-preview/question-card-person-${index + 1}.png`,
        ),
        text,
      }));
    }
  }

  @dropTask
  *setFinLive(): any {
    try {
      let ruleset = this.ruleset;
      if (!ruleset) {
        return;
      }
      let rulesetLink = ruleset?.rulesetLinks?.firstObject?.object || {};
      if (!rulesetLink) {
        return;
      }

      rulesetLink.useAiAnswers = true;

      yield ruleset.save();
      yield ruleset.activate();
      if (!this.hasOptedInToFin) {
        yield this.finOptInService.postAcceptOptIn.perform();
      }
      this.showConfettiCanvas = true;
      setTimeout(() => {
        this.fireConfettiAnimation();
      }, ENV.APP._500MS);
      setTimeout(() => {
        this.args.stepData?.continueCallback?.();
      }, ENV.APP._3000MS);
      this.notificationsService.notifyConfirmation(
        this.intl.t('onboarding.home.steps-header.ew-chatbot-set-fin-live.success-message'),
      );
    } catch (error) {
      console.error(error);
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.set-fin-live.error-saving'),
      });
    }
  }

  @action
  async skipStep(): Promise<void> {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'skipped',
      object: 'set_fin_live_not_ready_button',
      place: 'ai_bot_wizard',
      context: 'onboarding_guide_library.wizard.ai_bot.set_fin_live',
      section: 'ai_bot_set_fin_live_step.not_ready_yet_btn',
    });
    this.args.stepData?.onSkipCallback?.();
  }

  @action
  sendMessageToMessenger(message: string): void {
    let finPreview: any = window.document.querySelector('#hosted-messenger-fin-demo-preview');
    finPreview?.contentWindow?.postMessage(
      JSON.stringify({ type: 'intercom-send-message', message }),
      '*',
    );
  }

  @action
  async manageSettings(): Promise<void> {
    this.onboardingHomeExternalStepService.startAfterTransition('display_only_setup_fin', {
      source: this.args.step.identifier,
      method: 'startAfterTransition',
    });
    this.router.transitionTo('apps.app.operator');
  }

  private fireConfettiAnimation() {
    if (!this.confettiCanvas) {
      return;
    }
    let confettiObj = confetti.create(this.confettiCanvas, {
      resize: true,
    });
    confettiObj({
      particleCount: 600,
      spread: 300,
      origin: { y: 0.3 },
      ticks: 2000,
      gravity: 2,
    });
    setTimeout(() => {
      this.showConfettiCanvas = false;
    }, ENV.APP._2000MS);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::AiBotWizard::EwChatbotSetFinLive': typeof EwChatbotSetFinLive;
  }
}
