/* import __COLOCATED_TEMPLATE__ from './column-selector.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { SELECTABLE_COLUMNS } from 'embercom/lib/outbound/constants';

export default class ColumnSelector extends Component {
  @service intl;
  @service outboundHomeService;

  constructor() {
    super(...arguments);

    // Only evaluate this once, not each time the outboundHomeService changes.
    this.selectedColumns = this.outboundHomeService.selectedTableColumns;
  }

  get columns() {
    return SELECTABLE_COLUMNS.map((column) => {
      let data = column;
      if (column.labelIntlKey) {
        data['text'] = this.intl.t(column.labelIntlKey);
      }
      data['isSelected'] = this.selectedColumns.includes(column.value);
      return data;
    });
  }
}
