/* import __COLOCATED_TEMPLATE__ from './test-email.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type RouterService from '@ember/routing/router-service';
import { InboxCategory } from 'embercom/models/data/inbox/inbox-categories';
import { InboxType } from 'embercom/models/data/inbox/inbox-types';
import { action } from '@ember/object';

//@ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import { startSurvey } from 'embercom/lib/intercom-widget-helper';
import { EMAIL_FORWARDING_FEEDBACK_SURVEY } from 'embercom/lib/channels/constants';

interface TestEmailArgs {
  subject: string;
  body: string;
}

export default class TestEmail extends Component<TestEmailArgs> {
  @service declare appService: any;
  @service declare router: RouterService;
  @service declare purchaseAnalyticsService: any;

  encondedEmail: string = encodeURIComponent(this.inboxEmail);
  encodedSubject: string = encodeURIComponent(this.args.subject);
  encodedBody: string = encodeURIComponent(this.args.body);

  get gmailLink() {
    return sanitizeHtml(
      `https://mail.google.com/mail/?view=cm&fs=1&to=${this.encondedEmail}&su=${this.encodedSubject}&body=${this.encodedBody}`,
    );
  }

  get outlookLink() {
    return sanitizeHtml(
      `https://outlook.office.com/mail/deeplink/compose?to=${this.encondedEmail}&subject=${this.encodedSubject}&body=${this.encodedBody}`,
    );
  }

  get yahooLink() {
    return sanitizeHtml(
      `https://compose.mail.yahoo.com/?to=${this.encondedEmail}&subject=${this.encodedSubject}&body=${this.encodedBody}`,
    );
  }

  get inboxEmail() {
    return `support@${this.appService.app.email_sender_domain}`;
  }

  get inboxLink() {
    return this.router.urlFor(
      'inbox.workspace.inbox.inbox',
      this.appService.app.id,
      InboxCategory.Shared,
      InboxType.Unassigned,
    );
  }

  @action
  trackClickEvent(objectName: string) {
    this.purchaseAnalyticsService.trackEvent({
      action: 'clicked',
      context: 'email-forwarding-experiment',
      place: 'settings_channels_email',
      object: objectName,
    });
  }

  @action
  openSurvey() {
    this.trackClickEvent('survey-button');
    startSurvey(EMAIL_FORWARDING_FEEDBACK_SURVEY);
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::TestEmail': typeof TestEmail;
  }
}
