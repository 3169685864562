/* import __COLOCATED_TEMPLATE__ from './fin-contract-resolutions-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type FinTrialService from 'embercom/services/fin-trial-service';
import { AiAnswersState } from 'embercom/lib/operator/fin/types';

interface ContractResolutionsBannerArgs {
  usingAiAnswers: boolean;
  context?: 'setup';
}

interface Signature {
  Args: ContractResolutionsBannerArgs;
  Element: HTMLDivElement;
}

export default class FinContractResolutionsBanner extends Component<Signature> {
  @service declare finTrialService: FinTrialService;

  get showBanner() {
    return (
      !this.args.usingAiAnswers &&
      this.finTrialService.aiAnswersState === AiAnswersState.inactive &&
      this.finTrialService.isSalesforceContracted &&
      !this.finTrialService.isContractedForFin
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::FinContractResolutionsBanner': typeof FinContractResolutionsBanner;
  }
}
