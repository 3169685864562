/* import __COLOCATED_TEMPLATE__ from './first-path-preview.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';

export default class FirstPathPreview extends Component {
  get previewSteps() {
    let steps = [];
    let firstPathSteps = this.args.path.steps;
    for (let i = 0; i < firstPathSteps.length; i++) {
      let step = firstPathSteps.objectAt(i);
      steps.push(step);

      if (step.isFreeInput) {
        break;
      }
    }
    return steps;
  }

  get showOperatorPart() {
    return this.previewSteps.some(
      (step) => step.hasBlocks || (step.isAttributeCollector && step.attributeIdentifiers),
    );
  }

  get pathEndStep() {
    return this.previewSteps.lastObject.isEndStateStep ? this.previewSteps.lastObject : undefined;
  }

  get showComposer() {
    return this.previewSteps.lastObject.isFreeInput || this.previewSteps.lastObject.isAnswerBot;
  }
}
