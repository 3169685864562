/* import __COLOCATED_TEMPLATE__ from './inbox-external-step.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { INBOX_ESSENTIAL_ID } from 'embercom/lib/billing';
import { captureException } from 'embercom/lib/sentry';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';

export default class InboxExternalStepComponent extends Component {
  @service router;
  @service hostedMessengerConversationService;
  @service onboardingHomeService;
  @service navbarCollapsingService;
  @service appService;
  @service onboardingHomeExternalStepService;
  @service store;
  @service intl;
  @service inbox2OptIn;
  @service intercomEventService;
  @service permissionsService;

  @tracked needsHostedMessengerConversation =
    this.args.needsHostedMessengerConversation === undefined ||
    this.args.needsHostedMessengerConversation === null
      ? true
      : this.args.needsHostedMessengerConversation;

  @tracked collapsedSubmenuOnTour =
    this.args.collapsedSubmenuOnTour === undefined ? true : this.args.collapsedSubmenuOnTour;

  dependentStepIdentifier = 'send_test_message';
  inboxEssentialPlanId = INBOX_ESSENTIAL_ID;

  get dependentStepTitle() {
    return '';
  }

  get app() {
    return this.appService.app;
  }

  get adminHasInboxAccess() {
    return this.app.currentAdminHasInboxAccess;
  }

  get adminDoesNotHaveInboxAccess() {
    return !this.adminHasInboxAccess;
  }

  get isOnFreeWithInboxAccess() {
    return this.app.isOnFree && this.app.currentAdminHasInboxAccess;
  }

  get conversationId() {
    return this.hostedMessengerConversationService.latestConversationId;
  }

  get missingConversationId() {
    return !this.conversationId;
  }

  get sendTestMessageStep() {
    return this.onboardingHomeService.guide.findStepByIdentifier('send_test_message');
  }

  get sendTestMessageStepCompleted() {
    return this.sendTestMessageStep && this.sendTestMessageStep.completed;
  }

  get showDependentStepWarning() {
    return this.needsHostedMessengerConversation && this.missingConversationId;
  }

  @dropTask
  *completeStep() {
    yield this.args.completionCallback?.();
  }

  constructor() {
    super(...arguments);
    if (this.needsHostedMessengerConversation && this.sendTestMessageStepCompleted) {
      this.hostedMessengerConversationService.fetchConversationIdIfNecessary.perform();
    }
  }

  trackClickedAnalyticsEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.step.analyticsMetadata,
      action: 'clicked',
      object: 'go_to_inbox',
      section: 'inline_step',
    });
  }

  async _generateAndLoadExampleData() {
    await this._generateExampleData();
    return this._loadExampleData();
  }

  async _generateExampleData() {
    if (!this.args.generateExampleData) {
      return;
    }
    try {
      await this.onboardingHomeExternalStepService.generateExampleDataForStep(
        this.args.step.identifier,
      );
    } catch (e) {
      captureException(e, {
        fingerprint: ['generate_example_data', this.args.step.identifier, e.message],
        extra: {
          conversation_id: this.conversationId,
        },
      });
      throw e;
    }
  }

  _loadExampleData() {
    switch (this.args.step.identifier) {
      case 'respond_faster':
        if (this.app.savedReplies.length) {
          return;
        }
        return this.store.findAll('saved-reply');
    }
  }

  @action
  async transitionToOnboardingInboxFlow() {
    this.trackClickedAnalyticsEvent();
    if (this.args.step.identifier === 'automatic_assignments') {
      await this.permissionsService.checkPermission('can_manage_inbox_rules');
    }

    await this._generateAndLoadExampleData();

    if (this.collapsedSubmenuOnTour) {
      this.navbarCollapsingService.setCollapsedSubmenu('Inbox'); // eslint-disable-line @intercom/intercom/no-bare-strings
    } else {
      this.navbarCollapsingService.removeCollapsedSubmenu('Inbox'); // eslint-disable-line @intercom/intercom/no-bare-strings
    }

    this.onboardingHomeExternalStepService.startAfterTransition(this.args.step.identifier, {
      source: 'home/inbox-external-step transitionToOnboardingInboxFlow', // eslint-disable-line @intercom/intercom/no-bare-strings
      method: 'startAfterTransition',
    });

    if (this.args.step.identifier === 'automatic_assignments') {
      let rulesRoute = 'apps.app.settings.inbox-rules.list';
      return this.router.transitionTo(rulesRoute);
    }

    if (!this.needsHostedMessengerConversation) {
      this.router.transitionTo(this.app.inboxRoute);
      return;
    }

    let isReplyInInbox = this.args.step.identifier === 'reply_in_inbox';

    // New users see a shorter onboarding flow
    if (isReplyInInbox) {
      if (this.app.canUseFeature('inbox_2_new_user_onboarding')) {
        return this.router.transitionTo('inbox.workspace.onboarding', this.app.id, {
          queryParams: { type: 'short', from: 'onboarding' },
        });
      }

      return this.router.transitionTo('inbox.workspace.onboarding', this.app.id, {
        queryParams: { from: 'onboarding' },
      });
    }

    let isAllConversationRedirectNeeded = [
      'respond_faster',
      'collaborate_for_better_support',
    ].includes(this.args.step.identifier);
    if (isAllConversationRedirectNeeded && this.inbox2OptIn.hasCompletedOnboarding) {
      return this.router.transitionTo(
        'inbox.workspace.inbox.inbox.conversation.conversation',
        this.app.id,
        'shared',
        'all',
        this.conversationId,
      );
    }

    this.router.transitionTo(
      this.app.inboxConversationsConversationRoute,
      this.app.currentAdmin.email,
      this.conversationId,
    );
  }
}
