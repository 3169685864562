/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { modifier } from 'ember-modifier';
import ClipboardJS from 'clipboard';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';
import type HelpCenterSite from 'embercom/models/help-center-site';
import { captureException } from 'embercom/lib/sentry';

interface Args {
  selectedHelpCenter?: HelpCenterSite;
  allPrerequisitesDone: boolean;
}

interface Signature {
  Args: Args;
  Element: Element;
}

export default class TicketsPortalSettings extends Component<Signature> {
  @service declare notificationsService: $TSFixMe;
  @service declare intl: IntlService;

  clipboard: ClipboardJS | null = null;

  constructor(owner: $TSFixMe, args: $TSFixMe) {
    super(owner, args);
  }

  get isPortalLive() {
    return this.args.selectedHelpCenter?.ticketsPortalTurnedOn ?? false;
  }

  get ticketsPortalUrl() {
    return this.args.selectedHelpCenter?.ticketsPortalUrl ?? '';
  }

  copyTicketsPortalContainer = modifier(
    () => {
      this.onRender();
    },
    { eager: false },
  );

  onRender() {
    let container = document.querySelector('#tickets-portal-copy-url-container');
    let copyUrlButton = container?.querySelector('.form__input-suffix');

    if (copyUrlButton) {
      copyUrlButton.classList.add('bg-white', 'cursor-pointer');
      copyUrlButton.setAttribute('data-clipboard-text', this.ticketsPortalUrl);

      if (!this.clipboard) {
        this.clipboard = new ClipboardJS(copyUrlButton);
        this.clipboard.on('success', () => {
          this.notificationsService.notifyConfirmation(
            this.intl.t('new-settings.helpdesk.tickets.tickets-portal.settings.copy-url-success'),
          );
        });
        this.clipboard.on('error', (err) => {
          console.error(err);
          this.notificationsService.notifyWarning(
            this.intl.t('new-settings.helpdesk.tickets.tickets-portal.settings.copy-url-error'),
          );
        });
      }
    }
  }

  @action
  togglePortal() {
    if (this.args.selectedHelpCenter) {
      this.args.selectedHelpCenter.ticketsPortalTurnedOn = !this.isPortalLive;
    }
  }

  willDestroy() {
    super.willDestroy();
    try {
      this.clipboard?.destroy();
    } catch (e) {
      captureException(e, {
        fingerprint: ['clipboard.destroy', 'new-settings', 'tickets-portal'],
      });
    }
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tickets::TicketsPortal::Settings': typeof TicketsPortalSettings;
    'new-settings/helpdesk/tickets/tickets-portal/settings': typeof TicketsPortalSettings;
  }
}
