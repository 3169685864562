/* import __COLOCATED_TEMPLATE__ from './inbox.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import Component from '@glimmer/component';

interface Args {
  state: any;
  isFirstStep: boolean;
  showBiggerPulse: boolean;
}

interface Signature {
  Args: Args;
  Element: never;
}

// eslint-disable-next-line ember/no-empty-glimmer-component-classes
export default class Inbox extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::InboxTour::Inbox': typeof Inbox;
  }
}
