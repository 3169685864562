/* import __COLOCATED_TEMPLATE__ from './side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import templateOnlyComponent from '@ember/component/template-only';
import type ExternalContent from 'embercom/models/external-content';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    onClose: () => void;
    onUpdate: () => void;
    isOpen: boolean;
    externalContent?: ExternalContent;
  };
}

const SideDrawer = templateOnlyComponent<Signature>();
export default SideDrawer;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::ExternalContent::View::SideDrawer': typeof SideDrawer;
  }
}
