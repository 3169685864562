/* import __COLOCATED_TEMPLATE__ from './ai-answers-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */

import templateOnlyComponent from '@ember/component/template-only';
interface Signature {
  Args: {
    isFromOnboardingHome: boolean;
    messengerCardButtonClicked?: () => void;
  };
}
const AiAnswersBanner = templateOnlyComponent<Signature>();
export default AiAnswersBanner;
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::AiAnswersBanner': typeof AiAnswersBanner;
  }
}
