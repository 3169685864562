/* import __COLOCATED_TEMPLATE__ from './empty-filter-state.hbs'; */
/* RESPONSIBLE TEAM: team-pricing-and-packaging */
import templateOnlyComponent from '@ember/component/template-only';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    resetFilters: () => void;
  };
}

const EmptyFilterState = templateOnlyComponent<Signature>();
export default EmptyFilterState;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::CustomBots::EmptyFilterState': typeof EmptyFilterState;
  }
}
