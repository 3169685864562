/* import __COLOCATED_TEMPLATE__ from './proactive-support-with-outbound.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

export default class InviteTeammates extends Component {
  @service router;
  @service intercomEventService;

  @action
  playVideo(target) {
    target.play();
  }

  @action
  trackEvent() {
    this.intercomEventService.trackAnalyticsEvent({
      object: 'proactive_support_outbound_message',
      place: 'onboarding_home',
      context: 'create-message-button-clicked',
      section: 'proactive_support_with_outbound',
      action: 'clicked',
    });
  }
}
