/* import __COLOCATED_TEMPLATE__ from './phone-call-bot-channel.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type Router from '@ember/routing/router-service';

interface Signature {
  Args: {
    section: any;
    config: any;
    isDisabled: boolean;
  };
}

export default class PhoneCallBotChannel extends Component<Signature> {
  @service declare contentEditorService: any;
  @service declare router: Router;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::CustomBots::PhoneCallBotChannel': typeof PhoneCallBotChannel;
  }
}
