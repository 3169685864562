/* import __COLOCATED_TEMPLATE__ from './progress-bar.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import Component from '@glimmer/component';

interface Signature {
  Element: never;
  Args: {
    totalSteps: number;
    currentStep: number;
  };
}

export default class ProgressBar extends Component<Signature> {
  get stepCounts(): Array<string> {
    let stepCounts = new Array(this.args.totalSteps);
    stepCounts[this.args.currentStep - 1] = 'active';
    return stepCounts;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Common::ProgressBar': typeof ProgressBar;
  }
}
