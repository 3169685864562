/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';

interface Args {
  selfAssignedSelection: any;
  saveSelfAssignSetting: (value?: boolean) => {};
  model: any;
  currentShowTeammatesPresence: any;
  updateTrackedProperty: (key: string, value: any) => {};
  initialInactivityEnabled: boolean;
  initialInactivityThreshold: number;
  followingInactivityEnabled: boolean;
  followingInactivityThreshold: number;
  autoAwaySetting: any;
  saveTeammatePresenceSettings: () => {};
  persistAutoAwaySetting: () => {};
}

export default class General extends Component<Args> {
  @service declare router: RouterService;
  @service declare intercomEventService: any;

  @action
  openWorkflows() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'go_to_workflows_button',
      place: 'assignments_settings',
      section: 'general_workflows',
    });
    this.router.transitionTo('apps.app.automation.workflows-overview');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Helpdesk::Tabs::General': typeof General;
    'new-settings/helpdesk/tabs/general': typeof General;
  }
}
