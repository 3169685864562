/* import __COLOCATED_TEMPLATE__ from './preview.hbs'; */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { getTemplate } from 'embercom/lib/outbound/email-template-helper';
import { copy } from 'ember-copy';

/* RESPONSIBLE TEAM: team-proactive-support */

import { PREVIEW_DEVICE_OPTIONS } from '../../../../../lib/outbound/constants';
import EmailContentHandler from 'embercom/lib/outbound/email-content-handler';
import { templateSwitcherOptions } from 'embercom/models/data/outbound/constants';

export default class Preview extends Component {
  DEVICE_WEB = 'web';
  DEVICE_MOBILE = 'mobile';

  previewSwitcherDeviceOptions = PREVIEW_DEVICE_OPTIONS;
  contentHandler = new EmailContentHandler(this.store);
  originalEmailBlocks;

  @service store;
  @service intercomEventService;

  @tracked currentDevice = this.previewSwitcherDeviceOptions[0].device;
  @tracked selectedValue = templateSwitcherOptions.emailContent;

  constructor() {
    super(...arguments);
    this.originalEmailBlocks = copy(this.localizedEmailContent.blocks, true);
  }

  get localizedEmailContent() {
    // the question mark here is needed due to an ember.js framework bug
    // https://github.com/emberjs/ember.js/issues/18969
    return this.args.email?.localizedEmailContents.firstObject;
  }

  @action
  updateCurrentPreviewDevice(device) {
    this.currentDevice = device;
  }

  @action async onSelectionChange(value) {
    this.selectedValue = value;
    let template = await getTemplate(
      this.localizedEmailContent.emailTemplateType,
      this.localizedEmailContent.emailTemplateId,
      this.store,
    );
    let newBlocks;

    if (value === templateSwitcherOptions.templateContent) {
      newBlocks = this.contentHandler.createFragments(template.contentBlocks);
    } else {
      newBlocks = this.contentHandler.getBlocksForTemplate(this.originalEmailBlocks, template);
    }

    this.switchTemplateContent(newBlocks);
    this.args.templateSwitcherObject.switcherValue = value;
    this.trackAnalyticsEvent(
      value === templateSwitcherOptions.emailContent
        ? 'keep_email_content'
        : 'keep_template_content',
    );
  }

  switchTemplateContent(newBlocks) {
    let previewEmailContent = this.localizedEmailContent;
    previewEmailContent.blocks = newBlocks;

    this.args.email.localizedEmailContents = [previewEmailContent];
  }

  trackAnalyticsEvent(object) {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object,
    });
  }
}
