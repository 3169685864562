/* import __COLOCATED_TEMPLATE__ from './sidebar.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import showComposerSidebar from 'embercom/lib/outbound/composer-side-bar-helper';
import { DOCUMENT_TYPE } from '../../../email/composer/sidebar';

export default class Sidebar extends Component {
  documentType = DOCUMENT_TYPE.EMAIL_TEMPLATE;

  get showComposerSidebar() {
    return showComposerSidebar(this.args.composerApi?.composer.state.selectionAnchorNodeType.name);
  }
}
