/* import __COLOCATED_TEMPLATE__ from './domain-editor.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';

export default class DomainEditor extends Component {
  @service notificationsService;
  @service store;

  @task
  *createClickTrackingDomainOptOut(domain) {
    let clickTrackingDomainOptOut = this.store.createRecord('click-tracking-domain-opt-out', {
      domain,
    });
    try {
      yield clickTrackingDomainOptOut.save();
      this.notificationsService.notifyConfirmation(`${domain} added`);
      this.args.onClose();
    } catch (error) {
      this.store.unloadRecord(clickTrackingDomainOptOut);
      this.notificationsService.notifyResponseError(
        error,
        { default: 'Could not add the domain' },
        { responseProvidedErrors: [403, 409, 422] },
      );
    }
  }
}
