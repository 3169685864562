/* import __COLOCATED_TEMPLATE__ from './unauthenticated-domain-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { isPresent } from '@ember/utils';

export default class UnauthenticatedDomainBanner extends Component {
  get hasAuthenticatedDomain() {
    return isPresent(this.args.domains.findBy('isAuthenticated'));
  }
}
