/* import __COLOCATED_TEMPLATE__ from './ew-chatbot-meet-fin.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { type StepData } from '../../wizard';
import { modifier } from 'ember-modifier';
import { ref } from 'ember-ref-bucket';

interface Signature {
  Element: never;
  Args: Args;
}

interface Args {
  step: any;
  stepData: StepData;
}

export default class EwChatbotMeetFin extends Component<Signature> {
  @service declare intercomEventService: any;
  @ref('videoElement') videoElement!: HTMLVideoElement;
  @tracked isMessengerVideoPlaying = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'meet_fin_step',
      context: `onboarding_guide_library.wizard.ai_bot.meet_fin_step`,
      place: 'ai_bot_wizard',
      section: 'ai_bot_meet_fin_step',
    });
  }

  autoPlayVideo = modifier((videoElement: HTMLVideoElement) => {
    let readyFn = () => {
      if (videoElement.readyState >= 2) {
        videoElement.currentTime = 0;
        videoElement.muted = true;
        videoElement.play();
      }
    };

    let playFn = () => {
      this.isMessengerVideoPlaying = true;
    };

    let endedFn = () => {
      this.isMessengerVideoPlaying = false;
    };

    videoElement.addEventListener('loadeddata', readyFn);
    videoElement.addEventListener('play', playFn);
    videoElement.addEventListener('ended', endedFn);

    return function () {
      videoElement.removeEventListener('loadeddata', readyFn);
      videoElement.removeEventListener('play', playFn);
      videoElement.removeEventListener('ended', endedFn);
    };
  });

  @action replayVideo(): void {
    if (this.videoElement && (this.videoElement.paused || this.videoElement.ended)) {
      this.videoElement.currentTime = 0;
      this.videoElement.muted = true;
      this.videoElement.play();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::AiBotWizard::EwChatbotMeetFin': typeof EwChatbotMeetFin;
  }
}
