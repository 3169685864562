/* import __COLOCATED_TEMPLATE__ from './team-pill.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Signature {
  Args: {
    teamId?: string;
  };
}

export default class TeamPill extends Component<Signature> {
  @service declare appService: $TSFixMe;

  get team() {
    return this.appService.app.teams.findBy('id', this.args.teamId);
  }

  get teamEmoji() {
    return this.team?.avatar_emoji;
  }

  get teamName() {
    return this.team?.name;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::OfficeHours::TeamPill': typeof TeamPill;
    'new-settings/workspace/office-hours/team-pill': typeof TeamPill;
  }
}
