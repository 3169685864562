/* import __COLOCATED_TEMPLATE__ from './identity-verification-ios.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
interface Signature {
  Element: HTMLDivElement;
  Args: {
    selectedStep: string;
    onOpenStepChange: (step: string) => void;
    userSources: $TSFixMe;
    apiSecret: string;
    toggleHashCheckerVisibility: (visibility: boolean, secret: string) => void;
  };
}

const maskedSecret = '****************************************';

export default class IdentityVerificationIos extends Component<Signature> {
  @tracked showSecret = false;
  @tracked showUserHashCheckerModal = false;

  get maskedSecret() {
    return maskedSecret;
  }

  @action
  toggleSecretVisibility(show: boolean) {
    this.showSecret = show;
  }

  @action
  toggleHashCheckerVisibility(show: boolean) {
    this.showUserHashCheckerModal = show;
  }
}
declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::IdentityVerificationIos': typeof IdentityVerificationIos;
    'new-settings/workspace/security/identity-verification-ios': typeof IdentityVerificationIos;
  }
}
