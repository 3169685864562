/* import __COLOCATED_TEMPLATE__ from './auto-reply.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { enqueueTask } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import { taskFor } from 'ember-concurrency-ts';
import { tracked } from '@glimmer/tracking';

interface Args {
  settings: $TSFixMe;
}

export default class AutoReply extends Component<Args> {
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;

  @tracked showSideDrawer = false;

  @enqueueTask
  *updateSetting() {
    try {
      yield this.args.settings.updateSetting();
      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.update_success'),
      );
    } catch (error) {
      this.args.settings.toggleProperty('autoReplyEnabled');
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  toggleAutoReply() {
    this.args.settings.toggleProperty('autoReplyEnabled');
    taskFor(this.updateSetting).perform();
  }

  @action
  openSideDrawer() {
    this.showSideDrawer = true;
  }

  @action
  closeSideDrawer() {
    this.showSideDrawer = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::AutoReply': typeof AutoReply;
  }
}
