/* import __COLOCATED_TEMPLATE__ from './contact.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';

export interface Args {
  integrationCode: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class Contact extends Component<Signature> {
  @service declare intl: IntlService;

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.config-import-page.sections.contact.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::Sections::Contact': typeof Contact;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections/contact': typeof Contact;
  }
}
