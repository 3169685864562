/* import __COLOCATED_TEMPLATE__ from './template-style-settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { DEFAULT_MESSENGER_COLORS } from 'embercom/lib/messenger-settings-defaults';
import {
  DEFAULT_BODY_BACKGROUND,
  DEFAULT_CONTAINER_BACKGROUND,
  DEFAULT_H1_FONT_SIZE,
  DEFAULT_H2_FONT_SIZE,
  DEFAULT_PARAGRAPH_FONT_SIZE,
  DEFAULT_SMALL_FONT_SIZE,
  DEFAULT_TEMPLATE_FONT_COLOR,
  DEFAULT_LINK_FONT_COLOR,
} from 'embercom/lib/outbound/template-constants';

const FONT_STYLES = {
  bold: 'bold',
  italic: 'italic',
  normal: 'normal',
};

export default class TemplateStyleSettings extends Component {
  colorOptions = DEFAULT_MESSENGER_COLORS;

  get selectedBodyBackgroundColor() {
    return this.args.template.css?.bodyBackgroundColor || DEFAULT_BODY_BACKGROUND;
  }

  get selectedContainerBackgroundColor() {
    return this.args.template.css?.containerBackgroundColor || DEFAULT_CONTAINER_BACKGROUND;
  }

  get h1FontSize() {
    return this.args.template.css?.h1_font_size || DEFAULT_H1_FONT_SIZE;
  }

  get h2FontSize() {
    return this.args.template.css?.h2_font_size || DEFAULT_H2_FONT_SIZE;
  }

  get paragraphFontSize() {
    return this.args.template.css?.paragraph_font_size || DEFAULT_PARAGRAPH_FONT_SIZE;
  }

  get smallFontSize() {
    return this.args.template.css?.small_font_size || DEFAULT_SMALL_FONT_SIZE;
  }

  get templateFontColor() {
    return this.args.template.css?.templateFontColor || DEFAULT_TEMPLATE_FONT_COLOR;
  }

  get templateFontFamily() {
    let selectedValue = this.args.template.css?.templateFontFamily || 'sans-serif';
    return this.fontFamilies[0].items.filter((item) => item.value === selectedValue)[0].text;
  }

  get fontFamilies() {
    return [
      {
        items: [
          {
            text: 'Serif',
            value: 'serif',
            component: 'email/composer/sidebar/font-family-value',
          },
          {
            text: 'Sans Serif',
            value: 'sans-serif',
            component: 'email/composer/sidebar/font-family-value',
          },
          {
            text: 'Monospace',
            value: 'monospace',
            component: 'email/composer/sidebar/font-family-value',
          },
        ],
      },
    ];
  }

  get templateLinkFontColor() {
    return this.args.template.css?.templateLinkFontColor || DEFAULT_LINK_FONT_COLOR;
  }

  get isBold() {
    return this.args.template.css?.templateLinkFontWeight === FONT_STYLES.bold;
  }

  get isItalic() {
    return this.args.template.css?.templateLinkFontStyle === FONT_STYLES.italic;
  }

  @action
  toggleBoldStyle() {
    this.args.template.css.templateLinkFontWeight = this.isBold
      ? FONT_STYLES.normal
      : FONT_STYLES.bold;
  }

  @action
  toggleItalicStyle() {
    this.args.template.css.templateLinkFontStyle = this.isItalic
      ? FONT_STYLES.normal
      : FONT_STYLES.italic;
  }

  @action
  updateTemplateCss(key, value) {
    this.args.template.css[key] = value;
  }
}
