/* import __COLOCATED_TEMPLATE__ from './connect-social-media.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
import Component from '@ember/component';
import { readOnly } from '@ember/object/computed';
import { computed, action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isAny, isEvery } from '@intercom/pulse/lib/computed-properties';

export default Component.extend({
  intl: service(),
  tagName: '',
  store: service(),
  appService: service(),
  router: service(),
  intercomEventService: service(),
  app: readOnly('appService.app'),
  selectedAppPackage: null,
  appPackages: readOnly('step.modelData.appPackages'),
  areAllAppsInstalled: isEvery('appPackages', 'isInstalled'),
  isAnyAppInstalled: isAny('appPackages', 'isInstalled'),

  availableAppPackagesText: computed('allAvailableSocialMediaAppNames', function () {
    let formatter = new Intl.ListFormat('en', { style: 'long', type: 'conjunction' });
    return formatter.format(this.allAvailableSocialMediaAppNames);
  }),

  markAsDoneText: computed('intl.locale', 'notInstalledSocialMediaAppNames', function () {
    if (!this.isAnyAppInstalled) {
      return this.intl.t('onboarding.home.connect-social-media.mark-as-done');
    } else if (!this.areAllAppsInstalled) {
      let formatter = new Intl.ListFormat('en', { style: 'long', type: 'disjunction' });
      return this.intl.t('onboarding.home.connect-social-media.mark-as-done-one', {
        notInstalledSocialMediaAppNames: formatter.format(this.notInstalledSocialMediaAppNames),
      });
    }
  }),

  allAvailableSocialMediaAppNames: computed('appPackages', function () {
    return this.appPackages.map((app) => app.name);
  }),

  notInstalledSocialMediaAppNames: computed('appPackages.@each.isInstalled', function () {
    return this.appPackages.filter((app) => app.isNotInstalled).map((app) => app.name);
  }),

  _trackAnalyticsEvent(options) {
    this.intercomEventService.trackAnalyticsEvent({
      ...options,
      ...this.step.analyticsMetadata,
    });
  },

  selectAppPackage: action(function (appPackage) {
    this.set('selectedAppPackage', appPackage);
    this._trackAnalyticsEvent({
      context: this.selectedAppPackage.id,
      action: 'clicked',
      object: 'app_package',
    });
  }),

  closeModal: action(function () {
    this._trackAnalyticsEvent({
      context: this.selectedAppPackage.id,
      action: 'clicked',
      object: 'close_app_modal',
    });

    this.set('selectedAppPackage', null);

    // This prevents appstore/app-package/modal.js from overwriting the appPackage if the app_package_code query param is present
    this.router.replaceWith({
      queryParams: { app_package_code: null, step: this.step.identifier },
    });

    if (this.areAllAppsInstalled) {
      this.completionCallback();
    }
  }),

  actions: {
    markAsCompleted() {
      this._trackAnalyticsEvent({
        action: 'clicked',
        object: 'mark_as_done',
      });
      this.completionCallback();
    },
  },
});
