/* import __COLOCATED_TEMPLATE__ from './newsfeeds-modal.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { copy } from 'ember-copy';
import { inject as service } from '@ember/service';

export default class NewsfeedsModal extends Component {
  @service notificationsService;
  @service store;
  @service newsFeedService;
  @service intl;

  get ruleset() {
    return this.newsFeedService.activeRuleset;
  }

  @action
  async saveAndClose() {
    await this.newsFeedService.saveAndCloseNewsfeedModal();
    this.args.refresh();
  }

  @action
  closeNewsFeedModal() {
    this.newsFeedService.isNewsfeedModalOpen = false;
    this.args.refresh();
  }

  @action
  cancel() {
    this.newsFeedService.activeRuleset.rollbackAttributes();
    this.closeNewsFeedModal();
  }

  @action
  setTitle(text) {
    this.newsFeedService.activeRuleset.clientData.title = text;
    this.newsFeedService.activeRuleset.rulesetLinks.firstObject.object.name = text;
  }

  @action
  onUpdatePredicates(predicates) {
    this.ruleset.set('predicateGroup.predicates', copy(predicates));
    this.ruleset.get('fetchAudiencePreviewTask').perform();
  }

  @action
  onUpdateRolePredicates(rolePredicates) {
    this.ruleset.set('rolePredicateGroup.predicates', copy(rolePredicates));
    this.ruleset.get('fetchAudiencePreviewTask').perform();
  }
}
