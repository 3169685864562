/* import __COLOCATED_TEMPLATE__ from './paginator-component.hbs'; */
/* RESPONSIBLE TEAM: team-frontend-tech */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { computed } from '@ember/object';

export default Component.extend({
  isPaginating: false,
  hasErrored: false,
  // Used in error message
  objectName: 'users',
  paginationErrorMessage: null,
  errorText: computed('objectName', 'paginationErrorMessage', function () {
    if (this.paginationErrorMessage) {
      return this.paginationErrorMessage;
    } else {
      return `An error occurred while loading more ${this.objectName}.`;
    }
  }),
});
