/* import __COLOCATED_TEMPLATE__ from './add-address-empty-state.hbs'; */
/* RESPONSIBLE TEAM: team-growth-opportunities */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Signature {
  step: string | null;
  changeStep: (step: string) => void;
  openSidebar: () => void;
}

export default class AddAddressEmptyStateComponent extends Component<Signature> {
  @service declare appService: $TSFixMe;

  @action
  startSetup() {
    this.args.changeStep('overview');
    this.args.openSidebar();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::ConnectEmail::AddAddressEmptyState': typeof AddAddressEmptyStateComponent;
  }
}
