/* import __COLOCATED_TEMPLATE__ from './state-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import {
  matchBehaviors,
  objectTypes,
  oneOffStates,
  states as ongoingStates,
} from 'embercom/models/data/matching-system/matching-constants';
import { capitalize } from '@ember/string';

export default class StateComponent extends Component {
  @service appService;
  @service outboundHomeService;

  get app() {
    return this.appService.app;
  }

  get isOpen() {
    return this.args.activeFilter === 'state';
  }

  get selectedState() {
    let state = this.states.find((state) => state.value === this.args.selectedStateValue);
    return `State is ${state.text === 'Any' ? state.text.toLowerCase() : state.text}`;
  }

  get systemStates() {
    let isOneOff =
      this.args.selectedMatchBehaviorValues?.length === 1 &&
      this.args.selectedMatchBehaviorValues[0] === matchBehaviors.static;

    if (isOneOff) {
      return oneOffStates;
    } else {
      let filterableStates = { ...ongoingStates };
      delete filterableStates.ended;
      delete filterableStates.suspended;
      if ([objectTypes.tour, objectTypes.series].includes(this.outboundHomeService.activeList)) {
        delete filterableStates.scheduled;
      }
      return filterableStates;
    }
  }

  get states() {
    let defaultState = { text: 'Any', value: null };
    let allStates = this._mapSystemStatesToObjects();
    let states = allStates.concat(defaultState).sortBy('value');
    return states;
  }

  _mapSystemStatesToObjects() {
    return Object.keys(this.systemStates).map((state) => {
      return {
        text: capitalize(state),
        value: this.systemStates[state],
      };
    });
  }

  @action
  filterByState(state) {
    this.args.filterContent('selectedStateValue', state);
  }

  @action removeFilter() {
    this.args.filterContent('selectedStateValue', null);
    this.args.removeFromActiveFilter('state');
  }
}
