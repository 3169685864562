/* import __COLOCATED_TEMPLATE__ from './missing-contact-info-alert.hbs'; */
/* eslint-disable ember/no-empty-glimmer-component-classes */
/* RESPONSIBLE TEAM: team-app-security */

import Component from '@glimmer/component';

interface Signature {
  Element: HTMLElement;
  Args: {};
}

export default class MissingContactInfoAlert extends Component<Signature> {}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::MissingContactInfoAlert': typeof MissingContactInfoAlert;
    'new-settings/workspace/security/missing-contact-info-alert': typeof MissingContactInfoAlert;
  }
}
