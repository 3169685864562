/* import __COLOCATED_TEMPLATE__ from './template-iframe-preview-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-component-inheritance */
/* eslint-disable ember/no-jquery */
import $ from 'jquery';
import { later } from '@ember/runloop';
import { reads } from '@ember/object/computed';
import renderTemplate from '@intercom/pulse/lib/render-template';
import IframePreviewComponent from 'embercom/components/iframe-preview-component';
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
import emailTemplatesDefaultContentTemplate from 'embercom/templates-raw/email-templates/email-templates-default-content';

export default IframePreviewComponent.extend({
  usingCustomTemplate: true,
  iframeContent: reads('template.template'),
  unsubscribeText: '<a href="#" class="ic-unsubscribe-link">Unsubscribe from our emails</a>',

  sanitizeContent() {
    if (!this.isDestroying) {
      let content = renderTemplate(this.iframeContent, {
        content: emailTemplatesDefaultContentTemplate(),
        unsubscribe_link: this.unsubscribeText,
        unsubscribe_url: '',
      });
      this.set('safeIframeContent', sanitizeHtml(content, { config: 'email' }));
    }
  },

  // nolaneo - do this without a run later?
  // Solution: Hook up image load events from within the iframe to this function
  afterCreateIframe() {
    later(
      this,
      function () {
        if (!this.isDestroying) {
          let $iFrame = $('.m__html-editor__preview');
          let iFrameContent = $iFrame[0].contentDocument.documentElement;
          if (iFrameContent) {
            $iFrame.css('width', iFrameContent.scrollWidth);
            $iFrame.css('height', iFrameContent.scrollHeight);
          }
        }
      },
      250,
    );
  },
});
