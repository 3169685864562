/* import __COLOCATED_TEMPLATE__ from './sms-phone-numbers.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { task } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { showNewMessageInIntercomWidget } from 'embercom/lib/intercom-widget-helper';
import {
  countryData,
  FILTERED_COUNTRIES_FROM_ONBOARDING,
  TOLL_FREE_VERIFICATION_COUNTRY_LIST,
} from 'embercom/components/outbound/sms/onboarding/country-data';
import { VERIFICATION_STATUS, REJECTION_REASONS } from 'embercom/models/data/sms/constants';

export default class SmsPhoneNumbers extends Component {
  @service store;
  @service intl;
  @service appService;
  @service router;

  @tracked smsPhoneNumbers = [];

  @tracked countryCodeList = [...FILTERED_COUNTRIES_FROM_ONBOARDING];

  @tracked filteredCountryData = { ...countryData };

  @tracked showModal = false;
  @tracked rejectedPhoneNumber = false;

  verificationStatusToStampMap = {
    [VERIFICATION_STATUS.DRAFT]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.stamp-text.verification-required',
    ),
    [VERIFICATION_STATUS.PENDING]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.stamp-text.pending-review',
    ),
    [VERIFICATION_STATUS.SUBMITTED]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.stamp-text.in-review',
    ),
    [VERIFICATION_STATUS.IN_REVIEW]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.stamp-text.in-review',
    ),
    [VERIFICATION_STATUS.VERIFIED]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.stamp-text.active',
    ),
    [VERIFICATION_STATUS.REJECTED]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.stamp-text.rejected',
    ),
  };

  rejectionReasonToTranslation = {
    [REJECTION_REASONS.CONTENT_VIOLATION]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.rejection-reasons.content-violation',
      { errorCode: REJECTION_REASONS.CONTENT_VIOLATION, htmlSafe: true },
    ),
    [REJECTION_REASONS.CAMPAIGN_VIOLATION]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.rejection-reasons.campaign-violation',
      { errorCode: REJECTION_REASONS.CAMPAIGN_VIOLATION, htmlSafe: true },
    ),
    [REJECTION_REASONS.DISALLOWED_CONTENT]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.rejection-reasons.disallowed-content',
      { errorCode: REJECTION_REASONS.DISALLOWED_CONTENT, htmlSafe: true },
    ),
    [REJECTION_REASONS.HIGH_RISK]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.rejection-reasons.high-risk',
      { errorCode: REJECTION_REASONS.HIGH_RISK, htmlSafe: true },
    ),
    [REJECTION_REASONS.UNKNOWN_ERROR]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.rejection-reasons.unknown-error',
      { errorCode: REJECTION_REASONS.UNKNOWN_ERROR, htmlSafe: true },
    ),
    [REJECTION_REASONS.INVALID_INFORMATION]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.rejection-reasons.invalid-information',
      { errorCode: REJECTION_REASONS.INVALID_INFORMATION },
    ),
    [REJECTION_REASONS.OPT_IN_ERROR]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.rejection-reasons.opt-in-error',
      { errorCode: REJECTION_REASONS.OPT_IN_ERROR, htmlSafe: true },
    ),
    [REJECTION_REASONS.PHONE_NUMBER_ERROR]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.rejection-reasons.phone-number-error',
      { errorCode: REJECTION_REASONS.PHONE_NUMBER_ERROR, htmlSafe: true },
    ),
    [REJECTION_REASONS.AGE_GATE]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.rejection-reasons.age-gate',
      { errorCode: REJECTION_REASONS.AGE_GATE, htmlSafe: true },
    ),
    [REJECTION_REASONS.INVALID_SAMPLE_MESSAGE_URL]: this.intl.t(
      'outbound.sms.onboarding.toll-free-verification-form.rejection-reasons.invalid-sample-message-url',
      { errorCode: REJECTION_REASONS.INVALID_SAMPLE_MESSAGE_URL, htmlSafe: true },
    ),
  };

  verificationStatusToStampColorMap = {
    [VERIFICATION_STATUS.DRAFT]: 'yellow-light',
    [VERIFICATION_STATUS.PENDING]: 'sky',
    [VERIFICATION_STATUS.SUBMITTED]: 'sky',
    [VERIFICATION_STATUS.IN_REVIEW]: 'sky',
    [VERIFICATION_STATUS.VERIFIED]: 'green',
    [VERIFICATION_STATUS.REJECTED]: 'red',
  };

  get app() {
    return this.appService.app;
  }

  get twilioErrorCodeUrl() {
    return `https://www.twilio.com/docs/api/errors/${this.rejectedPhoneNumber.verificationRejectionCode}`;
  }

  get selectedCountry() {
    return countryData[this.selectedCountryCode];
  }

  get disableNewCountryBtn() {
    return Object.keys(this.filteredCountryData).length === 0;
  }

  get phoneNumbers() {
    return this.smsPhoneNumbers.map((number) => {
      return {
        id: number.id,
        country: countryData[number.countryCode],
        number: number.number,
        status: number.verificationStatus,
        verificationFormSubmittedOn: number.verificationFormSubmittedOn,
        verificationCreatedManually: number.verificationCreatedManually,
        stamp: {
          text: this.verificationStatusToStampMap[number.verificationStatus],
          color: this.verificationStatusToStampColorMap[number.verificationStatus],
        },
        tollFreeVerificationRequired: number.tollFreeVerificationRequired,
        verificationRejectionCode: number.verificationRejectionCode
          ? {
              text: this.rejectionReasonToTranslation[number.verificationRejectionCode],
              color: 'red',
            }
          : null,
        regulatoryBundleVerificationRequired:
          this.app.canUseFeature('ps-regulatory-compliance-countries-for-sms') &&
          number.regulatoryBundleVerificationRequired,
        regulatoruBundleSid: number.regulatoryBundleSid,
      };
    });
  }

  get phoneNumberVerificationRequired() {
    for (let phoneNumber of this.phoneNumbers) {
      if (phoneNumber.tollFreeVerificationRequired) {
        return phoneNumber;
      }
    }
  }

  get phoneNumberInVerification() {
    for (let phoneNumber of this.phoneNumbers) {
      if (
        TOLL_FREE_VERIFICATION_COUNTRY_LIST.includes(phoneNumber.country.code) &&
        [
          VERIFICATION_STATUS.PENDING,
          VERIFICATION_STATUS.SUBMITTED,
          VERIFICATION_STATUS.IN_REVIEW,
        ].includes(phoneNumber.status)
      ) {
        return phoneNumber;
      }
    }
  }

  get phoneNumberVerificationRejected() {
    for (let phoneNumber of this.phoneNumbers) {
      if (
        TOLL_FREE_VERIFICATION_COUNTRY_LIST.includes(phoneNumber.country.code) &&
        [VERIFICATION_STATUS.REJECTED].includes(phoneNumber.status)
      ) {
        return phoneNumber;
      }
    }
  }

  get columns() {
    let columns = [
      {
        label: 'Activated Countries',
        valuePath: 'country',
        width: '60%',
      },
      {
        label: 'Phone number',
        valuePath: 'number',
        width: '25%',
      },
      {
        label: 'Status',
        valuePath: 'status',
      },
    ];
    if (this.rejectedPhoneNumber) {
      columns.splice(2, 0, {
        label: 'Rejection Reason',
        valuePath: 'rejection-reason',
      });
    }
    return columns;
  }

  @task
  *fetchPhoneNumbers() {
    let smsPhoneNumbersList = yield this.store.findAll('sms/phone-number');
    this.smsPhoneNumbers = smsPhoneNumbersList.toArray();
    this.rejectedPhoneNumber = this.smsPhoneNumbers.find(
      (phoneNumber) => !!phoneNumber.verificationRejectionCode,
    );
    this.countryCodeList.push(...this.smsPhoneNumbers.map((number) => number.countryCode));
    this.setFilteredCountryData();
  }

  @action
  setFilteredCountryData() {
    this.filteredCountryData = Object.fromEntries(
      Object.entries(countryData).filter(([key, value]) => !this.countryCodeList.includes(key)),
    );
  }

  @action
  addNewCountry() {
    this.showModal = true;
  }

  @action
  resetModal() {
    this.fetchPhoneNumbers.perform();
    this.showModal = false;
  }

  @action
  redirectToTfnForm(phoneNumber) {
    return this.router.transitionTo(
      'apps.app.settings.channels.sms.toll-free-verification',
      phoneNumber.id,
    );
  }

  @action
  openMessenger(event) {
    event.preventDefault();
    showNewMessageInIntercomWidget();
  }
}
