/* import __COLOCATED_TEMPLATE__ from './workflow-sidedrawer-body.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type WorkflowSidedrawerService from 'embercom/services/workflow-sidedrawer-service';
import { registerDestructor } from '@ember/destroyable';
import { action } from '@ember/object';

interface Args {
  afterSetLive?: () => void;
  afterSetPause?: () => void;
}

export default class WorkflowSidedrawerBody extends Component<Args> {
  @service declare workflowSidedrawerService: WorkflowSidedrawerService;
  @service declare intercomEventService: any;

  constructor(owner: unknown, args: any) {
    super(owner, args);

    registerDestructor(this, () => {
      this.workflowSidedrawerService.teardown();
    });
  }

  get ruleset() {
    return this.workflowSidedrawerService.ruleset;
  }

  @action
  async setWorkflowLive() {
    await this.workflowSidedrawerService.setWorkflowLive();
    this.args.afterSetLive?.();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `set_fin_workflow_live`,
    });
  }

  @action
  onSetPause() {
    this.workflowSidedrawerService.teardown();
    this.args.afterSetPause?.();
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: `set_fin_workflow_pause`,
    });
  }

  @action
  onSetLive() {
    this.workflowSidedrawerService.teardown();
    this.workflowSidedrawerService.onSetLive();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::WorkflowSidedrawerBody': typeof WorkflowSidedrawerBody;
  }
}
