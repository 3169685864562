/* import __COLOCATED_TEMPLATE__ from './more-pill.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import Component from '@glimmer/component';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';

interface Signature {
  Args: {
    teams: any;
  };
}

export default class MorePill extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;

  get slicedTeamsList() {
    let slicedTeams = this.args.teams.slice(3, this.args.teams.length);

    return [
      {
        heading: this.intl.t(
          'components.settings.office-hours.table.columns.associated-team-inboxes',
        ),
        items: slicedTeams.map((teamId: string) => {
          let team = this.appService.app.teams.findBy('id', teamId);
          return {
            text: team?.name,
            value: teamId,
            emoji: team?.avatar_emoji,
            component: 'new-settings/workspace/office-hours/more-pill-dropdown-item',
          };
        }),
      },
    ];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::OfficeHours::MorePill': typeof MorePill;
    'new-settings/workspace/office-hours/more-pill': typeof MorePill;
  }
}
