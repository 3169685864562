/* import __COLOCATED_TEMPLATE__ from './non-connectable-app-message.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import Component from '@ember/component';
import { equal, readOnly } from '@ember/object/computed';

export default Component.extend({
  selectedAppNonConnectableReason: readOnly('selectedApp.nonConnectableReason'),
  appPackageOwnerShouldBeSameAsSelectedApp: equal(
    'selectedAppNonConnectableReason',
    'app_package_owner_app_should_be_same_as_selected_app',
  ),
  appPackageWithLimitedAdminPermissionOnSelectedApp: equal(
    'selectedAppNonConnectableReason',
    'app_package_with_limited_admin_permissions_on_selected_app',
  ),
  appPackageWithLimitedAdminPermissionOnOwnerApp: equal(
    'selectedAppNonConnectableReason',
    'app_package_with_limited_admin_permissions_on_owner_app',
  ),
  appIsSuspended: equal('selectedAppNonConnectableReason', 'app_is_suspended'),
});
