/* import __COLOCATED_TEMPLATE__ from './auto-reply-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import { action } from '@ember/object';
import {
  BaseConfig,
  BlocksDocument,
  EMOJI_TYPEAHEAD,
  INPUT_RULE_CODE_BLOCK,
  INPUT_RULE_EMOJI,
  INPUT_RULE_INLINE_CODE,
  INPUT_RULE_MARKDOWN_ANCHOR,
  INPUT_RULE_ORDERED_LIST,
  INPUT_RULE_UNORDERED_LIST,
} from '@intercom/embercom-prosemirror-composer';
import EmbercomFileUploader from 'embercom/lib/articles/embercom-file-uploader';
import {
  INLINE_CONTROL_ALIGNMENT,
  INLINE_CONTROL_BLOCK_TYPES,
  type TextDirection,
} from '@intercom/embercom-prosemirror-composer/lib/config/composer-config';
import { restartableTask } from 'ember-concurrency-decorators';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type RouterService from '@ember/routing/router-service';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  settings: $TSFixMe;
  showSideDrawer: boolean;
  closeSideDrawer: () => void;
}

class ComposerConfig extends BaseConfig {
  autoFocus = false;
  customStylesConfig = {
    allowCustomStyles: true,
    formattingSidebarClass: 'email-composer-formatting-side-bar',
  };
  allowImplicitMarginParagraphs = true;
  allowTextAlignment = true;
  allowedBlocks = [
    'paragraph',
    'image',
    'orderedList',
    'unorderedList',
    'heading',
    'subheading',
    'codeBlock',
    'button',
    'html',
  ];
  emojiInserter = 'composer/emoji-inserter';
  experimental = {
    hideInsertersOnMouseOut: true,
  };
  hideFromBlockFormatter = [INLINE_CONTROL_ALIGNMENT];
  hideFromTextFormatter = [INLINE_CONTROL_BLOCK_TYPES, INLINE_CONTROL_ALIGNMENT];
  inputRules = [
    INPUT_RULE_CODE_BLOCK,
    INPUT_RULE_EMOJI,
    INPUT_RULE_ORDERED_LIST,
    INPUT_RULE_UNORDERED_LIST,
    INPUT_RULE_INLINE_CODE,
    INPUT_RULE_MARKDOWN_ANCHOR,
  ];
  placeholder = '';
  textDirection = 'ltr' as TextDirection;
  tools = [
    { name: 'text-formatter' },
    { name: 'anchor-editor', options: { showHelpLinkHeader: true } },
    { name: 'button-editor', options: { showHelpLinkHeader: true } },
    { name: 'media-inserter' },
    { name: 'html/edit-button' },
    { name: 'image-editor', options: { supportAltAttributeEditing: true } },
  ];
  typeaheads = [EMOJI_TYPEAHEAD];
  updateImages = true;
  upload = {
    allowedImageFileTypes: ['image/png', 'image/jpeg', 'image/jpg', 'image/gif'],
    allowedAttachmentTypes: ['*'],
    uploader: EmbercomFileUploader,
    attrs: { policyUrl: '' },
  };

  constructor(app: $TSFixMe) {
    super();
    this.upload.attrs.policyUrl = `/apps/${app.id}/uploads`;
  }
}

export default class AutoReplySideDrawer extends Component<Args> {
  @service declare intercomEventService: $TSFixMe;
  @service declare router: RouterService;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;

  @tracked blocksDocument;
  @tracked selectedLanguage;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.selectedLanguage = this.defaultLanguage;
    this.blocksDocument = new BlocksDocument(this.current.blocks);
  }

  get composerConfig() {
    return new ComposerConfig(this.appService.app);
  }

  get current() {
    return this.args.settings.emailLanguages.find(
      (emailLanguage: $TSFixMe) => emailLanguage.language === this.selectedLanguage,
    );
  }

  get currentLabel() {
    return this.current.default
      ? `${this.current.languageName} [Default]`
      : this.current.languageName;
  }

  get defaultLanguage() {
    let match = this.args.settings.emailLanguages.find(
      (emailLanguage: $TSFixMe) => emailLanguage.default === true,
    );
    return match ? match.language : 'en';
  }

  get languagesList() {
    return this.args.settings.emailLanguages.map((emailLanguage: $TSFixMe) => ({
      text: emailLanguage.default
        ? `${emailLanguage.languageName} [Default]`
        : emailLanguage.languageName,
      value: emailLanguage.language,
    }));
  }

  get savingLocalizedContent() {
    return taskFor(this.saveLocalizedContents).isRunning;
  }

  @action
  updateBlocks(blocksDoc: BlocksDocument) {
    this.current.blocks = blocksDoc.blocks.map((block) =>
      createFragmentFromBlock(this.store, block),
    );
  }

  @action
  updateSelectedLanguage(language: $TSFixMe) {
    this.current.rollbackAttributes();
    this.selectedLanguage = language;
    this.blocksDocument = new BlocksDocument(this.current.blocks);
  }

  @restartableTask
  *saveLocalizedContents(): TaskGenerator<any> {
    try {
      let response = yield this.args.settings.save();
      this.store.pushPayload({ 'email-auto-reply-settings': response });
      this.intercomEventService.trackAnalyticsEvent({
        action: 'customized',
        object: 'email_auto_reply_settings',
        place: 'email_auto_reply',
        section: 'settings',
      });

      this.notificationsService.notifyConfirmation(
        this.intl.t('channels.email.settings.auto_reply.email-updated', {
          languageName: this.current.languageName,
        }),
      );

      this.args.closeSideDrawer();
    } catch (e) {
      this.notificationsService.notifyResponseError(e, {
        default: this.intl.t('channels.email.settings.update_failure'),
      });
    }
  }

  @action
  save() {
    taskFor(this.saveLocalizedContents).perform();
  }

  @action
  closeSideDrawer() {
    this.current.rollbackAttributes();
    this.blocksDocument = new BlocksDocument(this.current.blocks);
    this.args.closeSideDrawer();
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Customisation::AutoReplySideDrawer': typeof AutoReplySideDrawer;
  }
}
