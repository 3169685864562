/* import __COLOCATED_TEMPLATE__ from './list-component.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import ENV from 'embercom/config/environment';
import { statisticKeys } from 'embercom/models/data/outbound/constants';
import { contentWrapperTypes } from 'embercom/models/data/outbound/constants';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
export default class ListComponent extends Component {
  @service store;
  @service appService;
  @service outboundHomeService;
  @service realTimeEventService;

  @tracked selectedContent = this.args.selectedContent || [];
  @tracked contentData = this.args.data;

  constructor() {
    super(...arguments);

    this.realTimeEventService.on('BulkDeactivationStatus', this, 'refreshStats');
  }

  get statisticKeys() {
    return statisticKeys;
  }

  get app() {
    return this.appService.app;
  }

  get data() {
    if (!this.args.data) {
      return [];
    }
    let isPrioritizable = this.outboundHomeService.isPrioritizable || this.args.isPrioritizable;
    if (!this.args.keepSort && isPrioritizable) {
      return this.args.data.sort((w1, w2) => w1.priority - w2.priority);
    }
    return this.args.data;
  }

  get columnsHash() {
    let seenValuePaths = new Set();
    return this.args.columns.reduce((acc, column) => {
      seenValuePaths.add(column.valuePath);
      return Object.assign(acc, {
        [column.valuePath]: column,
      });
    }, {});
  }

  refreshStats(event) {
    if (event.eventType === 'complete' && !this.getStats.isRunning) {
      this.getStats.perform();
    }
  }

  @task
  *getStats() {
    if (this.args.statisticColumns) {
      let idsFor = (contentWrapperType) =>
        this.args.data
          .filter((contentWrapper) => contentWrapper.contentWrapperType === contentWrapperType)
          .map((contentWrapper) => contentWrapper.contentWrapperId);
      let newStats = yield this.outboundHomeService.getStats({
        ruleset_ids: idsFor(contentWrapperTypes.ruleset),
        selection_set_ids: idsFor(contentWrapperTypes.selectionSet),
        series_ids: idsFor(contentWrapperTypes.series),
        statistics: this.args.statisticColumns.map((col) => col.key),
        app_id: this.app.id,
        range_start: this.args.range?.start,
        range_end: this.args.range?.end,
        ...this._subaggregations,
      });
      this._updateStats(newStats);
    }
  }

  @task
  *onLoadMore() {
    if (!this.args.lastPageHit) {
      yield this.args.loadMore(this.args.pageSize, this.args.currentPage + 1, ENV.APP._500MS);
      this.getStats.perform();
    }
  }

  @task
  *onSortUpdate(sortBy) {
    yield this.args.sortUpdate(sortBy);
  }

  _updateStats(statsByContentWrapper) {
    statsByContentWrapper.forEach((statsForContentWrapper) => {
      statsForContentWrapper.forEach((stat) =>
        this.store.push(this.store.normalize('outbound/content-statistic', stat)),
      );
    });
  }

  get _subaggregations() {
    if (this.args.breakdownFailureStats) {
      return {
        subaggregations: ['failure_category.id'],
      };
    }
  }

  @action
  handleSelect(rowId) {
    let content;
    if (this.selectedContent.includes(rowId)) {
      content = this.selectedContent.without(rowId);
    } else {
      content = [...this.selectedContent, rowId];
    }
    this.selectedContent = content;
    this.data.forEach((con, index) => {
      set(con, 'isSelected', this.selectedContent.includes(con.id));
    });
  }

  @action
  onBulkSelect() {
    let hasAllSelected = this.data.every((content) => this.selectedContent.includes(content.id));
    if (hasAllSelected) {
      this.resetBulkSelect();
    } else if (this.selectedContent.length >= 0) {
      this.selectedContent = this.data.map((content) => content.id);
      this.data.forEach((content, index) => {
        if (index < this.data.length) {
          set(content, 'isSelected', true);
        }
      });
    }
  }
  @action
  resetBulkSelect() {
    this.selectedContent = [];
    this.data.forEach((content, index) => {
      if (index < this.data.length) {
        set(content, 'isSelected', false);
      }
    });
  }
}
