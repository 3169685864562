/* import __COLOCATED_TEMPLATE__ from './section.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import templateOnlyComponent from '@ember/component/template-only';
import { type InterfaceIconName } from '@intercom/pulse/lib/interface-icons';

export interface Args {
  accordion: any;
  isActive: boolean;
  isFrozen: boolean;
  onToggleSwitch: () => void;
  sectionId: string;
  icon: InterfaceIconName;
  crmName: string;
  intercomName?: string;
  requirements?: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

const Section = templateOnlyComponent<Signature>();
export default Section;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigImport::SectionsAccordion::Section': typeof Section;
    'new-settings/data/imports-exports/import-zendesk/config-import/sections-accordion/section': typeof Section;
  }
}
