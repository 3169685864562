/* import __COLOCATED_TEMPLATE__ from './checkbox.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';

interface Args {
  shouldAnimate: boolean;
  isChecked: boolean;
  id: string;
  size: 'small' | 'medium';
  checkboxColor?: 'light-gray' | 'gray';
  checkmarkBackground?: 'light-gray' | 'green';
}

export default class Checkbox extends Component<Args> {
  get size() {
    return this.args.size || 'small';
  }

  get checkboxColor() {
    return this.args.checkboxColor || 'gray';
  }

  get checkmarkBackground() {
    return this.args.checkmarkBackground || 'green';
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::FloatingWidget::Checklist::Checkbox': typeof Checkbox;
  }
}
