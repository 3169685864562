/* import __COLOCATED_TEMPLATE__ from './email-address-input-field.hbs'; */
/* RESPONSIBLE TEAM: team-product-exploration */
import type Store from '@ember-data/store';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import type IntlService from 'ember-intl/services/intl';
import Admin from 'embercom/models/admin';
import ajax from 'embercom/lib/ajax';
import { taskFor } from 'ember-concurrency-ts';

interface Args {}

interface Signature {
  Args: Args;
  Element: never;
}

export default class EmailAddressInputField extends Component<Signature> {
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare store: Store;

  @tracked adminEmail = '';
  @tracked passwordConfirmation: string | null = null;
  @tracked showConfirmationModal = false;
  @tracked isEmailCanceling = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.adminEmail = this.appService.app.currentAdmin.email;
  }

  get isEmailResending() {
    return taskFor(this.resendValidationEmail).isRunning;
  }

  get admin() {
    return this.appService.app.currentAdmin as Admin;
  }

  get hasEmailChanged() {
    return this.adminEmail !== this.appService.app.currentAdmin.email;
  }

  get disabledConfirmButton() {
    return !this.passwordConfirmation || this.passwordConfirmation.length < 3;
  }

  get title() {
    return this.intl.t('new-settings.submenu.personal.preferences');
  }

  get samlNotEnabled() {
    return !this.appService.app.saml_enabled;
  }

  get canChangeEmail() {
    return this.appService.app.currentAdmin.canChangeEmail && this.samlNotEnabled;
  }

  @action
  setConfirmationModalVisiblity(visibility = false) {
    this.showConfirmationModal = visibility;
  }

  @dropTask
  *save() {
    this.setConfirmationModalVisiblity(false);
    yield this.admin
      .updatePersonalSettings({
        id: this.admin.id,
        app_id: this.appService.app.id,
        email: this.adminEmail,
        current_password: this.passwordConfirmation,
      })
      .then((updatedAdmin: any) => {
        this.notificationsService.notifyConfirmation(
          this.intl.t('account.general.settings-updated'),
        );
        this.passwordConfirmation = null;
        this.setConfirmationModalVisiblity(false);
        this.appService.app.set(
          'currentAdmin',
          Admin.peekAndMaybeLoad(this.store, updatedAdmin.id),
        );
      })
      .catch((response) => {
        if (response.jqXHR.status === 401) {
          this.notificationsService.notifyError(this.intl.t('account.general.password-incorrect'));
          this.passwordConfirmation = null;
        } else if (response.jqXHR.status === 422) {
          this.notificationsService.notifyError(
            this.intl.t('account.general.cannot-update-profile', {
              errorMessage: JSON.parse(response.jqXHR.responseText).errors,
            }),
          );
        } else {
          this.notificationsService.notifyError(
            this.intl.t('account.general.cannot-update-profile-try-again'),
          );
        }
      });
  }

  @dropTask
  *resendValidationEmail() {
    try {
      yield ajax({
        type: 'PUT',
        url: '/ember/admins/resend_verification_email',
        data: JSON.stringify({
          id: this.admin.id,
          app_id: this.appService.app.id,
        }),
      });
      this.notificationsService.notifyConfirmation(this.intl.t('account.general.email-resend'));
    } catch (err) {
      if (err.jqXHR && err.jqXHR.status === 422) {
        this.notificationsService.notifyError(err.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('account.general.cannot-send-verification-email-try-again'),
        );
      }
    }
  }

  @action
  async cancelValidationEmail() {
    this.isEmailCanceling = true;
    try {
      await ajax({
        type: 'PUT',
        url: '/ember/admins/cancel_verification_email',
        data: JSON.stringify({
          id: this.admin.id,
          app_id: this.appService.app.id,
        }),
      });
      this.isEmailCanceling = false;
      this.notificationsService.notifyConfirmation(
        this.intl.t('account.general.pending-email-change-canceled'),
      );
      this.admin.set('pending_email_change', null);
    } catch (err) {
      this.isEmailCanceling = false;
      if (err.jqXHR && err.jqXHR.status === 422) {
        this.notificationsService.notifyError(err.jqXHR.responseJSON.errors);
      } else {
        this.notificationsService.notifyError(
          this.intl.t('account.general.cannot-cancel-try-again'),
        );
      }
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Personal::Preferences::EmailAddressInputField': typeof EmailAddressInputField;
    'new-settings/personal/preferences/email-address-input-field': typeof EmailAddressInputField;
  }
}
