/* import __COLOCATED_TEMPLATE__ from './deliverability-notifications.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import type IntlService from 'embercom/services/intl';
import type InboxApi from 'embercom/services/inbox-api';
import { dropTask } from 'ember-concurrency-decorators';
import type SpambotContact from 'embercom/models/spambot-contact';
import { taskFor } from 'ember-concurrency-ts';
import type Admin from 'embercom/models/admin';

interface Teammate {
  id: string;
  email: string;
}

interface Args {}

export default class DeliverabilityNotifications extends Component<Args> {
  @tracked showSideDrawer = false;
  @service declare store: Store;
  @service declare intl: IntlService;
  @service declare appService: $TSFixMe;
  @service declare notificationsService: $TSFixMe;
  @service declare inboxApi: InboxApi;

  @tracked showModal = false;
  @tracked newCompanyEmailAddress = '';
  @tracked spambotContacts: SpambotContact[] = [];

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    this.loadSpambotContacts();
  }

  @action
  handleTeammateChange(teammate: Teammate) {
    let admin = this.app.admins.find((admin: Admin) => admin.id === teammate.id);
    let spambotContact = this.spambotContacts.find(
      (contact) => contact.emailAddress === admin?.email,
    );

    if (!spambotContact) {
      taskFor(this.addSpambotContact).perform(admin);
    } else {
      taskFor(this.deleteSpambotContact).perform(admin);
    }
  }

  loadSpambotContacts() {
    this.spambotContacts = this.store.peekAll('spambot-contact') as unknown as SpambotContact[];
  }

  get app() {
    return this.appService.app;
  }

  get hasSpambotContacts() {
    return this.spambotContacts.length > 0;
  }

  get selectableAdmins() {
    return this.app.assignableHumanAdmins.map((admin: Admin) => ({
      text: admin.name,
      value: admin,
      isSelected: this.spambotContacts
        .map((spambotContact) => spambotContact.emailAddress)
        .includes(admin.email),
    }));
  }

  get teammates() {
    if (this.spambotContacts.length > 0) {
      let spambotContactsEmails = this.spambotContacts.map(
        (spambotContact) => spambotContact.emailAddress,
      );

      return this.app.admins.filter((admin: Admin) => spambotContactsEmails.includes(admin.email));
    }
    return [];
  }

  @dropTask
  *addSpambotContact(teammate: Teammate) {
    let spambotContactRecord = this.store.createRecord('spambot-contact', {
      emailAddress: teammate.email,
    });
    yield spambotContactRecord.save();
  }

  @dropTask
  *deleteSpambotContact(deletedTeammate: Teammate) {
    let spambotContact = this.spambotContacts.find(
      (contact) => contact.emailAddress === deletedTeammate.email,
    );

    yield spambotContact?.destroyRecord();
  }

  @action
  openSideDrawer() {
    this.showSideDrawer = true;
  }

  @action
  closeSideDrawer() {
    this.showSideDrawer = false;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Email::Advanced::DeliverabilityNotifications': typeof DeliverabilityNotifications;
  }
}
