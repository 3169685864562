/* import __COLOCATED_TEMPLATE__ from './general.hbs'; */
/* RESPONSIBLE TEAM: team-tickets-1 */
import { action } from '@ember/object';
import type RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type OfficeHoursSchedule from 'embercom/models/office-hours-schedule';

interface Signature {
  Args: {
    schedule: OfficeHoursSchedule;
    availabilitySetting: any;
    updateIntervals: (intervals: any[]) => void;
    saveAvailabilitySetting: (settings: any) => void;
  };
}

export default class GeneralTab extends Component<Signature> {
  @service declare router: RouterService;
  @service declare intercomEventService: any;
  @service declare appService: $TSFixMe;
  @service declare intl: $TSFixMe;

  @tracked automaticResponseDelay = 3;
  @tracked customResponseDelay = 4;
  @tracked reportingExcludeOooHours = this.args.availabilitySetting.reportingExcludeOooHours;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.fixCustomResponseDelayValue();
  }

  get customResponseTimeValues() {
    return this.args.availabilitySetting.customResponseTimeValues.filter(
      (customResponseTimeValue: any) => customResponseTimeValue.value !== 0,
    );
  }

  get responseDelayOptionsWithAutomatic() {
    return [
      {
        text: this.intl.t('messenger.office-hours.few-minutes'),
        id: 'expected-response-delay-minutes',
        value: 0,
      },
      {
        text: this.intl.t('messenger.office-hours.few-hours'),
        id: 'expected-response-delay-hours',
        value: 1,
      },
      {
        text: this.intl.t('messenger.office-hours.in-a-day'),
        id: 'expected-response-delay-day',
        value: 2,
      },
      {
        text: this.intl.t('messenger.office-hours.custom-time-option'),
        id: 'expected-response-delay-custom',
        value: 4,
      },
      {
        text: this.automaticResponseDelayText,
        description: this.intl.t('messenger.office-hours.dynamic-reply-type-description'),
        id: 'expected-response-delay-automatic',
        value: 3,
      },
    ];
  }

  get app() {
    return this.appService.app;
  }

  get selectedResponseDelay() {
    let { customResponseDelayEnabled, customResponseDelay } = this.args.availabilitySetting;
    return customResponseDelayEnabled ? customResponseDelay : this.automaticResponseDelay;
  }

  get automaticResponseDelayText() {
    let translatedText = this.intl.t(
      this.args.availabilitySetting.messengerAutomaticResponseTimeTranslationKey,
      {
        locale: this.intl.locale,
      },
    );
    return this.app.canUseOfficeHoursImprovements ? translatedText : `"${translatedText}"`;
  }

  get showReportingSettings() {
    if (this.args.schedule.isDefault) {
      return this.app.inboxIsActive && !isEmpty(this.args.schedule.timeIntervals);
    }

    return false;
  }

  @action
  saveReportingSettings() {
    this.reportingExcludeOooHours = !this.reportingExcludeOooHours;
    this.args.saveAvailabilitySetting({ reportingExcludeOooHours: this.reportingExcludeOooHours });
  }

  @action
  fixCustomResponseDelayValue() {
    if (
      this.selectedResponseDelay === this.customResponseDelay &&
      !this.args.availabilitySetting.customResponseDelayValue
    ) {
      this.args.availabilitySetting.customResponseDelayValue = 300;
      this.args.availabilitySetting.save();
    }
  }

  @action
  setCustomResponseDelay(delay: number) {
    this.args.saveAvailabilitySetting({ customResponseDelayValue: delay });
  }

  @action
  changeSelectedResponseDelay(selection: string) {
    let selectedResponseDelay = parseInt(selection, 10);
    if (selectedResponseDelay === this.automaticResponseDelay) {
      this.args.saveAvailabilitySetting({
        customResponseDelayEnabled: false,
        customResponseDelay: this.args.availabilitySetting.data.customResponseDelay,
      });
    } else {
      let params: any = {
        customResponseDelayEnabled: true,
        customResponseDelay: selectedResponseDelay,
      };
      if (
        selectedResponseDelay === this.customResponseDelay &&
        !this.args.availabilitySetting.customResponseDelayValue
      ) {
        params.customResponseDelayValue = 300;
      }
      this.args.saveAvailabilitySetting(params);
    }
    if (this.appService.app.canUseOfficeHoursImprovements) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'office_hours_reply_time',
        value: selection,
      });
    }
  }

  @action
  openCustomOfficeHoursTab() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'go_to_custom_office_hours_tab',
      place: 'general_office_hours_settings',
      section: 'general_office_hours_banner',
    });
    this.router.transitionTo('apps.app.settings.workspace.office-hours', {
      queryParams: {
        tab: 'custom-office-hours',
      },
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::OfficeHours::Tabs::General': typeof GeneralTab;
    'new-settings/workspace/office-hours/tabs/general': typeof GeneralTab;
  }
}
