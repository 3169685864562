/* import __COLOCATED_TEMPLATE__ from './blocked.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import UnblockBlockedUserModal from 'embercom/components/settings/unblock-blocked-user-modal';

export default class BlockedComponent extends Component {
  @service declare modalService: any;

  @action
  unblockUser(blockedUser: any) {
    this.modalService.openModal(UnblockBlockedUserModal, blockedUser);
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::People::Blocked': typeof BlockedComponent;
  }
}
