/* import __COLOCATED_TEMPLATE__ from './nav-search-component.hbs'; */
/* RESPONSIBLE TEAM: unused-components */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/require-computed-property-dependencies */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable ember/no-jquery */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import EmberObject, { computed, action } from '@ember/object';
import $ from 'jquery';
import { next, schedule } from '@ember/runloop';
import { A } from '@ember/array';
import { readOnly, or, empty, not, notEmpty } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import Component from '@ember/component';
import { lessThanProperty, ternary } from '@intercom/pulse/lib/computed-properties';
// eslint-disable-next-line @intercom/intercom/no-legacy-dropdown
import ComponentDropdownToggler from 'embercom/components/mixins/component-dropdown-toggler';
import KeyboardSelectableDropdown from 'embercom/components/mixins/keyboard-selectable-dropdown';
import { addEventListener, debounceTask } from 'ember-lifeline';

const SHORTCUT_GROUP = 'nav-search';

export default Component.extend(KeyboardSelectableDropdown, ComponentDropdownToggler, {
  appService: service(),
  app: readOnly('appService.app'),
  ariaRole: 'search',
  shortcuts: service(),
  lastSetSearchString: '',
  internalSearchString: '',

  classNameBindings: [
    'isBelowCollapseThreshold:nav__search__collapsed',
    'expandedView:o__expanded-view',
  ],

  classNames: ['nav__search'],
  collapseThreshold: 1279,
  isBelowCollapseThreshold: lessThanProperty('viewPortWidth', 'collapseThreshold'),

  init() {
    this._super(...arguments);
    this.refreshInternalSearchString();
    this.setupDefaultItems();
    this.setViewPortWidth();
  },

  didInsertElement() {
    this._super(...arguments);
    this.setupNavBackgroundBlocker();
    addEventListener(this, window, 'resize', () => this.setViewPortWidth());
    this.shortcuts.register(SHORTCUT_GROUP, this, [
      { key: '/', modalActive: false, method: this.shortcutPressed },
    ]);
  },

  willDestroyElement() {
    this.destroyNavBackgroundBlocker();
    this.shortcuts.unregister(SHORTCUT_GROUP);
    this._super(...arguments);
  },

  didUpdateAttrs() {
    this._super(...arguments);
    if (!this.isDestroying) {
      this.refreshInternalSearchString();
    }
  },

  shortcutPressed() {
    this.set('isExpanded', true);
    this.set('expandedView', true);
    this.focusOnInput();
  },

  collapse() {
    this.set('expandedView', false);
    this.resetSearch();
  },

  onNavBackgroundClick(e) {
    if ($(e.target).hasClass('nav__search__background-blocker')) {
      this.attemptToClose();
      if (this.internalSearchStringEmpty) {
        this.collapse();
      }
    }
  },

  refreshInternalSearchString() {
    let searchString = this.searchString;
    let lastSetSearchString = this.lastSetSearchString;
    let internalSearchString = this.internalSearchString;
    if (searchString === undefined) {
      searchString = '';
    }
    if (searchString !== lastSetSearchString) {
      this.set('lastSetSearchString', searchString);
      if (searchString !== internalSearchString) {
        this.set('internalSearchString', searchString);
        if (this.internalSearchStringNotEmpty) {
          this.set('expandedView', true);
        }
      }
    }
  },

  setupDefaultItems() {
    if (this.items === undefined) {
      this.set('items', A([]));
    }
  },

  setupNavBackgroundBlocker() {
    $('.nav__search__background-blocker', this.element).on('click', (e) => {
      next(() => {
        if (!this.isDestroying) {
          this.onNavBackgroundClick(e);
        }
      });
    });
  },

  destroyNavBackgroundBlocker() {
    $('.nav__search__background-blocker', this.element).off('click');
  },

  focusOnInput() {
    if (this.isDestroying) {
      return;
    }
    let input = $('.nav__search__input', this.element);
    if (input === undefined || input.is(':focus')) {
      return;
    }
    schedule('afterRender', this, function () {
      input.focus();
    });
  },

  createStandardSearchItem() {
    return EmberObject.create({
      name: this.internalSearchString,
      isHighlighted: this.selectedItemIsStandardSearch,
      isStandardSearch: true,
    });
  },

  blurInput() {
    if (this.isDestroying) {
      return;
    }
    let input = $('.nav__search__input', this.element);
    if (input === undefined || !input.is(':focus')) {
      return;
    }
    schedule('afterRender', this, function () {
      input.blur();
    });
  },

  disabled: false,
  expandedView: false,
  placeHolderText: 'Search…',
  preventDropdownToggleFromWithin: true,
  product: null,
  searchString: '',
  showSuggestions: true,
  testBindingClass: '',
  textInputFocused: false,
  isOpenOrTextInputFocused: or('textInputFocused', 'isOpen'),
  internalSearchStringEmpty: empty('internalSearchString'),
  internalSearchStringNotEmpty: not('internalSearchStringEmpty'),
  shouldShowFullBacking: or('internalSearchStringNotEmpty', 'isOpenOrTextInputFocused'),

  textInputClasses: computed('backingOptionClassForInput', 'testBindingClass', function () {
    return `nav__search__input ${this.backingOptionClassForInput} ${this.testBindingClass}`;
  }),

  backingOptionClassForInput: ternary('shouldShowFullBacking', 'o__with-full-backing', ''),
  searchInputIconColor: ternary('shouldShowFullBacking', 'gray', 'white'),

  selectedItemIsStandardSearch: computed('currentHighlightedItem', function () {
    let currentHighlightedItem = this.currentHighlightedItem;
    return currentHighlightedItem === undefined || currentHighlightedItem.isStandardSearch;
  }),

  selectableItems: computed(
    'internalSearchString',
    'items.[]',
    'showSuggestions',
    'isOpen',
    function () {
      if (!this.showSuggestions || !this.isOpen) {
        return A([]);
      }
      let searchString = this.internalSearchString;
      let selectableItems = this.items
        .filter(function (item) {
          return item.name.toLowerCase().includes(searchString.toLowerCase());
        })
        .slice(0, 10);

      if (this.internalSearchStringNotEmpty) {
        selectableItems.unshift(this.createStandardSearchItem());
      }
      return selectableItems;
    },
  ),

  selectableItemsNotEmpty: notEmpty('selectableItems'),

  // We have to provide our own keyDown here
  keyDown(e) {
    let keyCode = e.which !== undefined ? e.which : e.keyCode;
    switch (keyCode) {
      case 40:
        this.send('onDown');
        e.preventDefault();
        break;
      case 38:
        this.send('onUp');
        e.preventDefault();
        break;
      case 13:
        this.send('onEnter');
        e.stopPropagation();
        break;
      case 27:
        this.send('onEsc');
        break;
      default:
        this._super(e);
    }
  },

  attemptToOpen() {
    if (!(this.isDestroying || this.disabled)) {
      this.send('open');
    }
  },

  attemptToClose() {
    if (!this.isDestroying && this.isOpen) {
      this.send('close');
    }
  },

  clearSearchString() {
    this.set('internalSearchString', '');
  },

  setViewPortWidth() {
    debounceTask(this, '_setViewPortWidth', 100);
  },

  _setViewPortWidth() {
    this.set('viewPortWidth', window.innerWidth);
  },

  textInputFocusedAction: action(function () {
    this.set('textInputFocused', true);
  }),

  textInputBlurredAction: action(function () {
    this.set('textInputFocused', false);
  }),

  actions: {
    selectItem(item) {
      this.doSearch(item);
      this.send('closeWithoutCollapse');
      this.blurInput();
    },

    toggleExpandedView() {
      let expandedView = this.expandedView;
      this.set('expandedView', !expandedView);
      if (!expandedView) {
        this.focusOnInput();
      }
    },

    clearText() {
      if (this.internalSearchStringEmpty) {
        this.collapse();
      } else {
        this.clearSearchString();
        this.resetSearch();
        this.focusOnInput();
      }
    },

    close() {
      this._closeDropdown();
      if (this.internalSearchStringEmpty) {
        this.collapse();
      }
    },

    closeWithoutCollapse() {
      this._closeDropdown();
    },

    onEsc() {
      this.clearSearchString();
      this.send('close');
    },

    onEnter() {
      if (this.isOpen) {
        let item = this.highlightedItem;
        if (item === undefined) {
          item = this.createStandardSearchItem();
        }
        this.resetHighlightedItem();
        this.send('selectItem', item);
      }
    },
  },
});
