/* import __COLOCATED_TEMPLATE__ from './ew-company-url-fetching.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { type StepData } from '../../wizard';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { post } from 'embercom/lib/ajax';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type Store from '@ember-data/store';
import { taskFor } from 'ember-concurrency-ts';
import type { TaskGenerator } from 'ember-concurrency';
import {
  getDefaultColors,
  sanitizedDomainLink,
} from 'embercom/lib/onboarding/brand-fetch-functions';
import isValidUrl from 'embercom/lib/url-validator';

interface Signature {
  Element: never;
  Args: Args;
}

interface Args {
  step: any;
  stepData: StepData;
}

const DEFAULT_BASE_COLOR = '#0071b2';
export default class EwCompanyUrlFetching extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare appService: any;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare store: Store;

  @tracked companyUrl = '';

  messengerSettings: any = null;

  constructor(owner: unknown, args: Args) {
    super(owner, args);

    taskFor(this.loadMessengerSettings).perform();
  }

  get app() {
    return this.appService.app;
  }

  get totalStepCount() {
    return this.args.stepData.totalStepCount;
  }

  get currentStepIndex() {
    return this.args.stepData.currentStepIndex - 1;
  }

  get canSubmitUrl() {
    return this.companyUrl.length > 2 && isValidUrl(this.companyUrl);
  }

  @dropTask
  *loadMessengerSettings(): TaskGenerator<void> {
    this.messengerSettings = yield this.store.findRecord('messenger-settings/all', this.app.id);
  }

  @dropTask
  *submitCompanyUrl() {
    this.guideLibraryService.forcefulMessengerWizardURLProvided = true;
    this.guideLibraryService.forcefulMessengerWizardBrandFetchSuccessful = false;

    let url = sanitizedDomainLink(this.companyUrl);
    let fetchSuccess = false;
    let brandInfo: any = null;

    yield post(`/ember/onboarding/website_scraper`, {
      website_url: url,
      app_id: this.app.id,
    })
      .then((response: any) => {
        if (!response.brand_colors) {
          this.intercomEventService.trackAnalyticsEvent({
            action: 'failed',
            object: 'customize_messenger_with_brand_error_message',
            place: 'messenger-installation-wizard.ew-company-url-fetching',
            context: 'brand_scraping.return_unavailable',
            invalid_url: this.companyUrl,
          });
        } else {
          brandInfo = { ...response };
          this.setDefaultColors(response.brand_colors, response.theme);
          fetchSuccess = true;

          this.intercomEventService.trackEvent('forceful-messenger-wizard-url-fetch-success', {
            url: this.companyUrl,
          });
        }
      })
      .catch((e: any) => {
        this.intercomEventService.trackAnalyticsEvent({
          action: 'failed',
          object: 'customize_messenger_with_brand_error_message',
          place: 'messenger-installation-wizard.ew-company-url-fetching',
          context: 'brand_scraping.return_invalid',
          invalid_url: this.companyUrl,
          response: e.jqXHR.responseJSON.code,
        });
      });

    if (fetchSuccess) {
      if (brandInfo.logo_base64) {
        yield this.uploadLogo(brandInfo.logo_base64);
      }
      yield this.messengerSettings.save();
      this.guideLibraryService.forcefulMessengerWizardBrandFetchSuccessful = true;

      this.intercomEventService.trackAnalyticsEvent({
        action: 'success',
        object: 'customize_messenger_with_brand',
        place: 'messenger-installation_wizard.ew-company-url-fetching',
      });
    }

    this.args.stepData.continueCallback();
  }

  async uploadLogo(fileData: any) {
    await post(`/ember/messenger_settings/upload_logo/${this.app.id}`, {
      file_data: fileData,
      filename: 'logo.png',
      content_type: 'image/png',
    })
      .then((response: any) => {
        this.handleUploadSuccess(response);
      })
      .catch(() => {});
  }

  handleUploadSuccess(response: any) {
    this.messengerSettings.lookAndFeel.activeBrand.messengerLogoUrl = response.url;
    this.messengerSettings.lookAndFeel.activeBrand.messengerLogoId = response.id;

    if (this.messengerSettings.lookAndFeel.activeBrand.isDefault) {
      this.messengerSettings.lookAndFeel.logoUrl = response.url;
      this.messengerSettings.lookAndFeel.logoId = response.id;
    }
  }

  @action
  async continueWithoutUrl() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'dont_have_company_website_secondary_btn',
      place: 'messenger-installation-wizard.ew-company-url-fetching',
      context: 'forceful_messenger_installation_wizard_step',
      section: 'brand_customization_submission_buttons',
    });
    this.guideLibraryService.forcefulMessengerWizardURLProvided = false;
    await taskFor(this.resetMessengerSettingsToDefaults).perform();
    this.args.stepData.continueCallback();
  }

  @dropTask
  *resetMessengerSettingsToDefaults() {
    if (this.messengerSettings) {
      this.messengerSettings.lookAndFeel.activeBrand.messengerLogoUrl = null;
      this.messengerSettings.lookAndFeel.activeBrand.messengerLogoId = null;
      if (this.messengerSettings.lookAndFeel.activeBrand.isDefault) {
        this.messengerSettings.lookAndFeel.logoUrl = null;
        this.messengerSettings.lookAndFeel.logoId = null;
      }
      this.messengerSettings.lookAndFeel.primaryColor = DEFAULT_BASE_COLOR;
      this.messengerSettings.lookAndFeel.secondaryColor = DEFAULT_BASE_COLOR;
      yield this.messengerSettings.save();
    }
  }

  setDefaultColors(colors_arr: string[], theme: any) {
    let colors = getDefaultColors(colors_arr, theme);

    if (this.messengerSettings) {
      this.messengerSettings.lookAndFeel.primaryColor = colors.actionColor;
      this.messengerSettings.lookAndFeel.secondaryColor = colors.backgroundColor;
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::MessengerInstallationWizard::EwCompanyUrlFetching': typeof EwCompanyUrlFetching;
  }
}
