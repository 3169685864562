/* import __COLOCATED_TEMPLATE__ from './brand-email-dropdown-item.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import type SenderEmailVerificationService from 'embercom/services/sender-email-verification-service';
import { inject as service } from '@ember/service';
import type IntlService from 'ember-intl/services/intl';

interface Args {
  item: {
    text: string;
    value: string;
    componentAttrs: {
      verified: boolean;
      isSelected: boolean;
      isAuthenticated: boolean;
    };
  };
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
  Blocks: {
    default: [];
  };
}

export default class BrandEmailDropdownItem extends Component<Signature> {
  @service declare senderEmailVerificationService: SenderEmailVerificationService;
  @service declare appService: $TSFixMe;
  @service declare intl: IntlService;

  get issueText() {
    if (!this.args.item.componentAttrs.verified && !this.args.item.componentAttrs.isAuthenticated) {
      return this.intl.t('new-settings.workspace.brands.new-address-modal.issue-2');
    }
    return this.intl.t('new-settings.workspace.brands.new-address-modal.issue-1');
  }

  @action
  async resendVerificationLink() {
    await this.senderEmailVerificationService.resendVerificationEmail({
      senderEmailAddressSettingsId: this.args.item.value,
      appId: this.appService.app.id,
      adminId: this.appService.app.currentAdmin.id,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Brands::BrandEmailDropdownItem': typeof BrandEmailDropdownItem;
  }
}
