/* import __COLOCATED_TEMPLATE__ from './list.hbs'; */
/* RESPONSIBLE TEAM: team-data-interop */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

type Args = {
  customAuthenticationTokens: $TSFixMe;
};

export default class List extends Component<Args> {
  @tracked customAuthenticationTokens = this.args.customAuthenticationTokens;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::AppSettings::Authentication::List': typeof List;
    'new-settings/app-settings/authentication/list': typeof List;
  }
}
