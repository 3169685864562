/* import __COLOCATED_TEMPLATE__ from './question-group-side-drawer.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type QuestionGroup from 'embercom/models/ai-agent/question-group';
import type Store from '@ember-data/store';
import type IntlService from 'ember-intl/services/intl';
import { inject as service } from '@ember/service';
import type ExtractedQuestion from 'embercom/models/ai-agent/extracted-question';
import { helper } from '@ember/component/helper';
// @ts-ignore
import { sanitizeHtml } from '@intercom/pulse/lib/sanitize';
interface Args {
  onClose: () => void;
  questionGroupId: string;
  reloadModel: () => void;
}

export default class QuestionGroupSideDrawer extends Component<Args> {
  @service declare intl: IntlService & { languageNameFromCode: (code: string) => string };
  @service notificationsService: $TSFixMe;
  @service intercomEventService: $TSFixMe;
  @service declare store: Store;

  @tracked selectedTab = 'conversations';
  @tracked questionGroup?: QuestionGroup;
  @tracked displayedQuestions?: ExtractedQuestion[] = [];

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.fetchQuestionGroupWithExtractedQuestions).perform();
  }

  get extractedQuestions() {
    return this.questionGroup?.extractedQuestions || [];
  }

  get relatedContentSources() {
    return this.questionGroup?.contentSourcesUsed || [];
  }

  get errorMessage() {
    let message = this.intl.t(
      `operator.fin.setup.optimize.question-group-drawer.${this.selectedTab.replaceAll(
        /_/g,
        '-',
      )}-null-message`,
      {
        learnMoreUrl:
          'https://www.intercom.com/help/en/articles/7860255-support-content-best-practices-for-fin',
      },
    );
    return sanitizeHtml(message);
  }

  language = helper(([locale]: string[]) => {
    return this.intl.languageNameFromCode(locale);
  });

  @action
  onTabChange(value: string) {
    let container = document.querySelector('.side-drawer__body');
    if (container !== null) {
      container.scrollTop = 0;
    }
    this.displayedQuestions =
      value === 'conversations' ? this.extractedQuestions : this.filterByConversationStatus(value);
    this.selectedTab = value;
    this.trackAnalyticsEventForTabClick();
  }

  @action
  filterByConversationStatus(status: string) {
    return this.extractedQuestions.filter((question) => {
      return question.conversationResolutionState === status;
    });
  }

  @task({ restartable: true })
  *fetchQuestionGroupWithExtractedQuestions() {
    try {
      this.questionGroup = yield this.store.findRecord(
        'ai-agent/question-group',
        this.args.questionGroupId,
      );
      this.displayedQuestions = this.extractedQuestions;
    } catch (e) {
      this.notificationsService.notifyError(
        this.intl.t('operator.fin.setup.optimize.error-response'),
      );
    }
  }

  private trackAnalyticsEventForTabClick() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'side_drawer_conversations_tab',
      place: 'overview_optimize',
      section: 'ai_chatbot',
      tab_name: this.selectedTab,
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::Optimize::QuestionGroupSideDrawer': typeof QuestionGroupSideDrawer;
  }
}
