/* import __COLOCATED_TEMPLATE__ from './customer-support-channels-component.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { dropTask } from 'ember-concurrency-decorators';
import { post } from 'embercom/lib/ajax';

export default class CustomerSupportChannelsComponent extends Component {
  @service intercomEventService;
  @service onboardingHomeExternalStepService;
  @service router;
  @service permissionsService;
  @service appService;

  @dropTask
  *sendTestEmail() {
    let params = {
      app_id: this.appService.app.id,
    };
    let response = yield post(
      '/ember/onboarding/customer_support_channels/create_demo_conversation',
      params,
    );
    this.onboardingHomeExternalStepService.startAfterTransition(this.args.step.identifier, {
      source: 'home/customer-support-channels-component',
      method: 'startAfterTransition',
    });
    this.onboardingHomeExternalStepService.completeExternalStep();
    this.router.transitionTo(
      'inbox.workspace.inbox.inbox.conversation.conversation',
      this.appService.app.id,
      'shared',
      'all',
      response.conversation_id,
    );
  }
}
