/* import __COLOCATED_TEMPLATE__ from './fin-setup-banner.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';
import type { ChannelType } from 'embercom/lib/workflows/fin-workflow-preview';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    setupType: ChannelType;
    isFinLive: boolean;
  };
}

const FinSetupBanner = templateOnlyComponent<Signature>();
export default FinSetupBanner;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::CustomBots::FinSetupBanner': typeof FinSetupBanner;
    'operator/custom-bots/fin-setup-banner': typeof FinSetupBanner;
  }
}
