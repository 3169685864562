/* import __COLOCATED_TEMPLATE__ from './settings.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { UploadHelper, MediaHelper } from 'embercom/objects/media/media-helper';
export default class NewsfeedsSiteSettingsComponent extends Component {
  @service newsfeedSiteService;
  @service appService;
  @service notificationsService;
  @service intl;
  @tracked openSectionId = null;

  constructor() {
    super(...arguments);
    this.uploadHelper = UploadHelper.create({
      header: MediaHelper.create({
        type: 'header',
        siteSettingsName: 'headerUrl',
      }),
      logo: MediaHelper.create({
        type: 'logo',
        siteSettingsName: 'logoUrl',
      }),
      favicon: MediaHelper.create({
        type: 'favicon',
        siteSettingsName: 'faviconUrl',
      }),
      mediaTypes: ['header', 'logo', 'favicon'],
    });
    this.newsfeedSiteService.loadSite.perform();
  }

  get app() {
    return this.appService.app;
  }

  get site() {
    return this.newsfeedSiteService.site;
  }

  @action
  onOpenSectionChange(sectionId) {
    this.openSectionId = sectionId;
  }

  @action
  async save() {
    try {
      await this.saveMediaUpdates();
      await this.saveSite();
      this.notificationsService.notifyConfirmation(
        this.intl.t('outbound.newsfeeds.site-settings.notifications.updated'),
      );
    } catch (error) {
      this.handleServerError(error);
    }
  }

  @action
  async saveSite() {
    return this.newsfeedSiteService.saveSite.perform();
  }

  async saveMediaUpdates() {
    let media = [this.uploadHelper.header, this.uploadHelper.logo, this.uploadHelper.favicon];
    return this.newsfeedSiteService.saveMediaUpdates(media);
  }

  handleServerError(response) {
    let message = this.intl.t('outbound.newsfeeds.site-settings.notifications.server-error');
    let xhr = response.jqXHR;
    if (xhr && xhr.responseJSON && xhr.responseJSON.message) {
      let errorMessage = xhr.responseJSON.message;
      let displayedErrorMessage = `${message}: ${errorMessage}.`;

      this.notificationsService.notifyError(displayedErrorMessage);
    } else {
      this.notificationsService.notifyError(`${message}.`);
    }
  }
}
