/* import __COLOCATED_TEMPLATE__ from './enforce-identity.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface SettingsEnforceIdentityArgs {
  apiSecrets: {
    ios: string;
    android: string;
  };
  userSources: any;
  changeTabAndOpenSection: (tab: string, section: string) => void;
  selectedSection: string;
  idvSettings: $TSFixMe;
  reminder: $TSFixMe;
  pingDomains: $TSFixMe;
}

interface Signature {
  Element: HTMLDivElement;
  Args: SettingsEnforceIdentityArgs;
}

export default class EnforceIdentify extends Component<Signature> {
  @tracked showHashChecker = false;
  @tracked hashCheckerSecret = '';

  @action
  onOpenStepChange(stepId: string) {
    this.args.changeTabAndOpenSection('enforce-identity', stepId);
  }

  @action
  toggleHashCheckerVisibility(visibility: boolean, secret = '') {
    this.showHashChecker = visibility;
    this.hashCheckerSecret = secret;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Security::EnforceIdentity': typeof EnforceIdentify;
    'new-settings/workspace/security/enforce-identity': typeof EnforceIdentify;
  }
}
