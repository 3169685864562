/* import __COLOCATED_TEMPLATE__ from './branching-qualification-answers-editor.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file: 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
/* eslint-disable ember/no-actions-hash */
/* eslint-disable ember/no-classic-classes */
/* eslint-disable ember/no-classic-components */
/* eslint-disable @intercom/intercom/require-empty-tagname */
import { lt, gt } from '@ember/object/computed';
import { action } from '@ember/object';
import Component from '@ember/component';
export default Component.extend({
  categoryExamples: ['Developer', 'Product manager', 'Designer'],
  selectedAnswer: null,
  isEditingAnswerLabel: false,

  init() {
    this._super(...arguments);
    this.setSelectedAnswer(this.get('branchingQualificationAnswers.firstObject'));
  },

  canAddBranchingQualificationAnswer: lt('branchingQualificationAnswers.length', 3),
  hasBranchingQualificationAnswers: gt('branchingQualificationAnswers.length', 0),

  setSelectedAnswer(answer) {
    this.set('selectedAnswer', answer);
    this.set('isEditingAnswerLabel', false);
  },

  removeBranchingAnswer: action(function (answer) {
    this.branchingQualificationAnswers.removeFragment(answer);
    if (answer === this.selectedAnswer) {
      this.setSelectedAnswer(this.get('branchingQualificationAnswers.firstObject'));
    }
    this.set('isEditingAnswerLabel', false);
  }),

  beganEditingAnswer: action(function () {
    this.set('isEditingAnswerLabel', true);
  }),

  completedEditingAnswer: action(function (answer) {
    this.setSelectedAnswer(answer);
  }),

  actions: {
    addBranchingAnswer() {
      let answer = this.branchingQualificationAnswers.createFragment({
        justAdded: true,
      });
      answer.get('qualificationAttributes').createFragment({ identifier: 'email' });
      this.set('isEditingAnswerLabel', true);
    },

    setSelection(answer) {
      this.setSelectedAnswer(answer);
    },
  },
});
