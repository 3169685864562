/* import __COLOCATED_TEMPLATE__ from './customer-twilio-credentials-form.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { task } from 'ember-concurrency-decorators';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

const SUBACCOUNT_FRIENDLY_NAME_PREFIX = 'subaccount_intercom_app_';
export default class CustomerTwilioCredentialsForm extends Component {
  @service notificationsService;
  @service appService;
  @service store;

  @tracked customerTwilioCredentials = this.args.customerTwilioCredentials;

  @task
  *fetchCustomerTwilioCredentials() {
    let customerTwilioCredentialsList = yield this.store.findAll(
      'outbound-customer-twilio-credentials',
    );
    this.customerTwilioCredentials =
      customerTwilioCredentialsList.firstObject ||
      this.store.createRecord('outbound-customer-twilio-credentials');
  }

  @task({ drop: true })
  *setUpTwilioAccount() {
    yield this.customerTwilioCredentials
      .save()
      .then(() => {
        this.notificationsService.notifyConfirmation(
          'We have saved your Twilio credentials and set up a subaccount with a messaging service.',
        );
      })
      .catch(() => {
        this.notificationsService.notifyError(
          'There was a problem saving your Twilio credentials. Please check your account details and try again. If the problem persists please contact us.',
        );
      });
  }

  get subaccountName() {
    return SUBACCOUNT_FRIENDLY_NAME_PREFIX + this.appService.app.id;
  }
}
