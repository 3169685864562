/* import __COLOCATED_TEMPLATE__ from './configuration-page.hbs'; */
/* RESPONSIBLE TEAM: team-customer-data-platform */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { type ImportZendeskContext } from 'embercom/lib/apps/app/new-settings/data/imports-exports/import-zendesk/zendesk-context-loader';

export interface Args {
  context?: ImportZendeskContext;
  integrationCode: string;
}

interface Signature {
  Args: Args;
  Element: HTMLElement;
}

export default class ConfigurationPage extends Component<Signature> {
  @service declare intl: IntlService;

  translation = (path: string) => this.intl.t(this.translationKey(path));
  private translationKey = (path: string) =>
    `new-settings.data.imports-exports.import-zendesk.${path}`;
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Data::ImportsExports::ImportZendesk::ConfigurationPage': typeof ConfigurationPage;
    'new-settings/data/imports-exports/import-zendesk/configuration-page': typeof ConfigurationPage;
  }
}
