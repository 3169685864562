/* import __COLOCATED_TEMPLATE__ from './dropdown.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { createDropdownGroupFromGroupList } from '@intercom/pulse/lib/dropdown-group';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';

export default class Dropdown extends Component {
  @service appService;
  @service intl;

  get groupTree() {
    return createDropdownGroupFromGroupList(this.internalItems);
  }

  get internalItems() {
    let groupList = [];

    let items = this.args.items.map((i) => ({
      ...i,
      isSelected: this.args.selectedValues.includes(i.value),
    }));

    groupList.push({
      isMain: true,
      isFilterable: false,
      items,
      hasItemsMarkableAsSelected: true,
    });

    return {
      items: groupList,
      selectedCountLimit: 100,
      isFilterable: false,
    };
  }

  @action
  selectItem({ value }) {
    this.args.onSelectionChange(this.toggleSelectedItem(value));
  }

  @action
  debouncedFilterValues(value) {
    debounce(this, this.args.filterValues, value, 500);
  }

  toggleSelectedItem(value) {
    return this.args.selectedValues.includes(value)
      ? this.args.selectedValues.filter((i) => i !== value)
      : [...this.args.selectedValues, value];
  }
}
