/* import __COLOCATED_TEMPLATE__ from './import-support-content.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { dropTask } from 'ember-concurrency-decorators';
import ajax from 'embercom/lib/ajax';
import createFragmentFromBlock from 'embercom/lib/blocks/create-fragment-from-block';
import LoadFinProfileModule from 'embercom/lib/home/load-fin-profile';
import EnqueueApifyImportApi from 'embercom/lib/external-content/enqueue-apify-import-api';
import { getOwner } from '@ember/application';
import { FIN_GENERAL_RELEASE } from 'embercom/lib/home/guides';

export default class ImportSupportContent extends Component {
  @service onboardingHomeExternalStepService;
  @service contentImportService;
  @service intercomEventService;
  @service notificationsService;
  @service appService;
  @service realTimeEventService;
  @service intl;
  @service store;
  @service outboundHomeService;
  @service finOptInService;
  @service onboardingHomeService;

  @tracked showImportExternalContentModal = false;
  @tracked hasArticlesPublished = false;
  @tracked submissionErrorTranslationKey = '';
  @tracked addingAdditionalContent = false;
  ruleset = null;

  lastReceivedNexusTimestamp = 0;

  constructor() {
    super(...arguments);
    this.loadFinProfile.perform();
    if (!this.args.step.completed) {
      this.verifyPublishedArticlesExist.perform();
    }
    this.openStepCallback = this.args.openStepCallback || (() => {});
    this.markStepAsCompleted = this.markStepAsCompleted.bind(this);

    this.contentImportService.subscribeToContentImportRunStatusUpdates();
    this.fetchContentImportSources.perform();
    this.contentImportService.assignCompletionCallback(this.markStepAsCompleted.bind(this));
  }

  @dropTask
  *loadFinProfile() {
    if (this.ruleset) {
      return;
    }
    this.ruleset = yield LoadFinProfileModule.loadFinProfile(this);
  }

  @dropTask
  *fetchContentImportSources() {
    yield this.contentImportService.fetchContentImportSources();
    if (this.hasAnyCompletedContentImportSource) {
      this.markStepAsCompleted();
    }
  }

  get app() {
    return this.appService.app;
  }

  get hasAnyCompletedContentImportSource() {
    return this.contentImportService.successfulRuns.length > 0;
  }

  get hasAnyFailedContentImportSource() {
    return this.contentImportService.failedRuns.length > 0;
  }

  get isStepFailed() {
    return this.step?.state === 'failed';
  }

  get step() {
    return this.args.step;
  }

  get nextStepIdentifier() {
    if (this.step.get('nextStep.available')) {
      return this.step.nextStep.identifier;
    } else {
      return 'route_conversation_to_human';
    }
  }

  get contentImportSources() {
    return this.contentImportService.contentImportSources;
  }

  get resolutionBotBehaviour() {
    return this.ruleset?.rulesetLinks?.firstObject.object;
  }

  get botMode() {
    return this.resolutionBotBehaviour?.botMode;
  }

  get defaultLocale() {
    return this.appService.app.locale || 'en';
  }

  get isFinGeneralReleaseGuide() {
    return this.onboardingHomeService.guide.identifier === FIN_GENERAL_RELEASE;
  }

  get showLegalText() {
    return !this.isFinGeneralReleaseGuide && !this.finOptInService.hasOptedInToFin;
  }

  async markStepAsCompleted() {
    await this.store.findAll(
      'content-service/content-import-source',
      {
        app_id: this.appService.app.id,
      },
      {
        reload: true,
      },
    );
    if (this.step?.state === 'completed') {
      return;
    }
    if (!this.isFinGeneralReleaseGuide) {
      await this.activateAndEnableFin();
    }
    this.onboardingHomeExternalStepService.completeExternalStep();
    this.args.completionCallback();
  }

  @action
  showModal() {
    this.showImportExternalContentModal = true;

    this.intercomEventService.trackAnalyticsEvent({
      ...this.args.step.analyticsMetadata,
      action: 'clicked',
      object: 'add_content_button',
      context: 'ai_answer',
      place: 'onboarding_home',
      step: 'import_support_content',
    });
  }

  @action
  addAdditionalContent() {
    this.addingAdditionalContent = true;
  }

  @dropTask
  *verifyPublishedArticlesExist() {
    yield ajax({
      url: '/ember/articles/counts',
      type: 'GET',
      data: {
        app_id: this.app.id,
        admin_id: this.app.currentAdmin.id,
      },
    }).then((response) => {
      this.hasArticlesPublished = response.published_in_collection > 0;
    });
  }

  @dropTask
  *enqueueApifyImport() {
    this.trackAnalyticsEvent('submitted', 'url_submit_button');

    if (this.isUrlInvalid) {
      return;
    }

    let api = new EnqueueApifyImportApi(getOwner(this));
    let { submitted, submissionError, submissionErrorTranslationKey } =
      yield api.enqueueApifyImport(this.url, true);

    this.submitted = submitted;
    this.submissionError = submissionError;
    this.submissionErrorTranslationKey = submissionErrorTranslationKey;
    this.addingAdditionalContent = false;
  }

  @action
  async activateAndEnableFin() {
    try {
      let ruleset = this.ruleset;
      if (!ruleset) {
        return;
      }
      let rulesetLink = ruleset?.rulesetLinks?.firstObject?.object || {};
      if (!rulesetLink) {
        return;
      }
      rulesetLink.useAiAnswers = true;
      if (!['bot_only', 'looping'].includes(this.botMode)) {
        rulesetLink.botMode = 'bot_only';
      }

      if (this._isContentEmpty(rulesetLink)) {
        this._populateCloseConversationDefaultMessage(rulesetLink);
      }

      await ruleset.save();
      await ruleset.activate();
      if (!this.finOptInService.hasOptedInToFin) {
        await this.finOptInService.postAcceptOptIn.perform();
      }
      document.getElementById('embedded-messenger')?.contentWindow.location.reload();
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t('onboarding.home.steps.route-conversation-to-human.error-saving'),
      });
    }
  }

  _populateCloseConversationDefaultMessage(rulesetLink) {
    rulesetLink?.botOnlyMessageLocalizations?.forEach((messages) => {
      if (messages?.parts.length === 0) {
        messages?.parts.createFragment();
      }
      if (messages?.parts.firstObject.blocks.length === 0) {
        messages.parts.firstObject.blocks = [
          createFragmentFromBlock(this.store, {
            text: this.intl.t(
              'onboarding.home.steps.route-conversation-to-human.close-the-conversation-default-message',
              {
                htmlSafe: true,
              },
            ),
            type: 'paragraph',
          }),
        ];
      }
    });
  }

  _isContentEmpty(rulesetLink) {
    let currentMessage = rulesetLink?.botOnlyMessageLocalizations?.filter(
      (messages) => messages.locale === this.defaultLocale,
    );
    let isContentEmpty =
      currentMessage?.firstObject?.parts?.length === 0 ||
      currentMessage?.firstObject?.parts?.firstObject?.blocks.length === 0 ||
      currentMessage?.firstObject?.parts?.firstObject?.blocks?.firstObject.text === '';
    return isContentEmpty;
  }

  @action trackInputAnalytics() {
    this.trackAnalyticsEvent('inputed', 'url_form_input');
  }

  trackAnalyticsEvent(action, object) {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      context: 'external_content_modal',
      place: 'fin_setup',
    });
  }
}
