/* import __COLOCATED_TEMPLATE__ from './home.hbs'; */
/* RESPONSIBLE TEAM: team-phone */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { isPresent, isBlank } from '@ember/utils';
import { countryData } from 'embercom/components/outbound/sms/onboarding/country-data';
import {
  ONBOARDING_STEP_KEYS,
  ONBOARDING_PROGRESS_STEP_LIST,
} from 'embercom/models/data/sms/constants';

export default class OnboardingHome extends Component {
  @service appService;
  @tracked selectedCountryCode = null;
  @tracked purchasedPhoneNumber = null;

  @tracked onboardingSteps = [ONBOARDING_STEP_KEYS.SELECT_COUNTRY];

  @tracked currentStepIndex = 0;
  @tracked currentStep = this.onboardingSteps[this.currentStepIndex];

  onboardingStepKeys = ONBOARDING_STEP_KEYS;

  constructor(...args) {
    super(...args);
    this.checkIfSkipped();
  }

  // All onboarding steps as per a country
  get onboardingStepsByCountry() {
    let defaultSteps = [
      ONBOARDING_STEP_KEYS.SELECT_COUNTRY,
      ...this.selectedCountry.onboardingSteps,
    ];
    if (this.args.isAddCountryModal) {
      return _.without(
        defaultSteps,
        ONBOARDING_STEP_KEYS.CONFIGURE_SMS,
        ONBOARDING_STEP_KEYS.PREVIEW_SMS,
      );
    }
    return defaultSteps;
  }

  // progress step list based on onboarding steps
  get progressStepList() {
    let defaultProgressSteps = _.clone(ONBOARDING_PROGRESS_STEP_LIST);
    if (this.args.isAddCountryModal) {
      return _.omit(defaultProgressSteps, [ONBOARDING_STEP_KEYS.CONFIGURE_SMS]);
    }
    return defaultProgressSteps;
  }

  get displayProgressStepList() {
    return (
      this.currentStepIndex <= Object.keys(this.progressStepList).length &&
      Object.keys(this.progressStepList).includes(this.currentStep)
    );
  }

  // get the current step index from the onboarding steps
  get currentStepFromIndex() {
    return this.onboardingSteps[this.currentStepIndex];
  }

  get selectedCountry() {
    return countryData[this.selectedCountryCode];
  }

  checkIfSkipped() {
    // don't show onbarding anymore when phone number pruchased and further steps already skipped
    if (isPresent(this.args.phoneNumbers) && isBlank(this.purchasedPhoneNumber)) {
      this.args.setOnboardingStarted(false);
    }
  }

  @action
  incrementStep() {
    this.currentStepIndex += 1;
    this.currentStep = this.currentStepFromIndex;
    // scroll to top after each step
    document.getElementById('sms-onboarding-container').scrollIntoView();
  }

  @action
  processOnboarding() {
    this.args.setOnboardingStarted(true);
  }

  @action
  updateSelectedCountryCode(countryCode) {
    this.selectedCountryCode = countryCode;
    this.onboardingSteps = this.onboardingStepsByCountry;
    this.incrementStep();
  }

  @action
  updatePurchasedPhoneNumber(phoneNumber) {
    this.purchasedPhoneNumber = phoneNumber;
    this.incrementStep();
    if (!this.args.isAddCountryModal) {
      this.args.refreshAssociatedPhoneNumbers.perform();
    }
  }

  @action
  resetForm() {
    if (this.currentStepIndex >= this.onboardingStepsByCountry.length && this.args.resetModal) {
      this.args.resetModal();
    }
  }
}
