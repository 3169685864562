/* import __COLOCATED_TEMPLATE__ from './type-cell.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import {
  objectTypes,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';

const LETTER_A_CODE = 65;

export default class TypeCell extends Component {
  @service appService;

  get app() {
    return this.appService.app;
  }

  get contentName() {
    if (this.args.wrapper.hasMultipleContentFragments) {
      let contentType = this.args.content.contentType;

      if (contentType === objectTypes.legacyControlGroup) {
        return humanReadableObjectNames[contentType];
      } else if (this.args.wrapper.contents.length === 2 && this.args.wrapper.hasControlGroup) {
        return humanReadableObjectNames[contentType];
      } else {
        return `Test ${String.fromCharCode(
          LETTER_A_CODE + this.args.wrapper.contents.indexOf(this.args.content),
        )}`;
      }
    }
  }

  get queryParams() {
    let contentType = this.args.content.contentType;
    if (contentType >= 12 && contentType <= 15) {
      return {
        version: this._versionQueryParam,
      };
    } else {
      return {
        test: this.args.content.rulesetLinkId,
      };
    }
  }

  get _versionQueryParam() {
    if (this.args.content.contentType === objectTypes.legacyControlGroup) {
      return 'control';
    } else {
      return String.fromCharCode(
        LETTER_A_CODE + this.args.wrapper.contents.indexOf(this.args.content),
      );
    }
  }
}
