/* import __COLOCATED_TEMPLATE__ from './templates-list.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-bare-strings */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
  objectIcons,
  objectTypes,
  humanReadableObjectNames,
} from 'embercom/models/data/matching-system/matching-constants';
import { inject as service } from '@ember/service';

export default class TemplateList extends Component {
  @service intercomEventService;
  @service appService;

  get objectIcons() {
    return objectIcons;
  }

  get humanReadableObjectNames() {
    return {
      ...humanReadableObjectNames,
      [objectTypes.customBot]: 'Workflow',
      [objectTypes.tour]: 'Product tour',
    };
  }

  get objectTypes() {
    return objectTypes;
  }

  @action
  onTemplateClick(template) {
    if (this.args.isEmptyState) {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'clicked',
        object: 'message_template',
        context: 'empty_state',
        template_id: template.id,
      });
    } else {
      this.intercomEventService.trackAnalyticsEvent({
        action: 'viewed',
        object: template.id,
        context: 'template_modal',
      });
    }

    if (template.isAvailable) {
      this.args.onSelect(template);
    }
  }
}
