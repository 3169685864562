/* import __COLOCATED_TEMPLATE__ from './switch.hbs'; */
/* RESPONSIBLE TEAM: team-channels */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
  tab: string;
  changeTab: (tab: string) => void;
}

export default class Switch extends Component<Args> {
  @service declare appService: any;

  @tracked selectedValue = 'setup';

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.tab === 'monitor') {
      this.onSelectionChange(this.args.tab);
    }
  }

  @action
  onSelectionChange(value: string) {
    this.args.changeTab(value);
    this.selectedValue = value;
  }

  get paywallActiveOverride() {
    return !this.appService.app?.canUseFeature('switch');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Channels::Switch': typeof Switch;
  }
}
