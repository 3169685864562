/* import __COLOCATED_TEMPLATE__ from './new-message-button.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { objectTypes } from 'embercom/models/data/matching-system/matching-constants';

const OUTBOUND_ALL_ROUTE = 'apps.app.outbound.all';
export default class NewMessageButton extends Component {
  @service intercomEventService;
  @service router;

  @tracked showContentCreationModal =
    !this.args.disableAutoOpenContentCreationModal &&
    this.router.currentRouteName === OUTBOUND_ALL_ROUTE &&
    objectTypes.hasOwnProperty(this.router.currentRoute?.queryParams?.open_content_creation_modal);

  @action
  toggleContentCreationModal() {
    this.showContentCreationModal = !this.showContentCreationModal;
    this.intercomEventService.trackAnalyticsEvent({
      action: this.showContentCreationModal ? 'opened' : 'closed',
      object: 'admin',
      context: 'template_modal',
      location: this.args.location,
    });
  }
}
