/* import __COLOCATED_TEMPLATE__ from './add-content-preview.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import templateOnlyComponent from '@ember/component/template-only';

const AddContentPreview = templateOnlyComponent();
export default AddContentPreview;

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::AddContentPreview': typeof AddContentPreview;
    'operator/fin/setup/add-content-preview': typeof AddContentPreview;
  }
}
