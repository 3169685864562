/* import __COLOCATED_TEMPLATE__ from './success-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { action } from '@ember/object';
import type { Brand } from './types/brand';
import safeWindowOpen from 'embercom/lib/safe-window-open';

interface Args {
  onClose: () => void;
  brand: Brand;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class SuccessModal extends Component<Signature> {
  @action
  handleClose() {
    this.args.onClose();
  }

  @action
  handleClickMessengerStyles() {
    safeWindowOpen(
      '/a/apps/_/settings/channels/messenger/web?section=general&tab=styling',
      '_blank',
    );
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Brands::SuccessModal': typeof SuccessModal;
  }
}
