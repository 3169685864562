/* import __COLOCATED_TEMPLATE__ from './proactive-support-get-started.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { modifier } from 'ember-modifier';
import { action } from '@ember/object';
import { type StepData } from '../../wizard';
import { ref } from 'ember-ref-bucket';
import ENV from 'embercom/config/environment';

interface Signature {
  Element: never;
  Args: {
    step: any;
    stepData: StepData;
  };
}

export default class ProactiveSupportGetStarted extends Component<Signature> {
  @ref('videoElement') videoElement!: HTMLVideoElement;
  @tracked isMessengerVideoPlaying = false;

  autoPlayVideo = modifier((videoElement: HTMLVideoElement) => {
    let readyFn = () => {
      if (videoElement.readyState >= 2 && ENV.environment !== 'test') {
        videoElement.currentTime = 0;
        videoElement.muted = true;
        videoElement.play();
      }
    };

    let playFn = () => {
      this.isMessengerVideoPlaying = true;
    };

    let endedFn = () => {
      this.isMessengerVideoPlaying = false;
    };

    videoElement.addEventListener('loadeddata', readyFn);
    videoElement.addEventListener('play', playFn);
    videoElement.addEventListener('ended', endedFn);

    return function () {
      videoElement.removeEventListener('loadeddata', readyFn);
      videoElement.removeEventListener('play', playFn);
      videoElement.removeEventListener('ended', endedFn);
    };
  });

  @action replayVideo(): void {
    if (
      this.videoElement &&
      (this.videoElement.paused || this.videoElement.ended) &&
      ENV.environment !== 'test'
    ) {
      this.videoElement.currentTime = 0;
      this.videoElement.muted = true;
      this.videoElement.play();
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::ProactiveSupportWizard::ProactiveSupportGetStarted': typeof ProactiveSupportGetStarted;
  }
}
