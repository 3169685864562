/* import __COLOCATED_TEMPLATE__ from './ew-messenger-preview.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */
import Component from '@glimmer/component';
import { type StepData } from '../../wizard';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import type Store from '@ember-data/store';
import { type TaskGenerator } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import { taskFor } from 'ember-concurrency-ts';
import type GuideLibraryService from 'embercom/services/guide-library-service';

interface Signature {
  Element: never;
  Args: {
    step: any;
    stepData: StepData;
  };
}

export default class EwMessengerPreview extends Component<Signature> {
  @service declare intercomEventService: any;
  @service declare store: Store;
  @service declare appService: any;
  @service declare guideLibraryService: GuideLibraryService;

  @tracked messengerSettings: any;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    taskFor(this.fetchMessengerSettings).perform();
  }

  get app() {
    return this.appService.app;
  }

  get modifiedStepData() {
    return {
      ...this.args.stepData,
      currentStepIndex: this.args.stepData.currentStepIndex - 1,
    };
  }

  @action
  actionHandler() {
    this.args.stepData.continueCallback();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'messenger_preview_button',
      place: 'messenger-installation-wizard.ew-messenger-preview',
    });
  }

  @task({ drop: true })
  *fetchMessengerSettings(): TaskGenerator<void> {
    let messengerSettings = yield this.store.findRecord('messenger-settings/all', this.app.id);
    this.messengerSettings = messengerSettings;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::MessengerInstallationWizard::EwMessengerPreview': typeof EwMessengerPreview;
  }
}
