/* import __COLOCATED_TEMPLATE__ from './empty-state-guide-banner.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';

interface Args {
  data: any;
  createContent: () => void;
  templates: any[];
  disableNewSmsButton: boolean | undefined;
}

export default class EmptyStateGuideBanner extends Component<Args> {
  @service declare intercomEventService: any;
  @service declare appService: any;

  get app() {
    return this.appService.app;
  }

  @action
  showGetStartedWithOutboundArticle() {
    window.Intercom('showArticle', 3292835); // https://www.intercom.com/help/en/articles/3292835-get-started-with-outbound
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'get_started_with_outbound_help_link',
      context: 'discovery-banner',
      place: 'outbound.list-header',
      section: 'discovery-banner',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::EmptyStateGuideBanner': typeof EmptyStateGuideBanner;
    'outbound/empty-state-guide-banner': typeof EmptyStateGuideBanner;
  }
}
