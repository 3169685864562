/* import __COLOCATED_TEMPLATE__ from './custom-bots-auto-close-settings.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import {
  CONVERSATION_STARTED_TARGET,
  EVERYONE_TARGET,
  NEW_CONVERSATION_TARGET,
  OUTBOUND_TARGET,
  NEW_COMMENT_TARGET,
  USER_INACTIVITY_TARGET,
  ADMIN_INACTIVITY_TARGET,
  CONVERSATION_STATUS_CHANGED_TARGET,
} from 'embercom/lib/operator/custom-bots/constants';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';

export default class CustomBotsAutoCloseSettings extends Component {
  @service appService;
  @service intl;

  get app() {
    return this.appService.app;
  }

  get autoClosingTimerMinutesOptions() {
    return [
      {
        items: [3, 5, 7, 10, 15].map((timeMinutes) => {
          return {
            text: this.intl.t('operator.settings.custom-bots.auto-close.time-minutes', {
              number: timeMinutes,
            }),
            value: timeMinutes,
          };
        }),
      },
    ];
  }

  get orderedCustomBotSettings() {
    let settings = [
      {
        name: 'outbound-users',
        target: OUTBOUND_TARGET,
        settingsKey: 'autoClosingForUsers',
        hasHint: false,
      },
      {
        name: 'outbound-visitors',
        target: OUTBOUND_TARGET,
        settingsKey: 'autoClosingForVisitors',
        hasHint: false,
      },
      {
        name: 'button',
        target: EVERYONE_TARGET,
        settingsKey: 'autoClosingFromButton',
        hasHint: false,
      },
      {
        name: 'inbound-users',
        target: NEW_CONVERSATION_TARGET,
        settingsKey: 'autoClosingFromNewConversationUsers',
        hasHint: false,
      },
      {
        name: 'inbound-visitors',
        target: NEW_CONVERSATION_TARGET,
        settingsKey: 'autoClosingFromNewConversationVisitors',
        hasHint: false,
      },
      {
        name: 'conversation-started',
        target: CONVERSATION_STARTED_TARGET,
        settingsKey: 'autoClosingConversationStarted',
        hasHint: false,
      },
      {
        name: 'new-comment',
        target: NEW_COMMENT_TARGET,
        settingsKey: 'autoClosingNewComment',
        hasHint: false,
      },
      {
        name: 'user-inactivity',
        target: USER_INACTIVITY_TARGET,
        settingsKey: 'autoClosingUserInactivity',
        hasHint: false,
      },
      {
        name: 'admin-inactivity',
        target: ADMIN_INACTIVITY_TARGET,
        settingsKey: 'autoClosingAdminInactivity',
        hasHint: false,
      },
      {
        name: 'conversation-status-changed',
        target: CONVERSATION_STATUS_CHANGED_TARGET,
        settingsKey: 'autoClosingConversationStatusChanged',
        hasHint: false,
      },
    ];

    return settings.filter((s) => CUSTOM_BOT_CONFIG[s.target].visibleToApp(this.app));
  }
}
