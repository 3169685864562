/* import __COLOCATED_TEMPLATE__ from './template-preview.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import Component from '@glimmer/component';
import { CUSTOM_BOT_CONFIG } from 'embercom/objects/operator/configuration/configuration';
import type { BotConfigTarget as Target } from 'embercom/objects/operator/configuration/configuration';
import { TEMPLATE_GROUPS } from 'embercom/lib/operator/custom-bots/constants';
import type Template from 'embercom/models/template';
import type OperatorCustomBotsOverviewComponent from 'embercom/components/operator/custom-bots/overview';

interface Args {
  selectedTemplateContentObject: { [key: string]: any; target: Target };
  selectedTemplate: Template;
  templates: Template[];
  onClose: () => void;
  transitionBack: () => void;
  recordAnalyticsEvent: (eventName: string) => void;
  createWorkflow: OperatorCustomBotsOverviewComponent['createCustomBot'];
  selectTemplate: (template: Template) => void;
  shouldShowPaywallBadges: boolean;
  disableTransitionBack?: boolean;
  showSimilarTemplates?: boolean;
}

export default class TemplatePreview extends Component<Args> {
  get editorConfig() {
    return CUSTOM_BOT_CONFIG[this.args.selectedTemplateContentObject.target];
  }

  get templateGroup() {
    return TEMPLATE_GROUPS.find((group) => {
      return this.args.selectedTemplate.objective === group.id;
    }) as (typeof TEMPLATE_GROUPS)[number];
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Outbound::NewContentModal::Workflows::TemplatePreview': typeof TemplatePreview;
    'outbound/new-content-modal/workflows/template-preview': typeof TemplatePreview;
  }
}
