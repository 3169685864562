/* import __COLOCATED_TEMPLATE__ from './source.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */

import { action } from '@ember/object';
import Component from '@glimmer/component';
import type ContentImportSource from 'embercom/models/content-service/content-import-source';
import { SyncBehaviourTypes } from 'embercom/models/content-service/content-import-source';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import type IntlService from 'embercom/services/intl';
import { runTask, pollTask } from 'ember-lifeline';
import ENV from 'embercom/config/environment';
import moment from 'moment-timezone';
import { post } from 'embercom/lib/ajax';
import { FailureReasonTypes } from 'embercom/models/content-service/content-import-run';
import { CAN_MANAGE_KNOWLEDGE_BASE_CONTENT } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  source: ContentImportSource;
  resetSearch?: () => void | Promise<void>;
}

export default class Source extends Component<Args> {
  @service declare intl: IntlService;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare contentImportService: any;
  @service declare permissionsService: any;
  @tracked showDeleteSourceModal = false;
  @tracked showResyncSourceModal = false;
  @tracked showAbortSourceImportModal = false;

  constructor(owner: unknown, args: Args) {
    super(owner, args);
    if (this.args.source.latestImportRun) {
      pollTask(this, 'updateContentImportRun');
    }
  }

  get app() {
    return this.appService.app;
  }

  async updateContentImportRun(next: any) {
    if (this.args.source.latestImportRun) {
      // Only query when the tab is actually being used
      if (ENV.environment === 'test' || document.visibilityState === 'visible') {
        await this.args.source.latestImportRun.reload();
      }
      if (this.args.source.latestImportRun.isInProgress) {
        runTask(this, next, ENV.APP._5000MS);
      } else if (this.args.source.latestImportRun.isFullyIngested) {
        //reload source when ingested to get new page count
        this.args.source.reload();
      }
    }
  }

  @action async toggleDeleteSourceModal(): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
      this.showDeleteSourceModal = !this.showDeleteSourceModal;
      this.trackAnalyticsEvent('clicked', 'delete_source_button');
    } catch (e) {
      return;
    }
  }

  @action async toggleResyncSourceModal(): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
      this.showResyncSourceModal = !this.showResyncSourceModal;
      this.trackAnalyticsEvent('clicked', 'resync_source_button');
    } catch (e) {
      return;
    }
  }

  @action async toggleStatus() {
    try {
      this.args.source.status = this.args.source.status === 'active' ? 'deactivated' : 'active';
      await this.args.source.save();
      let action = this.args.source.status === 'deactivated' ? 'deactivated' : 'activated';
      this.trackAnalyticsEvent(action, 'external_sources_toggle');
    } catch (e) {
      this.args.source.rollbackAttributes();
      this.notificationsService.notifyResponseError(e);
    }
  }

  @action async deleteSource() {
    this.trackAnalyticsEvent('clicked', 'delete_confirm_button');

    try {
      await this.args.source.destroyRecord();
      if (this.args.resetSearch) {
        await this.args.resetSearch();
      }
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.external-content.source.delete-success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
    }
  }

  @action async resyncSource() {
    this.trackAnalyticsEvent('clicked', 'resync_confirm_button');

    try {
      await this.args.source.resync();
      await this.args.source.reload();
      this.notificationsService.notifyConfirmation(
        this.intl.t('operator.external-content.source.resync-success'),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error);
    }
    this.toggleResyncSourceModal();
  }

  @action
  openMessenger() {
    window.Intercom('showMessages');
  }

  get sourceOptionsList() {
    let options = [
      {
        text: this.intl.t('operator.external-content.source.delete'),
        icon: 'delete',
        onSelectItem: this.toggleDeleteSourceModal,
        tooltipText: this.intl.t('operator.external-content.source.delete-tooltip'),
        isDisabled: false,
      },
    ];

    if (this.args.source.canManuallySync && !this.args.source.canResyncFailure) {
      options.push({
        text: this.intl.t('operator.external-content.source.resync'),
        icon: 'sync',
        onSelectItem: this.toggleResyncSourceModal,
        tooltipText: this.args.source.wasSyncedOverAWeekAgo
          ? this.intl.t('operator.external-content.source.resync-tooltip')
          : this.intl.t('operator.external-content.source.resync-tooltip-disabled', {
              days: moment(this.args.source.lastSyncedAt).add(7, 'days').format('MMM DD yyyy'),
            }),
        isDisabled: !this.args.source.wasSyncedOverAWeekAgo,
      });
    }

    return options;
  }

  @action async toggleAbortSourceImportModal(): Promise<void> {
    try {
      await this.permissionsService.checkPermission(CAN_MANAGE_KNOWLEDGE_BASE_CONTENT);
      this.showAbortSourceImportModal = !this.showAbortSourceImportModal;
    } catch (e) {
      return;
    }
  }

  @action async abortLatestImportRun() {
    this.trackAnalyticsEvent('clicked', 'abort_import_button');
    if (this.args.source.latestImportRun?.id) {
      try {
        await post(
          `/ember/content_service/content_import_runs/${this.args.source.latestImportRun?.id}/abort`,
          {
            app_id: this.app.id,
            admin_id: this.app.currentAdmin.id,
          },
        );
        await this.args.source.destroyRecord();
        this.toggleAbortSourceImportModal();
        if (this.args.resetSearch) {
          await this.args.resetSearch();
        }

        this.notificationsService.notifyConfirmation(
          this.intl.t('ai-content-library.sources.stop-import-success'),
        );
      } catch (error) {
        this.notificationsService.notifyResponseError(error, {
          default: this.intl.t('ai-content-library.sources.stop-import-error'),
        });
      }
    }
  }

  get syncBehaviorTooltipText() {
    if (this.args.source.syncBehavior === SyncBehaviourTypes.MANUAL) {
      return this.args.source.wasSyncedOverAWeekAgo
        ? this.intl.t('operator.external-content.source.sync-behavior.manual.resync-instruction')
        : this.intl.t('operator.external-content.source.sync-behavior.manual.resync-after', {
            days: moment(this.args.source.lastSyncedAt).add(7, 'days').format('MMM DD yyyy'),
          });
    } else {
      return this.intl.t('operator.external-content.source.sync-behavior.automatic.resync-after', {
        days: moment(this.args.source.lastSyncedAt).add(7, 'days').format('MMM DD yyyy'),
      });
    }
  }

  get failureMessage() {
    if (
      this.args.source.canResyncFailure &&
      this.args.source.latestImportRun?.failureReason === FailureReasonTypes.NO_IMPORTED_CONTENT
    ) {
      return this.intl.t('components.external-content.import.failure.no-content');
    } else if (this.args.source.canResyncFailure) {
      return this.intl.t('components.external-content.import.failure.resync');
    } else {
      return this.intl.t('components.external-content.import.failure.message');
    }
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      context: 'side_drawer',
      place: 'external_content',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::ExternalContent::Sources::Source': typeof Source;
  }
}
