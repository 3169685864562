/* import __COLOCATED_TEMPLATE__ from './fallback-option-close-conversation.hbs'; */
/* RESPONSIBLE TEAM: team-product-guidance */

import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

interface Signature {
  Element: HTMLDivElement;
  Args: {
    continueCallback: () => void;
    resolutionBotBehaviour: any;
    triggerSave: () => void;
    disableContinue: boolean;
  };
}

export default class FallbackOptionCloseConversation extends Component<Signature> {
  @service declare appService: any;

  @tracked selectedBotOnlyLocale = this.defaultLocale;
  @tracked continueButtonEnabled = false;

  get defaultLocale() {
    return this.appService.app.locale || 'en';
  }

  @action
  onPartChange() {
    this.continueButtonEnabled = this._isContentEmpty();
  }

  _isContentEmpty() {
    let currentMessage = this.args.resolutionBotBehaviour?.botOnlyMessageLocalizations?.filter(
      (messages: any) => messages.locale === this.selectedBotOnlyLocale,
    );
    let isContentEmpty =
      currentMessage?.firstObject?.parts?.length === 0 ||
      currentMessage?.firstObject?.parts?.firstObject?.blocks.length === 0 ||
      currentMessage?.firstObject?.parts?.firstObject?.blocks?.firstObject.text === '';
    return isContentEmpty;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Onboarding::GuideLibrary::Steps::AiBotWizard::EwChatbotFinFallbackComponents::FallbackOptionCloseConversation': typeof FallbackOptionCloseConversation;
  }
}
