/* import __COLOCATED_TEMPLATE__ from './intervals-editor.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import {
  createIntervalGroupsFromIntervals,
  createDefaultIntervalGroup,
} from 'embercom/lib/office-hours/interval-group';

export default class IntervalsEditor extends Component {
  @tracked intervalGroups = [];
  @service store;
  @service intercomEventService;
  constructor() {
    super(...arguments);
    this.intervalGroups = createIntervalGroupsFromIntervals(this.args.timeIntervals);
  }

  @action
  addRow() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'clicked',
      object: 'add_hours_button',
      place: 'settings.office_hours',
      section: 'general',
    });
    this.intervalGroups.pushObject(createDefaultIntervalGroup(this.store));
    this.setTimeIntervals();
  }

  @action
  async removeRow(row) {
    if (this.intervalGroups.length === 1 && this.args.onBeforeRemoveLastRow) {
      let canRemoveHours = await this.args.onBeforeRemoveLastRow();
      if (!canRemoveHours) {
        return;
      }
    }
    this.intervalGroups.removeObject(row);
    this.setTimeIntervals();
  }

  @action
  setTimeIntervals() {
    let timeIntervals = this.intervalGroups.map((group) => group.timeIntervals.flat());
    this.args.updateIntervals(timeIntervals.flat());
  }
}
