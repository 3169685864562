/* import __COLOCATED_TEMPLATE__ from './rules-editor.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import type Store from '@ember-data/store';
import type AnswerBot from 'embercom/models/operator/visual-builder/step/answer-bot';
import type Hangup from 'embercom/models/operator/visual-builder/step/hang-up';
import type AnswerTerminal from 'embercom/models/operator/visual-builder/step/answer-terminal';
import type ArrayProxy from '@ember/array/proxy';
import type ConversationSla from 'embercom/models/inbox/conversation-sla';
import type OfficeHoursSchedule from 'embercom/models/office-hours-schedule';

interface Args {
  isConvoTaggingDisabled: boolean;
  isConversationSlaDisabled?: boolean;
  isAssignConversationDisabled?: boolean;
  isCloseConversationAllowed?: boolean;
  extraSimpleActions?: string[];
  extraRuleActions?: string[];
  step: AnswerBot | AnswerTerminal | Hangup;
  className?: string;
  conversationSlas?: ArrayProxy<ConversationSla>;
  officeHoursSchedules?: ArrayProxy<OfficeHoursSchedule>;
  hideAlwaysIFTTT?: boolean;
  isReadOnly: boolean;
}

export default class RulesEditor extends Component<Args> {
  @service declare appService: any;
  @service declare attributeService: any;
  @service declare store: Store;

  constructor(owner: unknown, args: any) {
    super(owner, args);
    this.ensureStepHasSimpleActionRule();
  }

  get app() {
    return this.appService.app;
  }

  get sharedActions() {
    let allowedActions = ['tag-user'];

    if (!this.args.isAssignConversationDisabled) {
      allowedActions.push('assign-conversation');
    }

    if (!this.args.isConvoTaggingDisabled) {
      allowedActions.push('add-tag-to-conversation');
    }

    if (this.app.hasSalesforceIntegration) {
      allowedActions.push('send-to-salesforce');
      allowedActions.push('create-salesforce-case');
    }

    if (this.app.hasHubSpotIntegration) {
      allowedActions.push('send-to-hubspot');
    }

    if (this.app.hasMarketoEnrichmentIntegration) {
      allowedActions.push('send-to-marketo');
      allowedActions.push('trigger-marketo-campaign');
    }

    if (this.app.canUsePriorityRules) {
      allowedActions.push('change-conversation-priority');
    }

    if (this.app.canUseInboxSlas && !this.args.isConversationSlaDisabled) {
      allowedActions.push('apply-conversation-sla');
    }

    return allowedActions;
  }

  get allowedSimpleActions() {
    let sharedActions = this.sharedActions;

    return sharedActions.concat(this.args.extraSimpleActions || []);
  }

  get allowedRuleActions() {
    let sharedActions = this.sharedActions;

    return sharedActions.concat(this.args.extraRuleActions || []);
  }

  get allowedRuleConditionalAttributes() {
    return this.attributeService.customBotFollowupActionAttributesGroupList;
  }

  get emptyRules() {
    return [];
  }

  ensureStepHasSimpleActionRule() {
    if (!this.args.step.hasSimpleActionRule) {
      this.args.step.simpleActionRule = this.store.createFragment('rules/rule');
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'OperatorFlows::Editor::RulesEditor': typeof RulesEditor;
    'operator-flows/editor/rules-editor': typeof RulesEditor;
  }
}
