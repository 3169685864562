/* import __COLOCATED_TEMPLATE__ from './fin-identity-editor.hbs'; */
/* RESPONSIBLE TEAM: team-ai-agent */
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { ref } from 'ember-ref-bucket';
import { defer } from 'rsvp';
//@ts-expect-error
import DefaultAvatars from '@intercom/pulse/lib/default-avatars';

export interface FinIdentityEditorArgs {
  identity: $TSFixMe; // app/models/operator-identity
  identityPreviewImgSrc: string;
  onAvatarImgChange: (imgSrc: string | unknown) => void;
}

export default class FinIdentityEditor extends Component<FinIdentityEditorArgs> {
  @service declare intercomEventService: $TSFixMe;

  @ref('inputElement') inputElement!: HTMLInputElement;
  @tracked fileWasChosen = false;

  constructor(owner: unknown, args: FinIdentityEditorArgs) {
    super(owner, args);
  }

  get isLoading() {
    return !this.args.identity;
  }

  get savedAvatarIsCustom() {
    return this.args.identity.avatarType === 'custom';
  }

  get identityPreview() {
    return {
      avatarType: this.args.identity.avatarType,
      avatarData: {
        avatarShape: this.args.identity.avatarData.avatarShape,
        url: this.args.identityPreviewImgSrc,
      },
    };
  }

  @action
  restoreAvatarToDefault() {
    this.args.identity.avatarType = 'default';
    this.args.identity.set('avatarUpload', null);
    this.args.onAvatarImgChange(DefaultAvatars.fin[128]);
  }

  @action
  openFilePicker() {
    this.inputElement.click();
  }

  @action
  onAvatarHovered() {
    this.intercomEventService.trackAnalyticsEvent({
      action: 'hovered',
      object: 'bot_identity_avatar',
      place: 'overview_personality',
      section: 'ai_agent',
      hasCustomAvatar: this.savedAvatarIsCustom,
    });
  }

  @action
  async setCustomAvatarFromFilePicker(fileList: FileList) {
    if (fileList.length < 1) {
      this.fileWasChosen = false;
      return;
    } else {
      this.fileWasChosen = true;
      let dataUrl = await this._readFileAsDataURL(fileList[0]);
      this.args.identity.avatarType = 'custom';
      this.args.identity.set('avatarUpload', fileList[0]);

      this.args.onAvatarImgChange(dataUrl);

      this._clearFilePicker();
    }
  }

  _readFileAsDataURL(file: File): Promise<unknown> {
    let deferred = defer();
    let reader = new FileReader();
    reader.onload = (e) => {
      if (e.target && typeof e.target.result === 'string') {
        deferred.resolve(e.target.result);
      }
    };
    reader.readAsDataURL(file);
    return deferred.promise;
  }

  _clearFilePicker() {
    if (this.inputElement) {
      this.inputElement.value = '';
    }
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'Operator::Fin::Setup::FinIdentityEditor': typeof FinIdentityEditor;
  }
}
