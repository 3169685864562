/* import __COLOCATED_TEMPLATE__ from './new-help-center-modal.hbs'; */
/* RESPONSIBLE TEAM: team-help-desk-experience */

import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import type NotificationsService from 'embercom/services/notifications-service';
import type IntlService from 'embercom/services/intl';
import { action } from '@ember/object';
import type { HelpCenter, HelpCenterWireFormat } from './types/help-center';
import { taskFor } from 'ember-concurrency-ts';

interface Args {
  isOpen: boolean;
  onClose: () => void;
  onCreateNewHelpCenter: (helpCenter: HelpCenter) => void;
}

interface Signature {
  Args: Args;
  Element: never;
}

export default class NewHelpCenterModal extends Component<Signature> {
  @service declare notificationsService: InstanceType<typeof NotificationsService>;
  @service declare appService: any;
  @service declare helpCenterService: any;
  @service declare intl: IntlService;

  @tracked name = '';
  @tracked identifier = '';

  // should equal VALID_IDENTIFIER from app/services/article_service/commands/site/update.rb
  validIdentifierRegex = /^[a-zA-Z0-9_-]+$/;
  identifierPrefix = 'intercom.help/';

  @action
  handleClose() {
    this.args.onClose();
  }

  @action
  async handleCreate() {
    try {
      let helpCenter = (await taskFor(this.helpCenterService.createHelpCenter).perform({
        name: this.name,
        identifier: this.identifier,
        creatingAdminId: this.appService.app.currentAdmin.id,
      })) as HelpCenterWireFormat;
      this.args.onCreateNewHelpCenter({
        id: parseInt(helpCenter.id, 10),
        name: helpCenter.name,
      });
      this.handleClose();
      this.notificationsService.notifyConfirmation(
        this.intl.t(
          'new-settings.workspace.brands.new-help-center-modal.successfully-created-help-center',
        ),
      );
    } catch (error) {
      this.notificationsService.notifyResponseError(error, {
        default: this.intl.t(
          'new-settings.workspace.brands.new-help-center-modal.failed-to-create-help-center',
        ),
      });
    }
  }

  get isCreating() {
    return taskFor(this.helpCenterService.createHelpCenter).isRunning;
  }

  get canCreate(): boolean {
    return Boolean(this.isReady && !this.isCreating);
  }

  @action
  handleChangeName(event: KeyboardEvent) {
    let input = event.target as HTMLInputElement;
    this.identifier = this.sanitizeIdentifier(input.value);
  }

  // helpers

  get isReady() {
    return this.nameIsValid && this.identifierIsValid;
  }

  get nameIsValid() {
    return Boolean(this.name);
  }

  get identifierIsValid() {
    return this.identifier && this.validIdentifierRegex.test(this.identifier);
  }

  sanitizeIdentifier(name: string) {
    // Taken from app/components/articles/shared/create-help-center-modal.ts
    return name.trim().toLowerCase().split(/\s+/).join('-');
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'NewSettings::Workspace::Brands::NewHelpCenterModal': typeof NewHelpCenterModal;
  }
}
