/* import __COLOCATED_TEMPLATE__ from './editor-content.hbs'; */
/* RESPONSIBLE TEAM: team-proactive-support */
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency-decorators';
import bracketedRelativeTimeAgo from 'embercom/lib/bracketed-relative-time-ago';

export default class NewsItemEditorContentComponent extends Component {
  @service contentEditorService;
  @service store;
  @service appService;

  @tracked messengerSettings = [];

  constructor() {
    super(...arguments);
    this.fetchMessengerSettings.perform();
  }

  get app() {
    return this.appService.app;
  }

  get wentLiveAt() {
    if (this.contentEditorService?.activeRulesetLink?.lastStateChange?.get('createdAt')) {
      return bracketedRelativeTimeAgo(
        this.contentEditorService.activeRulesetLink.lastStateChange.get('createdAt'),
      );
    }
    return null;
  }

  get isReactionsReply() {
    return this.args.newsItem.isReactionsReply;
  }

  get showUnreadBadge() {
    return !this.args.newsItem.localizedNewsItemContents?.firstObject?.deliverSilently;
  }

  @task({ drop: true })
  *fetchMessengerSettings() {
    this.messengerSettings = yield this.store.findRecord('messenger-settings/all', this.app.id);
  }
}
